import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import ReactPlayer from 'react-player';
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { FiX } from "react-icons/fi";

import "../../styles/cursoydiplomado/cursoydiplomado.css";

/* imagenes */
import botonplay from "../../img/cursosydiplomados/videoscursopasado/botonplay.png"


export const Cusospasados = ({ videos }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [cursosPasados, setCursosPasados] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const obtenerCursosPasados = async () => {
    try {
      const response = await axios.get(`${backendURL}/obtener_cursospasados`);
      setCursosPasados(response.data); // Suponiendo que el servidor devuelve directamente los cursos pasados
    } catch (error) {
      console.error("Error al obtener la lista de cursos pasados:", error);
    }
  };

  const handleTogglePopup = (video) => {
    setSelectedVideo(video);
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    obtenerCursosPasados();
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <BiChevronLeft />,
    nextArrow: <BiChevronRight />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
 

  return (
    <div className="contiene-videos-pasados">
      <div className="container cursospasados">
        <div className="titulocursospasados">
          <h1 className="cursospasados titulo">CURSOS PASADOS</h1>
        </div>
        <div className="videos-click">
        <Slider {...settings}>
            {cursosPasados.map((curso, index) => (
              <div className="contenedor-video" key={index}>
                <div className="card video">
                  <div className="img-content-video">
                    <img
                      src={curso.imagen_url}
                      className="w-100 video"
                      alt={`Video ${index + 1}`}
                    />
                  </div>
                  <div className="content-Autoridades">
                    <button
                      className="boton-icono-video-curso"
                      onClick={() => handleTogglePopup(curso.video_url)}
                    >
                      <img
                        src={botonplay}
                        className="play-video w-50"
                        alt="Botón de reproducción"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="contiene-video-popup">
        {showPopup && (
          <div className="popup-admi-videopasado">
            <div className="popup-videopasado">
            <button
              className="close-button-video-cursos"
              onClick={handleClosePopup}
            >
              <FiX className="close-video-curso" />
            </button>
            {/* Contenido del popup */}
            <ReactPlayer
              url={selectedVideo} // Utiliza la URL del video seleccionado
              className="react-player video pasado"
              controls
              loop
              
            />
          </div>
          </div>
        )}
      </div>
    </div>
  );
};