import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/cursoydiplomado/cursoydiplomado.css";

import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

/* imagenes */

import alianza1 from "../../img/cursosydiplomados/alianza1.jpg"
import alianza2 from "../../img/cursosydiplomados/alianza2.jpg"
import alianza3 from "../../img/cursosydiplomados/alianza3.jpg"
import alianza4 from "../../img/cursosydiplomados/alianza4.jpg"
import alianza5 from "../../img/cursosydiplomados/alianza5.jpg"

export default class GaleriaCursos extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      prevArrow: <BiChevronLeft />,
      nextArrow: <BiChevronRight />,
      responsive: [
        
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
  
      
        <div className="container medallas-diplomado-curso">
          <h1 className="galeriacursos-titulo-medalla">NUESTRAS ALIANZAS</h1>
          <div className="row logos-alianza">
            <div className="col  img-alianza">
              <div className="img-alianza">
              <img
                className="medallas-2-medallas-diplomado-curso "
                src={alianza1}
              /></div>
            </div>
            <div className="col c img-alianza">
            <div className="img-alianza">
              <img
                className="medallas-2-medallas-diplomado-curso"
                src={alianza2}
              />
              </div>
            </div>
            <div className="col col img-alianza">
            <div className="img-alianza">
              <img
                className="medallas-2-medallas-diplomado-curso"
                src={alianza3}
              />
              </div>
            </div>
            <div className="col  img-alianza">
            <div className="img-alianza">
              <img
                className="medallas-2-medallas-diplomado-curso"
                src={alianza4}
              />
              </div>
            </div>
            <div className="col  img-alianza">
            <div className="img-alianza">
              <img
                className="medallas-2-medallas-diplomado-curso "
                src={alianza5}
              />
              </div>
            </div>
          </div>
        </div>
      
    );
  }
}
