import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import "../../styles/escuela/escuela.css";

import { GrMailOption } from "react-icons/gr";
import bannerdocente from "../../img/nosotros escuela/banner-docente-ecole.png";
import bannermovil from "../../img/nosotros escuela/banner-docente-movil.png";
/* imagenes */


export const Autoridades = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [autoridades, setAutoridades] = useState([]);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado de isMobileView en función del ancho de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Cambia a "true" si el ancho es menor o igual a 700px
    };

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Llama a handleResize() una vez al cargar la página para establecer el estado inicial
    handleResize();

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    obtenerAutoridades();
  }, []);
  
  const obtenerAutoridades = async () => {
    try {
      const response = await axios.get(`${backendURL}/obtener_autoridades`);
    
      setAutoridades(response.data);
    } catch (error) {
      console.error("Error al obtener las autoridades", error);
    }
  };


    const redirigirCorreo = (correo) => {
      window.location.href = `mailto:${correo}`;
    };
  
  
  
    const settings = {
      dots: false,
      infinite: true,
      initialSlide: 0,  // Asegúrate de que esté configurado correctamente
      rtl: false, 
      slidesToShow: 3,
      slidesToScroll: 3,
      prevArrow: <BiChevronLeft />,
      nextArrow: <BiChevronRight />,
      
      
      
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            initialSlide: 0,
            rtl: false, 
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    }

    return (
      <>
      <div className=" container autoridades">
        <h1 className="titulo-autoridades">AUTORIDADES INSTITUCIONALES</h1>

        <div className="autoridades-slider">
          <Slider {...settings}>
          {autoridades.map((autoridad) => (
            <div key={autoridad.id}>
              <div className="card Autoridades">
                <div className="card-container Autoridades">
                  <div className="card Autoridades">
                    <div className="img-content-Autoridades">
                      <img src={`${backendURL}/imagen_autoridad/${autoridad.imagenautoridad}`} className="w-100 autoridades" alt={`Imagen de ${autoridad.nombreautoridad}`} />
                    </div>
                    <div className="content-Autoridades">
                      <p className="heading-Autoridades">
                        <button className="boton-icono-autoridades" onClick={() => redirigirCorreo(autoridad.correo)}>
                          <GrMailOption className="icono-mail-autoridades" />
                        </button>

                      </p>
                      <h5 className="correo-escuela">CORREO</h5>
                    </div>
                  </div>
                </div>
                <div className="texto-info-autoridades">
                  <h1 className="card-title-autoridades">{autoridad.nombreautoridad}</h1>
                  <h2 className="card-subtitle-autoridades">{autoridad.cargo}</h2>
                </div>
              </div>
            </div>
          ))}
          </Slider>
        </div>

      </div>
      <div className="container-fluid banner docente">
      <div className="docentes banner 2">
          {/* banner autoridades */}{" "}
          <div className="docentes">
            {" "}
            <Link to="/docentes">
              <button className="bannerdocente" onClick={() => window.scrollTo(0, 0)}>
              <img
                src={isMobileView ? bannermovil : bannerdocente}
                className="bannerdocentes w-100"
                
              /></button>
            </Link>
          </div>
        </div></div>
      </>
    );
  }

