import React from "react";

/* imagen */
import separadorpasantia from "../../img/especialidad/separadorpasantia.png";

export const Programapasantia = () => {
  return (
    <div className=" container programa pasantia">
      <h1 className="programa pasantia titulo principal"> Programa</h1>
      <h1 className="programa pasantia titulo principal1">
        PASANTÍA EN FRANCIA
      </h1>
      <div className="imagen-linea-carrera">
      <img src={separadorpasantia} className="w-20 separadorpasantia" />
      </div>
      <div className="cuerpo info programa">
        <h5 className="programa pasantia info">
          Programa de especialización exclusivo para los egresados de ECOLE.{" "}
        </h5>
        <h5 className="programa pasantia info">
          <strong>
            {" "}
            El programa consta de 2 meses de clases en Chile y concluye con una
            práctica de 6 meses en uno de los 25 restaurantes con convenio
            exclusivo de los Maîtres Cuisiniers de France,
          </strong>{" "}
          organización que apadrina a nuestra escuela desde sus inicios. En el
          caso de los que se especializan en Boulanger, concluyen con una
          pasantía en un establecimiento de la Asociación de Panaderos de
          Francia, respaldada por la Boulangerie-Pâtisserie de Christian Vabret.
          Durante la pasantía en Francia, el establecimiento que los recibe les
          da alojamiento, comida y sueldo de practicante.
        </h5>
        <br />
        <h5 className="programa pasantia info">
          <strong>
            Existe la opción de convalidar una de las dos prácticas requeridas
            durante tus estudios (Para los estudiantes que deben cumplir 3
            prácticas, convalidan 2 de ellas).
          </strong>
        </h5>
        <br />
        <h5 className="programa pasantia info">
          Brindamos apoyo en la gestión de visa “estudiante de larga estadía” ,
          junto con el permiso de estadía como “alumno en práctica” por el
          organismo correspondiente en Francia y el Consultado de Francia en
          Chile.
        </h5>
      </div>
    </div>
  );
};
