import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import axios from "axios"; 
import "../../../styles/estructura/estruccursos.css";


export const ArticuloEstructuraCursos = ({ curso }) => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const { id } = useParams(); // Mover la declaración de id aquí

  const [imagenes, setImagenes] = useState([]);

  useEffect(() => {
    const obtenerImagenesCurso = async () => {
      try {
        const response = await axios.get(`${backendURL}/obtener_imagenes_curso`, {
          params: { curso_id: id },
        });
        setImagenes(response.data.imagenes_info);
      } catch (error) {
        console.log(error);
      }
    };

    if (id) {
      obtenerImagenesCurso();
    }
  }, [id]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    prevArrow: <FaAngleLeft />,
    nextArrow: <FaAngleRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Si no hay un ID o no hay imágenes, no muestra nada
  if (!id || imagenes.length === 0) {
    return null;
  }

  // Si hay un ID y hay imágenes, muestra el título y el Slider
  return (
    <div className="container-xxl galeria-cursos-precio">
      <div className="container titulo-galeria-cursos-precio">
        <h1 className="titulo-galeria-cursos-precio">
          Descubre el trabajo que nuestros estudiantes han creado en cursos
          anteriores. ¡Mira nuestra galería de fotos!
        </h1>
      </div>
      <div>
        <Slider {...settings}>
          {imagenes.map((imagen, index) => (
            <div key={index}>
              <div className="card carrera">
                <img
                  src={imagen.url}
                  className="card-img-top carrera"
                  alt={`Imagen ${index}`}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
