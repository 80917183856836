import React, { useState, useEffect } from "react";
import axios from "axios";
import docu from "../../img/unidaddegenero/UG-ecole.docx"



export const DenunciaUnidad = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [mostrarInputArchivo, setMostrarInputArchivo] = useState(false);
  const [formData, setFormData] = useState({
    persona_denuncia: "",
    user_name: "",
    user_rut: "",
    mensaje: "",
  });

  const [archivoAdjunto, setArchivoAdjunto] = useState(null);
  const [formularioEnviado, setFormularioEnviado] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga
  

  const toggleInputArchivo = () => {
    setMostrarInputArchivo(!mostrarInputArchivo);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setArchivoAdjunto(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("persona_denuncia", formData.persona_denuncia);
    formDataToSend.append("user_name", formData.user_name);
    formDataToSend.append("user_rut", formData.user_rut);
    formDataToSend.append("mensaje", formData.mensaje);
    if (archivoAdjunto) {
      formDataToSend.append("archivo_adjunto", archivoAdjunto);
    }

    try {
      setIsLoading(true);
      const response = await axios.post(`${backendURL}/enviarformdenuncia`, formDataToSend);
      console.log(response.data);
      setFormularioEnviado(true); // Formulario enviado con éxito

      // Actualiza la página después de 2 segundos (o el tiempo que desees)
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Cambia 2000 a la cantidad de milisegundos que desees esperar antes de actualizar la página
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
    } finally {
      setIsLoading(false); // Desactivar la carga después de la solicitud
    }
  };

 


  return (
    <div className="container denuncias">
      <h1 className="titulo-denuncias">¿DESEAS HACER UNA DENUNCIA?</h1>
      <p className="pdf unidad">
      En el caso que hayas sido víctima o testigo de acoso sexual, violencia sexual y/o violencia de
      género, puedes realizar una denuncia al correo <strong>unidaddegenero@escuelaculinaria.cl</strong> adjuntando el formulario que se encuentra a continuación.
      </p>

      <section className="buton-denuncia">
        <a href={docu} download>
      <button  className="button-dercarga-unidad" type="button" >
          
                    <span className="button__icon-dercarga-unidad">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 35 35"
                        id="bdd05811-e15d-428c-bb53-8661459f9307"
                        data-name="Layer 2"
                        class="svg"
                      >
                        <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
                        <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
                        <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
                      </svg>
                    </span>
                    <span className="button__text-dercarga-unidad">
            <p className="titulo-boton-descarga-unidad">Descargar Formulario</p>
          </span>
                  </button> </a>
      </section>
      <section className="info-denuncia-unidad">
        <p className="info-denuncia">  <strong>En el caso de realizar una denuncia deber proporcionar la siguiente información:</strong></p>
       <ol className="unidad">
        <li className="info-denuncias-unidad">Los nombres, apellidos y RUT del denunciante y/o la presunta víctima, su correo electrónico, el rol o
cargo que ocupa en la Escuela.</li>
<li  className="info-denuncias-unidad">Una exposición detallada de los hechos que la motivan, en lo posible indicando fecha, horas y lugares
en que estima ocurridos los hechos denunciados, el medio por el cual habrían sido ejecutados y el
nombre de las personas que los hayan presenciado o que hayan tomado conocimiento de los mismos
por otros medios, en caso de existir.</li>
<li  className="info-denuncias-unidad">El nombre y todos los otros datos que conozca del presunto acosador, tales como descripción física,
vestuario etc.; y, la fecha y firma del denunciante.</li>
<li  className="info-denuncias-unidad">Hechos relevantes</li>
       </ol>
      
      
      </section>
{/* 
      <form  className="formulario-denuncia-completa"onSubmit={handleSubmit}>
        <div className="formulario-denuncia">
          <div className="form-group denuncia">
            <select
              na
              name="persona_denuncia"
              className="form-control denuncia"
              defaultValue=""
              required
              onChange={handleChange}
            >
              <option value="" disabled hidden>
                Persona quien denuncia
                <div>
                  <img
                    src={flecha}
                    className="w-100 flechadenuncia"
                    alt="flecha"
                  />
                </div>
              </option>
              <option
                className="seleccion-denuncia"
                value=" Persona afectada (yo)"
              >
                Persona afectada (yo)
              </option>
              <option className="seleccion-denuncia" value=" Tercera persona">
                Tercera persona
              </option>
            </select>
          </div>
        </div>
        <div className="form-denunucia">
          <h1 className="titulo-form-denuncia"> Datos del denunciante</h1>

          <div className="estructura-denuncia">
            <div className="row estructura-denuncia ">
              <div className="col-8  estructura-denuncia">
                <input
                  type="text"
                  name="user_name"
                  className="input-denuncia"
                  placeholder="Nombre Completo"
                  value={formData.user_name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-4 estructura-denuncia">
                <input
                  type="text"
                  name="user_rut"
                  className="input-denuncia1"
                  placeholder="Rut documento"
                  value={formData.user_rut}
                  onChange={handleChange}
                />
              </div>
            </div>
            <textarea
              className="controls denuncia"
              name="mensaje"
              id="mensaje"
              placeholder="Ingrese su denuncia"
              value={formData.mensaje}
              onChange={handleChange}
            />
            <div className="archivo-denuncia">
            <label className="tituloarchivo" onClick={toggleInputArchivo}>
              Adjuntar evidencia PDF
              <div className="clip-denuncia">
                <img src={clip} className="w-100 clip" alt="clip" />
              </div>
            </label>
            {mostrarInputArchivo && (
              <input
                type="file"
                name="archivo"
                className="form-control-file-denuncia"
                onChange={handleFileChange}
              />
            )}</div>
            <div className="boton-enviar-denuncia">
              <input className="botons-denuncia" type="submit" value="ENVIAR" />
            </div>
          </div>
        </div>
      </form>
      {isLoading && (
                      <div className="popup-admision-navbar">
          <div className="loading-spinner">
          <svg className="pl" width="240" height="240" viewBox="0 0 240 240">
	<circle className="pl__ring pl__ring--a" cx="120" cy="120" r="105" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 660" stroke-dashoffset="-330" stroke-linecap="round"></circle>
	<circle className="pl__ring pl__ring--b" cx="120" cy="120" r="35" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 220" stroke-dashoffset="-110" stroke-linecap="round"></circle>
	<circle className="pl__ring pl__ring--c" cx="85" cy="120" r="70" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 440" stroke-linecap="round"></circle>
	<circle className="pl__ring pl__ring--d" cx="155" cy="120" r="70" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 440" stroke-linecap="round"></circle>
</svg></div>
          </div>
        )}
 */}
      <span className="info-de">
       
          <strong className="infode">Si la denuncia se realiza por una tercera persona, el procedimiento de investigación se
iniciará únicamente si la presunta víctima lo consiente.</strong>
</span>
<span className="info-des">
       
<strong className="infode">Todo el procedimiento se desarrollará bajo estricta confidencialidad.</strong>
      
      </span>
    </div>
  );
};
