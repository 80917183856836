import React, { useState } from 'react';
import axios from 'axios';


export const Botoncrearautoridad = ({ cerrarVentana }) => {
    const [nombre, setNombre] = useState('');
    const [imagen, setImagen] = useState(null);
    const [cargo, setCargo] = useState('');
    const [correo, setCorreo] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      const formData = new FormData();
      formData.append('nombreautoridad', nombre);
      formData.append('imagenautoridad', imagen);
      formData.append('cargo', cargo);
      formData.append('correo', correo);
  
      try {
        const response = await axios.post(`${backendURL}/guardar_autoridad`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        // Manejar la respuesta según tus necesidades
        console.log(response.data);

         // Si el guardado fue exitoso, cierra la ventana
      if (response.data.message === 'autoridad guardado correctamente') {
        cerrarVentana();
      }
  
      } catch (error) {
        console.error('Error al guardar la autoridad', error);
      }
    };

  return (
    <>
      <div className="titulo-admi-info">
        <h2>Crear Autoridad</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen"
            onChange={(e) => setImagen(e.target.files[0])}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="nombre">
            Nombre:
          </label>
          <input
            className="input-admi"
            type="text"
            id="nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="descripcion">Cargo:</label>
          <input
            className="input-admi"
            type="text"
            id="cargo"
            value={cargo}
            onChange={(e) => setCargo(e.target.value)}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Correo:
          </label>
          <input
            className="input-admi"
            type="text"
            id="correo"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};

