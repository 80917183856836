import React from "react";
import linea from "../../img/docentes/linea.png";

export const Bienvenidaegresado = () => {
  return (
    <div className="container bienvenidaegresado">
      <div className="row egresados">
        <div className="col egresados-bienvenida">
          <h1 className="titulo1egresado"> ¡BIENVENIDOS!</h1>
          <div className="imagen-linea-egresado"> <img src={linea} className="w-20 linea-separdor-egresado" />
        </div> </div>
        <div className="col egresados-bienvenida">
          <h5 className="titulo2egresado">
            {" "}
            Además de ser una escuela de cocina, somos una escuela de
            emprendedores gastronómicos. Conoce a algunos de nuestros egresados
            que hoy son propietarios de restaurantes, pastelerías y negocios
            gastronómicos de alto nivel.
          </h5>
        </div>
      </div>
    </div>
  );
};
