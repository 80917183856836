import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";

export const Botoneditarportadasecundaria = ({
  cerrarVentana,
  portadaId,
  actualizarPortadaEnLista
}) => {
    const [portadaData, setPortadaData] = useState({
        imagen_segundo: null,
        url_segundo: ''
      });
      const backendURL = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    console.log('Portada ID:', portadaId);
    obtenerPortada();
  }, [portadaId]);

  const obtenerPortada = () => {
    axios
      .get(`${backendURL}/obtener_portadadoshome/${portadaId}`)
      .then((response) => {
        setPortadaData(response.data.portada);
      })
      .catch((error) => {
        console.error('Error al obtener los datos de la portada:', error);
      });
  };

  const handleGuardar = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('imagen_segundo', portadaData.imagen_segundo);
    formData.append('url_segundo', portadaData.url_segundo);

    try {
        const response = await axios.put(`${backendURL}/editar_portadadoshome/${portadaId}`, formData);
  
        console.log(response.data);
        cerrarVentana();
  
        // Actualiza la portada en la lista usando la función del componente padre
        actualizarPortadaEnLista(portadaId, portadaData);
  
        // Vacía el campo de selección de archivo para evitar mostrar la imagen anterior en el próximo formulario
        document.getElementById('imagen_segundo').value = '';
      } catch (error) {
        console.error('Error al actualizar la portada:', error);
      }
    };
  
    const handleChange = (event) => {
      if (event.target.id === 'imagen_segundo') {
        setPortadaData({
          ...portadaData,
          imagen_segundo: event.target.files[0]
        });
      } else {
        setPortadaData({
          ...portadaData,
          [event.target.id]: event.target.value
        });
      }
    };

  return (
    <>
      <div className="titulo-admi-info">
        <h2>Editar Portada página curso y diplomado</h2>
      </div>
      <form onSubmit={handleGuardar}>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen_segundo">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen_segundo" // Cambiar el id a "imagen_segundo"
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url_segundo">
            URL:
          </label>
          <input
            className="input-admi"
            type="text"
            id="url_segundo" // Cambiar el id a "url_segundo"
            value={portadaData.url_segundo}
            onChange={handleChange}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};