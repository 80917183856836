import React from "react";
import { Link } from "react-router-dom";

export const Articulolist = ({ articles }) => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  return (
    <div className="article list">
      <div className="row row-cols-1 row-cols-md-4 g-4 recetas">
        {articles.map((article) => (
          <div className="col-md-8 col-lg-6 col-xl-4 col-xll-4 recetas" key={article.id}>
            <Link
              to={article.categoria === "receta" ? `/detalle/estructurablog/${article.id}` : `/detalle/estructuratips/${article.id}`}
              className={`link-articulo ${article.categoria === "receta" ? "link-receta" : "link-tips"}`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="receta-container">
                <div className="img-container">
                  <img className="w-100 imagentips" src={`${backendURL}/imagen_receta/${article.imagen}`} alt={article.titulo} />
                </div>
                <div className="receta-body">
                <p className={`categoria ${article.categoria === "receta" ? "categoria-receta" : "categoria-tips"}`}>
                    {article.categoria}
                  </p>
                  <h2 className="texto-tips-titulo">{article.titulo}</h2>
                  <h4 className="texto-tips-info">{article.descripcion_principal}</h4>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};