import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TfiClose } from "react-icons/tfi";
import { FormularioAdmision } from "../formulario-admision";
import "../../styles/admision/admision.css";

import BotonCorreo from "./botoncorreoinfo";

/* imagenes*/
import icono1 from "../../img/admision/iconorequisito1.png";
import icono2 from "../../img/admision/iconorequisito2.png";
import icono3 from "../../img/admision/iconorequisito3.png";

export const Requisitos = () => {
  const [isRequisitosOpen, setIsRequisitosOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  
  const cerrarVentana = () => {
    setShowForm(false);
  };
  
  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const toggleRequisitos = () => {
    setIsRequisitosOpen(!isRequisitosOpen);
  };

  return (
    <>
      <div className="container Banner-requisito">
        <div className="titulo-requisitos"></div>

        <div className="Requisito">
          <div
            className="accordion accordion-flush-requisto"
            id="accordionFlushExample"
          >
            <div className="accordion-item-requisito">
              <h2 className="accordion-header-requisito" id="flush-headingOne">
                <button
                  className={`accordion-button ${
                    isRequisitosOpen ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={toggleRequisitos}
                  aria-expanded={isRequisitosOpen ? "true" : "false"}
                  aria-controls="flush-collapseOne-requisito"
                >
                  <h1 className="titulo-requisito"> REQUISITOS DE INGRESO</h1>
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className={`accordion-collapse requisito collapse ${
                  isRequisitosOpen ? "show" : ""
                }`}
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body-requisitos-info">
                  <div className="requisitos-info">
                    <div className="requisito-info-collapse">
                      <ul className="requisito-info-collapse">
                        <li className="requisito-info-collapse">
                          Evaluacion Diagnóstica
                        </li>
                        <li className="requisito-info-collapse">
                          Licencia de Enseñanza Media
                        </li>
                        <li className="requisito-info-collapse">
                          La ﬁrma conjunta con tu tutor ﬁnanciero del Contrato
                          anual de Servicios.
                        </li>
                        <li className="requisito-info-collapse">
                          Cédula de identidad vigente del estudiante y tutor
                          ﬁnanciero.
                        </li>
                        <li className="requisito-info-collapse">
                          Comprobante de domicilio, solo si el tutor ﬁnanciero
                          cancela en cuotas por medio de cheques.
                        </li>
                        <li className="requisito-info-collapse">
                          Certiﬁcado de Isapre o Fonasa a la cual pertenece el
                          estudiante.
                        </li>
                        <li className="requisito-importante">
                          Los estudiantes Ecole están cubiertos por un Seguro de
                          Accidentes durante toda la carrera.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="container contenedor-inforacion-admision">
          <div className="row requisito-icono">
            <div className="col  requisito-infoicono">
              <div className="desarrollo-inforequisito-icono">
                <div className="imagen-requisito icono">
                  <img src={icono1} className="w-100 icono-requisito" />
                </div>
                <div className="informacion-requisito-icono">
                  <h4 className="subtitulo-requisito-icono">
                    {" "}
                    Para información sobre: malla, precios y formas de pago
                  </h4>
                  
                  <Link to="/admision" onClick={() => window.scrollTo(0, 0)}>
                    {" "}
                    <button className="botoncarouselunohome"   onClick={toggleForm}>
                      Enviar Formulario
                    </button>
                  </Link>
                  {showForm && ( 
                         <div className="popups-admision">
                          
            <button
              className="close-admision"
              onClick={cerrarVentana}
              aria-label="Cerrar"
            >
              
                <TfiClose />
              
            </button>
            <FormularioAdmision/>
                         
                        
                         </div>
                      )}
                </div>
              </div>
            </div>
            
            <div className="col   requisito-infoicono">
              <div className="desarrollo-inforequisito-icono">
                <div className="imagen-requisito icono">
                  <img src={icono3} className="w-100 icono-requisito" />
                </div>
                <div className="informacion-requisito-icono">
                  <h4 className="subtitulo-requisito-icono">
                    Comunícate con nosotros vía WhatsApp{" "}
                  </h4>
                  <a
                    href="https://wa.me/56963046360?text=Me%20gustaría%20saber%20por%20información%20general"
                    className="whatsapprequisito"
                    target="_blank"
                  >
                    <button className="botoncarouselunohome">
                      Enviar Mensaje
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row requisito-icono-estudiante">
          <div className="col  requisito-infoicono">
              <div className="desarrollo-inforequisito-icono">
                <div className="imagen-requisito icono">
                  <img src={icono2} className="w-90 icono-requisito" />
                </div>
                <div className="informacion-requisito-icono">
                  <h4 className="subtitulo-requisito-icono">
                    {" "}
                    Si  eres estudiante  vigente  y quieres renovar continua con tu Postulación avanzada de admisión
                  </h4>
                  <a
                    href="https://ecole.umas.cl/MNPAA/"
                    className="whatsapprequisito"
                    target="_blank"
                  >
                    <button className="botoncarouselunohome">
                    Clic Aquí
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col  requisito-infoicono">
              <div className="desarrollo-inforequisito-icono">
                <div className="imagen-requisito icono">
                  <img src={icono2} className="w-90 icono-requisito" />
                </div>
                <div className="informacion-requisito-icono">
                  <h4 className="subtitulo-requisito-icono">
                    {" "}
                    Si eres estudiante nuevo y te encuentras en Postulación avanzada de admisión
                  </h4>
                  <a
                    href="https://ecole.umas.cl/MNP/"
                    className="whatsapprequisito"
                    target="_blank"
                  >
                    <button className="botoncarouselunohome">
                    Clic Aquí
                    </button>
                  </a>
                </div>
              </div>
            </div>

          </div>
          <BotonCorreo />
        </section>
      </div>
    </>
  );
};
