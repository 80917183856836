import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop";
import injectContext from "./store/appContext";
import { CartProvider } from "./component/CartContext";

/* importar los componentes  */

import { Navbar } from "./component/navbar";
import { Footer } from "./component/footer";
import { Home } from "./pages/Home";
import { Admision } from "./pages/Admision";
import { Escuela } from "./pages/escuela";
import { Carreras } from "./pages/Carreras";
import { Especializacionenfrancia } from "./pages/Especializacion-en-francia";
import { Cursos } from "./pages/Cursos-y-diplomados";
import { EstructuraCursos } from "./component/estructura cursos y blog/estruc-cursos";
import { Viewnoticia } from "./component/estructura cursos y blog/estructura-noticias";
import { Docentes } from "./pages/Docentes";
import { Egresados } from "./pages/Egresados";
import { Unidaddegenero } from "./pages/Unidad-de-genero";
import { Blog } from "./pages/Blog";
import { EstructuraBlog } from "./component/estructura cursos y blog/estruc-blog";
import { EstructuraTips } from "./component/estructura cursos y blog/estruc-tips";
import { Noticias } from "./pages/noticias";
import { Loginhome } from "./pages/Login";
import { Wtssp } from "./component/scrollwtssp";
import { Botonadmi } from "./component/botonadmision";
import { AdminstradorEcole } from "./pages/Administrador";
import { CarritoCompraDespliegue } from "./component/carrito-compra";
import { Pago } from "./component/pago";
import { Beneficios } from "./pages/beneficios-estudiante";
import { Botonadmimovil } from "./component/movil-admision";
import { Resultado } from "./pages/resultado";
import { ResultadoProvider } from "./component/useRespuesta";


const Layout = () => {
  
  const basename = process.env.BASENAME || "";
  const [showNavbarFooter, setShowNavbarFooter] = useState(true);
  const [carritoVisible, setCarritoVisible] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  return (
    <div>
        <ResultadoProvider>
       <CartProvider>
      <BrowserRouter basename={basename}>
        <ScrollToTop><CarritoCompraDespliegue/>
        {showNavbarFooter && <Navbar setCarritoVisible={setCarritoVisible} />}
         
          <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<Admision />} path="/admision" />
          <Route element={<Beneficios />} path="/beneficios-estudiantes" />
          
          <Route element={<Escuela />} path="/nosotros" />
          <Route element={<Carreras />} path="/carreras" />
          <Route
              element={<Especializacionenfrancia />}
              path="/especializacion-en-francia"
            />
              <Route element={<Cursos />} path="/cursos" />
              <Route
              path="/detalle/estructuracursos/:id"
              element={<EstructuraCursos />}
            />
             <Route element={<Docentes />} path="/docentes" />
             <Route element={<Egresados />} path="/egresados" />
             <Route element={<Unidaddegenero />} path="/unidad-de-genero" />
             <Route element={<Blog />} path="/blog" />
             <Route
              path="/detalle/estructurablog/:id"
              element={<EstructuraBlog />}
            />
            <Route
              path="/detalle/estructuratips/:id"
              element={<EstructuraTips />}
            />
             <Route element={<Noticias />} path="/noticias" />
             <Route
              path="/detalle/estructuranoticia/:id"
              element={<Viewnoticia/>}
            />
             <Route
              element={<Loginhome setShowNavbarFooter={setShowNavbarFooter} />}
              path="/login"
            />
             <Route element={<AdminstradorEcole setShowNavbarFooter={setShowNavbarFooter} />} path="/administrador" />
             <Route element={<Pago  setShowNavbarFooter={setShowNavbarFooter} />} path="/pago" />
             <Route element={<Resultado  setShowNavbarFooter={setShowNavbarFooter} />} path="/resultado" />
            <Route element={<h1>Not found!</h1>} />
          </Routes>
          {showNavbarFooter && <Botonadmimovil/>}
          {showNavbarFooter && <Botonadmi/>}
          {showNavbarFooter && <Wtssp /> }
          {showNavbarFooter && <Footer />}
        </ScrollToTop>
      </BrowserRouter>
      </CartProvider>
      </ResultadoProvider>
    </div>
  );
};

export default injectContext(Layout);
