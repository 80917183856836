import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
/* imagenes */
import foto from "../../img/especialidad/fondo-testimonio.png";


export const TestimonioEspecialidad = () => {
  const [testimonios, setTestimonios] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  
  useEffect(() => {
    // Fetch testimonios from the backend API
    axios.get(`${backendURL}/obtener_testiespecialidades`)
      .then(response => {
        setTestimonios(response.data);
      })
      .catch(error => {
        console.error("Error fetching testimonios:", error);
      });
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    prevArrow: <BsChevronLeft />,
    nextArrow: <BsChevronRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <div
      className="comentariospasantia"
      style={{
        backgroundImage: `url(${foto})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {" "}
      <h1 className="titulo-comentariopasantia">
        Testimonio de nuestros Estudiantes
      </h1>
      <div className=" container comentarios pasantia">
        <Slider {...settings}>
        {testimonios.map(testimonio => (
    <div key={testimonio.id}>
      <div className="row row-cols-1 row-cols-md-2 g-4 comentariopasantia">
        <div className="col comentariopasantiauno">
          <div className="cardcomentariopasantia">
            <div className="img comentariopasantia">
              <img
                src={testimonio.imagen_url}
                className="w-100 comentariopasantia"
                alt="..."
              />
            </div>
          </div>
        </div>{" "}
        <div className="col comentariopasantia">
          <div className="cardcomentariopasantia">
            <div className="comentariopasantia info">
              <h2 className="card-text comentariopasantia">
              <DangerouslySetHTMLContent html={testimonio.descripcion}/>
              </h2>
            </div>
          </div>
          <h3 className="card-titulo comentariopasantia">
            {testimonio.nombre_estudiante} / Estudiante Ecole
          </h3>
        </div>
      </div>
    </div>
  ))}
        </Slider>
      </div>
    </div>
  );
};
