import React, { useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const Botoncreartestimonioespecialidad = ({ onClose }) => {
  const [nombreEstudiante, setNombreEstudiante] = useState("");
  const [imagen, setImagen] = useState(null);
  const [descripcionEstudiante, setDescripcionEstudiante] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  

  const handleGuardar = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nombre_estudiante", nombreEstudiante);
    formData.append("imagen", imagen);
    formData.append("descripcion", descripcionEstudiante);
    

    try {
      const response = await axios.post(`${backendURL}/guardar_testiespecialidad`, formData);
      console.log(response.data);
      onClose();
      // Add any additional logic you want after successful creation
    } catch (error) {
      console.error(error);
      // Handle error cases
    }
  };

  const handleNombreChange = (e) => {
    setNombreEstudiante(e.target.value);
  };

  const handleImagenChange = (e) => {
    setImagen(e.target.files[0]);
  };

  const handleDescripcionChange = (value) => {
    setDescripcionEstudiante(value);
  };

 

  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  return (
    <>
      <button className="close-button-admi" onClick={onClose}>
        X
      </button>
      <div className="titulo-admi-info">
        <h2>Crear Testimonio Estudiante</h2>
      </div>
      <form onSubmit={handleGuardar}>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Nombre Estudiante:
          </label>
          <input
            className="input-admi"
            type="text"
            value={nombreEstudiante}
            onChange={handleNombreChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            iid="imagen"
            onChange={handleImagenChange}
          />
        </div>

        <div>
          <label className="titulo-input-admi" htmlFor="descripcionCurso">
            Descripción estudiante:
          </label>
        
          <ReactQuill
            modules={module}
            theme="snow"
            className="input-admi"
            id="descripcionCurso"
            value={descripcionEstudiante}
            onChange={handleDescripcionChange}
          />
        </div>
        
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};
