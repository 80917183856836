import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/administrador/admi.css";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Botoncrearchef } from "./componentes/cursoydiplomado/chef/boton-crear-chef";
import { EditarBotonCrearChef } from "./componentes/cursoydiplomado/chef/editar-crear-chef";

export const Admichefcursoydiplomado = () => {
  const [mostrarPopup, setMostrarPopup] = useState(false);
  const [chefs, setChefs] = useState([]);
  const [chefIdSeleccionado, setChefIdSeleccionado] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const cerrarVentana = () => {
    setMostrarPopup(false);
    setChefIdSeleccionado(null);
  };

  useEffect(() => {
    obtenerChefs();
  }, [currentPage]);

  const obtenerChefs = () => {
    axios
      .get(`${backendURL}/obtener_chefs`)
      .then((response) => {
        const totalChefs = response.data;
        
      
      // Imprimir todos los chefs en la consola
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const chefsPaginados = totalChefs.slice(startIndex, endIndex);
        setChefs(chefsPaginados);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const agregarChef = (nuevoChef) => {
    setChefs((prevChefs) => [...prevChefs, nuevoChef]);
  };

  const eliminarChef = (chefId) => {
    axios
      .delete(`${backendURL}/eliminar_chef/${chefId}`)
      .then((response) => {
        console.log(response.data);
        setChefs((prevChefs) =>
          prevChefs.filter((chef) => chef.id !== chefId)
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const abrirPopupEditar = (chefId) => {
    setChefIdSeleccionado(chefId);
    setMostrarPopup(true);
  };

  const editarChef = (chefId, chefData) => {
    setChefs((prevChefs) => {
      return prevChefs.map((chef) => {
        if (chef.id === chefId) {
          return {
            ...chef,
            ...chefData,
          };
        }
        return chef;
      });
    });
  };

  const totalPages = Math.ceil(chefs.length / itemsPerPage);

  const cambiarPagina = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <section>
        <h1 className="titulo-info-componenete-admi">Chef Cursos y Diplomado</h1>
      </section>
      <section className="boton-crear-chef-cursosydiplomado">
        <button className="boton-crear" onClick={() => setMostrarPopup(true)}>
          Crear
        </button>
        {mostrarPopup && !chefIdSeleccionado && (
          <div className="popup-admi">
            <div className="popup-content-admi">
              <button
                className="close-button-admi"
                onClick={() => cerrarVentana()}
              >
                X
              </button>
              <Botoncrearchef
                agregarChef={agregarChef}
                cerrarVentana={cerrarVentana}
              />
            </div>
          </div>
        )}
        {mostrarPopup && chefIdSeleccionado && (
          <div className="popup-admi">
            <div className="popup-content-admi">
              <button
                className="close-button-admi"
                onClick={() => cerrarVentana()}
              >
                X
              </button>
              <EditarBotonCrearChef
                cerrarVentana={cerrarVentana}
                chefId={chefIdSeleccionado}
                editarChef={editarChef}
              />
            </div>
          </div>
        )}
      </section>
      <section className="lista-chef-cursosydiplomado">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Nombre del Chef</th>
              <th className="titulobd">Imagen</th>
              <th className="titulobd">Descripción</th>
              <th className="titulobd">Url</th>
              <th className="titulobd">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {chefs.map((chef) => (
              <tr className="info-bd" key={chef.id}>
                <td className="info-bd">{chef.nombre}</td>
                <td className="info-bd">
                  <div className="imagen-info-admi">
                    <img
                      className="imagen-info-admi"
                      src={`${backendURL}/imagen_chef/${chef.imagen}`}
                      alt="Chef"
                    />
                  </div>
                </td>
                <td className="info-bd">{chef.descripcion}</td>
                <td className="info-bd">{chef.url}</td>
                <td className="edicion">
                  <button
                    className="boton-editar"
                    onClick={() => abrirPopupEditar(chef.id)}
                  >
                    <BiEdit />
                  </button>
                  <button
                    className="boton-eliminar"
                    onClick={() => eliminarChef(chef.id)}
                  >
                    <BiTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button
                className="page-link"
                onClick={() => cambiarPagina(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {[...Array(totalPages)].map((_, index) => (
              <li
                className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}
                key={index + 1}
              >
                <button
                  className="page-link"
                  onClick={() => cambiarPagina(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
            >
              <button
                className="page-link"
                onClick={() => cambiarPagina(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </section>
    </div>
  );
};
