import React from "react";

/* imagenes */
import imagen from "../../img/unidaddegenero/carrera8.png";

export const Bienvenidaunidaddegenero = () => {
  return (
    <div className="container bienvenida unidad">
      <div className=" titulo de bienvenida unidad">
        <h1 className="titulo bienvenida unidad">
          UNIDAD DE GÉNERO Y EQUIDAD
          
        </h1>
    
        <h3 className="subtitulo bienvenida unidad">
          Estamos comprometidos con la erradicación total de la violencia y la
          discriminación, razón por la cual nace la "Política Integral contra el
          Acoso Sexual, Violencia y Discriminación por Género", donde plasmamos
          nuestro compromiso con el desarrollo de ambientes de respeto a la
          integridad, libertad y dignidad de todas las personas que conforman
          nuestra comunidad.
        </h3>
        <h3 className="subtitulo bienvenida unidad">
          {" "}
          Esta política, elaborada a través de un procedimiento participativo,
          establece los lineamientos para prevenir, atender, investigar,
          sancionar y erradicar el acoso sexual y la violencia de género.
        </h3>
      </div>
     
    </div>
  );
};
