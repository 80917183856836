import React, { useState } from "react";
import axios from "axios";


export const BotoncrearAreas = ({ onClose }) => {
    const [imagen, setImagen] = useState(null);
    const [imagenresponsive, setImagenresponsive] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
   
  
    const handleGuardarArea = async () => {
      try {
        const formData = new FormData();
        formData.append('imagen', imagen);
        formData.append('imagen_responsive', imagenresponsive);
  
        const response = await axios.post(`${backendURL}/guardar_area`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
  
    // Si la receta se guardó exitosamente, cierra la ventana
    if (response.status === 200) {
      onClose();
    }
  } catch (error) {
    console.error(error);
  }
    };

   
  return (
    <>
      <button className="close-button-admi" onClick={onClose}>
        X
      </button>
      <div className="titulo-admi-info">
        <h2>Crear Area docente</h2>
      </div>
      <form  onSubmit={handleGuardarArea}>
       
        
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen"
            accept="image/*" onChange={(e) => setImagen(e.target.files[0])}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen movil:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen"
            accept="image/*" onChange={(e) => setImagenresponsive(e.target.files[0])}
          />
        </div>
        
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        
        </div>
      </form>
    </>
  );
};
