import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaInstagram } from "react-icons/fa";



export const Egresadoscart = () => {
  const [egresados, setEgresado] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Fetch testimonios from the backend API
    axios.get(`${backendURL}/obtener_egresados`)
      .then(response => {
        setEgresado(response.data);
      })
      .catch(error => {
        console.error("Error fetching testimonios:", error);
      });
  }, []);

  return (
    <div className="container egresados">
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4 g-2 egresado">
      {egresados.map(egresado => (
        <div key={egresado.id} className="col egresado">
          <div className="card egresado">
            <div className="card-img egresado">
              {" "}
              <img src={`${backendURL}/imagen_egresado/${egresado.imagen}`} className="w-100 egresadoimagen" />{" "}
            </div>{" "}
            <ul className="social-media egresado">
              <a
                href={egresado.url_empresa}
                target="_black"
              >
                {" "}
                <li className="info-egresado">
                  <FaInstagram />
                </li>
              </a>
              <li className="info-egresado">
                {" "}
                <p className="titulo egresado">
                {egresado.nombre_empresa}
                </p>{" "}
                <p className="subtitle egresado">Dueñ@ y Chef</p>
                <p className="subtitle egresado">{egresado.nombre_estudiante}</p>
              </li>
              <li className="info-egresado">
                {" "}
                <p className="subtitle egresado">Titulado en ECOLE</p>
                <p className="subtitle egresado">
                  {egresado.titulo}
                </p>{" "}
              </li>
            </ul>
          </div>
        </div>
        ))}
      </div>
    </div>
  );
};
