import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import { FaPlus } from "react-icons/fa";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import ReactPlayer from 'react-player';
import "../../styles/admision/admision.css"


 export const Responsivevideoadmi = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [listaPortadas, setListaPortadas] = useState([]);

  useEffect(() => {
    obtenerPortadas();
  }, []);

  const obtenerPortadas = () => {
    axios
      .get(`${backendURL}/videos_admision`)
      .then((response) => {
        setListaPortadas(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener la lista de portadas:", error);
      });
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    prevArrow: <BsChevronLeft />,
    nextArrow: <BsChevronRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="video admi">
      <div className="titulo-videoadmi">
        <h1 className="videoadmi-primer">Conoce Nuestra</h1>
        <h1 className="videoadmi-secun">Escuela y Profesionales </h1>
      </div>
      <div className="container videoadmi">
        <Slider {...settings}>
          {listaPortadas.map((videoadmi) => (
            <div key={videoadmi.id}>
              <div className="card videoadmi">
                <div className="card-body videoadmi">
                  <div className="ratio ratio-4x3 videoadmi">
                  <ReactPlayer
              url={videoadmi.url_video_admi} // Utiliza la URL del video seleccionado
              className="react-player video admision"
              controls
              loop
              width="100%"
              
              
            />
                    
                  </div>
                  <div className="info-videoadmi">
                    <p className="texto-info-videoadmi">
                      <DangerouslySetHTMLContent html={videoadmi.descripcion_video_admi} />
                    </p>{" "}
                    <a
                      href={videoadmi.url_video_admi}
                      target="_blank"
                      className="videoadmi"
                    >
                      <FaPlus className="mas" /> Ver más
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

