import React, { useRef, useEffect } from "react";
import "../../styles/style Home/home.css";
/* imagenes */
import logomedalla from "../../img/home/acreditacionhome.png";
import logo1 from "../../img/home/00LogoHome.png";
import logo2 from "../../img/home/logo-maitres-cuisiniers.png";

import infohome2 from "../../img/home/separador.png";

import chef from "../../img/home/bienvenida.png";

/* librerias */
import ScrollReveal from "scrollreveal";

export const HomeInfo = () => {
  const bienvenido = useRef(null);
  const bandera2 = useRef(null);
  const infoHome = useRef(null);
  const imginfoHome = useRef(null);

  useEffect(() => {
    const sr = ScrollReveal();
    sr.reveal(bienvenido.current, {
      duration: 10000,
      origin: "bottom",
      distance: "-100px",
    });
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();
    sr.reveal(bandera2.current, {
      duration: 9000,
      origin: "bottom",
      distance: "-100px",
    });
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();
    sr.reveal(infoHome.current, {
      duration: 10000,
      origin: "rigth",
      distance: "-100px",
    });
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();
    sr.reveal(imginfoHome.current, {
      duration: 10000,
      origin: "left",
      distance: "-100px",
    });
  }, []);
  return (
    <>
      <div className="container-fluid1">
        <div className="container infohome1">
          <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 g-4 homeprincipal">
            <div className="col infohome">
              <img src={infohome2} className="w-95 separadorhome" />
              <h1 className="titulohome">¡BIENVENID@ A ECOLE!</h1>
              <p className="Ecole Home1">
                Somos la primera y única escuela de Gastronomía francesa en
                Chile patrocinada por los Maîtres Cuisiniers de France.
              </p>
              <p className="Ecole Home1">
                Te invitamos a que seas parte de nuestra institución y te
                conviertas en un gran profesional de la gastronomía
                internacional.
              </p>
            </div>
            <div className="col infohome-img">
              <div className="img-home-principal">
                <img className="imagen-infohome1 w-100" src={chef} />
              </div>
            </div>
          </div>
          <div className="container-fluid medallas">
             <div className=" medallas infohome">
              <img className="medallas-1 w-100  " src={logomedalla} />
            </div> 
            <div className="row logos">
               <div className=" col-12 col-sm-6  col-md-6  logos">
                <div className="medalla-home">
                <img className="medallas-1 w-60" src={logo1} /></div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 logos">
              <div className="medalla-home"> <img className="medallas-2 w-60" src={logo2} /></div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
