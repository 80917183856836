import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import mapa from "../../img/especialidad/mapa.png";
import sombrero from "../../img/especialidad/sombrero.png";
import flecha from "../../img/especialidad/flecha-select-26.png"
import "../../styles/especialidad/especialidad.css";

export const MapaPasantia = () => {
  const [mapas, setMapas] = useState([]);
  const [selectedMapa, setSelectedMapa] = useState(null);
  const [mostrarCard, setMostrarCard] = useState(false);
  const [mostrarPopup, setMostrarPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const mapaRef = useRef(null);
  const bounds = useRef({});
  const [selectedOption, setSelectedOption] = useState("opcion1");
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Obtener los mapas desde la API backend
    axios
      .get(`${backendURL}/obtener_mapas`)
      .then(response => {
        setMapas(response.data);
      })
      .catch(error => {
        console.error("Error al obtener los mapas:", error);
      });
  }, []);

  const handleMarkerClick = (mapa, event) => {
    event.preventDefault();
    setSelectedMapa(mapa);
    const { top, left } = popupPosition;
    setMostrarPopup(true);
    setPopupPosition({
      top: event.clientY - top,
      left: event.clientX - left,
    });
    setMostrarCard(true);
  };

  const handleClosePopup = () => {
    setMostrarPopup(false);
    setSelectedMapa(null);
    setMostrarCard(false);
  };

  const handleResize = () => {
    const { top, left, width, height } = mapaRef.current.getBoundingClientRect();
    setPopupPosition({ top, left });
    bounds.current = { top, left, right: left + width, bottom: top + height };
  };

  useEffect(() => {
    const mapaElement = mapaRef.current;
    const handleResize = () => {
      const { top, left } = mapaElement.getBoundingClientRect();
      setPopupPosition({ top, left });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSelectChange = (event) => {
    const selectedMapa = mapas.find((mapa) => mapa.nombre_establecimiento === event.target.value);
    if (selectedMapa) {
      setSelectedOption(selectedMapa.nombre_establecimiento); // Actualiza el estado selectedOption
      const { top, left } = popupPosition;
      setMostrarPopup(true);
      setPopupPosition({
        top: 100, // Coloca la posición que desees
        left: 100, // Coloca la posición que desees
      });
      setSelectedMapa(selectedMapa);
      setMostrarCard(true);
    }
  };


  return (
    <div>
      <div className="container mapapasantia">
        <div className="titulo mapapasantia">
          <h1 className="titulo principal mapa pasantia">
            CONOCE LOS MEJORES A NIVEL MUNDIAL DE NUESTRA PASANTÍA
          </h1>
          <section>
          <select
  className="select-mapa-ciudad"
  value={selectedOption}
  onChange={(e) => handleSelectChange(e)}
> 
  <option value="opcion1" disabled>
    Buscar Establecimiento 
  </option> 
  {mapas.map((mapa) => (
    <option key={mapa.id} value={mapa.nombre_establecimiento}>
      {mapa.nombre_establecimiento}
    </option>
  ))}
</select>
          </section>
        </div>
        <div className="contenedor del mapa" ref={mapaRef}>
          <div className="imagen mapapasantia">
            <img src={mapa} className="w-100 mapa pasantia" alt="" />
            {mapas.map(mapa => (
              <button
                key={mapa.id} // Suponiendo que cada mapa tiene un ID único
                className="handle ubicacion"
                style={{
                  position: "absolute",
                  top: `${mapa.top}%`,
                  left: `${mapa.left}%`,
                  transform: "translate(-50%, -50%)",
                }}
                onClick={event => handleMarkerClick(mapa, event)}
              >
                <FaMapMarkerAlt className="ubicacion" />{" "}
                <p className="nombre ciudad mapa">{mapa.nombre_establecimiento}</p>
              </button>
            ))}
          </div>
        </div>
        <h2 className="infoprincipal mapa pasantia">
          <strong className="subrayarpasantia">Haz clic en el mapa</strong> y
          conoce los establecimientos de nivel mundial en los que podrás practicar
          y trabajar.
        </h2>
      </div>
      <div className="info-ciudad-pasantia">
        {selectedMapa && mostrarPopup && (
        
          <div className="popup-mapa" >
            
            <div className="overlay-mapa" onClick={handleClosePopup}>
              <div className="popup-content-mapa-carta">
                <div className="carta-mapapasantia ">
                  <div className="card carta-mapapasantia-mb-3">
                    <div className="row g-0 carta mapapasantia">
                      <div className="col-4 col-md-4 carta mapapasantia">
                        <img
                          src={selectedMapa.imagen_url}
                          alt="Trendy Pants and Shoes"
                          className="img-fluid carta mapapasantia rounded-start"
                        />
                      </div>
                      <div className="col-8 col-md-8 carta mapapasantia">
                        <div className="card-body-carta mapapasantia">
                          <h3 className="card-titulo-carta mapapasantia">
                            {selectedMapa.nombre_establecimiento}
                          </h3>
                          <span className="carta-mapa-ciudad">
                            Ciudad: {selectedMapa.ciudad}
                          </span>
                          <div className="info-mapa-card">
                            <div className="img-info-pasantiamapa">
                              <img
                                src={sombrero}
                                className="w-50 sombrero pasantia"
                              />
                            </div>
                            <p className="card-texto-carta mapapasantia">
                              Chef {selectedMapa.chef}
                            </p>
                          </div>
                          <a
                    className="mapapasantia"
                    href={selectedMapa.ubicacion} 
                    target="_blank" 
                  >
                          <button className="mapapasantia">
                            <div className="svg-wrapper-1 mapapasantia">
                              <div className="svg-wrapper mapapasantia">
                                <svg
                                  className="mapapasantia"
                                  height="24"
                                  width="24"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    className="mapapasantia"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path
                                    className="mapapasantia"
                                    d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <span className="mapapasantia">Ubicación</span>
                          </button></a>
                          <button className="boton-close-cardmapa">
                            <GrFormClose
                              className="boton-close-cardmapa"
                              onClick={handleClosePopup}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};