import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiTrash } from "react-icons/bi";

export const GaleriaMostrar = ({ cursoId }) => {
  const [imagenes, setImagenes] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Realizar una solicitud GET al backend para obtener las URLs de las imágenes asociadas al curso
    const obtenerImagenesCurso = async () => {
      try {
        const response = await axios.get(`${backendURL}/obtener_imagenes_curso`, {
          params: { curso_id: cursoId },
        });
        setImagenes(response.data.imagenes_info);
      } catch (error) {
        console.log(error);
      }
    };

    obtenerImagenesCurso();
  }, [cursoId]);

  const handleEliminarImagen = async (imagenId) => {
    try {
      // Realizar la solicitud DELETE al backend para eliminar la imagen por su ID
      const response = await axios.delete(`${backendURL}/eliminar_galeriacurso/${imagenId}`);
  
      // Ver la respuesta completa en la consola
      console.log("Respuesta de la eliminación:", response);
  
      // Volver a cargar las imágenes después de la eliminación
      const obtenerImagenesActualizadas = await axios.get(`${backendURL}/obtener_imagenes_curso`, {
        params: { curso_id: cursoId },
      });
      setImagenes(obtenerImagenesActualizadas.data.imagenes_info);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="imagenes-incorporadas">
        <div className="row admi-galeria-curso">
          {imagenes.map((imagen) => (
             <div className="col-4 admi-galeria-curso" key={imagen.id}>
              <div className="card imagen">
                <div className="contenedor-imagen">
                  <img className="imagen-galeria" src={imagen.url} alt="Imagen del curso" />
                </div>
                <button
                  className="eliminar-imagen"
                  onClick={() => {
                    handleEliminarImagen(imagen.id); // Pasa el ID de la imagen al botón eliminar
                  }}
                >
                  <BiTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};