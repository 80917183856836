import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../styles/style Home/home.css";
import { TfiClose } from "react-icons/tfi";
import { FormularioAdmision } from "../formulario-admision";

export const AdmiPortada = () => {
  const [listaPortadas, setListaPortadas] = useState([]);
  const [imagenKey, setImagenKey] = useState(Date.now());
  const [activeSlide, setActiveSlide] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Nuevo estado para el ancho de la ventana
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const obtenerPortadas = async () => {
    try {
      const response = await axios.get(`${backendURL}/obtener_portadahome`);
      setListaPortadas(response.data.portadas);
     
    } catch (error) {
      console.error("Error al obtener la lista de portadas:", error);
    }
  };
  
  const cerrarVentana = () => {
    setShowPopup(false);
  };

  const preloadImages = () => {
    const images = listaPortadas.map((portada) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src =
          windowWidth < 700
            ? `${backendURL}/imagen_portadahomeresponsive/${portada.imagen_responsive}`
            : `${backendURL}/imagen_portadahome/${portada.imagen_home}`;
        img.onload = resolve;
      });
    });

    Promise.all(images).then(() => {
      setPreloadedImages(images);
    });
  };

  const nextSlide = () => {
    setActiveSlide((prevSlide) => (prevSlide + 1) % listaPortadas.length);
  };

  useEffect(() => {
    obtenerPortadas();
  }, []); // Ejecutar el efecto solo una vez al montar el componente

  useEffect(() => {
    preloadImages();

    const interval = setInterval(nextSlide, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [listaPortadas]);

  useEffect(() => {
    // Actualizar la clave única para forzar la recarga de la imagen
    setImagenKey(Date.now());
  }, [activeSlide]);

  useEffect(() => {
    // Manejar el cambio de tamaño de la ventana
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Agregar el evento de cambio de tamaño
    window.addEventListener("resize", handleResize);

    // Limpiar el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // No olvides las dependencias vacías para que se ejecute solo una vez al montar el componente

  useEffect(() => {
    // Actualizar las imágenes cuando cambie el ancho de la ventana
    preloadImages();
  }, [windowWidth]);

  return (
    <section className="portada home1">
      <div
        id="carouselExampleFade"
        className="carousel slide carousel-fade-home"
        style={{ width: "99.5%", height: "100%" }}
      >
      {listaPortadas.map((portada, index) => (
  <div
    key={portada.id}
    className={`carousel-item-home ${
      index === activeSlide ? "active" : ""
    }`}
  >
    <button
      className="portada-boton"
      onClick={() => {
        if (index === 0) {
          setShowPopup(true);
        } else {
          window.location.href = portada.url_home;
        }
      }}
    >
      <img
        src={
          windowWidth < 700
            ? `${backendURL}/imagen_portadahomeresponsive/${portada.imagen_responsive}?${imagenKey}`
            : `${backendURL}/imagen_portadahome/${portada.imagen_home}?${imagenKey}`
        }
        className="d-block w-100"
        alt="..."
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
      />
    </button>
  </div>
))}

      </div>
      {showPopup && (
        <div className="popups-admision">
          <button
            className="close-admision"
            onClick={cerrarVentana}
            aria-label="Cerrar"
          >
            <TfiClose />
          </button>
          <FormularioAdmision />
        </div>
      )}
      <div className="carousel-indicators">
        {listaPortadas.map((_, index) => (
          <button
            key={index}
            type="button"
            className={`carousel-indicator ${
              index === activeSlide ? "active" : ""
            }`}
            onClick={() => setActiveSlide(index)}
          ></button>
        ))}
      </div>
    </section>
  );
};