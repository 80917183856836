import React, { useEffect } from "react";
import { LoginUser } from "../component/Login/estructuralogin";
import "../styles/login/login.css";


/* imagenes */
import fondo from "../img/login/fondo.png"

export const Loginhome = ({ setShowNavbarFooter }) => {
  useEffect(() => {
    setShowNavbarFooter(false); // Oculta el navbar y el footer al montar este componente
    return () => {
      setShowNavbarFooter(true); // Muestra el navbar y el footer al desmontar este componente
    };
  }, [setShowNavbarFooter]);

  return (
    <div className="login-view" style={{ backgroundImage: `url(${fondo})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '100vh' }}>
      <LoginUser />
    </div>
  );
};
