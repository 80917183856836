import React, { useRef, useEffect } from "react";
import "../../styles/docentes/docentes.css";
/* librerias */
import ScrollReveal from "scrollreveal";

/* imagenes */
import Cofundador from "../../img/docentes/cofundador.png";
import linea from "../../img/docentes/linea.png";

export const Bienveniddocente = () => {
  return (
    <div className="container bienvenida docente">
      <h1 className="titulo bienvenida docente">¡BIENVENID@! </h1>

      <h5 className="Titulo2 bienvenida docente">
        {" "}
        El 90% de nuestro cuepo docente de Ecole cuenta con pasantías, prácticas
        internacionales, así como también especializaciones y/o post grados.
      </h5>

      <div className="row row-cols-1 row-cols-md-2 g-4 cofundador">
        <div className="col cofundador">
          <div className=" cofundador-img">
            <img
              src={Cofundador}
              className="card-img-top cofundador"
              alt="..."
            />
          </div>
        </div>
        <div className="col cofundador">
          <div className=" cofundador">
            <div className="infocofundador">
              <h1 className="card-title bienvenida cofundador">Alain Nonnet</h1>

              <h2 className="card-title bienvenida cofundador">
                CO-FUNDADOR / VICEPRESIDENTE
              </h2>
              <img src={linea} className=" linea-separdor-docente" />
              <il className="confundador-info">
                <p className="card-text cofundador">
                  Cofundador Chevalier de la “Académie Culinaire de France”.
                </p>
                <p className="card-text cofundador">
                  Vice-Presidente “Les Maîtres Cuisiniers de France”.
                </p>
                <p className="card-text cofundador">
                  Miembro titular de la “Académie Culinaire de France”.
                </p>
                <p className="card-text cofundador">
                  Condecorado con la Legion d´Honor por el presidente de
                  Francia.
                </p>
              </il>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
