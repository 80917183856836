import React, { useState, useEffect } from 'react';
import axios from 'axios';

import "../../../styles/estructura/estruccursos.css";
import { useParams } from "react-router-dom";
import { FiInstagram } from "react-icons/fi";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';


/* imagen */
import chef from "../../../img/blog/CHEF.jpg";
import linea from "../../../img/docentes/linea.png";

/* imagenes */

import calendario from "../../../img/estructuras/blog/cursos/calen.png";
import tiempo from "../../../img/estructuras/blog/cursos/tiempo.png";
import precio from "../../../img/estructuras/blog/cursos/precio.png";
import instagram from "../../../img/estructuras/blog/cursos/instagram.png";

export const InfoEstructuraCursos = ({ curso }) => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const { chef_id } = curso;

  const [chefData, setChefData] = useState(null);

  useEffect(() => {
    obtenerChef();
  }, [chef_id]);

  const obtenerChef = () => {
    axios
      .get(`${backendURL}/obtener_chef/${chef_id}`)
      .then((response) => {
        setChefData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  return (
    <div>
      <section className="container info-estructura-curso">
        <div className="info estructura blog">
          <div className="separador-info">
            {" "}
            <img src={linea} className="w-100 separador" />
          </div>
          <h1 className="titulo-curso-principal">{curso.nombre_curso}</h1>

          <section className=" info-principal-curso">
            <div className="info-principal-curso">
            <DangerouslySetHTMLContent html={curso.descripcion_curso} />
            </div>
           
          </section>
        </div>
        <section className=" info-curso-tiempo">
          <div className="row info-curso-tiempo ">
            <div className="col col-xs-12 col-sm-4 info-cursotiempo ">
              <div className="icono-curso-tiempo">
                <img src={calendario} className="w-100 icono-cursotiempo" />{" "}
                <div className="informacion-cursos">
                  {" "}
                  <h3 className="informacion-curso">Fecha</h3>
                  <h5 className="informacion-fecha">{curso.fecha_curso}</h5>
                </div>
              </div>
            </div>
            <div className="col col-xs-12 col-sm-4 info-cursotiempo ">
              {" "}
              <div className="icono-curso-tiempo">
                <img src={tiempo} className="w-100 icono-cursotiempo" />
              </div>
              <div className="informacion-cursos"> 
                  <h3 className="informacion-curso">Horario</h3>
                  <h5 className="informacion-fecha">{curso.horario}.</h5>
                </div>
            </div>
            <div className="col col-xs-12 col-sm-4 info-cursotiempo ">
              {" "}
              <div className="icono-curso-tiempo">
                <img src={precio} className="w-100 icono-cursotiempo" />
              </div>
              <div className="informacion-cursos"> 
                  <h3 className="informacion-curso">Precio curso</h3>
                  <h5 className="informacion-fecha">${curso.precio_final.toLocaleString()}</h5>
                </div>
            </div>
          </div>
        </section>

        <div className="info estructura blog">
        <div className="card info-estructura-curso"key={chef_id}>
  <div className="img-info-estructura-curso">
  {chefData && chefData.imagen && (
    <img src={`${backendURL}/imagen_chef/${chefData.imagen}`} className=" img-info-estructura-curso" alt="Chef" />
  )}
  </div>
  <div className="titulo-info-nombre-profesional-curso">
    <div className="Red-social-chef-curso">
    {chefData && chefData.url && (
              <a href={chefData.url} target="_blank">
                <div className="instagram-chef">
                  <img src={instagram} className="icono-chef-curso" />
                </div> 
              </a>
            )}
          </div>
          <div className="info-nombre-chef-curso">
            {/* Verificar si chefData existe antes de acceder a sus propiedades */}
            {chefData && (
              <React.Fragment>
                <h1 className="titulo-nombre-chef-curso">{chefData.nombre}</h1>
                <h3 className="subtitulo-nombre-chef-curso">Chef Instructor</h3>
              </React.Fragment>
            )}
          </div>
          <div className="info-chef-curso">
            {/* Verificar si chefData existe antes de acceder a sus propiedades */}
            {chefData && (
    <DangerouslySetHTMLContent html={chefData.descripcion ?? ''} />
  )}
          </div>
  </div>
</div>
        </div>
      </section>
    </div>
  );
};
