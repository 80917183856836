import React from "react";

/* componenetes */
import { Portadaunidaddegenero } from "../component/unidad de genera/portada-unidad";
import { Bienvenidaunidaddegenero } from "../component/unidad de genera/bievenida-unidaddegenero";
import { PDFunidaddegenero } from "../component/unidad de genera/descargaspdfunidaddegenero";
import { DenunciaUnidad } from "../component/unidad de genera/denunciasunidad";

export const Unidaddegenero = () => {
  return (
    <div className="todo-home">
      
        <Portadaunidaddegenero />
      
      <section>
        <Bienvenidaunidaddegenero />
      </section>
      <section>
        <PDFunidaddegenero />
      </section>
      <section>
        {" "}
         <DenunciaUnidad /> 
      </section>
    </div>
  );
};
