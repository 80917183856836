import React, { useState, useEffect,  useContext  } from "react";
import { Link } from "react-router-dom";
import Logo from "../img/home/logoEcole.png";
import { FaWhatsapp } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { CarritoCompraDespliegue } from "./carrito-compra";
import { FormularioAdmision } from "./formulario-admision";
import { TfiClose } from "react-icons/tfi";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

import { useCart } from "./CartContext";

import "../styles/navbar & footer/navbar.css";

/* imagenes */
import logouser from "../img/navbar/usericono.png";
import flecha from "../img/navbar/flecha.png";
import matricula from "../img/navbar/botonadmisionnav.png";
import carrito from "../img/navbar/carrito.png";


export const Navbar = () => {
  const [activeItem, setActiveItem] = useState("");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1000);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeItem1, setActiveItem1] = useState("");
  const [clicked, setClicked] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [carritoVisible, setCarritoVisible] = useState(false);
  const { cartItems, toggleCartVisibility } = useCart();

  const toggleCarrito = () => {
    setCarritoVisible(!carritoVisible);
  };

  const toggleForm = () => {
    
      closeMenu();
    setShowForm(!showForm);
  };
  const cerrarVentana = () => {
    setShowForm(false);
  };
 
  

  const handleClickblog = () => {
    setClicked(!clicked);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

 

  const [startClicked, setStartClicked] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick1 = (item) => {
    setActiveItem1(item);
    setIsDropdownOpen(false); // Cerrar el dropdown al hacer clic en un elemento
  };

  const handleStartClick = () => {
    if (activeItem !== "") {
      setActiveItem("");
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Puedes ajustar esto para hacer el scroll más suave o instantáneo
    });
  };

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth > 1000);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    const burgerCheckbox = document.getElementById("burger");
    if (burgerCheckbox) {
      burgerCheckbox.checked = false;
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Puedes ajustar esto para hacer el scroll más suave o instantáneo
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let prevScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollPos >= currentScrollPos || currentScrollPos === 0);
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getNavbarClass = () => {
    return visible ? "navbar-visible" : "navbar-hidden";
  };

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
    setStartClicked(false);

    // Realizar scroll hacia el inicio de la página
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Puedes ajustar esto para hacer el scroll más suave o instantáneo
    });
  };


  return (
    <>
      {isLargeScreen ? (
        // Large screen navbar
        <nav className={`large-screen-navbar ${getNavbarClass()}`}>
          <div className="primernavbarpaginaweb">
            <div className=" Titulo Ecole">
              <div className="container navbar1">
                <div className="row navbar">
                  <div className="col-2">
                  <Link to="/admision" className="" >
                      <div className="contenedor-boton-matricula">
                        <img
                          src={matricula}
                          className="w-100 botonadmision-matricula"
                          onClick={toggleForm}
                        />
                      </div>
                     </Link>
                  </div> {showForm && ( 
                         <div className="popup-admision-navbar">
                          
            <button
              className="admision-close"
              onClick={cerrarVentana}
              aria-label="Cerrar"
            >
              
                <TfiClose />
              
            </button>
            <FormularioAdmision/>
                         
                        
                         </div>
                      )}
                  <div className="col-9">
                    <ul className="navbar1 infoestudiante">
                      <Link to="/docentes">
                        <li
                          className={`itemnavbarinfo ${
                            activeItem === "docente" ? "active" : ""
                          } ${
                            activeItem === "" && startClicked ? "hidden" : ""
                          }`}
                          onClick={() => handleItemClick("docente")}
                        >
                          Docentes
                        </li>
                      </Link>
                      <li className="itemnavbar1 separador ">|</li>
                      <Link to="/egresados">
                        {" "}
                        <li
                          className={`itemnavbarinfo ${
                            activeItem === "egresado" ? "active" : ""
                          } ${
                            activeItem === "" && startClicked ? "hidden" : ""
                          }`}
                          onClick={() => handleItemClick("engresado")}
                        >
                          Egresados
                        </li>
                      </Link>
                      <li className="itemnavbar1 separador">|</li>
                      <Link to="/unidad-de-genero">
                        <li
                          className={`itemnavbarinfo ${
                            activeItem === "unidad-de-genero" ? "active" : ""
                          } ${
                            activeItem === "" && startClicked ? "hidden" : ""
                          }`}
                          onClick={() => handleItemClick("unidad-de-genero")}
                        >
                          Unidad de Género
                        </li>
                      </Link>
                      <li className="itemnavbar1 separador ">|</li>
                      <li className="dropdown1 blog">
                        <div className="dropdownblog">
                          <a
                            className={`dropdownblog-toggle ${
                              clicked ? "clicked" : ""
                            }`}
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded={clicked ? "true" : "false"}
                            onClick={handleClickblog}
                          >
                            Blog
                            <div className="flecha-navbar">
                              <img
                                src={flecha}
                                className="w-100 iconoblog"
                                alt="Flecha"
                              />
                            </div>
                          </a>

                          <ul
                            className={`dropdown-menu blog ${
                              clicked ? "show" : ""
                            }`}
                          >
                            <li className="texto-blog-menu">
                              <Link to="/blog" onClick={handleClickblog}>
                                <a className="texto-blog-menu">
                                  Recetas y Tips
                                </a>
                              </Link>
                            </li>
                            <li className="texto-blog-menu">
                              <Link to="/noticias" onClick={handleClickblog}>
                                <a className="texto-blog-menu">Noticias</a>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-1 iconos-nav-1">
                    <a
                      className=" boton-icono-nav"
                      href="https://wa.me/56963046360?text=Me%20gustaría%20saber%20por%20información%20general"
                      role="button"
                      target="_blank"
                    >
                      <FaWhatsapp className="whts-icono-navbar" />
                    </a>

                    <a
                      className="boton-icono-nav"
                      href="https://www.instagram.com/ecolechile/"
                      role="button"
                      target="_blank"
                    >
                      <FaInstagram />
                    </a>

                    <a
                      className="boton-icono-nav"
                      href="https://www.facebook.com/ecolecft"
                      role="button"
                      target="_blank"
                    >
                     <FaFacebookF />
                    </a>
                    <a
                      className="boton-icono-nav"
                      href="https://www.tiktok.com/@ecolechile"
                      role="button"
                      target="_blank"
                    >
                      <FaTiktok/>
                    </a>

                    <a
                      className="boton-icono-nav"
                      href="https://www.youtube.com/user/ecolecft"
                      role="button"
                      target="_blank"
                    >
                      <FaYoutube />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* final encabezado */}
            {/* <!-- encabezado dos --> */}
            <nav className="navbar2 encabezado2 ">
              <Link to="/">
                {" "}
                <div className="logoprincipal" onClick={handleStartClick}>
                  <img className="logoprincipal w-100" src={Logo} alt="" />
                </div>
              </Link>

              <div>
                <ul className="menu navbar2">
                  <Link to="/admision">
                    <li
                      className={`itemnavbar ${
                        activeItem === "admision" ? "active" : ""
                      } ${activeItem === "" && startClicked ? "hidden" : ""}`}
                      onClick={() => handleItemClick("admision")}
                    >
                      Admisión
                    </li>
                  </Link>
                  <Link to="/nosotros">
                    {" "}
                    <li
                      className={`itemnavbar ${
                        activeItem === "nosotros" ? "active" : ""
                      } ${activeItem === "" && startClicked ? "hidden" : ""}`}
                      onClick={() => handleItemClick("nosotros")}
                    >
                      Nuestra Escuela
                    </li>
                  </Link>

                  <Link to="/carreras">
                    {" "}
                    <li
                      className={`itemnavbar ${
                        activeItem === "carreras" ? "active" : ""
                      } ${activeItem === "" && startClicked ? "hidden" : ""}`}
                      onClick={() => handleItemClick("carreras")}
                    >
                      {" "}
                      Nuestra Carrera
                    </li>
                  </Link>
                  <Link to="/especializacion-en-francia">
                    <li
                      className={`itemnavbar ${
                        activeItem === "especializacion-en-francia"
                          ? "active"
                          : ""
                      } ${activeItem === "" && startClicked ? "hidden" : ""}`}
                      onClick={() =>
                        handleItemClick("especializacion-en-francia")
                      }
                    >
                      {" "}
                      Especialización en Francia
                    </li>
                  </Link>
                  <Link to="/cursos">
                    {" "}
                    <li
                      className={`itemnavbar ${
                        activeItem === "cursos" ? "active" : ""
                      } ${activeItem === "" && startClicked ? "hidden" : ""}`}
                      onClick={() => handleItemClick("cursos")}
                    >
                      Cursos y Diplomados
                    </li>
                  </Link>

                  <li className="ropdown1">
                    <div className="dropdown1">
                      <div className="logouser">
                        <img className="w-10 logouser" src={logouser} />
                      </div>
                      <a
                        className="dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Mi ECOLE
                      </a>

                      <ul class="dropdown-menu">
                        <li className="info-menu-ecole">
                          <a
                            className="dropdown-item menu-navbar"
                            target="_blank"
                            href="https://ecole.trabajando.cl"
                          >
                            <p className="menu-navbar">Portal Laboral</p>
                          </a>
                        </li>
                        <li className="info-menu-ecole">
                          <a
                            className="dropdown-item menu-navbar"
                            target="_blank"
                            href="https://ecole.umas.cl/alumnos/Login"
                          >
                            <p className="menu-navbar">Intranet Alumnos</p>
                          </a>
                        </li>
                        <li className="info-menu-ecole">
                          <a
                            className="dropdown-item menu-navbar"
                            target="_blank"
                            href="https://ecole.umas.cl/Docentes/login"
                          >
                            <p className="menu-navbar"> Intranet Docentes</p>
                          </a>
                        </li>
                        <li className="info-menu-ecole">
                          <a
                            className="dropdown-item menu-navbar"
                            target="_blank"
                           
                          >
                             <Link to="/beneficios-estudiantes">
                            <p className="menu-navbar">Beneficios Estudiantes</p>
                          </Link>
                          </a>
                        </li>
                        <li className="info-menu-ecole">
                          <a
                            className="dropdown-item menu-navbar"
                            target="_blank"
                            href="https://ecole.umas.cl/MNP/"
                          >
                            <p className="menu-navbar">Postulación avanzada</p>
                          </a>
                        </li>
                        <li className="info-menu-ecole">
                          <a
                            className="dropdown-item menu-navbar"
                            target="_blank"
                            href="https://virtual.escuelaculinaria.cl/login/index.php"
                          >
                            <p className="menu-navbar"> Aula Virtual</p>
                          </a>
                        </li>
                        <li className="info-menu-ecole">
                          <a
                            className="dropdown-item menu-navbar"
                            target="_blank"
                            href="http://correo.escuelaculinaria.cl"
                          >
                            <p className="menu-navbar">Correo Ecole</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="carrito-compra">
                    <div className="carrito-icon" onClick={toggleCartVisibility}>
                      <img src={carrito} className="w-100 carrito-compra" />
                      <span className=" cantidad">{cartItems.length}</span>
                    </div>
                  
                  </li>  
                 
                </ul>
              </div>
            </nav>
          </div>
        </nav>
      ) : (
        // Mobile navbar
        <nav className={`mobile-navbar ${getNavbarClass()}`}>
          <div className="navbarmobile">
            <Link to="/" onClick={closeMenu}>
              <img src={Logo} className="logoprincipal2" alt="Logo de Ecole" />
            </Link>

            <button className="boton menu navbar">
              <label className="container burger">
                <input
                  className="burger"
                  type="checkbox"
                  checked={isMenuOpen}
                  onChange={() => {}}
                />
                <div className="checkmark" onClick={toggleMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </label>
            </button>

            <li className="carrito-compras">
                    <div className="carrito-icon" onClick={toggleCartVisibility}>
                      <img src={carrito} className="w-100 carrito-compra" />
                      <span className="cantidad">{cartItems.length}</span>
                    </div>
                  
                  </li>  

            <div className="navbar-movil-menu">
              {isMenuOpen && (
                <div className="infonavbarmobile">
                  <ul className="menu navbar mobil">
                    
                    <div className="parteunonavbarmobile">
                      <Link to="/nosotros">
                        <li className="itemnavbarmobil" onClick={closeMenu}>
                          Nuestra Escuela
                        </li>
                      </Link>
                      <Link to="/admision">
                        <li className="itemnavbarmobil" onClick={closeMenu}>
                          Admisión
                        </li>
                      </Link>
                      <Link to="/carreras">
                        <li className="itemnavbarmobil" onClick={closeMenu}>
                          {" "}
                          Nuestra Carrera
                        </li>
                      </Link>
                      <Link to="/especializacion-en-francia">
                        <li className="itemnavbarmobil" onClick={closeMenu}>
                          {" "}
                          Especialización en Francia
                        </li>
                      </Link>
                      <Link to="/cursos">
                        <li className="itemnavbarmobil" onClick={closeMenu}>
                          {" "}
                          Cursos y Diplomados
                        </li>
                      </Link>{" "}
                      <li className="itemdropmovil">
                        <div className="dropdown1">
                          <img className="w-25 logouser" src={logouser} />
                          <a
                            className="dropdown-toggle"
                            href="http://correo.escuelaculinaria.cl"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Mi ECOLE
                          </a>

                          <ul className="dropdown-menu">
                            <li onClick={closeMenu}>
                              <a
                                className="dropdown-item"
                                target="_blank"
                                href="https://ecole.trabajando.cl"
                              >
                                Portal Laboral
                              </a>
                            </li>
                            <li onClick={closeMenu}>
                              <a
                                className="dropdown-item"
                                target="_blank"
                                href="https://ecole.umas.cl/MNP/"
                              >
                                Postulación avanzada
                              </a>
                            </li>
                            <li onClick={closeMenu}>
                              <a
                                className="dropdown-item"
                                target="_blank"
                                href="https://ecole.umas.cl/alumnos/Login"
                              >
                                Intranet Alumnos
                              </a>
                            </li>
                            <li onClick={closeMenu}>
                              <a
                                className="dropdown-item"
                                target="_blank"
                                href="https://ecole.umas.cl/Docentes/login"
                              >
                                Intranet Docentes
                              </a>
                            </li>
                            <li onClick={closeMenu} >
                          <a
                            className="dropdown-item "
                            target="_blank"
                           
                          >
                             <Link to="/beneficios-estudiantes">
                           Beneficios Estudiantes
                          </Link>
                          </a>
                        </li>
                            <li onClick={closeMenu}>
                              <a
                                className="dropdown-item"
                                target="_blank"
                                href="https://virtual.escuelaculinaria.cl/login/index.php"
                              >
                                Aula Virtual
                              </a>
                            </li>
                            <li onClick={closeMenu}>
                              <a
                                className="dropdown-item"
                                target="_blank"
                                href="http://correo.escuelaculinaria.cl"
                              >
                                Correo Ecole
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </div>
                    <div className="partedosnavbarmobile">
                    <Link to="/admision" >
                          <div className="contenedor-boton-matricula-responsivo">
                            <img
                              src={matricula}
                              className="w-100 botonadmision-matricula"
                              onClick={closeMenu}
                            />
                          </div>
                        </Link>
                        
                      <Link to="/egresados">
                        {" "}
                        <li className="itemnavbarmobil" onClick={closeMenu}>
                          Egresados
                        </li>
                      </Link>
                      <Link to="/docentes">
                        <li className="itemnavbarmobil" onClick={closeMenu}>
                          Docentes
                        </li>
                      </Link>
                      <Link to="/unidad-de-genero">
                        <li className="itemnavbarmobil" onClick={closeMenu}>
                          Unidad de Género
                        </li>
                      </Link>
                      <li
                        className="itemnavbarmobil-blog"
                        onClick={toggleDropdown}
                      >
                        <button
                          className="itemnavbarmobil dropdown-toggle "
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Blog
                          
                        </button>
                        <ul className="dropdown-menu-nav dropdown-menu">
                          <li>
                            <Link to="/blog" onClick={closeMenu}>
                              <a className="dropdown-item"> Recetas y tips</a>
                            </Link>
                          </li>
                          <li>
                            <Link to="/noticias" onClick={closeMenu}>
                              <a className="dropdown-item"> Noticias</a>{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      
                      <li>
                       
                      </li>
                      <section className="redes-contenedor-navbar">
                      <div className="redes">
                        <a
                          className=" boton-icono-nav"
                          href="https://wa.me/56963046360?text=Me%20gustaría%20saber%20por%20información%20general"
                          role="button"
                          target="_blank"
                        >
                          <FaWhatsapp className="whts-icono-navbar" />
                        </a>
                        <a
                          className="boton-icono-nav"
                          href="https://www.instagram.com/ecolechile/"
                          role="button"
                          target="_blank"
                        >
                         <FaInstagram />
                        </a>

                        <a
                          className="boton-icono-nav"
                          href="https://www.facebook.com/ecolecft"
                          role="button"
                          target="_blank"
                        >
                              <FaFacebookF />
                        </a>
                        <a
                      className="boton-icono-nav"
                      href="https://www.tiktok.com/@ecolechile"
                      role="button"
                      target="_blank"
                    >
                      <FaTiktok/>
                    </a>
                   
                    <a 
                    className="boton-icono-nav"
                    href="https://cl.linkedin.com/school/ecole-chile/"
                     target="_blank">

                        <FaLinkedinIn />
                    </a>
                  

                        <a
                          className="boton-icono-nav"
                          href="https://www.youtube.com/user/ecolecft"
                          role="button"
                          target="_blank"
                        >
                           <FaYoutube />
                        </a>
                      </div>
                      </section>
                    </div>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </nav>
      )}
    </>
  );
};
