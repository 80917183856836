import React, { useState } from 'react';
import axios from 'axios';
export const Botoncrearportadadiploycurso = ({ cerrarVentana }) => {
  const [imagen, setImagen] = useState(null);
  const [imagen_responsive, setImagenresponsive] = useState(null);
  const [url, setUrl] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleImageChange = (e) => {
    setImagen(e.target.files[0]);
  };

  const handleImageresponsiveChange = (e) => {
    setImagenresponsive(e.target.files[0]);

  };
  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("imagen", imagen);
    formData.append("imagen_responsive", imagen_responsive);
    formData.append("url", url);

    axios
      .post(`${backendURL}/guardar_portadadc`, formData)
      .then((response) => {
        console.log(response.data);
        // Aquí puedes realizar acciones adicionales después de guardar la portada

        // Cierra la ventana
        cerrarVentana();
      })
      .catch((error) => {
        console.error(error);
        // Aquí puedes manejar el error en caso de que ocurra
      });
  };

  return (
    <>
      <div className="titulo-admi-info">
        <h2>Crear Portada página curso y diplomado</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input className="input-admi" type="file" id="imagen" onChange={handleImageChange} />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen_responsive">
            Imagen movil:
          </label>
          <input className="input-admi" type="file" id="imagen_responsive" onChange={handleImageresponsiveChange} />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            URL:
          </label>
          <input className="input-admi" type="text" id="url" value={url} onChange={handleUrlChange} />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};