import React, { useState, useEffect } from "react";
import axios from "axios";



export const Editarmapa = ({mapaId, onClose}) => {
    const [nombreEstablecimiento, setNombreEstablecimiento] = useState("");
    const [imagen, setImagen] = useState(null);
    const [ciudad, setCiudad] = useState("");
    const [chef, setChef] = useState("");
    const [ubicacion, setUbicacion] = useState("");
    const [top, setTop] = useState("");
    const [left, setLeft] = useState("");
    const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Fetch the existing testimonial data based on mapaId
    axios.get(`${backendURL}/obtener_mapas/${mapaId}`)
      .then(response => {
        const mapaData = response.data;
        setNombreEstablecimiento(mapaData.nombre_establecimiento);
        setCiudad(mapaData.ciudad);
        setChef(mapaData.chef);
        setUbicacion(mapaData.ubicacion);
        setTop(mapaData.top);
        setLeft(mapaData.left);
      })
      .catch(error => {
        console.error("Error fetching testimonio data:", error);
      });
  }, [mapaId]);

  const handleEdit = async (e) => {
    e.preventDefault();

   
    const formData = new FormData();
    formData.append("nombre_establecimiento", nombreEstablecimiento);
    formData.append("imagen", imagen);
    formData.append("ciudad", ciudad);
    formData.append("chef", chef );
    formData.append("ubicacion", ubicacion);
    formData.append("top", top );
    formData.append("left", left);
    

    try {
      const response = await axios.put(`${backendURL}/editar_mapa/${mapaId}`, formData);
      console.log(response.data);
      // Add any additional logic you want after successful editing
      onClose(); // Close the popup after successful edit
      setImagen(null);
    } catch (error) {
      console.error(error);
      // Handle error cases
    }
  };
  
  const handleChange = (event) => {
    setImagen(event.target.files[0]);
  };
    return(
        <>
         <button className="close-button-admi" onClick={onClose}>
        X
      </button>
      <div className="titulo-admi-info">
        <h2>Editar Testimonio Estudiante</h2>
      </div>
      <form onSubmit={handleEdit} >
      <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Nombre Establecimiento:
          </label>
          <input
            className="input-admi"
            type="text"
            value={nombreEstablecimiento}
            onChange={(e) => setNombreEstablecimiento(e.target.value)}
          />
        </div>
        

        <div>
          <label className="titulo-input-admi" htmlFor="descripcionCurso">
            Ciudad:
          </label>
          <input
            className="input-admi"
            type="text"
            value={ciudad}
            onChange={(e) => setCiudad(e.target.value)}
          />
          
        </div>

        <div>
          <label className="titulo-input-admi" htmlFor="descripcionCurso">
            Chef:
          </label>
          <input
            className="input-admi"
            type="text"
            value={chef}
            onChange={(e) => setChef(e.target.value)}
          />
          
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Ubicacion:
          </label>
          <input
            className="input-admi"
            type="text"
            value={ubicacion}
            onChange={(e) => setUbicacion(e.target.value)}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Top:
          </label>
          <input
            className="input-admi"
            type="number"
            value={top}
            onChange={(e) => setTop(parseFloat(e.target.value))}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Left:
          </label>
          <input
            className="input-admi"
            type="number"
            value={left}
            onChange={(e) => setLeft(parseFloat(e.target.value))}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
        
        
        </>
    )
}