import React, { useState } from "react";
import "../styles/navbar & footer/footer.css";
import Logo from "../img/home/logoEcole.png";
import acreditacion from "../img/footer/acreditacion.png";
import { BiPhone, BiEnvelope } from "react-icons/bi";
import { FaTiktok } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

/* imagenes */
import acreditacionfooter from "../img/footer/acreditacionfooter.png";
import separadorfooter from "../img/footer/separadorfooter.png";
import logofooter from "../img/footer/logofooter.png";
import banderas from "../img/footer/banderas.png";

export const Footer = () => {
  const [activeItem, setActiveItem] = useState(null);

  const handleTogglePhoneNumbers = (e, index) => {
    e.preventDefault();
    setActiveItem(index === activeItem ? null : index);
  };
  return (
    <>
      <img src={separadorfooter} className="w-100 separador footer" />
      <footer className=" container-fluid footer principal">
        <div className="container footer">
          <div className=" row footer ">
            <div className="col-xl-4  col-sm-12 col-xs-12 unologo-footer">
              {" "}
              <img className="Logo Footer" src={logofooter} alt="" />
            </div>

            <div className="col-xl-4 col-sm-12 col-xs-12 footer">
              <h4 className="titulo-info-footer">CONTACTO</h4>

              <ul className="container info">
                <li
                  className={`footer-info ${activeItem === 0 ? "active" : ""}`}
                  onClick={(e) => handleTogglePhoneNumbers(e, 0)}
                >
                  <a
                    className={`titulo-info-contacto ${
                      activeItem === 0 ? "active" : ""
                    }`}
                  >
                    Mesa Central
                  </a>
                </li>
                {activeItem === 0 && (
                  <ol className="info-contacto-dentro">
                    <li className="telefono-footer">
                      <BiPhone className="icono-telefono-footer" />
                      <p className="numero-telefono-footer"> +56 2 2206 9720</p>
                    </li>
                    <li className="telefono-footer">
                      <BiPhone className="icono-telefono-footer" />
                      <p className="numero-telefono-footer"> +56 2 2206 9723</p>
                    </li>
                    <li className="telefono-footer">
                      <BiEnvelope className="icono-telefono-footer" />
                      <p className="numero-telefono-footer">
                        recepcion@escuelaculinaria.cl
                      </p>
                    </li>
                    <li className="telefono-footer">
                      <a
                        className="botonde wtssp"
                        href="https://wa.me/56963046360?text=Me%20gustaría%20saber%20por%20Información"
                        role="button"
                        target="_blank"
                      >
                        <BsWhatsapp className="icono-telefono-footer" />
                        <p className="numero-telefono-footer">
                          +56 9 6304 6360
                        </p>
                      </a>
                    </li>
                  </ol>
                )}
                <li
                  className={`footer-info ${activeItem === 2 ? "active" : ""}`}
                  onClick={(e) => handleTogglePhoneNumbers(e, 2)}
                >
                  <a
                    className={`titulo-info-contacto ${
                      activeItem === 2 ? "active" : ""
                    }`}
                  >
                    Admisión
                  </a>
                </li>
                {activeItem === 2 && (
                  <ol className="info-contacto-dentro">
                    <li className="telefono-footer">
                      <BiEnvelope className="icono-telefono-footer" />
                      <p className="numero-telefono-footer">
                        admision@escuelaculinaria.cl
                      </p>
                    </li>
                    <li className="telefono-footer">
                      <a
                        className="botonde wtssp"
                        href="https://wa.me/56999314944?text=Me%20gustaría%20saber%20por%20Admisión"
                        role="button"
                        target="_blank"
                      >
                        {" "}
                        <BsWhatsapp className="icono-telefono-footer" />
                        <p className="numero-telefono-footer">
                          {" "}
                          +56 9 9931 4944
                        </p>{" "}
                      </a>
                    </li>
                  </ol>
                )}
                <li
                  className={`footer-info ${activeItem === 1 ? "active" : ""}`}
                  onClick={(e) => handleTogglePhoneNumbers(e, 1)}
                >
                  <a
                    className={`titulo-info-contacto ${
                      activeItem === 1 ? "active" : ""
                    }`}
                  >
                    Cursos cortos y diplomados
                  </a>
                </li>
                {activeItem === 1 && (
                  <ol className="info-contacto-dentro">
                    <li className="telefono-footer">
                      <a
                        className="botonde wtssp"
                        href="https://wa.me/56976693530?text=Me%20gustaría%20saber%20por%20los%20cursos%20y%20diplomado"
                        role="button"
                        target="_blank"
                      >
                        <BsWhatsapp className="icono-telefono-footer" />
                        <p className="numero-telefono-footer">
                          {" "}
                          +56 9 7669 3530
                        </p>
                      </a>
                    </li>
                    <li className="telefono-footer">
                      <BiEnvelope className="icono-telefono-footer" />
                      <p className="numero-telefono-footer">
                        cursos@escuelaculinaria.cl
                      </p>
                    </li>
                  </ol>
                )}

                <li
                  className={`footer-info ${activeItem === 3 ? "active" : ""}`}
                  onClick={(e) => handleTogglePhoneNumbers(e, 3)}
                >
                  <a
                    className={`titulo-info-contacto ${
                      activeItem === 3 ? "active" : ""
                    }`}
                  >
                    Aula Virtual
                  </a>
                </li>
                {activeItem === 3 && (
                  <ol className="info-contacto-dentro">
                    <li className="telefono-footer">
                      <BiEnvelope className="icono-telefono-footer" />
                      <p className="numero-telefono-footer">
                        ecolevirtual@escuelaculinaria.cl
                      </p>
                    </li>
                  </ol>
                )}
              </ul>
            </div>
            <div className="col-xl-4 col-sm-12 col-xs-12">
              <div className="ubicacion">
                <h4 className="titulo-info-footer-ubicacion"> UBICACIÓN</h4>

                <ul className="container info">
                  <li className="footer-info">
                    <a href="#">Américo Vespucio Sur 930</a>
                  </li>
                  <li className="footer-info">
                    <a href="#">Las Condes, Santiago, Chile.</a>
                  </li>
                </ul>
                <form className="ubicacion footer">
                  <iframe
                    className="ubicacion"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.0441921856445!2d-70.58223522336196!3d-33.422092196062096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf1c9e48f5bf%3A0x60e4fac34e48877f!2sEscuela%20Culinaria%20Francesa%2C%20CFT%20Ecole.!5e0!3m2!1ses-419!2scl!4v1683263278739!5m2!1ses-419!2scl"
                    width="250"
                    height="150"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </form>
              </div>
            </div>
          </div>
          <div className="footer1 row">
            <div className="col-lg-5 col-sm-12 col-xs-12 footer">
              <img
                src={acreditacionfooter}
                className="w-80 acreditacionfooter"
              />
            </div>
            <div className="col-lg-5 col-sm-12 col-xs-12 footer2">
              <div className="social_profilefooter">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/ecolecft"
                     target="_blank">
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/ecolechile/"
                     target="_blank">
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/user/ecolecft"
                     target="_blank">
                     <FaYoutube />
                    </a>
                  </li>
                  <li>
                    <a href="https://cl.linkedin.com/school/ecole-chile/"
                     target="_blank">

                        <FaLinkedinIn />
                    </a>
                  </li>
                  <li> <a
                    
                      href="https://www.tiktok.com/@ecolechile"
                      
                      target="_blank"
                    >
                      <FaTiktok/>
                    </a>
</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12 col-xs-12 footer2">
              <div className="imagen-banderas-footer">
                <img src={banderas} className="w-100 banderasfooter" />
              </div>
            </div>
          </div>

          <div className="footer2 row ">
            <div className="col-lg-12 col-sm-12 col-xs-12 footer">
              <p className="copyright footer">
                Escuela Culinaria Francesa <a href="#">2023.</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
