import React, { useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const BotoncrearReceta = ({ onClose }) => {
    const [titulo, setTitulo] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [categoria, setCategoria] = useState('');
    const [porciones, setPorciones] = useState('');
    const [preparacion, setPreparacion] = useState('');
    const [ingredientes, setIngredientes] = useState('');
    const [imagen, setImagen] = useState(null);
    const [esreceta, setEsReceta] = useState(false);
    const [estips, setEstips] = useState(false);
    const backendURL = process.env.REACT_APP_BACKEND_URL
  
    const handleGuardarReceta = async () => {
      try {
        const formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('descripcion_principal', descripcion);
        formData.append('categoria', categoria);
        formData.append('porciones', porciones);
        formData.append('preparacion', preparacion);
        formData.append('ingredientes', ingredientes);
        formData.append('imagen', imagen);
  
        const response = await axios.post(`${backendURL}/guardar_receta`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
  
        setEsReceta(response.data.es_receta);
        setEstips(response.data.es_tips);
    // Si la receta se guardó exitosamente, cierra la ventana
    if (response.status === 200) {
      onClose();
    }
  } catch (error) {
    console.error(error);
  }
    };

    var toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
    
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
      ['link'],
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    
      [
        { 'color': ['black','#4444','#696965','Dark Gray', 'grey','red','Indian Red', 'blue','Royal Blue', 'green','white','violet','aqua','orange','yellow','pink','purple', 'Turquoise', 'Teal', 'Lime', 'Cyan', 'Magenta', 'Brown', 'Maroon', 'Silver'] },  // Specify custom colors
        { 'background': ['#696965', 'yellow', 'pink', 'orange'] }  // Specify custom background colors
      ],
      [{ 'font': [] }],
      [
        { 'align': [] },
        { 'align': ['center', 'right', 'justify'] }  // Additional alignment options
      ],
    
      ['clean']   
    ];
      const module = {
        toolbar: toolbarOptions,
      };
  return (
    <>
      <button className="close-button-admi" onClick={onClose}>
        X
      </button>
      <div className="titulo-admi-info">
        <h2>Crear Receta o Tips</h2>
      </div>
      <form  onSubmit={handleGuardarReceta}>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Categoría:
          </label>
          <select className="input-admi" value={categoria} onChange={(e) => setCategoria(e.target.value)}>
          <option value="">Seleccionar categoría</option>
  <option value="receta">Receta</option>
  <option value="tips">Tips</option>
</select>
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Título:
          </label>
          <input
            className="input-admi"
            type="text"
            value={titulo} onChange={(e) => setTitulo(e.target.value)}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen"
            accept="image/*" onChange={(e) => setImagen(e.target.files[0])}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="descripcionCurso">
            Descripción principal:
          </label>
          <textarea  className="input-admi" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="porciones">
            Porciones:
          </label>
          <input type="number" value={porciones} onChange={(e) => setPorciones(e.target.value)} />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="preparacion">
            Preparación:
          </label>
          <ReactQuill
            modules={module} theme="snow" className="input-admi" value={preparacion} onChange={setPreparacion} />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="ingredientes">
            Ingredientes:
          </label>
          <ReactQuill modules={module} theme="snow" className="input-admi" value={ingredientes} onChange={ setIngredientes} />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
          {esreceta && (
          <div>
            <h3>Es Receta</h3>
          </div>
        )}

        {estips && (
          <div>
            <h3>Es Tips</h3>
          </div>
        )}
        </div>
      </form>
    </>
  );
};
