import React, { useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const BeneficioCrear = ({ onClose }) => {
const [fechaInicio, setFechaInicio] = useState("");
const [fechatermino, setFechatermino] = useState("");
const [imagenprincipal, setImagenprincipal] = useState(null);
const [imagensecundario, setImagensecundario] = useState(null);
const [titulo, setTitulo] = useState("");
const [descripcionprincipal, setDescripcionprincipal] = useState("");
const [descuento, setDescuento] = useState("");
const [tipo, setTipo ] = useState("");
const [beneficio, setBeneficio ] = useState("");
const backendURL = process.env.REACT_APP_BACKEND_URL;

    var toolbarOptions = [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],
    
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction
    
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
    
        [{ 'color': ['black','#4444','#696965','Dark Gray', 'grey','red','Indian Red', 'blue','Royal Blue', 'green','white','violet','aqua','orange','yellow','pink','purple', 'Turquoise', 'Teal', 'Lime', 'Cyan', 'Magenta', 'Brown', 'Maroon', 'Silver'] },
         { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
    
        ["clean"],
      ];
      const module = {
        toolbar: toolbarOptions,
      };

      const handleGuardar = async (e) => {
        e.preventDefault();
      
        const formData = new FormData();
        formData.append("fecha_inicio", fechaInicio);
        formData.append("fecha_termino", fechatermino);
        formData.append("titulo", titulo);
        formData.append("descripcion_principal", descripcionprincipal);
        formData.append("beneficios", beneficio);
        formData.append("tipo", tipo);
        formData.append("descuento", descuento);
        formData.append("imagen_principal", imagenprincipal);
        formData.append("imagen_secundaria", imagensecundario);

      
        // Agregar un console.log para verificar los valores en formData
        console.log("formData:", formData);
      
        try {
          const response = await axios.post(`${backendURL}/guardar_beneficio`, formData);
          console.log("Response:", response.data);
          onClose();
          // Add any additional logic you want after successful creation
        } catch (error) {
          console.error("Error:", error);
          // Handle error cases
        }
      };
    
      const handlefechainicioChange = (e) => {
        setFechaInicio(e.target.value);
      };
      const handlefechaterminoChange = (e) => {
        setFechatermino(e.target.value);
      };
    
      const handleImagenprincipalChange = (e) => {
        setImagenprincipal(e.target.files[0]);
      };

      const handleImagensecundarioChange = (e) => {
        setImagensecundario(e.target.files[0]);
      };
    
      const handletituloChange = (e) => {
        setTitulo(e.target.value);
      };
    
      const handledesprinChange = (e) => {
        setDescripcionprincipal(e.target.value);
      };
      
      const handlebeneficionotChange = (content) => {
        setBeneficio(content);
      };
      const handletipoChange = (e) => {
        setTipo(e.target.value);
      };

      const handledescuentoChange = (e) => {
        setDescuento(e.target.value);
      };

    
    

    return(
        <>
       
      <button className="close-button-admi" onClick={onClose}>
        X
      </button>
      <div className="titulo-admi-info">
        <h2>Crear Beneficio</h2>
      </div>
      <form onSubmit={handleGuardar} >
      <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen Principal:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen"
            onChange={handleImagenprincipalChange}
           
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen Secundario:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen"
            onChange={handleImagensecundarioChange}
            
          />
        </div>

        <div>
          <label className="titulo-input-admi" htmlFor="url">
          Titulo :
          </label>
          <input
            className="input-admi"
            type="text"
            value={titulo}
            onChange={handletituloChange}
            
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
          Tipo :
          </label>
          <input
            className="input-admi"
            type="text"
            value={tipo}
            onChange={handletipoChange}
            
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
          Descuento:
          </label>
          <input
            className="input-admi"
            type="text"
            value={descuento}
            onChange={handledescuentoChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
          Descripcion_prinicipal:
          </label>
          <input
            className="input-admi"
            type="text"
            value={descripcionprincipal}
            onChange={handledesprinChange}

          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
          fecha inicio:
          </label>
          <input
            className="input-admi"
            type="text"
            value={fechaInicio}
            onChange={handlefechainicioChange}
          />
          
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
          fecha termino:
          </label>
          <input
            className="input-admi"
            type="text"
            value={fechatermino}
            onChange={handlefechaterminoChange}
          />
          
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
          Beneficio
          </label>
          
          <ReactQuill
          modules={module}
          theme="snow"
          className="input-admi"
          id="descripcionnoticia"
          value={beneficio}
          onChange={handlebeneficionotChange}
          
        />
</div>
<div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
          

        </form>
        </>
    )
}