import React, { useState } from 'react';
import axios from 'axios';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const Botoncrearchef = ({ agregarChef, cerrarVentana }) => {
  const [imagen, setImagen] = useState(null);
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [url, setUrl] = useState('');
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleImagenChange = (event) => {
    setImagen(event.target.files[0]);
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('imagen', imagen);
    formData.append('nombre', nombre);
    formData.append('descripcion', descripcion);
    formData.append('url', url);

    
    axios.post(`${backendURL}/guardar_chef`, formData)
      .then((response) => {
        const nuevoChef = response.data;
        agregarChef(nuevoChef);
        cerrarVentana(); // Llamada a la función para cerrar la ventana
      })
      .catch((error) => {
        console.error(error);
        // Manejar errores de la solicitud
      });
  };

  const handleDescripcionChange = (value) => {
    setDescripcion(value); // Usar directamente el valor pasado como argumento
  };

  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [
      { 'color': ['black','#4444','#696965','Dark Gray', 'grey','red','Indian Red', 'blue','Royal Blue', 'green','white','violet','aqua','orange','yellow','pink','purple', 'Turquoise', 'Teal', 'Lime', 'Cyan', 'Magenta', 'Brown', 'Maroon', 'Silver'] }, // Specify custom colors
      { 'background': ['#696965', 'yellow', 'pink', 'orange'] }  // Specify custom background colors
    ],
    [{ 'font': [] }],
    [
      { 'align': [] },
      { 'align': ['center', 'right', 'justify'] }  // Additional alignment options
    ],
  
    ['clean']   
  ];
  const module = {
    toolbar: toolbarOptions,
  };

  return (
    <>
      <div className="titulo-admi-info">
        <h2>Descripción Chef cursos y diplomados</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen"
            onChange={handleImagenChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="nombre">
            Nombre:
          </label>
          <input
            className="input-admi"
            type="text"
            id="nombre"
            value={nombre}
            onChange={(event) => setNombre(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="descripcion">Descripción:</label>
          <ReactQuill
            modules={module}
          theme="snow"
            className="input-admi"
            id="descripcion"
            value={descripcion}
            onChange={handleDescripcionChange}/>
          
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            URL:
          </label>
          <input
            className="input-admi"
            type="text"
            id="url"
            value={url}
            onChange={(event) => setUrl(event.target.value)}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};

