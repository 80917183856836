import React from "react";
import { SliderBeneficios } from "../component/beneficios/slider-beneficios";
import { PortadoBeneficio } from "../component/beneficios/portada-beneficios";

export const Beneficios = () => {

    return(
        <div className="todo-home">
        <PortadoBeneficio/>
        <SliderBeneficios/>
        </div>
    )
}