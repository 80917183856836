import React, { useState, useEffect, useRef } from "react";
import "../../../styles/estructura/estruccursos.css";
import { FiCheckCircle, FiX } from "react-icons/fi";
import GaleriaCursos from "../../cursoydiplomadoportada.js/galeriacursos";
import regalogifcard from "../../../img/estructuras/blog/cursos/regalo1.png";
import regalo from "../../../img/estructuras/blog/cursos/regalo.png";
import Banner from "../../../img/cursosydiplomados/Estudiante.png";
import bannermovil from "../../../img/cursosydiplomados/BANNER-SEC-DCTO-RESP.png";
import { BsDashCircle, BsPlusCircle } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { CarritoCompraDespliegue } from "../../carrito-compra";
import { useCart } from "../../CartContext";

export const PrecioEstructuraCursos = ({ curso }) => {
  const { id } = useParams();
  const navigate = useNavigate(); // Obtiene la función navigate
  const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCarritoOpen, setIsCarritoOpen] = useState(false);
  const [precioGifcard, setPrecioGifcard] = useState(0);
  const [mensaje, setMensaje] = useState("");
  const [cursosEnCarrito, setCursosEnCarrito] = useState([]);
  const { addItemToCart, toggleCartVisibility, isCartVisible } = useCart();
  const [isBuyingNow, setIsBuyingNow] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState({});
  const [tuNombre, setTuNombre] = useState(localStorage.getItem('tuNombre') || '');
const [nombreInvitado, setNombreInvitado] = useState(localStorage.getItem('nombreInvitado') || '');
const [dedicatoria, setDedicatoria] = useState(localStorage.getItem('dedicatoria') || '');
  const tuNombreRef = useRef(); 
  const nombreInvitadoRef = useRef();
  const dedicatoriaRef = useRef();
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado de isMobileView en función del ancho de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Cambia a "true" si el ancho es menor o igual a 700px
    };

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Llama a handleResize() una vez al cargar la página para establecer el estado inicial
    handleResize();

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
   

    if (isBuyingNow) {
      // Oculta el carrito antes de la navegación
      const queryParams = new URLSearchParams();
      
  
      toggleCartVisibility(false);
      navigate(`/pago?ids=${JSON.stringify(selectedProductIds)}&${queryParams.toString()}`);
    }
  }, [selectedProductIds, isBuyingNow, toggleCartVisibility, navigate]);

  const handleBuyNowClick = () => {
    handleStartClick();
    const tuNombre = tuNombreRef.current.value;
    const nombreInvitado = nombreInvitadoRef.current.value;
    const dedicatoria = dedicatoriaRef.current.value;
  
    // Guarda los valores en el localStorage
    localStorage.setItem('tuNombre', tuNombre);
    localStorage.setItem('nombreInvitado', nombreInvitado);
    localStorage.setItem('dedicatoria', dedicatoria);
  
    // Agrega el regalo al estado selectedProductIds
    setSelectedProductIds((prevSelectedProductIds) => ({
      ...prevSelectedProductIds,
      [curso.id]: 1, // Ajusta la cantidad según sea necesario
    }));
  
    // Agrega el regalo al carrito
    addItemToCart(curso);
  
    setIsBuyingNow(true);
    toggleCartVisibility(false);
  };

  const handleBuyNowClickes = () => {
    handleStartClick();
    // Agrega el curso al estado selectedProductIds con la cantidad seleccionada
    setSelectedProductIds((prevSelectedProductIds) => ({
      ...prevSelectedProductIds,
      [curso.id]: 1, // Ajusta la cantidad según sea necesario
    }));
  // Agrega el curso al carrito
  addItemToCart(curso);
  setIsBuyingNow(true);
  toggleCartVisibility(false);

  };

  const handleAgregarAlCarrito = (curso) => {
   
    addItemToCart(curso);
    toggleCartVisibility(); 
  };
  
  // Cuando el componente se monta, verifica si hay valores guardados en el estado local
   useEffect(() => {
    // Cargar datos desde el local storage cuando el componente se monta
    const savedTuNombre = localStorage.getItem('tuNombre') || '';
    const savedNombreInvitado = localStorage.getItem('nombreInvitado') || '';
    const savedDedicatoria = localStorage.getItem('dedicatoria') || '';

    setTuNombre(savedTuNombre);
    setNombreInvitado(savedNombreInvitado);
    setDedicatoria(savedDedicatoria);

    // Agregar un evento para limpiar el local storage al actualizar la página
    const handleBeforeUnload = () => {
      localStorage.removeItem('tuNombre');
      localStorage.removeItem('nombreInvitado');
      localStorage.removeItem('dedicatoria');
    };
   
    

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remover el evento al desmontar el componente
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Cuando cambian los valores de los campos, guárdalos en el estado local
  useEffect(() => {
    localStorage.setItem('tuNombre', tuNombre);
    localStorage.setItem('nombreInvitado', nombreInvitado);
    localStorage.setItem('dedicatoria', dedicatoria);
   
  }, [tuNombre, nombreInvitado, dedicatoria]);

  
  const handleAgregarCarritoClick = (curso) => {
    setCursosEnCarrito([...cursosEnCarrito, curso.id]);
    setCursoSeleccionado(curso);
    setIsCarritoOpen(true);
  };
  
  const handleRegalarClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleInputChange = (ref, setState) => {
    const value = ref.current.value;
    setState(value);
  };

 

  const redirigirCorreo = () => {
    window.location.href = "mailto:cursos@escuelaculinaria.cl";
  };


  const handleStartClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="contiene-precio">
      <section className="container-xl pago-curso">
        <div className="titulo-principal-precio">
          <h1 className="titulo-principal-precio-compra">
            ¡Inscríbete y asegura tu cupo!
          </h1>
        </div>
        <div className="precio-regalo-gif">
          <div className="row row-cols-1 row-cols-md-3 g-4 precio-regalo-gif">
            <div className="botones-compra" >
              <button
                className="compra"
                
                onClick={() => handleAgregarAlCarrito(curso)}
              >
                <span>Añadir al carrito</span>
              </button>
            </div>
            <div className="botones-compra" onClick={() => window.scrollTo(0, 0)}>
              <button className="compra" onClick={handleBuyNowClickes}>
                <span>Comprar ahora</span>
              </button>
            </div>
            <div className="botones-compra">
              <div className="regalo-gif" data-tooltip="Size: 20Mb">
                <div class="regalo" onClick={handleRegalarClick}>
                  <div class="text">
                    <div className="img-icono-regalo">
                      <img src={regalo} alt="Regalo" />
                    </div>
                    Regalar
                  </div>
                  <span class="icon">
                    <div className="img-icono-regalo">
                      <img src={regalo} className="r" alt="Regalo" />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isPopupOpen && (
          <div className="popup-overlay-gifcard">
            
            <div className="popup-content-gifcard">
              
              <div className="card gifcard">
               
                <section className="gifcard"> <div className="boton-gifcard">
              <button
                className="close-popup-gifcard"
                onClick={handleClosePopup}
              >
                <FiX className="icono-close-gifcard" />
              </button>
            </div>
                 <div className="info-gifcard-principal">
                   <div className="imagen-gifcard">
                    <img
                      src={regalogifcard}
                      className="regalogifcard"
                      alt="Regalo"
                    />
                    
                  </div>
                  <div className="gif-curso">
                    <h1 className="titulo-gif">{curso.nombre_curso}</h1>
                    <p className="precio-gif">
                      ${curso.precio_final.toLocaleString()}
                    </p>
                    </div></div>
                  <div className="titulo-info-gifcard">
                    <h1 className="titulo-gifcard">
                      REGALA MOMENTOS MEMORABLES
                    </h1>
                    <span className="descripcion-gifcard">
                    Completa los datos y recibirás una Gift Card personalizada en tu correo para tu invitad@.
                    </span>

                    
                    <div className="formulario-gifcard">
                      <input
                        class="controls"
                        type="text"
                        name="nombre"
                        id="tuNombre"
                        placeholder="Tu Nombre"
                        ref={tuNombreRef}
                        defaultValue={tuNombre}
                        onChange={() => handleInputChange(tuNombreRef, setTuNombre)}
                      />

                      <input
                        class="controls"
                        type="nombre"
                        name="nombre"
                        id="nombreInvitado"
                        placeholder="Nombre invitad@"
                        ref={nombreInvitadoRef}
                        defaultValue={nombreInvitado}
                        onChange={() => handleInputChange(nombreInvitadoRef, setNombreInvitado)}
                        

                      />
                      <textarea
                        className="controls"
                        name="dedicatoria"
                        id="dedicatoria"
                        placeholder="Dedicatoria"
                        ref={dedicatoriaRef}
                        defaultValue={dedicatoria}
                        onChange={() => handleInputChange(dedicatoriaRef, setDedicatoria)}
                        
                      />
                    </div>
                    <div className=" boton-regalar-gifcard">
                      {" "}
                      <div className="regalo-gif" data-tooltip="Size: 20Mb" onClick={() => window.scrollTo(0, 0)}>
                        <div class="regalo" onClick={handleBuyNowClick}>
                          <div class="text">
                            <div className="img-icono-regalo">
                              <img src={regalo} alt="Regalo" />
                            </div>
                            Regalar
                          </div>
                          <span class="icon">
                            <div className="img-icono-regalo">
                              <img src={regalo} className="r" alt="Regalo" />
                            </div>
                          </span>
                        </div>
                      </div>
                     <span className="condiciones"> Ver condiciones de certificado de regalo</span>
                    </div>
                    
                  </div>
                </section>
              </div>
            </div>
          </div>
        )}
        <div className="info-extra-precio">
          <img
            src={isMobileView ? bannermovil : Banner}
            className="w-100 estudiantedescuento"
            alt="Banner"
          />
          <h2 className="info-curso-estudiante" >
            Escríbenos a:{" "}
            <button
              className="buton-correo-descuento"
              onClick={redirigirCorreo}
            >
              <strong>cursos@escuelaculinaria.cl</strong>
            </button>
          </h2>
        </div>
      </section>
    
    </div>
  );
};
