import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GrFormClose } from "react-icons/gr";
import { useCart } from "./CartContext";

import "../styles/carrito/carrito.css";

export const CarritoCompraDespliegue = () => {
  const [scrollEnabled, setScrollEnabled] = useState(false);
  const { cartItems, removeItemFromCart, clearCart, isCartVisible, toggleCartVisibility } = useCart();
  const [productQuantities, setProductQuantities] = useState({});
  const [isBuyingNow, setIsBuyingNow] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [discountValue, setDiscountValue] = useState(0)
  const [discountType, setDiscountType] = useState("");
   const [total, setTotal] = useState(0); // Estado para el total sin descuento
   const [discountApplied, setDiscountApplied] = useState(0); 
   const [cursoId, setCursoId] = useState(""); // Define cursoId
   const [descuento, setDescuento] = useState(0); // Define descuento
   const [isPercentageDiscount, setIsPercentageDiscount] = useState(false);
   const backendURL = process.env.REACT_APP_BACKEND_URL;
  /*const [discountedTotal, setDiscountedTotal] = useState(0); */
  

  
  const navigate = useNavigate(); // Obtiene la función navigate
  // ...

  const handleStartClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleBuyNowClick = () => {
    handleStartClick();
    setIsBuyingNow(true);
  
    // Obtén los IDs de los cursos seleccionados
    const selectedIds = cartItems.map((item) => item.id);
  
    // Obtén la cantidad de cada producto
    const quantities = { ...productQuantities };
  
    // Cierra el carrito antes de navegar a la página de pago
    toggleCartVisibility();
  
    // Construye un objeto que contenga las cantidades de cada producto seleccionado
    const quantitiesObject = {};
    selectedIds.forEach((id) => {
      quantitiesObject[id] = quantities[id] || 1;
    });
  
    // Navega al componente Pago con los IDs, cantidades, descuento y discountApplied en la URL
    navigate(`/pago?ids=${JSON.stringify(quantitiesObject)}`);
  };

 

/*  */

  
  useEffect(() => {
    const calculateTotal = () => {
      const cartTotal = cartItems.reduce((acc, item) => {
        const quantity = productQuantities[item.id] || 1;
        const itemTotal = item.precio_final * quantity;
        return acc + itemTotal;
      }, 0);
  
      setTotal(cartTotal);
    };
  
    calculateTotal();
  }, [cartItems, productQuantities]);

 

  return (
    <section className={`carrito ${isCartVisible ? "visible" : "oculto"}`}>
      <div className="carrito-desplegado">
        <section className="boton-cerrar-carrito">
          <button
            className="close-carrito-compra"
            onClick={toggleCartVisibility}
          >
           <p className="button-close-carrito">
            <GrFormClose/></p>
          </button>
        </section>
        <h1 className="titulo-carrito">CARRITO DE COMPRA</h1>
        <div className="mapeo-cursos-compra">
          <div
            className={`cursos-container ${
              scrollEnabled ? "scrollable" : ""
            }`}
          >
            {cartItems.map((item, index) => {
              const quantity = productQuantities[item.id] || 1;
              return (
                <div className="curso-seleccionado" key={index}>
                  <div className="row carrito-curso">
                    <div className="col-3 ">
                     <div className="img-carrito"> {item.imagen_principal_url && (
                        <img
                          src={item.imagen_principal_url}
                          className="imagen-curso-seleccionado"
                          alt="Imagen del curso"
                        />
                      )}</div>
                    </div>
                    <div className="col-7 ">
                      <div className="nombre-curso-carrito">
                        <h3 className="categoria-curso-carrito">
                          <p>{item.categoria}</p>
                        </h3>
                        <h2 className="nombre-curso-carrito">
                          {item.nombre_curso}
                        </h2>
                        <p className="precio-curso-carrito">
                          {item.precio_final.toLocaleString()}
                        </p>
                      </div>
                    </div>
                    <div className="col-2 ">
                      <div className="cantidad-curso-carrito">
                       
                         <button
                          className="eliminar-curso-carrito"
                          onClick={() => removeItemFromCart(item)}
                        >
                          Eliminar
                        </button> 
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <section className="descuento">
        </section> 
        <hr className="separador-carrito" />
        <section className="total-carrito">
          <div className="row precio-curso">
            <div className="col-6 curso-precio-nombre"><p className="titulo-total-precio">Total</p></div>
            <div className="col-6 curso-precio"><p className="titulo-precio-final"> ${total.toLocaleString()}</p></div>
          </div>
          <div className="button-compra-pago-final" onClick={() => window.scrollTo(0, 0)}>
          <button className="comprar-continuar"  onClick={handleBuyNowClick}>CONTINUAR COMPRA</button>
          </div>
        </section>
      </div>
    </section>
  );
};