import React from "react";
import "../../styles/style Home/home.css";

/* imagenes */

import infohome2 from "../../img/home/fondo.png";
import icono1 from "../../img/home/iconop.png";
import icono2 from "../../img/home/iconor.png";

/* librerias */


export const Info2home = () => {

  return (
    <>
      <div
        
        style={{
          backgroundImage: `url(${infohome2})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className=" segundainfohome"
      >
        <div className="container infosegundahome">
          <div className="row segundainfohome">
            <div className="col col-sm-12 col-md-6 segundainfohome">
              <h1 className="titulo-segundainfohome1">SOMOS ESCUELA</h1>
              <div className="numeracion-segundainfo">
                <img src={icono2} className="w-100 img-segundainfohome" />
                <h1 className="numeracion-segundainfohome">+25</h1>
              </div>
              <h3 className="info-segundainfohome">
                Restaurantes de "Les Maîtrise de Cuisine Française" con convenio
                exclusivo para pasantía en Francia.
              </h3>
            </div>
            <div className="col col-sm-12 col-md-6 segundainfohome">
              <h1 className="titulo-segundainfohome">NUESTROS PROFESIONALES</h1>
              <div className="numeracion-segundainfo">
                <img src={icono1} className="w-100 img-segundainfohome" />
                <h1 className="numeracion-segundainfohome">+300</h1>
              </div>
              <h3 className="info-segundainfohome">
                Titulados "Maîtres Cuisiniers de France", una gran trayectoria
                de 19 años formando profesionales.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
