import React, { useState, useEffect } from "react";
import { BiTrash } from "react-icons/bi";
import { CrearProximoCurso } from "./crear-proximo-curso";
import axios from "axios";

export const ProximosCursos = () => {
  const [cursosproximo, setCursosproximo] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    obtenerProximosCursos();
  }, []);

  const obtenerProximosCursos = async () => {
    try {
      const response = await fetch(`${backendURL}/proximos_cursos`);
      const data = await response.json();
      setCursosproximo(data);
    } catch (error) {
      console.error("Error al obtener los próximos cursos:", error);
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleEliminarCurso = async (proxicursoId) => {
    try {
      await axios.delete(`${backendURL}/eliminar_proximos_cursos/${proxicursoId}`);
      obtenerProximosCursos();
    } catch (error) {
      console.error("Error al eliminar el próximo curso:", error);
    }
  };

  return (
    <>
      <section>
        <h1 className="titulo-info-componenete-admi">Próximos Cursos</h1>
      </section>
      <section className="boton-crear-chef-cursosydiplomado">
        <button className="boton-crear" onClick={togglePopup}>
          Crear
        </button>
      </section>
      {showPopup && (
        <div className="popup-admi">
          <div className="popup-content-admi">
            <CrearProximoCurso onClose={togglePopup} />
          </div>
        </div>
      )}
      <section className="lista-chef-cursosydiplomado">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Imagen Web</th>
              <th className="titulobd">Imagen Mobil</th>
              <th className="titulobd">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {cursosproximo.map((proxicurso) => (
              <tr key={proxicurso.id} className="info-bd">
                <td className="info-bd">
                  <img
                    className="imagen-info-admi-web"
                    src={`${backendURL}/imagen_proximocurso/${proxicurso.imagen_web}`}
                    alt="Imagen Web"
                  />
                </td>
                <td className="info-bd">
                  <img
                    className="imagen-info-admi"
                    src={`${backendURL}/imagen_proximocurso/${proxicurso.imagen_mobil}`}
                    alt="Imagen Mobil"
                  />
                </td>
                <td className="edicion">
                  <button
                    className="boton-eliminar"
                    onClick={() => handleEliminarCurso(proxicurso.id)}
                  >
                    <BiTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </>
  );
};