import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const Botoneditarvideoadmision = ({
  cerrarVentana,
  videoadmisionId,
  actualizarPortadaEnLista
}) => {
  const [portadaData, setPortadaData] = useState({
    descripcion_video_admi: "", // Cambia null a una cadena vacía
    url_video_admi: ""
  });
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    console.log('Portada ID:',  videoadmisionId);
    obtenerPortada();
  }, [videoadmisionId]);

  const obtenerPortada = () => {
    axios
      .get(`${backendURL}/obtenervideos_admision/${videoadmisionId}`)
      .then((response) => {
        setPortadaData(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener los datos de la video admision:', error);
      });
  };

  const handleGuardar = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('descripcion_video_admi', portadaData.descripcion_video_admi);
    formData.append('url_video_admi', portadaData.url_video_admi);

    try {
      const response = await axios.put(`${backendURL}/editarvideos_admision/${videoadmisionId}`, formData);

      console.log(response.data);
      cerrarVentana();
      actualizarPortadaEnLista(videoadmisionId, portadaData);

      // Clear the URL input after successful submission
      setPortadaData({
        ...portadaData,
        url_video_admi: ""
      });
    } catch (error) {
      console.error('Error al actualizar la portada:', error);
    }
  };

  const handleChange = (value) => {
    setPortadaData({
      ...portadaData,
      descripcion_video_admi: value
    });
  };

  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean']   
  ];

  const modules = {
    toolbar: toolbarOptions,
  };

  return (
    <>
      <div className="titulo-admi-info">
        <h2>Editar Portada Admision</h2>
      </div>
      <form onSubmit={handleGuardar}>
        <div>
          <label className="titulo-input-admi" htmlFor="descripcion_video_admi">
            Descripción:
          </label>

          <ReactQuill
            modules={modules} // Cambia "module" a "modules"
            theme="snow"
            className="input-admi"
            id="descripcion_video_admi"
            value={portadaData.descripcion_video_admi}
            onChange={handleChange} // Agrega el manejador de cambios
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url_video_admi">
            URL:
          </label>
          <input
            className="input-admi"
            type="text"
            id="url_video_admi"
            value={portadaData.url_video_admi}
            onChange={(e) => setPortadaData({ ...portadaData, url_video_admi: e.target.value })}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};