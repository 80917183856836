import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

/* imagenes */

import imagendeunidad from "../../img/unidaddegenero/politicas.png";
import descarga from "../../img/admision/descarga.png";
import acoso from "../../img/unidaddegenero/acoso-sexual-ecole.png";
import discriminacion from "../../img/unidaddegenero/discriminacion-genero-ecole.png";
import violencia from "../../img/unidaddegenero/violencia-genero-ecole.png";

export const PDFunidaddegenero = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [noticias, setNoticias] = useState([]);
  const [lightboxOpens, setLightboxOpens] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
   const backendURL = process.env.REACT_APP_BACKEND_URL;

  const openLightbox = () => {
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const response = await axios.get(
          `${backendURL}/obtener_imagenunidad`
        );
        setGalleryImages(response.data.imagenunidad);
      } catch (error) {
        console.error("Error fetching gallery images:", error);
      }
    };

    fetchGalleryImages();
  }, []);

  const obtenerInformacionPDF = async () => {
    try {
      const response = await axios.get(
        `${backendURL}/static/pdfunidaduno/politicasunidad.pdf`,
        {
          responseType: "blob", // Indicamos que esperamos una respuesta de tipo blob (archivo binario)
        }
      );

      // Crear una URL temporal para el blob
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Crear un enlace temporal y simular un clic para descargar el PDF
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "politicasunidad.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el PDF:", error);
    }
  };

  const obtenerPDF = async () => {
    try {
      const response = await axios.get(
        `${backendURL}/static/pdfunidaduno/politicasunidad.pdf`,
        {
          responseType: "blob", // Indicamos que esperamos una respuesta de tipo blob (archivo binario)
        }
      );

      // Crear una URL temporal para el blob
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Crear un enlace temporal y simular un clic para descargar el PDF
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "politicasunidad.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el PDF:", error);
    }
  };

  const obtenerPDFdos = async () => {
    try {
      const response = await axios.get(
        `${backendURL}/static/pdfpoliticas/politicasescuela.pdf`,
        {
          responseType: "blob", // Indicamos que esperamos una respuesta de tipo blob (archivo binario)
        }
      );

      // Crear una URL temporal para el blob
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Crear un enlace temporal y simular un clic para descargar el PDF
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "politicasescuela.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el PDF:", error);
    }
  };

  const obtenerPDFtres = async () => {
    try {
      const response = await axios.get(
        `${backendURL}/static/pdfpoliticas/politicasescuela.pdf`,
        {
          responseType: "blob", // Indicamos que esperamos una respuesta de tipo blob (archivo binario)
        }
      );

      // Crear una URL temporal para el blob
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Crear un enlace temporal y simular un clic para descargar el PDF
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "politicasunidadgenero.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el PDF:", error);
    }
  };

  /* noticiasunidad */
  useEffect(() => {
    // Hacer la solicitud GET para obtener las noticias
    fetch(`${backendURL}/obtener_noticiasunidad`)
      .then((response) => response.json())
      .then((data) => setNoticias(data));
  }, []);

  return (
    <div>
      <div className=" container pdf unidad">
        <div className=" titulo  pdf unidad ">
          <h1 className="titulo unidad descarga"></h1>
          <h4 className="subtitulo unidad descarga"></h4>
        </div>

        <div className="row  pdf unidad">
          <div className="col-12  pdf unidad">
            {galleryImages.map((image, index) => (
              <div className=" pdf unidad imagen" key={index}>
                <a
                  href={`${backendURL}/static/politica/unidaddegenero.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`${backendURL}/imagen_imagenunidad/${image.imagenunidad}`}
                    className="card-img-top  pdf unidad w-100"
                    alt="..."
                    onClick={openLightbox}
                  />
                </a>
              </div>
            ))}
            {/*  {lightboxOpen && (
              <div className="lightbox-overlay" onClick={closeLightbox}>
                <div className="lightbox-content">
                  <img src={imagendeunidad} alt="Imagen" />
                </div>
              </div>
            )} */}
          </div>
          <div className="col-12 pdf unidad">
            <section>
              <h1 className="titulo-noticias-destaca-unidad">NOTICIAS</h1>
            </section>
            <section className="estructura-noticia-unidad">
              {noticias.map((noticia, index) => (
                <div className="pdf unidad imagen" key={index}>
                  <a
                    href={noticia.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="imagen-noticia-unidad">
                      <img
                        src={noticia.imagen_url}
                        className="pdf-unidad W-100"
                        alt={noticia.titulo} // Puedes usar el título como texto alternativo
                      />
                    </div>
                  </a>
                  <div className="info-noticia-unidad">
                    <h1 className="titulo-unidad-noticia">{noticia.titulo}</h1>
                    <p className="descripcion-noticia-unidad">
                      {noticia.descripcion}
                    </p>
                  </div>
                </div>
              ))}
            </section>
          </div>
        </div>
        <section className="buton-noticias">
          {" "}
          <Link to="/noticias">
            {" "}
            <button className="button-noticias-unidad">
              {" "}
              MÁS NOTICIAS{" "}
              <label className="container-unidad-noticia">
                <svg
                  className="chevron-right"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 320 512"
                >
                  <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                </svg>
              </label>
            </button>
          </Link>
        </section>
      </div>
      <div className="descarga pdf">
        <div className="titulo-unidad-descarga">
          <h1 className="titulo-unidad-descarga">COMPROMISO DE ECOLE CON EL</h1>
          <h2 className="titulo-unidad-descarga">
            CUMPLIMIENTO DE LA LEY 21.369
          </h2>
        </div>
        <section className="info-unidad-dos">
          <div className="container">
            <p className="segundo-info-unidad">
              El Centro de Formación Técnica Ecole se rige por ciertos
              principios y valores éticos y morales, como son{" "}
              <strong>
                {" "}
                el respeto por la dignidad humana, la no discriminación
                arbitraria, la igualdad de trato y género, la defensa
                irrestricta de los derechos humanos y el velar por la integridad
                física y psíquica de toda la comunidad.
              </strong>
            </p>

            <p className="segundo-info-unidad">
              Lo anteriormente descrito sumado al marco de{" "}
              <strong>Ley No 21.369</strong> , que regula la prevención,
              investigación y sanción del acoso sexual, la violencia y la
              discriminación de género en el ámbito de la educación superior, la
              presente política tiene por objeto prevenir, investigar y
              sancionar cualquier acto o situación constitutiva de acoso sexual,
              violencia y discriminación de género producidos en el ámbito
              académico, de investigación, extensión o de vinculación con el
              medio, que atente contra la seguridad, libertad y dignidad de la
              comunidad estudiantil y comunidad educativa y administrativa.
            </p>
          </div>{" "}
          <div className="conceptos-unidad">
            <section className="titulo-conceptos-unidad">
              <h1 className="titulo-conceptos-unidad">CONCEPTOS</h1>
            </section>
            <section className="conceptos-informacion">
              <div className="container">
                <div className="row">
                  <div className=" col-sm-12 col-md-4">
                    <div className="conceptos">
                      <div className="imagen-conceptos">
                        <img src={acoso} alt="" />
                      </div>
                      <div className="info-conceptos">
                        <h3 className="titulo-info-concepto-unidad">
                          Acoso Sexual
                        </h3>
                        <p className="info-unidad-concept">
                          Incluye toda práctica, comentario o actitud con
                          implicancias sexuales no deseadas dirigidas hacia una
                          persona que está en una posición de subordinación, en
                          especial en ámbitos laborales y educativos. El acoso
                          sexual es una forma de discriminación de género.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" col-sm-12 col-md-4">
                    <div className="conceptos">
                      <div className="imagen-conceptos">
                        <img src={discriminacion} alt="" />
                      </div>
                      <div className="info-conceptos">
                        <h3 className="titulo-info-concepto-unidad">
                          Discriminación de Género
                        </h3>
                        <p className="info-unidad-concept">
                          Cualquier distinción, exclusión o restricción que se
                          haga en función de roles y normas establecidas
                          socialmente para los géneros y que impiden que una
                          persona, ya sea hombre o mujer pueda ejercer
                          plenamente sus derechos humanos.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" col-sm-12 col-md-4">
                    <div className="conceptos">
                      <div className="imagen-conceptos">
                        <img src={violencia} alt="" />
                      </div>
                      <div className="info-conceptos">
                        <h3 className="titulo-info-concepto-unidad">
                          Violencia de Género
                        </h3>
                        <p className="info-unidad-concept">
                          Todo acto que resulta o puede resultar en daño o
                          sufrimiento físico, sexual, psicológico o económico a
                          una persona por razón de su genero
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <div className="descargable-pdf">
          <section className="titulo-descargable">
            <h1 className="titulo-descargable">DOCUMENTOS DESCARGABLES</h1>
            <h2 className="titulo-descargable">PDF • WORD</h2>
          </section>
          <div className="container-fluid denuncia">
          <div className="row  denuncia-imagen">
            <div className="col pdf-unidad-des">
              <a
                href={`${backendURL}/static/pdfunidaduno/politicasunidad.pdf`}
                target="_blank"
              >
                <button
                  className="buttonDownload-unidad"
                  onClick={obtenerInformacionPDF}
                >
                  <div className="info-descarga-boton">
                  <h2 className="titulo-descarga-pdf">Ley 21.369</h2>
                  <div className="imagen-descarga-denuncia">
                    <img
                      src={descarga}
                      className="w-100 descarga-pdf-denuncia"
                    />{" "}
                  </div>
                </div>
                </button>
              </a>
            </div>
            {/*   <div className="col pdf-unidad-des"><h1>|</h1></div> */}

            <div className="col pdf-unidad-des">
              <a
                href={`${backendURL}/static/pdfunidaddos/politicasunidadgenero.pdf`}
                target="_blank"
              >
                <button
                  className="buttonDownload-unidad"
                  onClick={obtenerPDFdos}
                >
                  <div className="info-descarga-boton">
                  <h2 className="titulo-descarga-pdf">Qué hacer en caso de denuncia</h2>
                  <div className="imagen-descarga-denuncia">
                    <img
                      src={descarga}
                      className="w-100 descarga-pdf-denuncia"
                    />{" "}
                  </div></div>
                </button>
              </a>
            </div>
            {/*   <div className="col pdf-unidad-des"></div><h1>|</h1> */}
            <div className="col pdf-unidad-des">
              <a
                href={`${backendURL}/static/pdfpoliticas/politicasescuela.pdf`}
                target="_blank"
              >
                <button
                  className="buttonDownload-unidad"
                  onClick={obtenerPDFtres}
                > <div className="info-descarga-boton">
                  <h2 className="titulo-descarga-pdf">Política École ley 21.369</h2>
                  <div className="imagen-descarga-denuncia">
                    <img
                      src={descarga}
                      className="w-100 descarga-pdf-denuncia"
                    />{" "}
                  </div>
                  </div>
                </button>
              </a>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};
