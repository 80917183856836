import React from "react";
import "../styles/egresados/egresado.css";

/* componenetes */
import { Portadaegresado } from "../component/egresados/portadaegresados";
import { Bienvenidaegresado } from "../component/egresados/bienvenidaegresado";
import { Egresadoscart } from "../component/egresados/cart-egresados";
import { BannerEmpleo } from "../component/egresados/banner-egresado";
export const Egresados = () => {
  return (
    <div className="todo-home">
      
        <Portadaegresado />
     
      <div className=" bienvenidaegresado">
        <Bienvenidaegresado />
      </div>

      <div className="egresados">
        <Egresadoscart />
      </div>
      <div className="banner empleo">
        <BannerEmpleo />
      </div>
    </div>
  );
};
