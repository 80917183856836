import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiTrash } from "react-icons/bi";
import { DocentesGet } from "./get-docentes";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const Docentes = ({ areaId, onClose }) => {
  const [areaData, setAreaData] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [nombreDocente, setNombreDocente] = useState("");
  const [tipoDocente, setTipoDocente] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [imagen, setImagen] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (areaId) {
      axios
        .get(`${backendURL}/obtener_area/${areaId}`)
        .then((response) => {
          setAreaData(response.data.area);
          setSelectedAreaId(areaId);
        })
        .catch((error) => {
          console.error("Error fetching area data:", error);
        });
    }
  }, [areaId]);

  const toggleCreateForm = () => {
    setShowCreateForm(!showCreateForm);
  };

  const handleCreateDocente = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("imagen", imagen);
    formData.append("nombre_docente", nombreDocente);
    formData.append("tipo_docente", tipoDocente);
    formData.append("descripcion", descripcion);
    formData.append("area_docente.id", areaId);

    axios
      .post(`${backendURL}/guardar_docente`, formData)
      .then((response) => {
        setSuccessMessage(response.data.message);
      })
      .catch((error) => {
        setErrorMessage("Error al crear el docente.");
      });
  };
  
  
  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  

  return (
    <>
      <button className="close-button-admi" onClick={onClose}>
        X
      </button>

      <section className="titulo-docentes-admi">
        {areaData && (
          <>
            <h1>Docentes</h1>
            <img
              className="imagen-area-docente"
              src={`${backendURL}/imagen_area/${areaData.imagen}`}
              alt="Área"
            />
          </>
        )}
      </section>
      <section>
        <button className="boton-crear" onClick={toggleCreateForm}>
          Crear
        </button>
        {showCreateForm && (
          <form className="crear-docente-form" onSubmit={handleCreateDocente}>
            <div>
              <label className="titulo-input-admi" htmlFor="imagen">
                Imagen:
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setImagen(e.target.files[0])}
              />
            </div>
            <div>
              <label className="titulo-input-admi" htmlFor="imagen">
                Nombre Docente:
              </label>
              <input
                type="text"
                placeholder="Nombre del docente"
                value={nombreDocente}
                onChange={(e) => setNombreDocente(e.target.value)}
              />
            </div>
            <div>
              <label className="titulo-input-admi" htmlFor="imagen">
                Tipo Area Docente:
              </label>
              <input
                type="text"
                placeholder="Tipo de docente"
                value={tipoDocente}
                onChange={(e) => setTipoDocente(e.target.value)}
              />
            </div>
            <div>
              <label className="titulo-input-admi" htmlFor="imagen">
                Descripcion Docente:
              </label>
              <ReactQuill
               modules={module}
                theme="snow"
                value={descripcion}
                onChange={setDescripcion}
              />
            </div>
            <button type="submit">Guardar</button>
            {successMessage && <p>{successMessage}</p>}
            {errorMessage && <p>{errorMessage}</p>}
          </form>
        )}
      </section>
      <section>  
<DocentesGet areaId={selectedAreaId} /> </section>
    </>
  );
};