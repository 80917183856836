import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import { Link } from "react-router-dom"; 
import { Articulolist } from "../../blog/componentes de tips/articulolist";



export const Articuloblog = () => { 
const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("Mostrar todos");
const [recetas, setRecetas] = useState([]);
const backendURL = process.env.REACT_APP_BACKEND_URL;


const handleLinkClick = () => {

   setTimeout(() => {
    window.location.reload();
  }, 100); // Puedes ajustar el tiempo según sea necesario
  // Hacer scroll hacia arriba cuando se hace clic en el enlace
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

 
};


useEffect(() => {
  let url = `${backendURL}/obtener_recetas`;
  
  if (categoriaSeleccionada !== "Mostrar todos") {
    url += `?categoria=${categoriaSeleccionada}`;
  }

  axios.get(url)
    .then(response => {
      setRecetas(response.data);
    })
    .catch(error => {
      console.error("Error fetching recetas:", error);
    });
}, [categoriaSeleccionada]);

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: true,
      },
    },
  ],
};

return (
  <div className="container articulo">
    <h1 className="titulo articulo">Articulos Relacionados</h1>
   
   <div className="slider-articulos">
   
    <Slider {...settings}>
    {recetas.map((article) => (
<div key={article.id}>
<Link to={`/detalle/estructurablog/${article.id}`}  onClick={handleLinkClick}>
<Articulolist articles={[article]} />
</Link>
</div>
))}
    </Slider>
  </div></div>
);
};