import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Slider from "react-slick";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import testimonio from "../../img/cursosydiplomados/testimonio.png";
import "../../styles/cursoydiplomado/cursoydiplomado.css";

export const TestimonioAlumno = () => {
  const [testimonios, setTestimonios] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    // Fetch testimonios from the backend API
    axios.get(`${backendURL}/obtener_testimonios`)
      .then(response => {
        setTestimonios(response.data);
      })
      .catch(error => {
        console.error("Error fetching testimonios:", error);
      });
  }, []);


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,

    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    centerMode: true,
    centerPadding: "0",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <section>
      <div className="container testimonio-alumno">
        <div className="titulocursospasados">
          <h1 className="cursospasados titulo">TESTIMONIOS</h1>
        </div>
        <div>
          <div className="autoridades-slider">
            <Slider {...settings}>
            {testimonios.map(testimonio => (
              <div className="testi-curso">
                <div  key={testimonio.id} className="card testimonio-alumno">
                  <div className="infocompleta-testimonio">
                    <div className="testimonio-info">
                      <div className="info-alumno">
                      <DangerouslySetHTMLContent html={testimonio.descripcion}/>
                      </div>
                    </div>
                    <div className="row alumno">
                      <div className="col-5 imagen-alumno">
                        <img src={testimonio.imagen_url} className="alumno-testimonio" />
                      </div>
                      <div className=" col-7 nombre-estudiante">
                        <div className="nombre-estudiante">
                          <h1 className="nombre-alumno">{testimonio.nombre_testimonio}</h1>
                          <h2 className="curso-alumno-testimonio">
                          {testimonio.curso_realizado}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               ))}
              
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};
