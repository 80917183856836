import React from "react";

/* imagenes */
import logocarrera from "../../img/nuestra-carreras/logocarrera.png";

export const Titulos = () => {
  return (
    <div className="titulos de carrera">
      <div className="img-titulo-carrera">
        <img src={logocarrera} className="w-100 logocarrera" />
      </div>
      <h2 className="titulos de carrera">
        Título Técnico de Nivel Superior en
      </h2>
      <h1 className="tituloprincipalcarrera">GASTRONOMÍA INTERNACIONAL</h1>
      <h2 className="titulos de carrera1">
        MENCIÓN EN GASTRONOMÍA FRANCESA O MENCIÓN PANADERÍA
      </h2>

      <h4 className="titulo de carrera">
        Carrera teórico / práctica de 2 años que entrega el título de Técnico de
        Nivel Superior en Gastronomía Internacional. École es una institución
        con acreditación avanzada, diurna y con modalidad semipresencial. 
      </h4>
      <h4 className="titulo de carrera-dos">
      Es la única Escuela en el mundo que otorga una certificación de los "Maîtres
        Cuisiniers de France" con docentes miembros de la Académie Culinaire de
        France.
        
        </h4>
    </div>
  );
};
