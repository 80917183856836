import React, { useEffect, useState } from "react";
import "../../styles/portada-carrera/carrera.css";

import web from "../../img/nuestra-carreras/web.jpg";
import mobil from "../../img/nuestra-carreras/movil.jpg";

export const Diploycerti = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado isMobile
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    // Llamamos a la función inicialmente
    updateIsMobile();

    // Agregamos un listener para el evento de cambio de tamaño de la ventana
    window.addEventListener("resize", updateIsMobile);

    // Limpiamos el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  return (
    <div>
      {isMobile ? (
        <img src={mobil} alt="Imagen Móvil" />
      ) : (
        <img src={web} alt="Imagen Web" />
      )}
    </div>
  );
};