import React from "react";
/* imagenes */
import imagenportadablogestruc from "../../../img/estructuras/blog/portada-estruc-blog.png";

export const PortadaEstructuraBlog = () => {
  return (
    <div>
      <section>
      <hr className="separador-portada-artips" />
      </section>
    </div>
  );
};
