import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/blog/blog.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/* imagenes */
import imagen1 from "../../img/blog/comida1.jpg";
import imagen2 from "../../img/blog/comida2.jpg";
import imagen3 from "../../img/blog/comida3.png";
import imagen4 from "../../img/blog/postre.png";
import imagen5 from "../../img/blog/postre2.jpg";
import imagen6 from "../../img/blog/postre3.jpg";
import imagen7 from "../../img/blog/postre4.png";

export const Galeriablog = () => {
  const [recetas, setRecetas] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Fetch testimonios from the backend API
    axios.get(`${backendURL}/obtener_recetas`)
      .then(response => {
        setRecetas(response.data);
      })
      .catch(error => {
        console.error("Error fetching testimonios:", error);
      });
  }, []);


  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="container-fluid blog galeria">
      <div className="card slider blog">
        <Slider {...settings}>
        {recetas.map(receta => (
          <div key={receta.id} >
            <div className="card-img-galeria-blog">
              <img src={`${backendURL}/imagen_receta/${receta.imagen}`} />
            </div>
          </div>
           ))}
            
        </Slider>
      </div>
    </div>
  );
};
