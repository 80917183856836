import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import Slider from "react-slick";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import "../../styles/beneficio/beneficio.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";



/* imagenes */
import calendario from "../../img/beneficio/calendario.png";


export const SliderBeneficios = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [beneficio, setBeneficio] = useState([]);
  const [activeBeneficioIndex, setActiveBeneficioIndex] = useState(0); // Estado para rastrear la tarjeta activa
  const [modalVisible, setModalVisible] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [selectedBeneficio, setSelectedBeneficio] = useState(null);

  const openModal = (index) => {
    setModalVisible(true);
    setSelectedBeneficio(index);
  };

 const handlebeneficioClick = (beneficio, index) => {
  setSelectedBeneficio(beneficio);
  setActiveButton(index);
 
  };

  const handleBeneficioClick = (index) => {
    setActiveBeneficioIndex(index);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedBeneficio(null);
  };

  useEffect(() => {
    // Fetch testimonios from the backend API
    axios.get(`${backendURL}/beneficios`)
      .then(response => {
        setBeneficio(response.data);
      })
      .catch(error => {
        console.error("Error fetching beneficio:", error);
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <BsChevronLeft />,
      nextArrow: <BsChevronRight />,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint:767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            
          },
        },
      ],
  };
  
  return (
    <section>
      <div className="slider-beneficio">
        <h1 className="titulo-beneficio">BENEFICIOS PARA ESTUDIANTES</h1>
      </div>
      <section className="botones-beneficios">
    
      </section>
      <div className="container beneficios">
      <Slider {...settings}>
      {beneficio.map((beneficio, index) => (
        <div className="beneficio-contiene">
  <div className="contiene-todo-beneficio" key={index}>
    <div
       className={`card-beneficio ${activeBeneficioIndex === index ? "tarjeta-activa" : ""}`}
    >
      <div className="imagen-beneficio">
        <img src={beneficio.imagen_url} alt={beneficio.titulo} />
      </div>
      <div className="info-beneficio">
        <h2 className="titulo-benficio-card">{beneficio.titulo}</h2>
        <div className="descuento-beneficio">{beneficio.descuento} de dcto.</div>
        <p className="tipo-beneficio">{beneficio.tipo}</p>
        <button
          className="beneficio-vermas"
          onClick={() => handlebeneficioClick(beneficio, index)}
        >
          VER MÁS
        </button>
      </div>
    </div>
  </div>
  </div>
))}
      </Slider>
</div>

        {selectedBeneficio && (
          <div className="popup-admision-beneficio">
          <div className="contiene-beneficio">
            <div className="card-pop-up-beneficio">
                <section className="button-close-beneficio">
              <button className="button-close-beneficio" onClick={closeModal}><FaTimes /></button>
              </section>
              <div className="contenendor-beneficio">
              <div className="row">
                <div className="col col-sm-12 col-md-6 beneficio">
                  <div className="imagen-popup-beneficio">
                    <img
                      src={selectedBeneficio.imagen_url_secundaria}
                      className="imagen-beneficio-pop-up"
                    />
                  </div>
                </div>
                <div className="col col-sm-12 col-md-6">
                  <div className="row beneficio2">
                    <div className="col-4 beneficio">
                        <div className="imagen-info-beneficio"><img
                        src={selectedBeneficio.imagen_url}
                        className="imagen-portada2"
                      /></div>
                      
                    </div>
                    <div className="col-8">
                      <div className="beneficio-informacion-completa">
                        <div className="tipo-info-beneficio">
                          {" "}
                          {selectedBeneficio.tipo}
                        </div>
                        <h2 className="titulo-benficio-card">
                          {selectedBeneficio.titulo}
                        </h2>
                        <p className="descripcion-general-beneficio">
                          {selectedBeneficio.descripcion_principal}
                        </p>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="fecha-beneficio">
                    <div className="imagen-calendario-beneficio">
                    <img src={calendario} className="claendario-beneficio" />
                    </div>
                    <p className="info-fecha-beneficio">
                      {" "}
                      Valido desde el {
                       selectedBeneficio.fecha_inicio}{" "}
                      hasta el {selectedBeneficio.fecha_termino}{" "}
                    </p>
                  </div>
                  <div className="descripcion-beneficio">
                    <ul>
                      <li>
                        
                        <p> <DangerouslySetHTMLContent html={selectedBeneficio.beneficios}/></p>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
        )}
      
    </section>
  );
};
