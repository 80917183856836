import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Botoncrearmapa } from "./boton-crear-mapa";
import { Editarmapa } from "./boton-editar-mapa";

export const Mapa = () => {
  const [mapas, setMapas] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editTestId, setEditTestId] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const closePopups = () => {
    setShowCreatePopup(false);
    setShowEditPopup(false);
  };

  const toggleEditPopup = (mapaId) => {
    setShowEditPopup(!showEditPopup);
    setEditTestId(mapaId);
  };

  useEffect(() => {
    // Fetch testimonios from the backend API
    axios
      .get(`${backendURL}/obtener_mapas`)
      .then((response) => {
        setMapas(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Mapas:", error);
      });
  }, []);

  const handleDelete = async (mapaId) => {
    try {
      const response = await axios.delete(
        `${backendURL}/eliminar_mapa/${mapaId}`
      );
     
      // Actualizar el estado local eliminando el mapa con el ID correspondiente
      setMapas((prevMapas) => prevMapas.filter((mapa) => mapa.id !== mapaId));
     
    } catch (error) {
      console.error(error);
      // Handle error cases
    }
  };

  return (
    <>
      <section>
        <h1 className="titulo-info-componenete-admi">Testimonios</h1>
      </section>
      <section className="boton-crear-chef-cursosydiplomado">
        <button className="boton-crear" onClick={togglePopup}>
          Crear
        </button>
      </section>
      {showPopup && (
        <div className="popup-admi">
          <div className="popup-content-admi">
            <Botoncrearmapa onClose={togglePopup} />
          </div>
        </div>
      )}

      <section className="lista-chef-cursosydiplomado">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Nombre Establecimiento</th>
              <th className="titulobd">Imagen Testimonio</th>
              <th className="titulobd">Ciudad</th>
              <th className="titulobd">Chef</th>
              <th className="titulobd">ubicacion</th>
              <th className="titulobd">top</th>
              <th className="titulobd">left</th>
              <th className="titulobd">Acciones</th>
            </tr>
          </thead>
          {mapas.map((mapa) => {
          

            return (
              <tr key={mapa.id} className="info-bd">
                <td className="info-bd">{mapa.nombre_establecimiento}</td>
                <td>
                  <img
                    className="imagen-info-admi"
                    src={mapa.imagen_url}
                    alt="Imagen Testimonio"
                  />
                </td>
                <td className="info-bd">{mapa.ciudad}</td>
                <td className="info-bd">{mapa.chef}</td>
                <td className="info-bd">{mapa.ubicacion}</td>
                <td className="info-bd">{mapa.top}</td>
                <td className="info-bd">{mapa.left}</td>

                <td className="edicion">
                  <button
                    className="boton-editar"
                    onClick={() => toggleEditPopup(mapa.id)}
                  >
                    <BiEdit />
                  </button>
                  <button
                    className="boton-eliminar"
                    onClick={() => handleDelete(mapa.id)}
                  >
                    <BiTrash />
                  </button>
                </td>
              </tr>
            );
          })}
        </table>
      </section>
      {showEditPopup && (
        <div className="popup-admi">
          <div className="popup-content-admi">
            <Editarmapa mapaId={editTestId} onClose={closePopups} />
          </div>
        </div>
      )}
    </>
  );
};
