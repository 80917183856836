import React, { useState, useEffect } from "react";
import axios from "axios";
import { Articulolist } from "./componentes de tips/articulolist";

export const Cardtips = () => {
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("Mostrar todos");
  const [recetas, setRecetas] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    let url = `${backendURL}/obtener_recetas`;
    
    if (categoriaSeleccionada !== "Mostrar todos") {
      url += `?categoria=${categoriaSeleccionada}`;
    }

    axios.get(url)
      .then(response => {
       
        setRecetas(response.data);
      })
      .catch(error => {
        console.error("Error fetching recetas:", error);
      });
  }, [categoriaSeleccionada]);

  const handleCategoriaChange = (event) => {
    setCategoriaSeleccionada(event.target.value);
  };

  return (
    <div className="container tips">
      <div className="categories">
      
      <select  className="form-control denuncia" value={categoriaSeleccionada} onChange={handleCategoriaChange}>
        <option value="Mostrar todos">Mostrar todos</option>
        <option value="receta">Recetas</option>
        <option value="tips">Tips</option>
      </select></div>
       
      <Articulolist articles={recetas} />
    </div>
  );
};