import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import axios from "axios";

/* imagens */
import imagen1 from "../../img/login/1.png"
import imagen2 from "../../img/login/2.png"
import imagen3 from "../../img/login/3.png"
import imagen4 from "../../img/login/4.png"
import logo from "../../img/login/logo.png"




export const LoginUser = () => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleLogin = (e) => {
    e.preventDefault();

    if (name.length === 0) {
      alert("El campo de usuario está vacío");
    } else if (password.length === 0) {
      alert("El campo de contraseña está vacío");
    } else {
      axios
        .post(`${backendURL}/login`, { name, password })
        .then(function (response) {
          const token = response.data.access_token;

          if (token) {
            // Almacena el token en sessionStorage
            sessionStorage.setItem("token", token);
            // Redirigir al usuario a la vista /administrador
            navigate("/administrador");
          } else {
            alert("Usuario inválido");
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response && error.response.status === 401) {
            alert("Credenciales inválidas");
          }
        });
    }
  };

  useEffect(() => {
    // Comprueba si el usuario ya ha iniciado sesión al cargar el componente
    const token = sessionStorage.getItem("token");
    if (token) {
      navigate("/administrador");
    }

    // Resetea los valores de usuario y contraseña al desmontar el componente
    return () => {
      setName("");
      setPassword("");
    };
  }, []);

  
  const settings = {

    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
    ],
  };


  return (
    <section>
      <div className="container">
    <div className="row contenedor-login">
      <div className="col-6">

        <div className="login">
        <div className="log"> 
        <section className="log">
           
      <div className="contenedor-img-logo">
        <img src={logo} className="w-75 logo-login" />
      </div>
      <form className="log-form" onSubmit={handleLogin}>
         
        <input
        className="input-login"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          id="form3usuario"
          name="u"
          placeholder="Usuario"
          required
        />
        <input
        className="input-login"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          id="form3contraseña"
          name="p"
          placeholder="Contraseña"
          required
        />
        <button type="submit" className="boton-login">
          Ingresar
        </button>
      </form>
      </section>
      
      </div>
    </div> 
    </div>
      <div className="col-6 logincol">
        <div className="contenedor-login-imagen">
        <Slider {...settings}>
          <div className="contenedor-imagen-login">
            <img src={imagen3} className="imagen-login" />
            </div>
          <div className="contenedor-imagen-login">
            <img src={imagen1} className="imagen-login" />
            </div>
          <div className="contenedor-imagen-login">
            <img src={imagen2} className="imagen-login" />
            </div>
          <div className="contenedor-imagen-login">
            <img src={imagen4}  className="imagen-login"/>
            </div>
          </Slider> 
      </div>
      </div>
    </div>
    </div>
    </section>
  
  );
};