import React from "react";
/* imagenes */
import separadorpasantia from "../../img/especialidad/separadorpasantia.png";

export const Videopasantia = () => {
  return (
    <div className="video pasantia container video pasantia">
      <div className=" videopasantia row ">
        <div className="videopasantia col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
          <div className="videopasantia">
            <div className="body videopasantia">
              <div className="ratio ratio-4x3 videopasantia">
                <iframe
                  src="https://www.youtube.com/embed/HxL0NSwQkmc"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="infovideopasantia col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6  ">
          <h1 className="titulosegundoninfovideopasantia">
            Escuela Culinaria Francesa
          </h1>
          <div className="imagen-linea-carrera">
          <img src={separadorpasantia} className="w-20 separadropasantia" />
          </div>
          
          <h2 className="segundainfovideopasantia">
            {" "}
            Auberge du vieux puits Michelin – Chef Gilles Goujon – Miembro
            Maîtres Cuisiniers de France.
          </h2>{" "}
          <h3 className="segundainfovideopasantia">
            {" "}
            Alumnos en Práctica: Pablo Ramírez y Pato Meirone Realización de
            Pablo Ramírez.
          </h3>
        </div>
      </div>
    </div>
  );
};
