import React, { createContext, useContext, useState } from "react";

// Contexto para el carrito
const CartContext = createContext();

// Proveedor del contexto del carrito
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]); // Array para almacenar los productos en el carrito
  const [isCartVisible, setIsCartVisible] = useState(false); // Estado para controlar la visibilidad del carrito
  const [selectedProducts, setSelectedProducts] = useState([]); // Array para almacenar los productos seleccionados

  
  
  const addItemToCart = (item, quantity) => {
    // Verificar si el producto ya está en el carrito
    const existingItemIndex = cartItems.findIndex(
      (cartItem) => cartItem.id === item.id
    );
  
    if (existingItemIndex !== -1) {
      // Si el producto ya está en el carrito, actualiza la cantidad
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].cantidad += quantity;
      setCartItems(updatedCartItems);
    } else {
      // Si el producto no está en el carrito, agrégalo
      setCartItems([...cartItems, { ...item, cantidad: quantity }]);
    }
  };
  // Eliminar un producto del carrito
  const removeItemFromCart = (itemToRemove) => {
    const updatedCart = cartItems.filter((item) => item.id !== itemToRemove.id);
    setCartItems(updatedCart);
  };

  // Limpiar el carrito
  const clearCart = () => {
    setCartItems([]);
  };

  // Alternar la visibilidad del carrito
  const toggleCartVisibility = () => {
    setIsCartVisible((prevState) => !prevState);
  };

   // Calcular el total en función de la cantidad de productos del mismo ID
   const calculateTotal = () => {
    let total = 0;

    cartItems.forEach((item) => {
      total += item.precio_final * item.cantidad;
    });

    return total;
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        selectedProducts,
        addItemToCart,
        removeItemFromCart,
        clearCart,
        isCartVisible,
        toggleCartVisibility,
        calculateTotal
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Hook personalizado para usar el contexto del carrito
export const useCart = () => {
  return useContext(CartContext);
};