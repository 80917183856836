import React, { useState, useEffect } from "react";
import axios from "axios";



export const Editaregresado = ({egresadoId, onClose}) => {
  const [NombreEgresado, setNombreEgresado] = useState("");
  const [titulo, setTitulo] = useState("");
  const [NombreEmpresa, setNombreEmpresa] = useState("");
  const [UrlEmpresa, setUrlEmpresa] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Fetch the existing testimonial data based on egresadoId
    axios.get(`${backendURL}/obtener_egresado/${egresadoId}`)
      .then(response => {
        const egresadoData = response.data;
        setNombreEgresado(egresadoData.nombre_estudiante);
        setTitulo(egresadoData.titulo);
        setNombreEmpresa(egresadoData.nombre_empresa);
        setUrlEmpresa(egresadoData.url_empresa);
      })
      .catch(error => {
        console.error("Error fetching egresado data:", error);
      });
  }, [egresadoId]);

  const handleEdit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nombre_estudiante", NombreEgresado);
    formData.append("titulo", titulo);
    formData.append("nombre_empresa", NombreEmpresa);
    formData.append("url_empresa", UrlEmpresa);
    

    try {
      const response = await axios.put(`${backendURL}/editar_egresado/${egresadoId}`, formData);
      console.log(response.data);
      // Add any additional logic you want after successful editing
      onClose(); // Close the popup after successful edit
    } catch (error) {
      console.error(error);
      // Handle error cases
    }
  };
  
    return(
        <>
         <button className="close-button-admi" onClick={onClose}>
        X
      </button>
      <div className="titulo-admi-info">
        <h2>Editar Testimonio Estudiante</h2>
      </div>
      <form onSubmit={handleEdit} >
      <div>
          <label className="titulo-input-admi" htmlFor="url">
            Nombre Estudiante:
          </label>
          <input
            className="input-admi"
            type="text"
            value={NombreEgresado}
            onChange={(e) => setNombreEgresado(e.target.value)}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="descripcionCurso">
            Titulo del Egresado:
          </label>

          <input
            className="input-admi"
            id="descripcionCurso"
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Nombre Empresa:
          </label>
          <input
            className="input-admi"
            type="text"
            value={NombreEmpresa}
            onChange={(e) => setNombreEmpresa(e.target.value)}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Url Empresa:
          </label>
          <input
            className="input-admi"
            type="text"
            value={UrlEmpresa}
            onChange={(e) => setUrlEmpresa(e.target.value)}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
        
        
        </>
    )
}