import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";

export const Botoneditarportadahome = ({
  cerrarVentana,
  portadaId,
  actualizarPortadaEnLista
}) => {
    const [portadaData, setPortadaData] = useState({
        imagen_home: null,
        imagen_responsive: null,
        url_home: ''
      });

  useEffect(() => {
    console.log('Portada ID:', portadaId);
    obtenerPortada();
  }, [portadaId]);
  
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const obtenerPortada = () => {
    axios
      .get(`${backendURL}/obtener_portadahome/${portadaId}`)
      .then((response) => {
        setPortadaData(response.data.portada);
      })
      .catch((error) => {
        console.error('Error al obtener los datos de la portada:', error);
      });
  };

  const handleGuardar = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('imagen_home', portadaData.imagen_home);
    formData.append('imagen_responsive', portadaData.imagen_responsive);
    formData.append('url_home', portadaData.url_home);

    try {
        const response = await axios.put(`${backendURL}/editar_portadahome/${portadaId}`, formData);
  
        console.log(response.data);
        cerrarVentana();
  
        // Actualiza la portada en la lista usando la función del componente padre
        actualizarPortadaEnLista(portadaId, portadaData);
  
        // Vacía el campo de selección de archivo para evitar mostrar la imagen anterior en el próximo formulario
        document.getElementById('imagen_home').value = '';
        document.getElementById('imagen_responsive').value = '';
      } catch (error) {
        console.error('Error al actualizar la portada:', error);
      }
    };
  
    const handleChange = (event) => {
      if (event.target.id === 'imagen_home') {
        setPortadaData({
          ...portadaData,
          imagen_home: event.target.files[0]
        });
      } else {
        setPortadaData({
          ...portadaData,
          [event.target.id]: event.target.value
        });
      }
    };
    const handleChangemovil = (event) => {
      if (event.target.id === 'imagen_responsive') {
        setPortadaData({
          ...portadaData,
          imagen_responsive: event.target.files[0]
        });
      } else {
        setPortadaData({
          ...portadaData,
          [event.target.id]: event.target.value
        });
      }
    };

  return (
    <>
      <div className="titulo-admi-info">
        <h2>Editar Portada página curso y diplomado</h2>
      </div>
      <form onSubmit={handleGuardar}>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen_home">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen_home" // Cambiar el id a "imagen_home"
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen_home">
            Imagen movil:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen_responsive" // Cambiar el id a "imagen_home"
            onChange={handleChangemovil}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url_home">
            URL:
          </label>
          <input
            className="input-admi"
            type="text"
            id="url_home" // Cambiar el id a "url_home"
            value={portadaData.url_home}
            onChange={handleChange}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};