import React, { useState, useEffect } from "react";
import axios from "axios";

export const Descuentoficial = () => {
  const [curso, setCurso] = useState([]);
  const [cursoId, setCursoId] = useState(0);
  const [nombre, setNombre] = useState("");
  const [beneficio, setBeneficio] = useState(""); // Cambiado "beneficio" a "setBeneficio"
  const [apellido, setApellido] = useState("");
  const [rut, setRut] = useState(""); // Cambiado "rut" a "setRut"
  const [telefono, setTelefono] = useState(""); // Cambiado "telefono" a "setTelefono"
  const [correo, setCorreoElectronico] = useState("");
  const [descuento, setDescuento] = useState(0); // Cambiado "descuentos" a "descuento"
  const [fechaInicio, setFechaInicio] = useState(""); // Cambiado "setfechaInicio" a "setFechaInicio"
  const [fechaTermino, setFechaTermino] = useState(""); // Cambiado "setfechatermino" a "setFechaTermino"
  const [codigoGenerado, setCodigoGenerado] = useState("");
  const [descuentos, setDescuentos] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Nuevo estado para controlar la carga

  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${backendURL}/descuentos`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          valor_descuento: descuento, // Cambiado "descuentos" a "descuento"
          nombre: nombre,
          apellido: apellido,
          tipo_beneficiario: beneficio, // Cambiado "beneficio" a "setBeneficio"
          rut: rut, // Cambiado "rut" a "setRut"
          telefono: telefono, // Cambiado "telefono" a "setTelefono"
          correo: correo,
          fecha_inicio: fechaInicio, // Cambiado "setfechaInicio" a "setFechaInicio"
          fecha_termino: fechaTermino, // Cambiado "setfechatermino" a "setFechaTermino"
          curso_id: cursoId,
        }),
      });

      if (response.status === 201) {
        const responseData = await response.json();
        console.log(responseData.mensaje);
        setCodigoGenerado(responseData.codigo); // Agregar esta línea
      } else {
        console.error("Error al crear el descuento");
      }
    } catch (error) {
      console.error("Error de red:", error);
    }
  };

  useEffect(() => {
    const obtenerCurso = async () => {
      try {
        const response = await axios.get(
            `${backendURL}/cardcursoydiplo`
        );
        setCurso(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    obtenerCurso();
  }, []);

  const handleReiniciarCodigo = () => {
    setCodigoGenerado("");
  };

  useEffect(() => {
    // Realizar una solicitud GET al backend para obtener la lista de descuentos
    fetch(`${backendURL}/descuentoscurso`)
      .then((response) => response.json())
      .then((data) => {
        setDescuentos(data.descuentos);
        setIsLoading(false); // Marcar como cargado
      })
      .catch((error) => {
        console.error("Error al obtener los descuentos:", error);
        setIsLoading(false); // Marcar como cargado incluso si hay un error
      });
  }, [backendURL]);

  return (
    <>
      <div className="descuento-form">
        <section>
          <h1>Crear Código de Descuento por curso</h1>
        </section>

        <div className="formulario descuento">
          <form className="form-descuento" onSubmit={handleFormSubmit}>
            <div>
              <label>Valor de Descuento:</label>
              <select
                className="descuento-input"
                name="valorDescuento"
                value={descuento}
                onChange={(e) => setDescuento(parseFloat(e.target.value))}
              >
                <option>Selecciona porcentaje</option>
                <option value="5">5%</option>
                <option value="10">10%</option>
                <option value="15">15%</option>
                <option value="20">20%</option>
                <option value="25">25%</option>
                <option value="30">30%</option>
                <option value="35">35%</option>
                <option value="40">40%</option>
                <option value="45">45%</option>
                <option value="50">50%</option>
                <option value="55">55%</option>
                <option value="60">60%</option>
                <option value="65">65%</option>
                <option value="70">70%</option>
                <option value="75">75%</option>
                <option value="80">80%</option>
                <option value="85">85%</option>
                <option value="90">90%</option>
                <option value="95">95%</option>
                <option value="100">100%</option>
              </select>
            </div>
            <div>
              <label>Beneficiario:</label>
              <select
                className="descuento-input"
                name="beneficiario"
                value={beneficio}
                onChange={(e) => setBeneficio(e.target.value)}
              >
                <option>Selecciona Beneficiario</option>
                <option value="estudiante">Estudiante</option>
                <option value="alianza">Alianza</option>
              </select>
            </div>

            <div>
              <label>Nombre:</label>
              <input
                className="descuento-input"
                type="text"
                name="nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </div>

            <div>
              <label>Apellido:</label>
              <input
                className="descuento-input"
                type="text"
                name="apellido"
                value={apellido}
                onChange={(e) => setApellido(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Rut:</label>
              <input
                className="descuento-input"
                type="text"
                name="rut"
                value={rut}
                onChange={(e) => setRut(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Teléfono:</label>
              <input
                className="descuento-input"
                type="text"
                name="telefono"
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Correo Electrónico:</label>
              <input
                className="descuento-input"
                type="email"
                name="correo"
                value={correo}
                onChange={(e) => setCorreoElectronico(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Fecha de Inicio:</label>
              <input
                className="descuento-input"
                type="date"
                name="fechaInicio"
                value={fechaInicio}
                onChange={(e) => setFechaInicio(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Fecha de Término:</label>
              <input
                className="descuento-input"
                type="date"
                name="fechaTermino"
                value={fechaTermino}
                onChange={(e) => setFechaTermino(e.target.value)}
              />
            </div>
            <div>
              <label>Curso:</label>
              <select
                className="descuento-input"
                name="curso_id" // Asegúrate de que el nombre coincida con el campo en el servidor
                value={cursoId}
                onChange={(e) => setCursoId(e.target.value)}
              >
                <option>Selecciona Curso </option>
                {curso.map((cursoItem) => (
                  <option key={cursoItem.id} value={cursoItem.id}>
                    {cursoItem.nombre_curso}
                  </option>
                ))}
              </select>
            </div>

            <button className="enviar-descuento" type="submit">
              Crear Descuento
            </button>
          </form>

          <h3 className="titulo-codigo">Código Generado:</h3>
          <div className="codigo-descuento">
            {codigoGenerado && <p className="codigo-generado">{codigoGenerado}</p>}
          </div>
          <div className="boton-descuento-admi">
          <button
            className="enviar-descuento"
            type="button"
            onClick={handleReiniciarCodigo}
          >
            Reiniciar
          </button></div>
        </div>
      </div>

      <section>
        <section>
          <h1>Lista descuento Estudiante</h1>
        </section>
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd"> Beneficiario</th>
              <th className="titulobd"> Descuento</th>
              <th className="titulobd"> Nombre</th>
              <th className="titulobd"> RUT</th>
              <th className="titulobd">Informacion Beneficiario</th>
              <th className="titulobd">Codigo</th>
              <th className="titulobd">Estado</th>
              <th className="titulobd">Días</th>
              <th className="titulobd"> Fecha  de descuento</th>
              <th className="titulobd"> Curso</th>
            </tr>
          </thead>
          <tbody>
            {descuentos &&
              descuentos.map((descuento) => (
                <tr className="info-bd" key={descuento.id}>
                  <td className="info-bd">{descuento.tipo_beneficiario} </td>
                  <td className="info-bd">{descuento.valor_descuento} </td>
                  <td className="info-bd">
                    {descuento.nombre} {descuento.apellido}
                  </td>
                  <td className="info-bd">{descuento.rut} </td>
                  <td className="info-bd">
                    {descuento.correo} / {descuento.telefono}{" "}
                  </td>
                  <td className="info-bd">{descuento.codigo}</td>
                  <td className="info-bd">{descuento.estado}</td>
                  <td className="info-bd">{descuento.dias_activos}</td>
                  <td className="info-bd">{descuento.fecha_inicio} / {descuento.fecha_termino}</td>
                  <td className="info-bd">{descuento.curso_id}</td>

                  {/* Puedes agregar el campo de estado aquí si es necesario */}
                </tr>
              ))}
          </tbody>
        </table>
      </section>
    </>
  );
};
