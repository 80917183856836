import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Botoncrearautoridad } from "./crear-autoridad";
import { Botoneditarautoridad } from "./editar-autoridad";

export const Autoridades = () => {
  const [mostrarPopup, setMostrarPopup] = useState(false);
  const [autoridades, setAutoridades] = useState([]);
  const [autoridadselecionado, setAutoridadIdselecionado] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  

  useEffect(() => {
    obtenerAutoridades();
  }, []);

  const obtenerAutoridades = async () => {
    try {
      const response = await axios.get(`${backendURL}/obtener_autoridades`);
      setAutoridades(response.data);
    } catch (error) {
      console.error("Error al obtener las autoridades", error);
    }
  };

  const cerrarVentana = () => {
    setMostrarPopup(false);
    setAutoridadIdselecionado(null);
  };

  const eliminarAutoridad = async (autoridadId) => {
    try {
      const response = await axios.delete(`${backendURL}/eliminar_autoridad/${autoridadId}`);
      console.log(response.data); // Mensaje de éxito del backend
      obtenerAutoridades(); // Actualiza la lista de autoridades después de eliminar
    } catch (error) {
      console.error("Error al eliminar la autoridad", error);
    }
  };

  const editarAutoridad = (autoridadId) => {
    setAutoridadIdselecionado(autoridadId);
    setMostrarPopup(true);
  };

    return (
      <div>
        <section>
          <h1 className="titulo-info-componenete-admi">AUTORIDADES</h1>
        </section>
        <section className="boton-crear-chef-cursosydiplomado">
          <button className="boton-crear" onClick={() => setMostrarPopup(true)}>
            Crear
          </button>
          {mostrarPopup && !autoridadselecionado && (
            <div className="popup-admi">
              <div className={`popup-content-admi ${mostrarPopup && !autoridadselecionado ? 'scroll-noticia' : ''}`}>
                <button
                  className="close-button-admi"
                  onClick={() => cerrarVentana()}
                >
                  X
                </button>
                 <Botoncrearautoridad
                  cerrarVentana={cerrarVentana}
                /> 
              </div>
            </div>
          )}
          {mostrarPopup && autoridadselecionado !== null && (
        <div className="popup-admi">
          <div className={`popup-content-admi ${mostrarPopup && !autoridadselecionado ? 'scroll-noticia' : ''}`}>
            <button className="close-button-admi" onClick={() => cerrarVentana()}>
              X
            </button>
            <Botoneditarautoridad
              autoridadId={autoridadselecionado}
              cerrarVentana={cerrarVentana}
            />
          </div>
        </div>
      )}
        </section>
        <section className="lista-chef-cursosydiplomado">
          <table className="lista">
            <thead>
              <tr>
                <th className="titulobd">Nombre Autoridad</th>
                <th className="titulobd">Imagen </th>
                <th className="titulobd">Cargo</th>
                <th className="titulobd">Correo</th>
                <th className="titulobd">Acciones</th>
              </tr>
            </thead>
            <tbody>
            {autoridades.map((autoridad) => (
              <tr key={autoridad.id} className="info-bd">
                <td className="info-bd">{autoridad.nombreautoridad}</td>
                <td className="info-bd">
                  <div className="imagen-info-admi">
                    <img
                      className="imagen-info-admi"
                      src={`${backendURL}/imagen_autoridad/${autoridad.imagenautoridad}`}
                      alt={autoridad.nombreautoridad}
                    />
                  </div>
                </td>
                <td className="info-bd">{autoridad.cargo}</td>
                <td className="info-bd">{autoridad.correo}</td>
                <td className="edicion">
                  <button className="boton-editar" onClick={() => editarAutoridad(autoridad.id)}>
                    <BiEdit />
                  </button>
                  <button className="boton-eliminar"  onClick={() => eliminarAutoridad(autoridad.id)}>
                    <BiTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          </table>
        
        </section>
      </div>
    );
  };
  