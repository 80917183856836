import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Calendariopdf } from "./crear-calendario";


export const PDFCalendarioAcademico = () => {
    const [showMC, setShowMC] = useState(false);
    const [showF, setShowF] = useState(false);
    const [pdfmalla, setPdfmalla] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;


    const abrirpdf = () => {
        setShowMC(true);
      };
    
      const cerrarpdf = () => {
        setShowMC(false);
      };

      const abrirpdfF = () => {
        setShowF(true);
      };
    
      const cerrarpdfF = () => {
        setShowF(false);
      };


      
      const eliminarPdf = async () => {
        try {
          const pdfResponse = await axios.delete(
            `${backendURL}/delete_pdfcalendarioacademico/CalendarioAcademico.pdf`
          );
          console.log(pdfResponse.data.message);
      
        
          // Actualizar la lista de PDFs después de eliminar
          const updatedPdfmalla = pdfmalla.filter(
            (pdf) => pdf.pdf_url !== "static/calendario/CalendarioAcademico.pdf"
          );
          setPdfmalla(updatedPdfmalla);
        } catch (error) {
          console.error("Error al eliminar PDF o imagen:", error);
        }
      };

    


return (
    <>
      <section>
        <h1 className="titulo-info-componenete-admi">PDF Especialidad</h1>
      </section>
      <span>importante:  se debe incorprar documentos pdf para actualizar documentos</span>
      <section className="editor-pdf">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Nombre PDF</th>
              <th className="titulobd">URL</th>
              <th className="titulobd">Acciones</th>
            </tr>
          </thead>
          <tbody>
            
              <tr className="info-bd" >
                <td className="info-bd">Calendario Academico</td>
                <td className="info-bd">
                  <a
                    href={`${backendURL}/static/calendario/CalendarioAcademico.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver PDF
                  </a>
                </td>
                <td className="edicion">
                  <button className="boton-editar" onClick={abrirpdf}>
                    <BiEdit />
                  </button>
                  <button className="boton-eliminar" onClick={eliminarPdf}>
                    <BiTrash />
                  </button>
                </td>
              </tr>
          </tbody>
          {showMC && (
        <div className="popup-admi">
        <div className="popup-content-admi">
          <button className="close-button-admi" onClick={cerrarpdf}>
            X
          </button>
           <Calendariopdf onClose={cerrarpdf}/>
            
          </div>
        </div>
      )}
        </table>
      </section>
    </>
  );
};
