import React from "react";
import ReactPlayer from "react-player";

export const VideoComponent = () => {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        url="https://youtu.be/_PtfSqQpMHQ"
        className="react-player"
        controls
        loop
      />
    </div>
  );
};
