import React, { useState, useEffect } from "react";

import portadanoticia from "../../img/noticias/portadanoticia.jpg";
import noticia2 from "../../img/noticias/NOTICIAS-MOIVL.jpg"

import "../../styles/noticias/noticias.css";

export const Portadanoticias = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado de isMobileView en función del ancho de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Cambia a "true" si el ancho es menor o igual a 700px
    };

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Llama a handleResize() una vez al cargar la página para establecer el estado inicial
    handleResize();

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div  className="portadas-general">
      <img src={isMobileView ? noticia2 : portadanoticia} className="w-100 noticia-portada" />
    </div>
  );
};
