import React, { useState, useEffect } from "react";
import portadaescuela from "../img/nosotros escuela/portadanosotros.jpg";
import "../styles/escuela/escuela.css";

import { Autoridades } from "../component/escuela/cardequipo";
import { Historiaecole } from "../component/escuela/historia";
import { Bonappetit } from "../component/escuela/bonappetit";
/* librerias */

/* imagenes */

import logo from "../img/nosotros escuela/nosotrosescuela.png";
import portad2 from "../img/nosotros escuela/NUESTRA-ESCUELA-MOVIL.jpg"
import Mision from "../img/nosotros escuela/mision.png";
import Vision from "../img/nosotros escuela/vision.png";


export const Escuela = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado de isMobileView en función del ancho de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Cambia a "true" si el ancho es menor o igual a 700px
    };

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Llama a handleResize() una vez al cargar la página para establecer el estado inicial
    handleResize();

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="todo-home">
    
        <div  className="portadas-general">
          <img className="portada-Escuela w-100" src={isMobileView ? portad2 : portadaescuela} alt="" />
        </div>
  
      {/* quienes somos */}
      <div className="container escuela">
        <div className="info-principal-escuela">
          <h5 className="info-titulo-escuela">
          Nuestra Escuela se encuentra acreditada por la CNA y cuenta con más de 21 años de experiencia
en el rubro gastronómico. Nuestra exclusiva alianza con los Maîtres Cuisiniers de France nos
convierte en la única escuela en el mundo que entrega la certificación de estudio respaldada por
esta reconocida organización Francesa.
          </h5>
          <h5 className="info-titulo-escuela"></h5>
        </div>

        <div className="container infoescuela">
          <div className="row row-cols-1 row-cols-md- row-cols-xl-2  g-4 escuela">
            <div className="col escuela">
              <div className="escuela-imagen">
                <img src={logo} className="img-top escuela" alt="..." />
              </div>
            </div>
            <div className="col escuela">
              <div className=" escuela">
                <div className="infoescuela">
                  <h5 className="title-bienvenidaescuela">
                    {" "}
                    Enseñamos en talleres con equipamiento de última generación, donde elaboramos preparaciones
con las mejores materias primas. Somos un centro de formación técnica de prestigio, inmerso en
un entorno riguroso pero cercano.
                  </h5>
                  <h5 className="title-bienvenidaescuela">
                    {" "}
                    <strong>
                    Promovemos el interés y el especial gusto por la gastronomía
francesa. Creemos en nuestros estudiantes y en su futuro como grandes profesionales. Nos
encargamos de guiarlos, entregarles las herramientas y potenciar las competencias necesarias
para su inmersión en el mundo laboral y del emprendimiento.
                    </strong>
                  </h5>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* separador bonappetit */}
      <div className="bonappetit">
        {" "}
        <Bonappetit />
      </div>
      {/* desarrollo de Mision Vision & Historia */}
      <div className="container mi-vi">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6-mision">
            <div className="card  mision">
              <div className="img-vision">
                <img className="img-Mision w-100" src={Mision} alt="" />
              </div>
              <h1 className="titulo-mivi">Misión</h1>
              <div className="mision-info">
                {" "}
                Nuestra misión es formar profesionales gastronómicos certificados a nivel internacional
con competencias prácticas para empleabilidad y emprendimiento, adquiridas en un entorno
didáctico y participativo en instalaciones de primer nivel.
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6-vision">
            <div className="card  mision">
              <div className="img-vision">
                <img className="img-Vision w-100" src={Vision} alt="" />
              </div>
              <h1 className="titulo-mivi">Visión</h1>
              <div className="mision-info">
                {" "}
                Nuestra visión es ser referentes en formación gastronómica, con reconocimiento
internacional, aportando a la innovación y desarrollo en Chile.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* historia de ecole */}
      <div className=" historia">
        <Historiaecole />
      </div>
      <div className=" autoridades">
        {" "}
        <Autoridades />
      </div>
    </div>
  );
};
