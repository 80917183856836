import React, { useState } from "react";

export const CrearBtoncursopasado = ({ onClose }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [popupVisible, setPopupVisible] = useState(true);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleVideoChange = (event) => {
    const videoValue = event.target.value; // Obtiene el valor del input de video
    setVideoUrl(videoValue);
  };

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setImageFile(imageFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Crea un objeto FormData para enviar los datos del formulario al backend
    const formData = new FormData();
    formData.append("video_url", videoUrl);
    formData.append("imagen_video_url", imageFile);

    try {
      const response = await fetch(`${backendURL}/crear_cursospasados`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.message); // Muestra el mensaje del backend

        // Cierra el popup después de recibir una respuesta exitosa del backend
        setPopupVisible(false);

        // Llama a la función onClose para cerrar toda la ventana del popup
        onClose();
      } else {
        const errorData = await response.json();
        console.log(errorData.error); // Muestra el mensaje de error del backend
      }
    } catch (error) {
      console.error("Error al agregar el curso pasado:", error);
    }
  };

  return (
    <>
      {popupVisible && (
        <>
          <section>
            <h1>Crear un Curso Pasado</h1>
          </section>

          <section>
            <form className="formulario-crear-video-pasado" onSubmit={handleSubmit}>
              <label className="titulo-input-admi">
                Video del curso pasado:
                <input
                  className="input-admi"
                  type="url"
                  accept="video/*"
                  onChange={handleVideoChange}
                  value={videoUrl} // Asigna el valor del input desde el estado
                />
              </label>
              <label className="titulo-input-admi">
                Imagen del curso pasado:
                <input
                  className="input-admi"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </label>
              <div className="boton-guardar">
                <button className="boton-crear">Agregar</button>
              </div>
            </form>
          </section>
        </>
      )}
    </>
  );
};