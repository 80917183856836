import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import emailjs from '@emailjs/browser';

import "../../styles/admision/admision.css";
import { FormularioAdmision } from "../formulario-admision";
import { TfiClose } from "react-icons/tfi";
import logo from "../../img/home/logoEcole.png";

import { FiX } from "react-icons/fi";


/* imagenes */
import Malla from "../../img/admision/diurno.png";
import relojmalla from "../../img/admision/relojmalla.png";
import banner from "../../img/admision/banneradmi.png";
import descarga from "../../img/admision/descarga.png";
import cursouno from "../../img/admision/curso1.png";


import logoinscripcion from "../../img/admision/inscripcion.png";

export const Mallacurricular = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [activeButton, setActiveButton] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showFormadmi, setShowFormadmi] = useState(false);
  const [formData, setFormData] = useState({
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    telefono: "",
    correo: ""
  });
  const [formularioEnviado, setFormularioEnviado] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga

 
 

  useEffect(() => {
    setShowForm(false);
  }, []);

  const toggleForm = () => {
    setShowForm(!showForm);
  };
  const toggleFormadmi = () => {
    setShowFormadmi(!showFormadmi);
  };
  const cerrarVentana = () => {
    setShowFormadmi(false);
  };

  


    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
      setIsLoading(true);

    
      emailjs
        .sendForm('service_gvxhwe7', 'template_tslq9fn', form.current, 'jnh08jR8LCj39om0i', )
        .then(
          (result) => {
            console.log(result.text);
            setFormularioEnviado(true); // Formulario enviado con éxito
    
            setTimeout(() => {
              window.location.reload();
            }, 100);
          },
          (error) => {
            console.log(error.text);
          }
        )
        .finally(() => {
          setIsLoading(false); // Move it here so that it is executed regardless of success or error
        });
    };


  return (
    <section className="Mc-Info">
      <section className="Boton-Admision-formulario">
        <div className="admi-form">
          <div> <h1 className="titulo-admi-form"> COMPLETA TUS DATOS DE POSTULACIÓN PARA INICIAR TU INGRESO </h1></div>
          <div> <a href="https://ecole.umas.cl/MNP/"><button className="button-admision">MATRÍCULA</button></a>
        </div>
        </div>
      </section>
      {showFormadmi && ( 
                        <div className="popup-admision-navbar">
                          
            <button
              className="close"
              onClick={cerrarVentana}
              aria-label="Cerrar"
            >
              
                <TfiClose />
              
            </button>
            <FormularioAdmision/>
                        
                        </div>
                      )}
      <div className=" inscripcion-curso-gratis">
        <div className="row inscripcion-curso">
          <div className="col-12 col-sm-4 inscripcion-curso">
            <img src={logoinscripcion} className="w-50 curso-gratis" />
          </div>
          <div className="col-12 col-sm-4 inscripcion-curso">
            <h1 className="titulo-curso-gratis">¡INSCRÍBETE EN UNA</h1>
            <h1 className="titulo-curso-gratis">
              <strong>CLASE DE PRUEBA GRATIS!</strong>
            </h1>
          </div>
          <div className="col-12 col-sm-4 inscripcion-curso">
            <button className="boton-cursos-gratis" onClick={toggleForm}>
              Inscribirse
            </button>
            {showForm && (
                <div className="popup-admision-navbar" style={{ overflowY: 'auto'}} >
              <div className="popup curso-gratis">
                
                <div className="row  curso-gratis">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 curso-gratis">
                  <div className="contenedor-imagen-curso-gratis">           

    <img src={cursouno} className=" imagen-clases-gratis-form" alt="..."/>
 

                  
                  
                      </div>{" "}
                      </div>
                      
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 curso-gratis ">
                    <section className="form-register-curso-gratis">
                    <button className="close-curso" onClick={toggleForm}>
                  <FiX className="close-video-curso-admi-gratis" />
                </button>
                    <div className="form-register-cursos-gratis">
                      <div className="titulo-form-curso-gratis">
                        {" "}
                        <img
                          src={logo}
                          className="logo-curso w-50"
                          alt="Ecole Logo"
                        />
                        <h4 className="titulo informacion-clase-gratis">
                          INSCRIPCIÓN
                        </h4>
                        <h4 className="titulo informacion-clase-gratis1">
                          <strong>CLASE DE PRUEBA GRATIS</strong>
                        </h4>
                      </div>

                      <form ref={form} onSubmit={sendEmail}>
                      <input
                        className="controls"
                        placeholder="Nombre"
                        type="text"
                         name="name"
                        
                      />
                      <input
                        className="controls"
                        placeholder="Apellido Paterno"
                        type="text"
                        name="apellido"
                      />
                      <input
                        className="controls"
                        placeholder="Apellido Materno"
                        type="text"
                        name="materno"
                      />
                      <input
                        className="controls"
                        placeholder="Telefono"
                        type="tel"
                        name="telefono"
                        
                      />
                      <input
                        className="controls"
                        placeholder="Correo Electronico"
                        type="email"
                        name="correo"
                      />
                     <section className="boton-form-curso-gratis"><input  className="botons" type="submit" value="ENVIAR" /></section> 
                    </form>
                  </div> 
                  {isLoading && ( 
                      <div className="popup-admisionnavbar">
          <div className="loading-spinner">
          <p className="mensajes-form-envio">Gracias por contactarnos, tu
información fue enviada.</p>
          <svg className="pl" width="240" height="240" viewBox="0 0 240 240">
	<circle className="pl__ring pl__ring--a" cx="120" cy="120" r="105" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 660" stroke-dashoffset="-330" stroke-linecap="round"></circle>
	<circle className="pl__ring pl__ring--b" cx="120" cy="120" r="35" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 220" stroke-dashoffset="-110" stroke-linecap="round"></circle>
	<circle className="pl__ring pl__ring--c" cx="85" cy="120" r="70" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 440" stroke-linecap="round"></circle>
	<circle className="pl__ring pl__ring--d" cx="155" cy="120" r="70" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 440" stroke-linecap="round"></circle>
</svg></div>
          </div>
         )}  
                  </section> 
                  </div>
                  </div>{" "}
                </div>
              </div>
            )}{" "}
          </div>
        </div>
      </div>
      <div className="contienen-banner-malla">
        <div className="banner-titulo-mallac">
        <div className="titulo-primero-banner">
          <div className="img-banner-mallac">
            <img src={banner} className="w-100 titulo-banner-malla" />
          </div>{" "}
          <div className="titulo-banner-malla-admi">
            <h1 className="titulo1-admi-banner">TÍTULO TÉCNICO DE NIVEL SUPERIOR GASTRONOMÍA INTERNACIONAL</h1>

          </div>
          </div>
          <h2 className="titulo1-admi-banner">MENCIÓN GASTRONOMÍA FRANCESA / MENCIÓN EN PANADERÍA</h2>
        </div>
      </div>
      <div className="container Malla">
        <div className="row malla">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 malla1">
            <div class="row infoiconomalla">
              <div className="col info1-Mallacurricular">
                <div className="info-img-logo-unomallacurricular">
                  <img src={relojmalla} className="w-100 diurno" />
                </div>
                <div>
                  <div className="info-titulo-mallacurricular">
                    <h1 className="titulo-info-img-logo-mallacurricular">
                      Duración
                    </h1>
                    <h2 className="subtitulo-info-img-logo-mallacurricular">
                      2 años / 1 año de Especialización en Francia(Opcional)
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col info-Mallacurricular">
                <div className="info-img-logo-mallacurricular">
                  {" "}
                  <img src={Malla} className="w-100 diurno" />
                </div>{" "}
                <div>
                  <div className="info-titulo-mallacurricular">
                    <h1 className="titulo-info-img-logo-mallacurricular">
                      Modalidad
                    </h1>
                    <h2 className="subtitulo-info-img-logo-mallacurricular">
                      Diurna / Semipresencial
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 malla">
            <div className="card Malla">
              <h1 className="titulo-malla-cv">Malla Curricular</h1>
              <div className="col info-Mallacurricular">
                <h3 className="info-malla-cv">
                  Conoce todo sobre cada semestre y asignaturas
                </h3>
              </div>

              <div className="col info-Mallacurricular"> 
              <a
                href={`${backendURL}/static/pdf/mallacurrricular.pdf`}
                target="_blank"
              >
              <button className="button-dercarga-malla" type="button" >
          <span className="button__text-dercarga-malla">
            <p className="titulo-boton-descarga">Descargar</p>
          </span>
                    <span className="button__icon-dercarga-malla">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 35 35"
                        id="bdd05811-e15d-428c-bb53-8661459f9307"
                        data-name="Layer 2"
                        class="svg"
                      >
                        <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
                        <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
                        <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
                      </svg>
                    </span>
                  </button>
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <section className="info-becas">
        <div className="titulo-info-beca">
          <h1 className="titulo-beca-info"> BECAS Y FINANCIAMIENTO</h1>
        </div>

        <div className="botones becas">
          <p className="botones-admision-becas">
            <button
              className={`boton-beca ${activeButton === "button1" ? "active" : ""}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample1"
              aria-expanded="false"
              aria-controls="collapseExample1"
              onClick={() => setActiveButton("button1")}
            >
              Alain Nonnet
            </button>
            <button
              className={`boton-beca ${activeButton === "button2" ? "active" : ""}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample2"
              aria-expanded="false"
              aria-controls="collapseExample2"
              onClick={() => setActiveButton("button2")}
            >
              Talento Joven
            </button>
            <button
              className={`boton-beca ${activeButton === "button3" ? "active" : ""}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample3"
              aria-expanded="false"
              aria-controls="collapseExample3"
              onClick={() => setActiveButton("button3")}
            >
              Talento Joven Región
            </button>
            <button
              className={`boton-beca ${activeButton === "button4" ? "active" : ""}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample4"
              aria-expanded="false"
              aria-controls="collapseExample4"
              onClick={() => setActiveButton("button4")}
            >
              Deportiva
            </button>
          </p>
          <div
            className={`collapse ${activeButton === "button1" ? "show" : ""}`}
            id="collapseExample1"
          >
            <div className="card card-descarga-beca">
            Dirigida al mejor estudiante de cada promoción. La beca contempla el financiamiento del 50% del arancel del plan de Especialización en Francia, Maîtrise de Cuisine Française. 
            </div>
            
              {" "}
              <div className="button-descarga-beca" data-tooltip="">
              <a
                href={`${backendURL}/static/pdfban/BecaAlainNonnet.pdf`}
                target="_blank"
              >
                <button className="boton-descargas-becas">
                <div className="button-wrapper-descarga-beca">
               
                  <div className="text-button-descarga-beca" > <div className=" img-boton-descarga-requisito">< img src={descarga} className="w-100 boton-descarga-admision" /> </div>Descargar detalle de la Beca</div>
                  <span className="icon-button-descarga-beca">
                  <div className=" img-boton-descarga-requisito-abajo">< img src={descarga} className="w-100 boton-descarga-admision" /> </div>
                  </span>
                </div></button>
                </a>
              </div>
           
          </div>
          <div
            className={`collapse ${activeButton === "button2" ? "show" : ""}`}
            id="collapseExample2"
          >
            <div className="card card-descarga-beca">
            Dirigida a estudiantes de la carrera de Gastronomía o Servicios de Alimentación Colectiva de liceos técnicos o afines de la Región Metropolitana.
            </div>
            
              {" "}
              <div className="button-descarga-beca" data-tooltip="">
              <div className="button-wrapper-descarga-beca">
              <a
                href={`${backendURL}/static/pdfTJ/BecaTalentoJoven.pdf`}
                target="_blank"
              >
               <button className="boton-descargas-becas">
                  <div className="text-button-descarga-beca"> <div className=" img-boton-descarga-requisito">< img src={descarga} className="w-100 boton-descarga-admision" /> </div>Descargar detalle de la Beca</div>
                   <span className="icon-button-descarga-beca"> 
                  <div className=" img-boton-descarga-requisito-abajo">< img src={descarga} className="w-100 boton-descarga-admision" /> </div>
                   </span> 
               </button>
               </a>
               </div>
              </div>

          </div>
          <div
            className={`collapse ${activeButton === "button3" ? "show" : ""}`}
            id="collapseExample3"
          >
            <div className="card card-descarga-beca">
            Dirigida a estudiantes de la carrera de Gastronomía o Servicios de Alimentación Colectiva de liceos técnicos o afines de regiones.
            </div>
            
              {" "}
              <div className="button-descarga-beca" data-tooltip="">
                <div className="button-wrapper-descarga-beca">
                <a
                href={`${backendURL}/static/pdfTJR/BecaTalentoJovenRegion.pdf`}
                target="_blank"
              >
                <button  className="boton-descargas-becas">
                  <div className="text-button-descarga-beca"> <div className=" img-boton-descarga-requisito">< img src={descarga} className="w-100 boton-descarga-admision" /> </div>Descargar detalle de la Beca</div>
                  <span className="icon-button-descarga-beca">
                  <div className=" img-boton-descarga-requisito-abajo">< img src={descarga} className="w-100 boton-descarga-admision" /> </div>
                  </span>
                </button>
                </a>
                </div>
              </div>
          
          </div>
          <div
            className={`collapse ${activeButton === "button4" ? "show" : ""}`}
            id="collapseExample4"
          >
            <div className="card card-descarga-beca">
            Dirigida a deportistas destacados tanto nacionales como internacionales.
            </div>
           
              <div className="button-descarga-beca" data-tooltip="">
                <div className="button-wrapper-descarga-beca">
                <a
                href={`${backendURL}/static/pdfDeportiva/BecaDeportiva.pdf`}
                target="_blank"
              >
                <button className="boton-descargas-becas">
                  <div className="text-button-descarga-beca"> <div className=" img-boton-descarga-requisito">< img src={descarga} className="w-100 boton-descarga-admision" /> </div>Descargar detalle de la Beca</div>
                  <span className="icon-button-descarga-beca">
                  <div className=" img-boton-descarga-requisito-abajo">< img src={descarga} className="w-100 boton-descarga-admision" /> </div>
                  </span>
               </button> 
               </a></div>
              </div>
           
          </div>
        </div>
      </section>
    </section>
  );
};
