import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import axios from "axios";


class Card extends Component {
  
 

  constructor(props) {
    super(props);
    this.state = {
      isHovering: false,
    };
  }

  handleMouseHover = () => {
    this.setState({
      isHovering: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      isHovering: false,
    });
  };

 ;

  render() {
    return (
      <div
        className="card slider"
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseLeave}
      ><Link to={this.props.link} target="_blank" className="linkdebutonsecundariohome">
        <div className="card-slider-info-home">
          <img
            className="cardsliderhome w-100"
            src={this.props.image}
            alt="imagen"
          />
        </div>
        </Link>

      </div>
    );
  }
}

export default function AutoPlay() {
  const [listaPortadas, setListaPortadas] = useState([]);
  const [imagenKey, setImagenKey] = useState(Date.now());
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    obtenerPortadas();
  }, []);

  const obtenerPortadas = () => {
    axios
      .get(`${backendURL}/obtener_portadadoshome`)
      .then((response) => {
        setListaPortadas(response.data.portadas);
      })
      .catch((error) => {
        console.error("Error al obtener la lista de portadas:", error);
      });
  };

  

  const settings = {

      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      prevArrow: <BsChevronLeft />,
      nextArrow: <BsChevronRight />,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: false,
          },
        },
      ],
    };

    return (
      <div className="container-fluid carousel info">
        <div className="titulo carousel info">
          <h1 className="titulo carousel info">NOVEDADES Y DESTACADOS</h1>
        </div>
        <div className="carousel home">
          <Slider {...settings}>
          {listaPortadas.map((portada) => (
            <div>
              <Card
               image={`${backendURL}/imagen_portadadoshome/${portada.imagen_segundo}?${imagenKey}`}
               link={portada.url_segundo}
               
              />
            </div>
          ))}
            
          </Slider>
        </div>
      </div>
    );
  }

