import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import { Botoncrearvideoadmision } from "./boton-crear-videosadmision";
import { Botoneditarvideoadmision } from "./boton-editar-videosadmision";

export const VideoAdmision = () => {
    const [mostrarCrearPopup, setMostrarCrearPopup] = useState(false);
    const [mostrarEditarPopup, setMostrarEditarPopup] = useState(false);
    const [listaPortadas, setListaPortadas] = useState([]);
    const [videoadmisionIdSeleccionada, setPortadaIdSeleccionada] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL
     // Agrega una clave única para la URL de la imagen

    const cerrarVentana = () => {
      setMostrarCrearPopup(false);
      setMostrarEditarPopup(false);
    };
  
    useEffect(() => {
      obtenerPortadas();
    }, []);
  
    const obtenerPortadas = () => {
      axios
        .get(`${backendURL}/videos_admision`)
        .then((response) => {
          setListaPortadas(response.data);
        })
        .catch((error) => {
          console.error("Error al obtener la lista de portadas:", error);
        });
    };
  
    const eliminarVideoAdmision = (videoadmisionId) => {
      axios
        .delete(`${backendURL}/eliminar_videosadmision/${videoadmisionId}`)
        .then((response) => {
          console.log(response.data);
          setListaPortadas((prevVideos) =>
          prevVideos.filter((videoadmi) => videoadmi.id !== videoadmisionId)
          );
        })
        .catch((error) => {
          console.error("Error al eliminar la videoadmi:", error);
        });
    }; 
  
    const handleEditarvideoadmsion = (videoadmisionId) => {
      setPortadaIdSeleccionada(videoadmisionId);
      setMostrarEditarPopup(true);
    };
  
    const actualizarPortadaEnLista = (videoadmisionId, videoData) => {
      // Encuentra la portada en la lista y actualiza sus datos
      const portadasActualizadas = listaPortadas.map((videoadmi) => {
        if (videoadmi.id === videoadmisionId) {
          return {
            ...videoadmi,
            descripcion_video_admi: videoData.descripcion_video_admi,
            url_video_admi: videoData.url_video_admi,
          };
        }
        return videoadmi;
      });
  
      setListaPortadas(portadasActualizadas);
    };
      // Coloca el console.log fuera del bucle map
  
  
    return (
      <>
      <section>
          <h1 className="titulo-info-componenete-admi">Video Admision</h1>
        </section>
        <section className="boton-portada-cursosydiplomado">
          <button
            className="boton-crear"
            onClick={() => setMostrarCrearPopup(true)}
          >
            Crear
          </button>
        </section>
  
        {mostrarCrearPopup && (
          <div className="popup-admi">
            <div className="popup-content-admi">
              <button className="close-button-admi" onClick={cerrarVentana}>
                X
              </button>
              <Botoncrearvideoadmision cerrarVentana={cerrarVentana} />  
            </div>
          </div>
        )} 
  
        <section className="lista-chef-cursosydiplomado">
          <table className="lista">
            <thead>
              <tr>
                <th className="titulobd">URL Video</th>
                <th className="titulobd">Descripcion</th>
                <th className="titulobd">Acciones</th>
              </tr>
            </thead>
            <tbody>
            {listaPortadas.map((videoadmi) => {

            
            return (
              <tr className="info-bd" key={videoadmi.id}>
                <td className="info-bd">
                  <div className="imagen-info-admi">
                  <a href={videoadmi.url_video_admi} target="_blank" rel="noopener noreferrer">
                Ver video
              </a>
                  </div>
                </td>
                <td className="info-bd"> <DangerouslySetHTMLContent html={videoadmi.descripcion_video_admi}/></td>
                <td className="edicion">
                  <button
                    className="boton-editar"
                    onClick={() => handleEditarvideoadmsion(videoadmi.id)}
                  >
                    <BiEdit />
                  </button>
                  <button
                    className="boton-eliminar"
                     onClick={() => eliminarVideoAdmision(videoadmi.id)} 
                  >
                    <BiTrash />
                  </button>
                </td>
              </tr>
            );
          })}
            </tbody>
          </table>
        </section>
  
        { mostrarEditarPopup && (
          <div className="popup-admi">
            <div className="popup-content-admi">
              <button className="close-button-admi" onClick={cerrarVentana}>
                X
              </button>
              <Botoneditarvideoadmision
                cerrarVentana={cerrarVentana}
                videoadmisionId={videoadmisionIdSeleccionada}
                actualizarPortadaEnLista={actualizarPortadaEnLista} // Pasa la función para actualizar la lista al componente hijo
              />   
            </div>
          </div>
        )}  *
      </>
    );
  };
