import React from "react";
import "../styles/docentes/docentes.css";

/* componenetes */
import { Portadadocentes } from "../component/docentes/portadadocentes";
import { Bienveniddocente } from "../component/docentes/docentesbienvenida";
import { Docentescar } from "../component/docentes/carddocentes";

export const Docentes = () => {
  return (
    <div className="todo-home">
      
        <Portadadocentes />
     
      <section>
        <Bienveniddocente />
      </section>
      <section>
        <div className="container cuerpo docente">
          <div className="titulocard docentes">
            <h1 className="docentetitulo1">Cuerpo de Docentes</h1>
          </div>
        </div>

        <Docentescar />
      </section>
    </div>
  );
};
