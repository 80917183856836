import React from "react";
import "../styles/admision/admision.css";
import { AdmiPortada } from "../component/admision/formularioadmision";
import { Mallacurricular } from "../component/admision/card-mallacuricular";
import { Requisitos } from "../component/admision/requisitos";
import { Responsivevideoadmi } from "../component/admision/cardvideoadmi";

export const Admision = () => {
  return (
    <div className="todo-home">
      <AdmiPortada />

      <div className="mc">
        <Mallacurricular />
      </div>
      <div className="container-fluid requisitos">
        <Requisitos />
      </div>
      <div className=" videoadmi ">
        <Responsivevideoadmi />
      </div>
    </div>
  );
};
