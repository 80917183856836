import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Botoncrearportadadiploycurso } from "../cursoydiplomado/portada-cursoydiplocomponenete/boton-crear-portada-cursoydiplo";
import { Botoneditarportadacursoydiplo } from "../cursoydiplomado/portada-cursoydiplocomponenete/boton-editar-portadacursoydiplo";

export const Admiportadadiploycursopage = () => {
  const [mostrarCrearPopup, setMostrarCrearPopup] = useState(false);
  const [mostrarEditarPopup, setMostrarEditarPopup] = useState(false);
  const [listaPortadas, setListaPortadas] = useState([]);
  const [portadaIdSeleccionada, setPortadaIdSeleccionada] = useState(null);
  const [imagenKey, setImagenKey] = useState(Date.now()); // Agrega una clave única para la URL de la imagen
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const cerrarVentana = () => {
    setMostrarCrearPopup(false);
    setMostrarEditarPopup(false);
  };

  useEffect(() => {
    obtenerPortadas();
  }, []);

  const obtenerPortadas = () => {
    axios
      .get(`${backendURL}/obtener_portadadc`)
      .then((response) => {
        setListaPortadas(response.data.portadas);
      })
      .catch((error) => {
        console.error("Error al obtener la lista de portadas:", error);
      });
  };

  const eliminarPortada = (portadaId) => {
    axios
      .delete(`${backendURL}/eliminar_portadadc/${portadaId}`)
      .then((response) => {
        console.log(response.data);
        setListaPortadas((prevPortadas) =>
          prevPortadas.filter((portada) => portada.id !== portadaId)
        );
      })
      .catch((error) => {
        console.error("Error al eliminar la portada:", error);
      });
  };

  const handleEditarPortada = (portadaId) => {
    setPortadaIdSeleccionada(portadaId);
    setMostrarEditarPopup(true);
  };

  const actualizarPortadaEnLista = (portadaId, portadaData) => {
    // Encuentra la portada en la lista y actualiza sus datos
    const portadasActualizadas = listaPortadas.map((portada) => {
      if (portada.id === portadaId) {
        return {
          ...portada,
          imagen: portadaData.imagen,
          imagen_responsive: portadaData.imagen_responsive,
          url: portadaData.url
        };
      }
      return portada;
    });

    setListaPortadas(portadasActualizadas);
  };

  return (
    <>
    <section>
        <h1 className="titulo-info-componenete-admi">Portada Cursos y Diplomado</h1>
      </section>
      <section className="boton-portada-cursosydiplomado">
        <button
          className="boton-crear"
          onClick={() => setMostrarCrearPopup(true)}
        >
          Crear
        </button>
      </section>

      {mostrarCrearPopup && (
        <div className="popup-admi">
          <div className="popup-content-admi">
            <button className="close-button-admi" onClick={cerrarVentana}>
              X
            </button>
            <Botoncrearportadadiploycurso cerrarVentana={cerrarVentana} />
          </div>
        </div>
      )}

      <section className="lista-chef-cursosydiplomado">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Imagen portada</th>
              <th className="titulobd">Imagen movil</th>
              <th className="titulobd">URL</th>
              <th className="titulobd">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {listaPortadas.map((portada) => (
              <tr className="info-bd" key={portada.id}>
                <td className="info-bd">
                  <div className="imagen-info-admi">
                    <img
                      className="imagen-info-admi"
                      src={`${backendURL}/imagen_portadadc/${portada.imagen}?${imagenKey}`} // Agrega un parámetro adicional a la URL de la imagen
                      alt=""
                    />
                  </div>
                </td>
                <td className="info-bd">
                  <div className="imagen-info-admi">
                    <img
                      className="imagen-info-admi"
                      src={`${backendURL}/imagen_portadacursoresponsive/${portada.imagen_responsive}?${imagenKey}`} // Agrega un parámetro adicional a la URL de la imagen
                      alt=""
                    />
                  </div>
                </td>
                <td className="info-bd">{portada.url}</td>
                <td className="edicion">
                  <button
                    className="boton-editar"
                    onClick={() => handleEditarPortada(portada.id)}
                  >
                    <BiEdit />
                  </button>
                  <button
                    className="boton-eliminar"
                    onClick={() => eliminarPortada(portada.id)}
                  >
                    <BiTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {mostrarEditarPopup && (
        <div className="popup-admi">
          <div className="popup-content-admi">
            <button className="close-button-admi" onClick={cerrarVentana}>
              X
            </button>
            <Botoneditarportadacursoydiplo
              cerrarVentana={cerrarVentana}
              portadaId={portadaIdSeleccionada}
              actualizarPortadaEnLista={actualizarPortadaEnLista} // Pasa la función para actualizar la lista al componente hijo
            />
          </div>
        </div>
      )}
    </>
  );
};