import React, { useState} from "react";
import axios from "axios";
import { FcAddImage } from "react-icons/fc";

import { GaleriaMostrar } from "./mostrar-galeria";

export const Galeriacurso = ({ cursoId, nombreCurso }) => {
  const [mostrarInput, setMostrarInput] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleIngresarClick = () => {
    setMostrarInput(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Obtén el archivo de imagen del formulario
      const formData = new FormData();
      formData.append("imagen", event.target.elements["imagen"].files[0]);

      // Realiza la solicitud POST al endpoint del backend para guardar la imagen
      await axios.post(`${backendURL}/guardar_galeriacurso`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        params: { curso_id: cursoId },
      });

      // Cerrar el formulario y actualizar la página
      setMostrarInput(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseClick = () => {
    setMostrarInput(false);
  };

  return (
    <>
      <section className="admi-galeria curso">
        <h1>Galeria "{nombreCurso}"</h1>
      </section>
      <section className="galeria-botones">
        <button className="boton-crear" onClick={handleIngresarClick}>
          Agregar
        </button>
        {mostrarInput && (
          <form className="agregar-imagen-galeria" onSubmit={handleSubmit}>
            <input type="file" name="imagen" />
            <button className="agregarimagen" type="submit">
              <FcAddImage />
            </button>
            
          </form>
        )}
      </section>
      <GaleriaMostrar cursoId={cursoId} />
    </>
  );
};