import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

/* componentes */
import { PortadaEstructuraCursos } from "./estructura componentes/portadaestruccursos";
import { BienvenidaEstructuraCursos } from "./estructura componentes/bienvenidaestrcucursos";
import { PrecioEstructuraCursos } from "./estructura componentes/precioestruccursos";
import { InfoEstructuraCursos } from "./estructura componentes/infodeestruccursos";
import { ArticuloEstructuraCursos } from "./estructura componentes/articulo-cursos";

export const EstructuraCursos = () => {
  const { id } = useParams();
  const [cursoData, setCursoData] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    obtenerDatosCurso();
  }, []);

  const obtenerDatosCurso = async () => {
    try {
      const response = await axios.get(
        `${backendURL}/cardcursoydiplo/${id}`
      );
      const curso = response.data;
      setCursoData(curso);
    } catch (error) {
      console.error(error);
    }
  };

  if (!cursoData) {
    // Aquí podrías mostrar un spinner o un mensaje de carga mientras se obtienen los datos
    return <div>Cargando...</div>;
  }


  return (
    <div  className="todo-home">
      <section className="portada-estructura-curso">
        {" "}
        <PortadaEstructuraCursos curso={cursoData} />
      </section>
      <section className="bienvenida-estructura-curso">
        {" "}
        <BienvenidaEstructuraCursos curso={cursoData}  />
      </section>

      <section className="info-estructura-curso">
        {" "}
        <InfoEstructuraCursos curso={cursoData} /> 
      </section>
      <section className="precio-estructura-curso">
        {" "}
        <PrecioEstructuraCursos curso={cursoData} />{" "}
      </section>

      <section className="articulo-estructura-curso">
        {" "}
         <ArticuloEstructuraCursos curso={cursoData} /> 
      </section>
    </div>
  );
};
