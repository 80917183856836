import React from "react";
import { Portadaespecialidad } from "../component/especialidad/portada";
import { Programapasantia } from "../component/especialidad/programapasantia";
import { TestimonioEspecialidad } from "../component/especialidad/testimoniopasantia";
import { Videopasantia } from "../component/especialidad/videopromocionalpasantia";

import { SeguroPasantia } from "../component/especialidad/seguridadpasantia";
import { MapaPasantia } from "../component/especialidad/mapapasantia";
import { Footerglosario } from "../component/especialidad/footerpasantia";
export const Especializacionenfrancia = () => {
  return (
    <div className="todo-home">
      
        <Portadaespecialidad />
    
      <div className=" especialidad francesa">
        <Programapasantia />
      </div>
      <div>
        <TestimonioEspecialidad />
      </div>
      <div className="pasantiafrancesa">
        <Videopasantia />
      </div>

      <div className="seguropasantia">
        <SeguroPasantia />
      </div>

      <div className="mapaespasantia">
        <MapaPasantia />
      </div>
      <div className="container mapapasantia">
        <Footerglosario />
      </div>
    </div>
  );
};
