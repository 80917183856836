import React, { useState } from "react";
import { Link } from "react-router-dom";
import iconoadmi from "../img/iconopaginas/botonadmi.png";
import { FormularioAdmision } from "./formulario-admision";
import { TfiClose } from "react-icons/tfi";
import "../styles/index.css";

export const Botonadmi = () => {
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };
  const cerrarVentana = () => {
    setShowForm(false);
  };
  return (
    <>
    <Link to="/admision" className="contenedor-icono-admi" >
      <img src={iconoadmi} className="iconoadmin w-100"  onClick={toggleForm} />
      <div className="alerta">Más Información</div>
    </Link>
{showForm && ( 
  <div className="popups-admision">
   
<button
className="admision-close"
onClick={cerrarVentana}
aria-label="Cerrar"
>

<TfiClose />

</button>
<FormularioAdmision/>
  
 
  </div>
)}</>
  );
};