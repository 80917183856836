import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import { Botoncreanoticia } from "./recetas y tips/crear-noticias";
import { Editarnoticia } from "./recetas y tips/editar-noticia";





export const Noticias = () =>{
    const [noticias, setNoticias] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editTestId, setEditTestId] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

    const togglePopup = () => {
        setShowPopup(!showPopup);
      };
      
      const closePopups = () => {
        setShowCreatePopup(false);
        setShowEditPopup(false);
      };
    
      const toggleEditPopup = (noticiaId) => {
        setShowEditPopup(!showEditPopup);
        setEditTestId(noticiaId);
      };


      useEffect(() => {
        // Fetch testimonios from the backend API
        axios.get(`${backendURL}/obtener_noticias`)
          .then(response => {
            setNoticias(response.data);
          })
          .catch(error => {
            console.error("Error fetching testimonios:", error);
          });
      }, []);

      const handleDelete = async (noticiaId) => {
        try {
            const response = await axios.delete(`${backendURL}/eliminar_noticia/${noticiaId}`);
            console.log(response.data);
            // You can add additional logic here, like updating the UI after deletion
        } catch (error) {
            console.error(error);
            // Handle error cases
        }
    };

    return(
        <><section>
        <h1 className="titulo-info-componenete-admi">Noticias</h1>
      </section>
      <section className="boton-crear-chef-cursosydiplomado">
        <button className="boton-crear" onClick={togglePopup} >
          Crear
        </button></section> 
        {showPopup && (
        <div className="popup-admi">
          <div className={`popup-content-admi ${showEditPopup ? 'scroll-noticia' : ''}`}>
          <Botoncreanoticia onClose={togglePopup} /> 
          </div>
        </div>
      )}
        
        <section className="lista-chef-cursosydiplomado">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Fecha</th>
              <th className="titulobd">Imagen </th>
              <th className="titulobd">Titulo principal</th>
              <th className="titulobd">Descripcion principal</th>
              <th className="titulobd">Url video</th>
              <th className="titulobd">Acciones</th>

            </tr>
          </thead>
          {noticias.map(noticia => (
    <tr key={noticia.id} className="info-bd">
      <td className="info-bd">{noticia.fecha}</td>
      <td>
        <img
          className="imagen-info-admi"
          src={`${backendURL}/imagen_noticia/${noticia.imagen}`}
          alt="Imagen "
        />
      </td>
      <td className="info-bd">{noticia.titulo_principal}</td>
      <td className="info-bd">{noticia.descripcion_principal} </td>
      {/* <td className="info-bd"><DangerouslySetHTMLContent html={noticia.descripcion_noticias}/></td> */}
      <td className="info-bd">{noticia.url_video}</td>
      <td className="edicion">
        <button className="boton-editar"  onClick={() => toggleEditPopup(noticia.id)} >
          <BiEdit />
        </button>
        <button className="boton-eliminar"onClick={() => handleDelete(noticia.id)} >
          <BiTrash />
        </button>
      </td>
    </tr>
  ))}
        </table></section>
        {showEditPopup && (
        <div className="popup-admi">
          <div className={`popup-content-admi ${showEditPopup ? 'scroll-noticia' : ''}`}>
           
         <Editarnoticia noticiaId={editTestId} onClose={closePopups} /> 
          </div></div>
        
      )}</>
    )

}