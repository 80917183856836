import React, { useState, useEffect,  useRef } from "react";
import "../styles/resultado/resultado.css";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

export const Resultado = ({ setShowNavbarFooter }) => {
  const [countdown, setCountdown] = useState(5);
  const [resultadoOrdenPago, setResultadoOrdenPago] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const [formularioInfo, setFormularioInfo] = useState({
    numero_orden: "",
    nombre: "",
    rut: "",
    correo_compra: "",
    telefono: "",
    cursos: "",
    monto: "",
  });

  useEffect(() => {
    setShowNavbarFooter(false);
    return () => {
      setShowNavbarFooter(true);
    };
  }, [setShowNavbarFooter]);

  useEffect(() => {
    setShowNavbarFooter(false);

    const idSesion = new URLSearchParams(location.search).get("id_sesion");

    if (idSesion) {
      obtenerResultado(idSesion);
    } else {
      console.error("No se encontró el parámetro id_sesion en la URL");
    }

    return () => {
      setShowNavbarFooter(true);
    };
  }, [setShowNavbarFooter, location.search]);

  const obtenerResultado = async (idSesion) => {
    try {
      // Tu usuario y contraseña de autenticación
      const usuario = "ecole";
      const contraseña = "wxa66vea8uL16e2N";

      // URL de la API para obtener el resultado de la orden de pago
      const apiUrl = `https://iopago.net/api-cert/io/pagos/resultado?id_sesion=${idSesion}`;

      // Configuración de la solicitud
      const config = {
        auth: {
          username: usuario,
          password: contraseña,
        },
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*", // Reemplaza con el dominio permitido en producción
        },
      };

      // Realizar la solicitud GET a la API
      const response = await axios.get(apiUrl, config);

      setResultadoOrdenPago(response.data.resultado);

      enviarRespuestaAPython(response.data.resultado);

      // Puedes manejar la respuesta de la API según tus necesidades aquí
    } catch (error) {
      console.error(
        "Error al obtener el resultado de la orden de pago:",
        error
      );
      // Puedes manejar los errores según tus necesidades aquí
    }
  };

  
  const enviarRespuestaAPython = async (respuestaOrdenPago) => {
    try {
      const { ordenPago, codigoResultado } = respuestaOrdenPago;
  
      let nuevoEstado;
      if (codigoResultado === 0) {
        nuevoEstado = "exitoso";
      } else if (codigoResultado === 1) {
        nuevoEstado = "abortada";
      } else if (codigoResultado === -1) {
        nuevoEstado = "fallida";
      } else {
        console.error("Código de resultado no válido");
        return;
      }
  
      const response = await axios.post(`${backendURL}/updateEstado`, {
        numero_orden: ordenPago,
        estado: nuevoEstado,
        codigoResultado: codigoResultado,
      });
  
      
  
      const facturaInfo = {
        numero_orden: response.data.factura.numero_orden,
        estado: response.data.factura.estado,
        correo_compra: response.data.factura.correo_compra,
        rut: response.data.factura.rut,
        nombre: response.data.factura.nombre,
        telefono: response.data.factura.telefono,
        monto: response.data.factura.monto,
        cursos: response.data.factura.cursos_asociados || [],
      };
      
      console.log("info de la data factura", facturaInfo);
  
       const sendEmailResponse = await axios.post(
        "https://holamundoejemplolala.cl.aleramatech.com/sendEmail.php",
        facturaInfo
      ); 
     
  
      // Puedes manejar la respuesta según tus necesidades aquí
    } catch (error) {
      console.error("Error al enviar respuesta al servidor Python:", error);
      // Puedes manejar errores según tus necesidades aquí
    }
  };



  useEffect(() => {
    let countdownInterval;
    const mostrarError = () => {
      let secondsLeft = 3; // Iniciar el contador en 3 segundos
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
        secondsLeft -= 1;

        if (secondsLeft === 0) {
          clearInterval(countdownInterval);
          navigate("/");
        }
      }, 2000);
    };

    // Iniciar el contador y la redirección al montar el componente
    mostrarError();

    // Limpiar el intervalo cuando el componente se desmonta
    return () => clearInterval(countdownInterval);
  }, [navigate]);

  return (
    <>
       {resultadoOrdenPago && resultadoOrdenPago.codigoResultado === 0 ? (
      // Si el códigoResultado es 0, muestra el contenido de éxito
      <div className="resultado-factura">
        <div className="success-checkmark-factura">
          <div className="check-icon-factura">
            <span className="icon-line line-tip-factura"></span>
            <span className="icon-line line-long-factura"></span>
            <div className="icon-circle-factura"></div>
            <div className="icon-fix-factura"></div>
          </div>
        </div>
        <center>
          <h1 className="titulo-resultado-factura">Compra Realizada</h1>
          <p className="orden-crear">
            Regresas al home en {countdown} segundos...
          </p>
        </center>
      </div>
    ): (
        // Si el códigoResultado no es 0 o no hay resultado, muestra el contenido de error
        <div className="resultado-factura">
          <div className="container orden-pago-error">
            <div className="circle-border"></div>
            <div className="circle">
              <div className="error"></div>
            </div>
          </div>
          <center>
            <h1 className="titulo-resultado-factura-error">
              Ups! Ocurrió un Problema
            </h1>
            <p className="orden-crear">
              Regresas al home en {countdown} segundos...
            </p>
          </center>
         
        </div>
      )}

    </>
  );
};
