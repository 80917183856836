import React from "react";

import { Portadacarrera } from "../component/carreras/portada-carrera";
import { Titulos } from "../component/carreras/titulo-carrera";
import { Infoperfil } from "../component/carreras/infoperfil";
import { Diploycerti } from "../component/carreras/diploycerti";
import { FotterCarerra } from "../component/carreras/finalcarrera";

export const Carreras = () => {
  return (
    <div className="todo-home">
      <Portadacarrera />
      <div className="titulo carrera">
        <Titulos />
      </div>
      <div className="container infoperfil">
        <Infoperfil />
      </div>
      <div className="diplomado">
        {" "}
        <Diploycerti />
      </div>
      <div>
        <FotterCarerra />
      </div>
    </div>
  );
};
