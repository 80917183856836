import React from "react";
import "../../styles/blog/blog.css";

/* imagenes */
import bienvenida from "../../img/blog/bienvenidablog.jpg";
export const BienvenidaBlog = () => {
  return (
    <div className="container bienvenida blog">
      <div className=" bienvenida blog">
        <div className="infobienvenida blog">
          <h1 className=" bienvenida blog">
            {" "}
            PORQUE LA COCINA ES PARA TODOS, TE INVITAMOS A VIVIR UNA EXPERIENCIA
            ÚNICA.
          </h1>
        </div>
      </div>
    </div>
  );
};
