 import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


export const EditarDocente = ({docenteId, onClose}) => {
    const [descripcion, setDescripcion] = useState('');
    const [Nombre, setNombre] = useState('');
    const [TipoDocente, setTipoDocente] = useState('');
    const [docenteData, setDocenteData] = useState({});
    const [imagen, setImagen] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        axios.get(`${backendURL}/obtener_docentes/${docenteId}`)
            .then(response => {
                const fetchedDocenteData = response.data.docente;
                setDocenteData(fetchedDocenteData);
                setNombre(fetchedDocenteData.nombre_docente);
                setDescripcion(fetchedDocenteData.descripcion);
                setTipoDocente(fetchedDocenteData.tipo_docente);
            })
            .catch(error => {
                console.error("Error fetching docente data:", error);
            });
    }, [docenteId]);

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        setImagen(selectedImage);
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("nombre_docente", Nombre);
        formData.append("tipo_docente", TipoDocente);
        formData.append("descripcion", descripcion);

        if (imagen) {
            formData.append("imagen", imagen);
        }

        try {
            const response = await axios.put(`${backendURL}/editar_docente/${docenteId}`, formData);
            console.log(response.data);
            // Add any additional logic you want after successful editing
            onClose();
            // Realizar acciones adicionales después de la edición
        }  catch (error) {
            console.error("Error al editar el docente:", error.response); // Cambio aquí
        }
    };

    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
      
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
      
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      
        [
          { 'color': ['#696965', 'red', 'blue', 'green'] },  // Specify custom colors
          { 'background': ['#696965', 'yellow', 'pink', 'orange'] }  // Specify custom background colors
        ],
        [{ 'font': [] }],
        [
          { 'align': [] },
          { 'align': ['center', 'right', 'justify'] }  // Additional alignment options
        ],
      
        ['clean']   
      ];
      const module = {
        toolbar: toolbarOptions,
      };
    return(
        <>
        <button className="close-button-admi" onClick={onClose}>
        X
      </button>
        <section>
        <form className="crear-docente-form">
        <div>
                        <label className="titulo-input-admi" htmlFor="imagen">
                           imagen:
                        </label>
                        <input
                 type="file"
                placeholder="Nombre del docente"
                accept="image/*"
                onChange={handleImageChange}
            />
                    </div>
                    <div>
                        <label className="titulo-input-admi" htmlFor="imagen">
                            Nombre Docente:
                        </label>
                        <input
                type="text"
                placeholder="Nombre del docente"
                value={Nombre}
                onChange={(e) => setNombre(e.target.value)}
            />
                    </div>
                    <div>
                        <label className="titulo-input-admi" htmlFor="imagen">
                            Tipo Area Docente:
                        </label>
                        <input
                type="text"
                placeholder="Tipo de docente"
                value={TipoDocente}
                onChange={(e) => setTipoDocente(e.target.value)}
            />
                    </div>
                    <div>
                        <label className="titulo-input-admi" htmlFor="imagen">
                            Descripcion Docente:
                        </label>
                        <ReactQuill
                modules={module}
                theme="snow"
                value={descripcion}
                onChange={setDescripcion}
            />
                    </div>
                    <button type="submit" onClick={handleEditSubmit} >Guardar</button>
                </form>
        </section>
        </>
    )
} 