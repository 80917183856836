import React  from "react";
import { Link } from "react-router-dom";


/* imagenes */

import prueba1 from "../../img/home/1home.jpg";
import prueba2 from "../../img/home/2home.jpeg";
import prueba3 from "../../img/home/3home.jpg";

export const Galeriahome = () => {
 

  return (
    <div className="container home footergaleria1">
      <div
        className="row row-cols-1 row-cols-md-3 g-4 galeria home"
      >
        <div className="col-12 col-md-6 col-lg-4  col-xl-4 col-xll-3 galeria home ">
          <div className="card galeria home1">
            <div className="card infohomegaleria">
              <div className="img infohomegaleria">
                <img src={prueba1} className="w-100 infogaleriahome1" />
              </div>
              <span className="titulo infohomegaleria">ADMISIÓN</span>
              <p className="info infohomegaleria">
                Para más información o conocer sobre nuestras carreras, malla y
                formas de pago has clic aquí.
              </p>
              <Link to="/admision" onClick={() => window.scrollTo(0, 0)}>
                <button className="boton infohomegaleria">VER MÁS</button>
              </Link>
            </div>
          </div>
        </div>
        <div className=" col-12 col-md-6 col-lg-4  col-xl-4 col-xll-3 galeria home ">
          <div className="card galeria home1">
            <div className="card infohomegaleria">
              <div className="img infohomegaleria">
                <img src={prueba2} className=" w-100 infogaleriahome1" />
              </div>
              <span className="titulo infohomegaleria">
                {" "}
                NUESTROS CURSOS Y DIPLOMADOS
              </span>
              <p className="info infohomegaleria">
                Descubre Nuestros diferentes cursos que ofrecemos para
                aficionados y emprendedores.
              </p>
              <Link to="/cursos" onClick={() => window.scrollTo(0, 0)}>
                <button className="boton infohomegaleria">VER MÁS</button>
              </Link>
            </div>
          </div>
        </div>
        <div className=" col-12 col-md-6 col-lg-4  col-xl-4 col-xll-3 galeria home ">
          <div className="card galeria home1">
            <div className="card infohomegaleria">
              <div className="img infohomegaleria">
                <img src={prueba3} className=" w-100 infogaleriahome1" />
              </div>
              <span className="titulo infohomegaleria">
                {" "}
                MENCIÓN GASTRONOMÍA Y PANADERÍA
              </span>
              <p className="info infohomegaleria">
                Conoce nuestras alianzas exclusivas con Maítres Cuisiniers de
                France y Christian Vabret.
              </p>
              <Link to="/carreras" onClick={() => window.scrollTo(0, 0)}>
                <button className="boton infohomegaleria">VER MÁS</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
