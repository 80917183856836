import React from "react";
import "../../../styles/estructura/estructips.css";
/* imagenes */
import imagenportadablogestruc from "../../../img/estructuras/blog/tips/portadatips.png";

export const PortadaEstructuratips = () => {
  return (
    <div>
      <section>
       <hr className="separador-portada-artips" />
      </section>
    </div>
  );
};
