import React, { useState } from 'react';
import axios from 'axios';



export const Botoncrearportadadsecundaria = ({ cerrarVentana }) => {
  const [imagen_segundo, setImagen] = useState(null);
  const [url_segundo, setUrl] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleImageChange = (e) => {
    setImagen(e.target.files[0]);
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("imagen_segundo", imagen_segundo);
    formData.append("url_segundo", url_segundo);

    axios
      .post(`${backendURL}/guardar_portadadoshome`, formData)
      .then((response) => {
        console.log(response.data);
        // Aquí puedes realizar acciones adicionales después de guardar la portada

        // Cierra la ventana
        cerrarVentana();
      })
      .catch((error) => {
        console.error(error);
        // Aquí puedes manejar el error en caso de que ocurra
      });
  };

  return (
    <>
      <div className="titulo-admi-info">
        <h2>Crear Portada Secundaria</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen_segundo">
            Imagen:
          </label>
          <input className="input-admi" type="file" id="imagen_segundo" onChange={handleImageChange} />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url_segundo">
            URL:
          </label>
          <input className="input-admi" type="text" id="url_segundo" value={url_segundo} onChange={handleUrlChange} />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};