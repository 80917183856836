import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Pdfuno } from "./crear-pdfuno";
import { Pdfdos } from "./crear-pdfdos";
import { PoliticasEscuela } from "./crear-pdftres";
import { PoliticasUnidad } from "./crear-imagenpdf";



export const PDFUnidad = () => {
    const [showMC, setShowMC] = useState(false);
    const [showF, setShowF] = useState(false);
    const [showu, setShowu] = useState(false);
    const [showimagen, setShowimagen] = useState(false);
    const [pdfmalla, setPdfmalla] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const abrirpdf = () => {
        setShowMC(true);
      };
    
      const cerrarpdf = () => {
        setShowMC(false);
      };

      const abrirpdfF = () => {
        setShowF(true);
      };
    
      const cerrarpdfF = () => {
        setShowF(false);
      };

      const abrirpdfu = () => {
        setShowu(true);
      };
    
      const cerrarpdfu = () => {
        setShowu(false);
      };

      const abrirpdfimagen = () => {
        setShowimagen(true);
      };
    
      const cerrarpdfimagen = () => {
        setShowimagen(false);
      };




      
    const eliminarPdf = async () => {
        try {
          const pdfResponse = await axios.delete(
            `${backendURL}/delete_unipdfuno/politicasunidad.pdf`
          );
          console.log(pdfResponse.data.message);
      
        
          // Actualizar la lista de PDFs después de eliminar
          const updatedPdfmalla = pdfmalla.filter(
            (pdf) => pdf.pdf_url !== "static/pdfunidaduno/politicasunidad.pdf"
          );
          setPdfmalla(updatedPdfmalla);
        } catch (error) {
          console.error("Error al eliminar PDF o imagen:", error);
        }
      };

        
      const eliminarPdfunidad = async () => {
        try {
          const pdfResponse = await axios.delete(
            `${backendURL}/delete_unipdfdos/politicasunidadgenero.pdf`
          );
          console.log(pdfResponse.data.message);
      
        
          // Actualizar la lista de PDFs después de eliminar
          const updatedPdfmalla = pdfmalla.filter(
            (pdf) => pdf.pdf_url !== "static/pdfunidaddos/politicasunidadgenero.pdf"
          );
          setPdfmalla(updatedPdfmalla);
        } catch (error) {
          console.error("Error al eliminar PDF o imagen:", error);
        }
      };
 


      const eliminarPdfpoliticasescuela = async () => {
        try {
          const pdfResponse = await axios.delete(
            `${backendURL}/delete_unidadpdftres/politicasescuela.pdf`
          );
          console.log(pdfResponse.data.message);
      
        
          // Actualizar la lista de PDFs después de eliminar
          const updatedPdfmalla = pdfmalla.filter(
            (pdf) => pdf.pdf_url !== "static/pdfpoliticas/politicasescuela.pdf"
          );
          setPdfmalla(updatedPdfmalla);
        } catch (error) {
          console.error("Error al eliminar PDF o imagen:", error);
        }
      };


      const eliminarPdfimagen = async () => {
        try {
          const pdfResponse = await axios.delete(
            `${backendURL}/delete_pdfuniimagen/unidaddegenero.pdf`
          );
          console.log(pdfResponse.data.message);
      
        
          // Actualizar la lista de PDFs después de eliminar
          const updatedPdfmalla = pdfmalla.filter(
            (pdf) => pdf.pdf_url !== "static/politica/unidaddegenero.pdf"
          );
          setPdfmalla(updatedPdfmalla);
        } catch (error) {
          console.error("Error al eliminar PDF o imagen:", error);
        }
      };




return (
    <>
      <section>
        <h1 className="titulo-info-componenete-admi">PDF unidad de genero</h1>
      </section>
      <span>importante:  se debe incorprar documentos pdf para actualizar documentos</span>
      <section className="editor-pdf">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Nombre PDF</th>
              <th className="titulobd">URL</th>
              <th className="titulobd">Acciones</th>
            </tr>
          </thead>
          <tbody>
            
              <tr className="info-bd" >
                <td className="info-bd">Politicas de Acoso</td>
                <td className="info-bd">
                  <a
                    href={`${backendURL}/static/pdfunidaduno/politicasunidad.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver PDF
                  </a>
                </td>
                <td className="edicion">
                  <button className="boton-editar" onClick={abrirpdf}>
                    <BiEdit />
                  </button>
                  <button className="boton-eliminar"  onClick={eliminarPdf} >
                    <BiTrash />
                  </button>
                </td>
              </tr>
          </tbody>
          {showMC && (
        <div className="popup-admi">
        <div className="popup-content-admi">
          <button className="close-button-admi" onClick={cerrarpdf}>
            X
          </button>
            <Pdfuno/>
            
          </div>
        </div>
      )}
       <tbody>
            
            <tr className="info-bd" >
              <td className="info-bd">Politicas de Escuela</td>
              <td className="info-bd">
                <a
                  href={`${backendURL}/static/pdfunidaddos/politicasunidadgenero.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver PDF
                </a>
              </td>
              <td className="edicion">
                <button className="boton-editar" onClick={abrirpdfF}>
                  <BiEdit />
                </button>
                <button className="boton-eliminar"   onClick={eliminarPdfunidad}  >
                  <BiTrash />
                </button>
              </td>
            </tr>
        </tbody>
        {showF && (
      <div className="popup-admi">
      <div className="popup-content-admi">
        <button className="close-button-admi" onClick={cerrarpdfF}>
          X
        </button>
           <Pdfdos/> 
          
        </div>
      </div>
    )}
    <tbody>
            
            <tr className="info-bd" >
              <td className="info-bd">Politicas de Escuela dos</td>
              <td className="info-bd">
                <a
                  href={`${backendURL}/static/pdfpoliticas/politicasescuela.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver PDF
                </a>
              </td>
              <td className="edicion">
                <button className="boton-editar" onClick={abrirpdfu}>
                  <BiEdit />
                </button>
                <button className="boton-eliminar" onClick={eliminarPdfpoliticasescuela}  >
                  <BiTrash />
                </button>
              </td>
            </tr>
        </tbody>
        {showu && (
      <div className="popup-admi">
      <div className="popup-content-admi">
        <button className="close-button-admi" onClick={cerrarpdfu}>
          X
        </button>
           <PoliticasEscuela/>
          
        </div>
      </div>
    )} 
     <tbody>
            
            <tr className="info-bd" >
              <td className="info-bd">Politica de prevención</td>
              <td className="info-bd">
                <a
                  href={`${backendURL}/static/politica/unidaddegenero.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver PDF
                </a>
              </td>
              <td className="edicion">
                <button className="boton-editar" onClick={abrirpdfimagen}>
                  <BiEdit />
                </button>
                <button className="boton-eliminar"  onClick={eliminarPdfimagen}  >
                  <BiTrash />
                </button>
              </td>
            </tr>
        </tbody>
      {showimagen && (
      <div className="popup-admi">
      <div className="popup-content-admi">
        <button className="close-button-admi" onClick={cerrarpdfimagen}>
          X
        </button>
          <PoliticasUnidad/> 
          
        </div>
      </div>
    )}
        </table>
      </section>
    </>
  );
};
