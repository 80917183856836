import React, { useState } from "react";
import axios from "axios";


export const Botoncrearegresado = ({ onClose }) => {
  const [NombreEgresado, setNombreEgresado] = useState("");
  const [imagen, setImagen] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [NombreEmpresa, setNombreEmpresa] = useState("");
  const [UrlEmpresa, setUrlEmpresa] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleGuardar = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nombre_estudiante", NombreEgresado);
    formData.append("imagen", imagen);
    formData.append("titulo", titulo);
    formData.append("nombre_empresa", NombreEmpresa);
    formData.append("url_empresa", UrlEmpresa);


    try {
      const response = await axios.post(`${backendURL}/guardar_egresado`, formData);
      console.log(response.data);
      onClose();
      // Add any additional logic you want after successful creation
    } catch (error) {
      console.error(error);
      // Handle error cases
    }
  };

  const handleNombreChange = (e) => {
    setNombreEgresado(e.target.value);
  };

  const handleImagenChange = (e) => {
    setImagen(e.target.files[0]);
  };

  const handleDescripcionChange = (e) => {
    setTitulo(e.target.value);
  };

  const handleCursoChange = (e) => {
    setNombreEmpresa(e.target.value);
  };
  const handleurlChange = (e) => {
    setUrlEmpresa(e.target.value);
  };

  
  return (
    <>
      <button className="close-button-admi" onClick={onClose}>
        X
      </button>
      <div className="titulo-admi-info">
        <h2>Crear Egresado</h2>
      </div>
      <form onSubmit={handleGuardar}>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Nombre Estudiante:
          </label>
          <input
            className="input-admi"
            type="text"
            value={NombreEgresado}
            onChange={handleNombreChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            iid="imagen"
            onChange={handleImagenChange}
          />
        </div>

        <div>
          <label className="titulo-input-admi" htmlFor="descripcionCurso">
            Titulo del Egresado:
          </label>

          <input
            className="input-admi"
            id="descripcionCurso"
            value={titulo}
            onChange={handleDescripcionChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Nombre Empresa:
          </label>
          <input
            className="input-admi"
            type="text"
            value={NombreEmpresa}
            onChange={handleCursoChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Url Empresa:
          </label>
          <input
            className="input-admi"
            type="text"
            value={UrlEmpresa}
            onChange={handleurlChange}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};
