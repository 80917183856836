import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
/* imagenes */
import ejemplo from "../../img/noticias/ejemplonitica.jpeg"
import { Link } from "react-router-dom";
export const Cuerponoticias = () => {
  const noticiasPorPagina = 9; // Número de noticias por página
  const [paginaActual, setPaginaActual] = useState(1); // Estado para almacenar la página actual
  const [noticias, setNoticias] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Fetch noticias from the backend API
    axios.get(`${backendURL}/obtener_noticias`)
      .then(response => {
        const noticiasInversas = response.data.reverse();
        setNoticias(response.data);
      })
      .catch(error => {
        console.error("Error fetching noticias:", error);
      });
  }, []);
  

  // Cálculo de los índices de inicio y fin de las noticias a mostrar en la página actual
  const indiceInicio = (paginaActual - 1) * noticiasPorPagina;
  const indiceFin = paginaActual * noticiasPorPagina;

  // Array de noticias a mostrar en la página actual
  const noticiasPaginaActual = noticias.slice(indiceInicio, indiceFin);

  // Función para cambiar de página
  const cambiarPagina = (nuevaPagina) => {
    setPaginaActual(nuevaPagina);
  };
  // Volver al principio de la página cuando cambia la página actual
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paginaActual]);

  return (
    <section className="container cuerpo-noticia">
      <div className="cuerpo-card-noticias">
        <div className=" row noticias">
          {/* Iterar sobre las noticias de la página actual */}
          {noticiasPaginaActual.map((noticia, index) => (
            <div className="col noticias">
              <div className="card noticias"key={noticia.id}>
               <Link  to={`/detalle/estructuranoticia/${noticia.id}`} > <div className="card-image-noticias">
                  <img
                      src={`${backendURL}/imagen_noticia/${noticia.imagen}`}
                      className="img-posicion-noticia w-100"
                      alt="Sección"
                    /></div>
                    <div className="footer-noticias">
                   
                   <span className="date-noticias"> {noticia.fecha}</span>
                
                <p className="card-title-noticias">{noticia.titulo_principal}</p>
                <p className="card-body-noticias">{noticia.descripcion_principal}</p>
                </div></Link>
              </div>
            </div>
          ))}
        </div>
      </div>

      <nav aria-label="Page navigation example-noticias">
        <ul className="pagination">
          {/* Botón de página anterior */}
          <li className="page-item-noticias">
            <button
              className="page-link-noticias"
              onClick={() => cambiarPagina(paginaActual - 1)}
              disabled={paginaActual === 1} // Desactivar el botón si está en la primera página
            >
              <FaChevronLeft className="left-noticias" />
            </button>
          </li>

          {/* Generar los números de página */}
          {Array.from({
            length: Math.ceil(noticias.length / noticiasPorPagina),
          }).map((_, index) => (
            <li className="page-item--noticias" key={index}>
              <button
                className={`page-link-noticias ${
                  paginaActual === index + 1 ? "active" : ""
                }`}
                onClick={() => cambiarPagina(index + 1)}
              >
                {index + 1}
              </button>
            </li>
          ))}

          {/* Botón de página siguiente */}
          <li className="page-item-noticias">
            <button
              className="page-link-noticias"
              onClick={() => cambiarPagina(paginaActual + 1)}
              disabled={
                paginaActual === Math.ceil(noticias.length / noticiasPorPagina)
              } // Desactivar el botón si está en la última página
            >
              <FaChevronRight className="rigth-noticias" />
            </button>
          </li>
        </ul>
      </nav>
    </section>
  );
};
