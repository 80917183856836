import React, { createContext, useContext, useState } from 'react';

const ResultadoContext = createContext();

export const ResultadoProvider = ({ children }) => {
  const [resultadoTransaccion, setResultadoTransaccion] = useState(null);

  const value = {
    resultadoTransaccion,
    setResultadoTransaccion,
  };

  return (
    <ResultadoContext.Provider value={value}>
      {children}
    </ResultadoContext.Provider>
  );
};

export const useResultado = () => {
  const context = useContext(ResultadoContext);
  if (!context) {
    throw new Error('useResultado debe ser utilizado dentro de un ResultadoProvider');
  }
  return context;
};