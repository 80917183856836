import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/cursoydiplomado/cursoydiplomado.css";
import { Link } from "react-router-dom";

/* imagenes */
import Banner from "../../img/cursosydiplomados/Estudiante.png";
import Bannermovil from "../../img/cursosydiplomados/BANNER-SEC-DCTO-RESP.png";
import linea from "../../img/docentes/linea.png";



export const Cursostabla = () => {
  const [activo, setActivo] = useState(true);
  const [cursos, setCursos] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false); // Initialize updateTrigger to false
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado de isMobileView en función del ancho de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Cambia a "true" si el ancho es menor o igual a 700px
    };

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Llama a handleResize() una vez al cargar la página para establecer el estado inicial
    handleResize();

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cursosFiltrados = cursos.filter(curso => curso.categoria === 'curso');
  const diplomadosFiltrados = cursos.filter(curso => curso.categoria === 'diplomado');

  const handleClick = () => {
    setActivo(!activo);
  };
  const estadoClase = activo ? 'activo' : 'inactivo';

  const redirigirCorreocurso = () => {
    window.location.href = "mailto: cursos@escuelaculinaria.cl";
  };

   
  useEffect(() => {
    obtenerCursos();
  }, [updateTrigger]);

  const obtenerCursos = async () => {
    try {
      const timestamp = Date.now();
      const response = await axios.get(`${backendURL}/cardcursoydiplo?timestamp=${timestamp}`);
     // Actualizar el estado de cursos
    setCursos(response.data);

    // Activar el trigger de actualización
    setUpdateTrigger(!updateTrigger);
  } catch (error) {
    console.log(error);
  }
};

  return (
    <div className="container cursos" >
<section className="cursos">
<div className="bannercurso">
      <img src={linea} className="w-20 separador-cursos" />
      <h1 className="titulocursos">CURSOS CORTOS</h1> 
      </div>
     {/*  <button className="buttonprueba" onClick={handleClick}> aqui</button> */}
      <div  className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-3 g-2  cursos ">
      {cursosFiltrados.map(cursos => (
  <div className={`col cursos ${estadoClase}`} key={cursos.id}>
    {cursos.participantes <= 0 ? ( // Verifica si hay participantes agotados
      // Tarjeta para cursos sin participantes
      <div className="card cursos">
        <div className="card-container curso">
          <div className="card cursoydiplomado">
            <div
              className="img-content-cursoydiplomado"
              style={{
                backgroundImage: `url(${backendURL}/imagen_cardcursoydiplo/portada_principal/${cursos.imagen_portada_principal})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="content-cursoydiplomado">
              <p className="heading-cursoydiplomado">
                {" "}
                
              </p>
              <h1 className="cursos-actual agotado">AGOTADO</h1>
            </div>
          </div>
        </div>
        <div className="card-info cursos">
          <p className="fechacurso">{cursos.fecha_curso}</p>
          <p className="text-title cursos">{cursos.nombre_curso}</p>
          <p className="text-cuerpo cursos">{cursos.pie_curso_portada}</p>
        </div>
      </div>
    ) : (
      // Tarjeta para cursos con participantes
      <div className="card cursos">
        <div className="card-container curso">
          <div className="card cursoydiplomado">
            <div
              className="img-content-cursoydiplomado"
              style={{
                backgroundImage: `url(${backendURL}/imagen_cardcursoydiplo/portada_principal/${cursos.imagen_portada_principal})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="content-cursoydiplomado">
              <p className="heading-cursoydiplomado">
                {" "}
                <img
                  src={`${backendURL}/imagen_cardcursoydiplo/titulo/${cursos.imagen_titulo_portada}`}
                  className="w-100 titulo-diplo"
                />
              </p>
              <h1 className="cursos-actual ">
                ${cursos.precio_final.toLocaleString()}
              </h1>
              <Link
                to={`/detalle/estructuracursos/${cursos.id}`}
                onClick={() => window.scrollTo(0, 0)}
              >
                <button className="button-cursoydiplomado">
                  Detalle del Programa
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="card-info cursos">
          <p className="fechacurso">{cursos.fecha_curso}</p>
          <p className="text-title cursos">{cursos.nombre_curso}</p>
          <p className="text-cuerpo cursos">{cursos.pie_curso_portada}</p>
        </div>
      </div>
    )}
  </div>
))}
         
       
      </div>
</section>
      <section className="diplomado">
      <div className="bannerdiplomado">
        
          {" "}
          <img src={linea} className="w-20 separador-cursos" />
        

        <h1 className="titulocursos">DIPLOMADOS</h1> 
      </div>
      
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-3 g-2  cursos ">
        {diplomadosFiltrados.map(cursos => (
           <div  className={`col cursos ${estadoClase}`}>
       {cursos.participantes <= 0 ? ( // Verifica si hay participantes agotados
      // Tarjeta para cursos sin participantes
      <div className="card cursos" key={cursos.id}>
        <div className="card-container curso">
          <div className="card cursoydiplomado">
            <div
              className="img-content-cursoydiplomado"
              style={{
                backgroundImage: `url(${backendURL}/imagen_cardcursoydiplo/portada_principal/${cursos.imagen_portada_principal})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="content-cursoydiplomado">
              <p className="heading-cursoydiplomado">
                {" "}
                
              </p>
              <h1 className="cursos-actual agotado">AGOTADO</h1>
            </div>
          </div>
        </div>
        <div className="card-info cursos">
          <p className="fechacurso">{cursos.fecha_curso}</p>
          <p className="text-title cursos">{cursos.nombre_curso}</p>
          <p className="text-cuerpo cursos">{cursos.pie_curso_portada}</p>
        </div>
      </div>
    ) : (
      // Tarjeta para cursos con participantes
      <div className="card cursos">
        <div className="card-container curso">
          <div className="card cursoydiplomado">
            <div
              className="img-content-cursoydiplomado"
              style={{
                backgroundImage: `url(${backendURL}/imagen_cardcursoydiplo/portada_principal/${cursos.imagen_portada_principal})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="content-cursoydiplomado">
              <p className="heading-cursoydiplomado">
                {" "}
                <img
                  src={`${backendURL}/imagen_cardcursoydiplo/titulo/${cursos.imagen_titulo_portada}`}
                  className="w-100 titulo-diplo"
                />
              </p>
              <h1 className="cursos-actual ">
                ${cursos.precio_final.toLocaleString()}
              </h1>
              <Link
                to={`/detalle/estructuracursos/${cursos.id}`}
                onClick={() => window.scrollTo(0, 0)}
              >
                <button className="button-cursoydiplomado">
                  Detalle del Programa
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="card-info cursos">
          <p className="fechacurso">{cursos.fecha_curso}</p>
          <p className="text-title cursos">{cursos.nombre_curso}</p>
          <p className="text-cuerpo cursos">{cursos.pie_curso_portada}</p>
        </div>
      </div>
    )}
</div>
        ))}
         
        </div>
      </section>
      
      <section className="banner-imagen-descuento">
      <div className="banner imagen descuento">
        {" "}
        <img src={isMobileView ? Bannermovil : Banner} className="w-100 estudiantedescuento" />
        <h2 className="info-curso-estudiante">Escríbenos a: <button className="correo-cursos" onClick={redirigirCorreocurso} ><strong className="correo-cursos"> cursos@escuelaculinaria.cl</strong></button> </h2>
      </div></section>
    </div>
  );
};
