import React, { useContext } from "react";
import { Context } from "../store/appContext";
import { Portada } from "../component/Home/Homeprincipal";
import "../styles/style Home/home.css";
import AutoPlay from "../component/Home/Carosuel-Home-card";
import ScrollReveal from "scrollreveal";
import { HomeInfo } from "../component/Home/HomeInfo";
import { Info2home } from "../component/Home/homesegundainfo";
import { Galeriahome } from "../component/Home/galeriahome";

export const Home = () => {
  const { store, actions } = useContext(Context);

  return (
    <div className="todo-home">
      <div className="portada home">
        <Portada />
      </div>
      <div className="container carousel">
        <AutoPlay />
      </div>
      <div className=" homeinfo1">
        <div className="container infohome">
          <HomeInfo />
        </div>
      </div>
      <div className="segundo info">
        <Info2home />
      </div>
      <div className=" galeriahomenew ">
        <Galeriahome />
      </div>{" "}
    </div>
  );
};

