import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Mallapdf } from "./crearmalla";
import { ANpdf } from "./crearAN";
import { BTJpdf } from "./crearBTJ";
import { BTJRpdf } from "./crearBTJR";
import { BDeportiva } from "./crearDeportiva";

export const PDFAdmision = () => {
  const [pdfmalla, setPdfmalla] = useState([]);
  const [showMC, setShowMC] = useState(false);
  const [showAN, setShowAN] = useState(false);
  const [showTJ, setShowTJ] = useState(false);
  const [showTJR, setShowTJR] = useState(false);
  const [showD, setShowD] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const abrirMalla = () => {
    setShowMC(true);
  };

  const cerrarMalla = () => {
    setShowMC(false);
  };

  /* alain nonennt */
  
  const abrirAN = () => {
    setShowAN(true);
  };

  const cerrarAN = () => {
    setShowAN(false);
  };

   /* TALENTO JOVEN */
  
   const abrirTJ = () => {
    setShowTJ(true);
  };

  const cerrarTJ = () => {
    setShowTJ(false);
  };
    /* TALENTO JOVEN REGION */
  
    const abrirTJR = () => {
        setShowTJR(true);
      };
    
      const cerrarTJR = () => {
        setShowTJR(false);
      };

       /* TALENTO DEPORTIVA */
  
    const abrirD = () => {
        setShowD(true);
      };
    
      const cerrarD = () => {
        setShowD(false);
      };


  const eliminarPdfMalla = async () => {
    try {
      const response = await axios.delete(
        `${backendURL}/pdfmallacv/delete/mallacurrricular.pdf`
      );
      console.log(response.data.message);

      // Actualizar la lista de PDFs después de eliminar
      const updatedPdfmalla = pdfmalla.filter(
        (pdf) => pdf.pdf_url !== "static/pdf/mallacurrricular.pdf"
      );
      setPdfmalla(updatedPdfmalla);
    } catch (error) {
      console.error("Error al eliminar PDF:", error);
    }
  };

  
  const eliminarPdfAN = async () => {
    try {
      const response = await axios.delete(
        `${backendURL}/pdfban/delete/BecaAlainNonnet.pdf`
      );
      console.log(response.data.message);

      // Actualizar la lista de PDFs después de eliminar
      const updatedPdfmalla = pdfmalla.filter(
        (pdf) => pdf.pdf_url !== "/static/pdfban/BecaAlainNonnet.pdf"
      );
      setPdfmalla(updatedPdfmalla);
    } catch (error) {
      console.error("Error al eliminar PDF:", error);
    }
  };

  
  const eliminarPdfTJ = async () => {
    try {
      const response = await axios.delete(
        `${backendURL}/pdftalentojoven/delete/BecaTalentoJoven.pdf`
      );
      console.log(response.data.message);

      // Actualizar la lista de PDFs después de eliminar
      const updatedPdfmalla = pdfmalla.filter(
        (pdf) => pdf.pdf_url !== "static/pdfTJ/BecaTalentoJoven.pdf"
      );
      setPdfmalla(updatedPdfmalla);
    } catch (error) {
      console.error("Error al eliminar PDF:", error);
    }
  };
  
  const eliminarPdfTJR = async () => {
    try {
      const response = await axios.delete(
        `${backendURL}/pdftjr/delete/BecaTalentoJovenRegion.pdf`
      );
      console.log(response.data.message);

      // Actualizar la lista de PDFs después de eliminar
      const updatedPdfmalla = pdfmalla.filter(
        (pdf) => pdf.pdf_url !== "static/pdfTJR/BecaTalentoJovenRegion.pdf"
      );
      setPdfmalla(updatedPdfmalla);
    } catch (error) {
      console.error("Error al eliminar PDF:", error);
    }
  };

  
  const eliminarPdfDeportiva = async () => {
    try {
      const response = await axios.delete(
        `${backendURL}/pdfdeportiva/delete/BecaDeportiva.pdf`
      );
      console.log(response.data.message);

      // Actualizar la lista de PDFs después de eliminar
      const updatedPdfmalla = pdfmalla.filter(
        (pdf) => pdf.pdf_url !== "static/pdfDeportiva/BecaDeportiva.pdf"
      );
      setPdfmalla(updatedPdfmalla);
    } catch (error) {
      console.error("Error al eliminar PDF:", error);
    }
  };



  return (
    <>
      <section>
        <h1 className="titulo-info-componenete-admi">PDF admisión</h1>
      </section>
      <span> importante: para creación de pdf de malla curricular y las becas deben ingresar imagenes no documento pdf</span>
      <section className="editor-pdf">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Nombre PDF</th>
              <th className="titulobd">URL</th>
              <th className="titulobd">Acciones</th>
            </tr>
          </thead>
          <tbody>
            
              <tr className="info-bd" >
                <td className="info-bd">Malla Curricular</td>
                <td className="info-bd">
                  <a
                    href={`${backendURL}/static/pdf/mallacurrricular.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver PDF
                  </a>
                </td>
                <td className="edicion">
                  <button className="boton-editar" onClick={abrirMalla}>
                    <BiEdit />
                  </button>
                  <button className="boton-eliminar" onClick={eliminarPdfMalla}>
                    <BiTrash />
                  </button>
                </td>
              </tr>
          </tbody>
          {showMC && (
        <div className="popup-admi">
        <div className="popup-content-admi">
          <button className="close-button-admi" onClick={cerrarMalla}>
            X
          </button>
            <Mallapdf/>
            
          </div>
        </div>
      )}

          <tbody>
            
            <tr className="info-bd" >
              <td className="info-bd">Alain Nonnet</td>
              <td className="info-bd">
                <a
                  href={`${backendURL}/static/pdfban/BecaAlainNonnet.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver PDF
                </a>
              </td>
              <td className="edicion">
                <button className="boton-editar" onClick={abrirAN}>
                  <BiEdit />
                </button>
                <button className="boton-eliminar" onClick={eliminarPdfAN}>
                  <BiTrash />
                </button>
              </td>
            </tr>
       
        </tbody>

        {showAN && (
        <div className="popup-admi">
        <div className="popup-content-admi">
          <button className="close-button-admi" onClick={cerrarAN}>
            X
          </button>
          <ANpdf/>
            
          </div>
        </div>
      )}


        <tbody>
            
            <tr className="info-bd" >
              <td className="info-bd"> Talento Joven</td>
              <td className="info-bd">
                <a
                  href={`${backendURL}/static/pdfTJ/BecaTalentoJoven.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver PDF
                </a>
              </td>
              <td className="edicion">
                <button className="boton-editar" onClick={abrirTJ}>
                  <BiEdit />
                </button>
                <button className="boton-eliminar" onClick={eliminarPdfTJ}>
                  <BiTrash />
                </button>
              </td>
            </tr>
       
        </tbody>


        {showTJ && (
        <div className="popup-admi">
        <div className="popup-content-admi">
          <button className="close-button-admi" onClick={cerrarTJ}>
            X
          </button>
            <BTJpdf/>
            
          </div>
        </div>
      )}


        <tbody>
            
            <tr className="info-bd" >
              <td className="info-bd">Talento Joven Región</td>
              <td className="info-bd">
                <a
                  href={`${backendURL}/static/pdfTJR/BecaTalentoJovenRegion.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver PDF
                </a>
              </td>
              <td className="edicion">
                <button className="boton-editar" onClick={abrirTJR}>
                  <BiEdit />
                </button>
                <button className="boton-eliminar" onClick={eliminarPdfTJR}>
                  <BiTrash />
                </button>
              </td>
            </tr>
       
        </tbody>

        {showTJR && (
        <div className="popup-admi">
        <div className="popup-content-admi">
          <button className="close-button-admi" onClick={cerrarTJR}>
            X
          </button>
            <BTJRpdf/>
            
          </div>
        </div>
      )}

        <tbody>
            
            <tr className="info-bd" >
              <td className="info-bd"> Deportiva</td>
              <td className="info-bd">
                <a
                  href={`${backendURL}/static/pdfDeportiva/BecaDeportiva.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver PDF
                </a>
              </td>
              <td className="edicion">
                <button className="boton-editar"  onClick={abrirD}>
                  <BiEdit />
                </button>
                <button className="boton-eliminar" onClick={eliminarPdfDeportiva}>
                  <BiTrash />
                </button>
              </td>
            </tr>
       
        </tbody>



        {showD && (
        <div className="popup-admi">
        <div className="popup-content-admi">
          <button className="close-button-admi" onClick={cerrarD}>
            X
          </button>
            <BDeportiva/>
            
          </div>
        </div>
      )}
        </table>
      </section>
    </>
  );
};
