import React, { useState } from "react";
import axios from "axios";


export const Botoncrearmapa = ({ onClose }) => {
  const [nombreEstablecimiento, setNombreEstablecimiento] = useState("");
  const [imagen, setImagen] = useState(null);
  const [ciudad, setCiudad] = useState("");
  const [chef, setChef] = useState("");
  const [ubicacion, setUbicacion] = useState("");
  const [top, setTop] = useState("");
  const [left, setLeft] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  

  const handleGuardar = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nombre_establecimiento", nombreEstablecimiento);
    formData.append("imagen", imagen);
    formData.append("ciudad", ciudad);
    formData.append("chef", chef );
    formData.append("ubicacion", ubicacion);
    formData.append("top", top );
    formData.append("left", left);
    
    

    try {
      const response = await axios.post(`${backendURL}/guardar_mapa`, formData);
      console.log(response.data);
      onClose();
      // Add any additional logic you want after successful creation
    } catch (error) {
      console.error(error);
      // Handle error cases
    }
  };

  const handleNombreChange = (e) => {
    setNombreEstablecimiento(e.target.value);
  };
  const handleChefChange = (e) => {
    setChef(e.target.value);
  };

  const handleUbicacionChange = (e) => {
    setUbicacion(e.target.value);
  };

  const handleImagenChange = (e) => {
    setImagen(e.target.files[0]);
  };

  const handleCiudadChange = (e) => {
    setCiudad(e.target.value);
  };

  const handleTopChange = (e) => {
    setTop(parseFloat(e.target.value)); // Convierte el valor a un número
  };

  const handleLeftChange = (e) => {
    setLeft(parseFloat(e.target.value)); // Convierte el valor a un número
  };

 

  
  return (
    <>
      <button className="close-button-admi" onClick={onClose}>
        X
      </button>
      <div className="titulo-admi-info">
        <h2>Crear Establecimiento Mapa</h2>
      </div>
      <form onSubmit={handleGuardar}>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Nombre Establecimiento:
          </label>
          <input
            className="input-admi"
            type="text"
            value={nombreEstablecimiento}
            onChange={handleNombreChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            iid="imagen"
            onChange={handleImagenChange}
          />
        </div>

        <div>
          <label className="titulo-input-admi" htmlFor="descripcionCurso">
            Ciudad:
          </label>
          <input
            className="input-admi"
            type="text"
            value={ciudad}
            onChange={handleCiudadChange}
          />
          
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="descripcionCurso">
            Chef:
          </label>
          <input
            className="input-admi"
            type="text"
            value={chef}
            onChange={handleChefChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="descripcionCurso">
            Ubicacion:
          </label>
          <input
            className="input-admi"
            type="text"
            value={ubicacion}
            onChange={handleUbicacionChange}
          />
        </div>
        <div>
      <label className="titulo-input-admi" htmlFor="top">
        Top (%):
      </label>
      <input
        className="input-admi"
        type="number"
        value={top}
        onChange={handleTopChange}
      />
      <label className="titulo-input-admi" htmlFor="left">
        Left (%):
      </label>
      <input
        className="input-admi"
        type="number"
        value={left}
        onChange={handleLeftChange}
      />
    </div>
        
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};
