import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const Botoneditarcursosydiplomado = ({ cursoId, onClose }) => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  
  const [formData, setFormData] = useState({
    // Agrega el resto de los campos que necesitas editar aquí
    nombre_curso: "",
    pie_curso_portada: "",
    categoria: "",
    fecha_curso: "",
    precio_curso: "",
    participantes: "",
    horas: "",
    secciones: "",
    modalidad: "",
    descripcion_curso:"",
    horario: "",
    descuento: "",
    chef_id: "", // Agregar campo para el chef seleccionado
    precio_final: "",
    imagen_portada_principal: null,
    imagen_titulo_portada: null,
    imagen_portada_curso: null,
    imagen_responsive_curso: null,
  });

  const [chefs, setChefs] = useState([]);
  const [chefId, setChefId] = useState(0); 
  const [quillText, setQuillText] = useState(""); 

  const handleQuillChange = (content, delta, source, editor) => {
    setQuillText(content); // Actualiza quillText con el contenido actual
    setFormData({ ...formData, descripcion_curso: content }); // Actualiza descripcion_curso en el estado formData
  };
  useEffect(() => {
    const obtenerChefs = async () => {
      try {
        const response = await axios.get(`${backendURL}/obtener_chefs`);
        setChefs(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    obtenerChefs();
  }, []);

  useEffect(() => {
    const obtenerDatosCurso = async () => {
      try {
        const response = await axios.get(
          `${backendURL}/cardcursoydiplo/${cursoId}`
        );
        const curso = response.data;
        setFormData({
          // Asignar los valores actuales del curso a los campos del formulario
          nombre_curso: curso.nombre_curso,
          pie_curso_portada: curso.pie_curso_portada,
          categoria: curso.categoria,
          fecha_curso: curso.fecha_curso,
          precio_curso: curso.precio_curso.toString(),
          participantes: curso.participantes.toString(),
          horas: curso.horas.toString(),
          secciones: curso.secciones.toString(),
          modalidad: curso.modalidad,
          descripcion_curso:curso.descripcion_curso,
          horario: curso.horario,
          descuento: curso.descuento.toString(),
          chef_id: curso.chef_id.toString(),
          precio_final: curso.precio_final.toString(),
        });
        // Actualiza quillText con la descripción del curso
        setQuillText(curso.descripcion_curso);
      } catch (error) {
        console.error(error);
      }
    };

    if (cursoId) {
      obtenerDatosCurso();
    }
  }, [cursoId]);

  // Función para calcular el precio final y actualizar el estado del formulario
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "descuento") {
      const precioCurso = parseFloat(formData.precio_curso);
      const descuento = parseFloat(value);
      const precioFinal = precioCurso - (precioCurso * descuento) / 100;
      setFormData({
        ...formData,
        [id]: value,
        precio_final: descuento >= 0 ? Math.floor(precioFinal) : precioCurso,
      });
    } else if (id === "descripcion_curso") {
      setFormData({ ...formData, descripcion_curso: quillText }); // Actualiza descripcion_curso con quillText
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleImageChange = (e, fieldName) => {
    const newImage = e.target.files[0];
    setFormData({ ...formData, [fieldName]: newImage });
  };

  const handleCategoriaChange = (e) => {
    const categoriaSeleccionada = e.target.value;
    if (categoriaSeleccionada === "curso") {
      setFormData({ ...formData, categoria: "curso" });
    } else if (categoriaSeleccionada === "diplomado") {
      setFormData({ ...formData, categoria: "diplomado" });
    }
  };

  const handleChefChange = (selectedChefId) => {
    setFormData({ ...formData, chef_id: Number(selectedChefId) });
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setFormData({
        ...formData,
        descripcion_curso: quillText,});
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      const response = await axios.put(
        `${backendURL}/cardcursoydiploeditar/${cursoId}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data); // Mensaje de éxito o datos actualizados

      // Aquí cierras la ventana emergente después de guardar los cambios
      onClose();

      // Aquí puedes realizar acciones adicionales después de la actualización exitosa
    } catch (error) {
      console.error("Error al enviar datos al backend:", error);
    }
  };

  

  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
    ['link'],
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [
      { 'color': ['black','#4444','#696965','Dark Gray', 'grey','red','Indian Red', 'blue','Royal Blue', 'green','white','violet','aqua','orange','yellow','pink','purple', 'Turquoise', 'Teal', 'Lime', 'Cyan', 'Magenta', 'Brown', 'Maroon', 'Silver'] },  // Specify custom colors
      { 'background': ['#696965', 'yellow', 'pink', 'orange'] }  // Specify custom background colors
    ],
    [{ 'font': [] }],
    [
      { 'align': [] },
      { 'align': ['center', 'right', 'justify'] }  // Additional alignment options
    ],
  
    ['clean']   
  ];
  const modules = {
    toolbar: toolbarOptions,
  };
  return (
    <>
      <div className="contenciondelformadmi">
        <form className="contenido-admi" onSubmit={handleSubmit}>
          <div>
            <label
              className="titulo-input-admi"
              htmlFor="imagenPortadaPrincipal"
            >
              Imagen Portada Principal:
            </label>
            <input
              className="input-admi"
              type="file"
              id="imagenPortadaPrincipal"
              onChange={(e) => handleImageChange(e, "imagen_portada_principal")}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="imagenTituloPortada">
              Imagen Portada Título:
            </label>
            <input
              className="input-admi"
              type="file"
              id="imagenTituloPortada"
              onChange={(e) => handleImageChange(e, "imagen_portada_titulo")}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="imagenPortadaCurso">
              Imagen Portada Curso:
            </label>
            <input
              className="input-admi"
              type="file"
              id="imagenPortadaCurso"
              onChange={(e) => handleImageChange(e, "imagen_portada_curso")}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="imagenResponsiveCurso">
              Imagen Responsivo Curso:
            </label>
            <input
              className="input-admi"
              type="file"
              id="imagenResponsiveCurso"
              onChange={(e) => handleImageChange(e, "imagen_responsive_curso")}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="nombreCurso">
              Nombre del Curso:
            </label>
            <input
              className="input-admi"
              type="text"
              id="nombre_curso"
              value={formData.nombre_curso}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="pieCursoPortada">
              Pie de Página de la Portada:
            </label>
            <input
              className="input-admi"
              type="text"
              id="pie_curso_portada"
              value={formData.pie_curso_portada}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="categoria">
              Categoría:
            </label>
            <select
              className="input-admi"
              id="categoria"
              value={formData.categoria}
              onChange={(e) => {
                handleChange(e);
                handleCategoriaChange(e);
              }}
            >
              <option value="">Seleccionar categoría</option>
              <option value="curso">Curso</option>
              <option value="diplomado">Diplomado</option>
            </select>
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="fechaCurso">
              Fecha del Curso:
            </label>
            <input
              className="input-admi"
              type="text"
              id="fecha_curso"
              value={formData.fecha_curso}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="precioCurso">
              Precio del Curso:
            </label>
            <input
              className="input-admi"
              type="number"
              id="precio_curso"
              value={formData.precio_curso}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="participantes">
              Participantes:
            </label>
            <input
              className="input-admi"
              type="number"
              id="participantes"
              value={formData.participantes}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="horas">
              Horas:
            </label>
            <input
              className="input-admi"
              type="number"
              id="horas"
              value={formData.horas}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="secciones">
              Secciones:
            </label>
            <input
              className="input-admi"
              type="number"
              id="secciones"
              value={formData.secciones}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="modalidad">
              Modalidad:
            </label>
            <select
              className="input-admi"
              id="modalidad"
              value={formData.modalidad}
              onChange={handleChange}
            >
              <option value="">Seleccionar modalidad</option>
              <option value="presencial">Presencial</option>
              <option value="semipresencial">Semipresencial</option>
              <option value="online">Online</option>
            </select>
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="descripcion_curso">
              Descripción del Curso:
            </label>
            <ReactQuill
          modules={modules}
          theme="snow"
          className="input-admi"
          id="descripcion_curso"
          value={quillText}
  onChange={handleQuillChange}// Usa la función handleQuillChange para manejar los cambios
        />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="horario">
              Horario:
            </label>
            <input
              className="input-admi"
              type="text"
              id="horario"
              value={formData.horario}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="descuento">
              Descuento (%):
            </label>
            <input
              className="input-admi"
              type="number"
              id="descuento"
              value={formData.descuento}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="titulo-input-admi" htmlFor="precioFinal">
              Precio Final:
            </label>
            <input
              className="input-admi"
              type="number"
              id="precio_final"
              value={formData.precio_final}
              onChange={handleChange}
              readOnly // Para evitar que el usuario pueda modificarlo directamente
            />
          </div>
          <div>
          <label className="titulo-input-admi" htmlFor="chefId">
            Chef:
          </label>
          <select
  className="input-admi"
  id="chefId"
  value={formData.chef_id}
  onChange={(e) => handleChefChange(e.target.value)}
>
  <option value="">Seleccionar chef</option>
  {chefs.map((chef) => (
    <option key={chef.id} value={chef.id}>
      {chef.nombre}
    </option>
  ))}
</select>
          </div>
          
          
          <div className="boton-guardar">
            <button className="boton-crear" type="submit">
              Guardar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
