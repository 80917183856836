import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Botoncrearportadadhome } from "./botoncrear-portadahome";
import { Botoneditarportadahome } from "./boton-editar-portadahome";



export const PortadaPrincipal = () => {
    const [mostrarCrearPopup, setMostrarCrearPopup] = useState(false);
    const [mostrarEditarPopup, setMostrarEditarPopup] = useState(false);
    const [listaPortadas, setListaPortadas] = useState([]);
    const [portadaIdSeleccionada, setPortadaIdSeleccionada] = useState(null);
    const [imagenKey, setImagenKey] = useState(Date.now()); // Agrega una clave única para la URL de la imagen
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const cerrarVentana = () => {
      setMostrarCrearPopup(false);
      setMostrarEditarPopup(false);
    };
  
    useEffect(() => {
      obtenerPortadas();
    }, []);
  
    const obtenerPortadas = () => {
      axios
        .get(`${backendURL}/obtener_portadahome`)
        .then((response) => {
          setListaPortadas(response.data.portadas);
        })
        .catch((error) => {
          console.error("Error al obtener la lista de portadas:", error);
        });
    };
  
    const eliminarPortada = (portadahomeId) => {
      axios
        .delete(`${backendURL}/eliminar_portadahome/${portadahomeId}`)
        .then((response) => {
          console.log(response.data);
          setListaPortadas((prevPortadas) =>
            prevPortadas.filter((portada) => portada.id !== portadahomeId)
          );
        })
        .catch((error) => {
          console.error("Error al eliminar la portada:", error);
        });
    };
  
    const handleEditarPortada = (portadaId) => {
      setPortadaIdSeleccionada(portadaId);
      setMostrarEditarPopup(true);
    };
  
    const actualizarPortadaEnLista = (portadaId, portadaData) => {
      // Encuentra la portada en la lista y actualiza sus datos
      const portadasActualizadas = listaPortadas.map((portada) => {
        if (portada.id === portadaId) {
          return {
            ...portada,
            imagen_home: portadaData.imagen_home,
            imagen_responsive: portadaData.imagen_responsive,
            url_home: portadaData.url_home,
          };
        }
        return portada;
      });
  
      setListaPortadas(portadasActualizadas);
    };
  
    return (
      <>
      <section>
          <h1 className="titulo-info-componenete-admi">Portada Home</h1>
        </section>
        <section className="boton-portada-cursosydiplomado">
          <button
            className="boton-crear"
            onClick={() => setMostrarCrearPopup(true)}
          >
            Crear
          </button>
        </section>
  
        {mostrarCrearPopup && (
          <div className="popup-admi">
            <div className="popup-content-admi">
              <button className="close-button-admi" onClick={cerrarVentana}>
                X
              </button>
               <Botoncrearportadadhome cerrarVentana={cerrarVentana} />
            </div>
          </div>
        )} 
  
        <section className="lista-chef-cursosydiplomado">
          <table className="lista">
            <thead>
              <tr>
                <th className="titulobd">Imagen portada</th>
                <th className="titulobd">Imagen movil</th>
                <th className="titulobd">URL</th>
                <th className="titulobd">Acciones</th>
              </tr>
            </thead>
            <tbody>
            {listaPortadas.map((portada) => (
    <tr className="info-bd" key={portada.id}>
      <td className="info-bd">
        <div className="imagen-info-admi">
          <img
            className="imagen-info-admi"
            src={`${backendURL}/imagen_portadahome/${portada.imagen_home}?${imagenKey}`}
            alt=""
          />
        </div>
      </td>
      <td className="info-bd">
        <div className="imagen-info-admi">
          <img
            className="imagen-info-admi"
            src={`${backendURL}/imagen_portadahomeresponsive/${portada.imagen_responsive}?${imagenKey}`}
            alt=""
          />
        </div>
      </td>
      <td className="info-bd">{portada.url_home}</td>
      <td className="edicion">
        <button
          className="boton-editar"
          onClick={() => handleEditarPortada(portada.id)}
        >
          <BiEdit />
        </button>
        <button
          className="boton-eliminar"
          onClick={() => eliminarPortada(portada.id)}
        >
          <BiTrash />
        </button>
      </td>
    </tr>
  ))}
            </tbody>
          </table>
        </section>
  
        { mostrarEditarPopup && (
          <div className="popup-admi">
            <div className="popup-content-admi">
              <button className="close-button-admi" onClick={cerrarVentana}>
                X
              </button>
               <Botoneditarportadahome
                cerrarVentana={cerrarVentana}
                portadaId={portadaIdSeleccionada}
                actualizarPortadaEnLista={actualizarPortadaEnLista} // Pasa la función para actualizar la lista al componente hijo
              /> 
            </div>
          </div>
        )}  *
      </>
    );
  };