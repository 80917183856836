import React, { useState, useEffect } from "react";
import beneficio from "../../img/beneficio/BENEFICIOS.jpg";
import beneficiomobil from "../../img/beneficio/T-BENEFICIO-MOVIL.jpg";
import "../../styles/beneficio/beneficio.css";

export const PortadoBeneficio = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado de isMobileView en función del ancho de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Cambia a "true" si el ancho es menor o igual a 700px
    };

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Llama a handleResize() una vez al cargar la página para establecer el estado inicial
    handleResize();

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="portada-beneficio">
      <img
        src={isMobileView ? beneficiomobil : beneficio}
        alt="Portada Beneficio"
        className="portada-beneficio-img"
      />
    </section>
  );
};