import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Botoncrearegresado } from "./crear-egresado";
import { Editaregresado } from "./editar-egresado";




export const Egresados = () =>{
    const [egresados, setEgresado] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editTestId, setEditTestId] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

    const togglePopup = () => {
        setShowPopup(!showPopup);
      };
      
      const closePopups = () => {
        setShowCreatePopup(false);
        setShowEditPopup(false);
      };
    
      const toggleEditPopup = (egresadoId) => {
        setShowEditPopup(!showEditPopup);
        setEditTestId(egresadoId);
      };


      useEffect(() => {
        // Fetch testimonios from the backend API
        axios.get(`${backendURL}/obtener_egresados`)
          .then(response => {
            setEgresado(response.data);
          })
          .catch(error => {
            console.error("Error fetching testimonios:", error);
          });
      }, []);

      const handleDelete = async (egresadoId) => {
        try {
            const response = await axios.delete(`${backendURL}/eliminar_egresado/${egresadoId}`);
            console.log(response.data);
            // You can add additional logic here, like updating the UI after deletion
        } catch (error) {
            console.error(error);
            // Handle error cases
        }
    };

    return(
        <><section>
        <h1 className="titulo-info-componenete-admi">Egresados</h1>
      </section>
      <section className="boton-crear-chef-cursosydiplomado">
        <button className="boton-crear" onClick={togglePopup} >
          Crear
        </button></section> 
        {showPopup && (
        <div className="popup-admi">
          <div className="popup-content-admi">
           <Botoncrearegresado onClose={togglePopup} /> 
          </div>
        </div>
      )}
        
        <section className="lista-chef-cursosydiplomado">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Nombre Egresado</th>
              <th className="titulobd">Imagen </th>
              <th className="titulobd">Titulo</th>
              <th className="titulobd">Nombre empresa</th>
              <th className="titulobd">Url Empresa</th>
              <th className="titulobd">Acciones</th>

            </tr>
          </thead>
          {egresados.map(egresado => (
    <tr key={egresado.id} className="info-bd">
      <td className="info-bd">{egresado.nombre_estudiante}</td>
      <td>
        <img
          className="imagen-info-admi"
          src={`${backendURL}/imagen_egresado/${egresado.imagen}`}
          alt="Imagen "
        />
      </td>
      <td className="info-bd">{egresado.titulo}</td>
      <td className="info-bd">{egresado.nombre_empresa}</td>
      <td className="info-bd">{egresado.url_empresa}</td>
      <td className="edicion">
        <button className="boton-editar"  onClick={() => toggleEditPopup(egresado.id)} >
          <BiEdit />
        </button>
        <button className="boton-eliminar"onClick={() => handleDelete(egresado.id)} >
          <BiTrash />
        </button>
      </td>
    </tr>
  ))}
        </table></section>
        {showEditPopup && (
        <div className="popup-admi">
          <div className="popup-content-admi">
          <Editaregresado egresadoId={editTestId} onClose={closePopups} />
          </div>
        </div>
      )}</>
    )

}