import React from "react";
/* componentes */
import { Portadanoticias } from "../component/noticias/portadanoticias";
import { Cuerponoticias } from "../component/noticias/cuerponoticia";
import { Bienvenidanoticia } from "../component/noticias/bienvenidanoticia";

export const Noticias = () => {
  return (
    <div className="todo-home">
      <Portadanoticias />

      <section>
        {" "}
        <Bienvenidanoticia />
      </section>
      <section className="container noticias">
        <Cuerponoticias />
      </section>
    </div>
  );
};
