import React, { useState } from "react";
import axios from "axios";
export const  CrearNoticiasUnidad = ({ onClose }) => {
const [imagen, setImagen] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [url, seturl] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleGuardar = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
   
    formData.append("imagen", imagen);
    formData.append("titulo", titulo);
    formData.append("descripcion", descripcion);
    formData.append("url", url);
  
    // Agregar un console.log para verificar los valores en formData
    console.log("formData:", formData);
  
    try {
      const response = await axios.post(`${backendURL}/guardar_noticiasunidad`, formData);
      console.log("Response:", response.data);
      onClose();
      // Add any additional logic you want after successful creation
    } catch (error) {
      console.error("Error:", error);
      // Handle error cases
    }
  };

  

  const handleImagenChange = (e) => {
    setImagen(e.target.files[0]);
  };

  const handletituloChange = (e) => {
    setTitulo(e.target.value);
  };

  const handledesprinChange = (e) => {
    setDescripcion(e.target.value);
  };
  
  const handleurlvideoChange = (e) => {
    seturl(e.target.value);
  };
  return (
    <>
      <button className="close-button-admi" onClick={onClose}>
        X
      </button>
      <div className="titulo-admi-info">
        <h2>Crear Noticias</h2>
      </div>
      <form onSubmit={handleGuardar}>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
          Titulo :
          </label>
          <input
            className="input-admi"
            type="text"
            value={titulo}
            onChange={handletituloChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            iid="imagen"
            onChange={handleImagenChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="descripcionCurso">
          Descripcion :
          </label>

          <input
            className="input-admi"
            id="descripcionCurso"
            value={descripcion}
            onChange={handledesprinChange}
          />
        </div>
        
        <div>
          <label className="titulo-input-admi" htmlFor="url">
          Url:
          </label>
          <input
            className="input-admi"
            type="text"
            value={url}
            onChange={handleurlvideoChange}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
}