import React from "react";
import { TfiClose } from "react-icons/tfi";
import logo from "../img/home/logoEcole.png";



export const FormularioAdmision = () =>{
    return(
        <>
        <div className="popup-admision">
             
              <section className="form-register">
                <img src={logo} className="logo w-50" alt="Ecole Logo" />
                <h4 className="titulo informacion">MÁS INFORMACIÓN</h4>
                <iframe
                        src="https://crm.escuelaculinaria.cl/formularios/matricula"
                        style={{
                            width: "100%",
                            minHeight: "510px",
                            border: "0",
                            fontSize: "10px"
                        }}
                        id="myIframe"></iframe>
              </section>
            </div>
        
        
        </>
    )
}