import React from "react";
import { Portadacursos } from "../component/cursoydiplomadoportada.js/portadadiplomado";
import { Tabladiplomado } from "../component/cursoydiplomadoportada.js/cursos ydiplomado";
import { Cursostabla } from "../component/cursoydiplomadoportada.js/cursos";
import { ProximoCursos } from "../component/cursoydiplomadoportada.js/carouselcursosproximo";
import { Cusospasados } from "../component/cursoydiplomadoportada.js/cursospasados";
import GaleriaCursos from "../component/cursoydiplomadoportada.js/galeriacursos";
import { Segundotitulo } from "../component/cursoydiplomadoportada.js/segundaportada";
import { TestimonioAlumno } from "../component/cursoydiplomadoportada.js/testimonios-alumnos";



export const Cursos = () => {
  return (
    <div className="todo-home">
      <Portadacursos />
      <div><Segundotitulo/></div>
      <div className="container tabladiplomado">
        <Tabladiplomado />
      </div>
      <div className="cursostablas">
        <Cursostabla />
      </div>
      <div className="container-fluid carouselproximocurso">
        <ProximoCursos />
      </div>
      <div className="container cursos pasados">
         <GaleriaCursos />
      </div>
      <section className="galeria-curso">
        {" "}
       <Cusospasados />
      </section>
      <section>
        <TestimonioAlumno/>
      </section>
    </div>
  );
};
