import React from "react";
import "../../styles/noticias/noticias.css";

export const Bienvenidanoticia = () => {
  return (
    <section className="container bienvenida-noticias">
      <div className="titulo-bienvenida-noticias">
        <h1 className="titulo-bienvenida-noticias">
          ¡BIENVENIDO A NUESTRO PORTAL DE NOTICIAS!{" "}
        </h1>
        <h3 className="titulo-bienvenida-noticias">
          Infórmate de las nuevas tendencias de cocina internacional y de
          nuestra escuela.
        </h3>
      </div>
    </section>
  );
};
