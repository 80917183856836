import React, { useState, useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';
import axios from "axios";
import "../../styles/cursoydiplomado/cursoydiplomado.css";
import { TfiClose } from "react-icons/tfi";
import Rut from "rut.js";

/* imagenes */
import empresa from "../../img/cursosydiplomados/botonempresa.png";
import estudiante from "../../img/cursosydiplomados/botonestudiante.png";


import logo from "../../img/home/logoEcole.png";
export const Tabladiplomado = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [showForm, setShowForm] = useState(false);
  const [showForm1, setShowForm1] = useState(false);
  const [rut, setRut] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar la carga
  const [formData, setFormData] = useState({
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    telefono: "",
    correo: "",
    mensaje: ""
  });

  const [formularioEnviado, setFormularioEnviado] = useState(false);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("nombre", formData.nombre);
    formDataToSend.append("apellido_paterno", formData.apellido_paterno);
    formDataToSend.append("apellido_materno", formData.apellido_materno);
    formDataToSend.append("telefono", formData.telefono);
    formDataToSend.append("correo", formData.correo);
    formDataToSend.append("mensaje", formData.mensaje);

    try {
      setIsLoading(true); 
      const response = await axios.post(`${backendURL}/enviarformestudiantes`, formDataToSend);
      console.log(response.data);
      setFormularioEnviado(true); // Formulario enviado con éxito

      // Actualiza la página después de 2 segundos (o el tiempo que desees)
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Cambia 2000 a la cantidad de milisegundos que desees esperar antes de actualizar la página
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
    } finally {
      setIsLoading(false); // Desactivar la carga después de la solicitud
    }
  };



  const [formularioEnviados, setFormularioEnviados] = useState(false);

 


  // Ocultar el formulario cuando se monta el componente
  useEffect(() => {
    setShowForm(false);
  }, []);

  useEffect(() => {
    setShowForm1(false);
  }, []);

  // Función para cambiar la visibilidad del formulario
  const toggleForm = () => {
    setShowForm(!showForm);
  };
  const toggleForm1 = () => {
    setShowForm1(!showForm1);
  };

 

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs.sendForm('service_23rlbs2', 'template_fgnlodd', form.current, '-S-4cdQH5aRUmsBSW')
      .then((result) => {
          console.log(result.text);
          setFormularioEnviado(true); // Formulario enviado con éxito
    
          setTimeout(() => {
            window.location.reload();
          }, 100);
        
      }, (error) => {
          console.log(error.text);
        }
          )
          .finally(() => {
            setIsLoading(false); // Move it here so that it is executed regardless of success or error
          });
  };
  


  const sendEmpresa = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs.sendForm('service_23rlbs2', 'template_fb7b89g', form.current, '-S-4cdQH5aRUmsBSW')
      .then((result) => {
          console.log(result.text);
          setFormularioEnviado(true); // Formulario enviado con éxito
    
          setTimeout(() => {
            window.location.reload();
          }, 100);
        
      }, (error) => {
          console.log(error.text);
        }
          )
          .finally(() => {
            setIsLoading(false); // Move it here so that it is executed regardless of success or error
          });
  };
  



  return (
    <div>
      
      <div className="container diplomadotitulo">
        <h1 className="diplomadotitulo">
          PORQUE LA COCINA ES PARA TODOS, TE INVITAMOS A VIVIR UNA EXPERIENCIA
          ÚNICA.
        </h1>
        <h3 className="titulo2diplomado">
          ¡Inscríbete y sé parte de ECOLE con lo mejor de nuestra gastronomía!
        </h3>
      </div>
      <section className="botones-diplomado-cursos">
        <div className="row botones-diplomado-cursos">
          <div className="col-md botones-diplomado-cursos">
            <div className="contenedor-botones-diplomado-cursos">
              <img
                src={estudiante}
                className="w-100 img-botones-diplomado-cursos"
              />
              <button
                className="botonformulariodiploycurso"
                onClick={toggleForm}
              >
                Información adicional
              </button>
            </div>
          </div>
          {showForm && (
            <div className="container-fluid overlay-diplomado">
              <div className="popup diplomado">
                <button className="close curso" onClick={toggleForm}>
                  <TfiClose />
                </button>
                <section className="form-register-cursos">
                  <img src={logo} className="logo w-75" />
                  <h2>Cursos para Persona</h2>
                  <h4 className="titulo informacion">Más información</h4>
                  <p className="info-formulario">
                  Completa el formulario y recibe información sobre cursos
                  </p>
                  <form ref={form} onSubmit={sendEmail}>
                  <input
                    class="controls"
                    type="text"
                    name="name"
    
                    placeholder="  Nombre"
                    
                  />
                  <input
                    class="controls"
                    type="text"
                    name="apellido"
                    
                    placeholder="   Apellido Paterno"
                    
                  />
                  <input
                    class="controls"
                    type="text"
                    name="materno"

                    placeholder="  Apellido Materno"
                   
                  />
                  <input
                    class="controls"
                    type="tel"
                    name="telefono"
                    
                    placeholder="  Telefono"
                   
                  />

                  <input
                    class="controls"
                    type="email"
                    name="correo"
                   
                    placeholder="  Correo electrónico"
                   
                  />
                  <textarea
                    className="controls"
                    name="mensaje"
                   
                    placeholder="  Mensaje"

                  />

<section className="boton-enviar-form-curso">
                  <input className="botons" type="submit" value="enviar" />
                 </section>  {isLoading && ( 
                      <div className="popup-admisionnavbar">
          <div className="loading-spinner">
            <p className="mensajes-form-envio">Gracias por contactarnos, tu
información fue enviada.</p>
          <svg className="pl" width="240" height="240" viewBox="0 0 240 240">
	<circle className="pl__ring pl__ring--a" cx="120" cy="120" r="105" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 660" stroke-dashoffset="-330" stroke-linecap="round"></circle>
	<circle className="pl__ring pl__ring--b" cx="120" cy="120" r="35" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 220" stroke-dashoffset="-110" stroke-linecap="round"></circle>
	<circle className="pl__ring pl__ring--c" cx="85" cy="120" r="70" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 440" stroke-linecap="round"></circle>
	<circle className="pl__ring pl__ring--d" cx="155" cy="120" r="70" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 440" stroke-linecap="round"></circle>
</svg></div>
          </div>
         )} 
                </form>
                </section>
              </div>
            </div>
          )}
          <div className="col-md botones-diplomado-cursos">
            <div className="contenedor-botones-diplomado-cursos">
              <img
                src={empresa}
                className="w-100 img-botones-diplomado-cursos"
              />
              <button
                className="botonformulariodiploycurso"
                onClick={toggleForm1}
              >
                Información adicional
              </button>
            </div>
          </div>
          {showForm1 && (
            <div className="container-fluid overlay-diplomado">
              <div className="popup diplomado">
                <button className="close curso" onClick={toggleForm1}>
                  <TfiClose />
                </button>
                <section class="form-register-cursos">
                  <img src={logo} className="logo w-75" />
                  <h2>Cursos para Empresa</h2>
                  <h4 className="titulo informacion">Más información</h4>
                  <p className="info-formulario">
                  Completa el formulario y recibe información sobre cursos
                  </p>
                  <form ref={form} onSubmit={sendEmpresa}>
                  <input
                    class="controls"
                    type="text"
                    name="nombre_empresa"
                    id="nombres"
                    placeholder="   Nombre Empresa"
                   
                  />
                  <input
                    class="controls"
                    type="text"
                    name="nombre"
                    id="nombres"
                    placeholder="   Nombre"
                    
                  />
                  <input
                    class="controls"
                    type="text"
                    name="apellido"
                    id="apellidos"
                    placeholder="  Apellido"
                 
                  />
                  
                  <input
                    class="controls"
                    type="text"
                    name="rut_empresa"
                    placeholder="  RUT Empresa"
                 
                  />

                  <input
                    class="controls"
                    type="tel"
                    name="telefono"
                    id="Telefono"
                    placeholder="  Telefono"
                    
                  />

                  <input
                    class="controls"
                    type="email"
                    name="correo"
                    id="correo"
                    placeholder="  Correo electrónico"
                   
                  />
                  <textarea
                    className="controls"
                    name="mensaje"
                    id="mensaje"
                    placeholder="   Mensaje"
                
                  />
<section className="boton-enviar-form-curso">
                  <input class="botons" type="submit" value="enviar" />
      </section>            {isLoading && (
                      <div className="popup-admisionnavbar">
          <div className="loading-spinner">
          <p className="mensajes-form-envio">Gracias por contactarnos, tu
información fue enviada.</p>
          <svg className="pl" width="240" height="240" viewBox="0 0 240 240">
	<circle className="pl__ring pl__ring--a" cx="120" cy="120" r="105" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 660" stroke-dashoffset="-330" stroke-linecap="round"></circle>
	<circle className="pl__ring pl__ring--b" cx="120" cy="120" r="35" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 220" stroke-dashoffset="-110" stroke-linecap="round"></circle>
	<circle className="pl__ring pl__ring--c" cx="85" cy="120" r="70" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 440" stroke-linecap="round"></circle>
	<circle className="pl__ring pl__ring--d" cx="155" cy="120" r="70" fill="none" stroke="#000" stroke-width="20" stroke-dasharray="0 440" stroke-linecap="round"></circle>
</svg></div>
          </div>
        )}
                </form>
                </section>
              </div>
            </div>
          )} 
        </div>
      </section>
      
    </div>
  );
};

