import React, { useRef,  useState, useEffect } from "react";
import "../../styles/escuela/escuela.css";
/* librerias */
import ScrollReveal from "scrollreveal";

/* imagenes */
import Banner from "../../img/nosotros escuela/bannernuestrahistoria.jpg";
import portada2historia from"../../img/nosotros escuela/NUESTRA-HISTORIA-MOVIL (1).jpg"
import historia from "../../img/nosotros escuela/historia.jpg";
import historia1 from "../../img/nosotros escuela/historia1.png";
import historia2 from "../../img/nosotros escuela/historia2.png";

export const Historiaecole = () => {
  const titulohistoria = useRef(null);
  const imagen0 = useRef(null);
  const info0 = useRef(null);
  const imagen1 = useRef(null);
  const info1 = useRef(null);
  const imagen2 = useRef(null);
  const info2 = useRef(null);

  useEffect(() => {
    const sr = ScrollReveal();
    sr.reveal(titulohistoria.current, {
      duration: 20000,
      origin: "start",
      distance: "-100px",
    });
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();
    sr.reveal(imagen0.current, {
      duration: 20000,
      origin: "left",
      distance: "-100px",
    });
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();
    sr.reveal(info0.current, {
      duration: 20000,
      origin: "rigth",
      distance: "-100px",
    });
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();
    sr.reveal(imagen1.current, {
      duration: 20000,
      origin: "left",
      distance: "-100px",
    });
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();
    sr.reveal(info1.current, {
      duration: 20000,
      origin: "rigth",
      distance: "-100px",
    });
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();
    sr.reveal(imagen2.current, {
      duration: 20000,
      origin: "rigth",
      distance: "-100px",
    });
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();
    sr.reveal(info2.current, {
      duration: 10000,
      origin: "left",
      distance: "-100px",
    });
  }, []);

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado de isMobileView en función del ancho de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Cambia a "true" si el ancho es menor o igual a 700px
    };

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Llama a handleResize() una vez al cargar la página para establecer el estado inicial
    handleResize();

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className=" historia">
      <img  src={isMobileView ? portada2historia : Banner} className="w-100 hisotoria" />
      <div className="container historia">
        <div className="evento-historia">
          <div className="foto" data-aos="fade-up">
            <img src={historia1} alt="" />
          </div>
          <div className="fecha">
            {" "}
            <h1 className="titulo-año">2001</h1>{" "}
            <h3 className="info-titulo-año">
              {" "}
              En marzo 2001, el chef Alain Nonnet (Vice-Presidente de los Maîtres Cuisiniers de
France) visita Chile con la intención de aportar su experiencia a la gastronomía nacional, a través
de la formación. Junto con Carmen Versluys, actual Rectora, y Fernando León, realizan el proyecto
Escuela Culinaria Francesa con su restaurant de aplicación Les Maîtres (2002-2006).
            </h3>
          </div>
        </div>
        <div className="evento-historia">
          <div className="foto-dos" data-aos="fade-right">
            <img src={historia} alt="" />
          </div>
          <div className="fecha-1">
            {" "}
            <h1 className="titulo-año">2019</h1>{" "}
            <h3 className="info-titulo-año-1">
              {" "}
              En 2019 el CNED certifica que el Centro de Formación Técnica Escuela Culinaria Francesa, École, ha
desarrollado satisfactoriamente su proyecto institucional, en razón de lo cual ha alcanzado su
plena autonomía institucional que lo habilita a entregar el título técnico de nivel superior en forma
independiente.
            </h3>
          </div>
        </div>
        <div class="evento-historia">
          <div class="foto" data-aos="fade-left">
            <img src={historia2} alt="" />
          </div>
          <div class="fecha">
            {" "}
            <h1 className="titulo-año">2022</h1>{" "}
            <h3 className="info-titulo-año">
              {" "}
              En el 2022 nuestra escuela es acreditada por la CNA por cuatro años, quedando oficialmente
como: Centro de Formación Técnica Acreditado / Acreditado en Nivel Avanzado / Áreas de Gestión
Institucional y Docencia de Pregrado.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
