import React from "react";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import "../../../styles/estructura/estrucblog.css";

export const Infoestrucblog = ({receta}) => {
  return (
    <div>
      <section className="container info estructura blog">
        <div className="info estructura blog">
          <div className="row info estructura blog">
            <div className="col-sm-12 col-md-4 info estructura blog">
              <div className="titulo-ingredientes">
              <h1 className="titulo instructivo">INGREDIENTES</h1><h1 className="titulo instructivocantidad">Cantidad</h1>
              </div><div className="ingredientes blog">
                <ul>
                <DangerouslySetHTMLContent html={receta.ingredientes} />
                  
                </ul>
              </div>
            </div>
            
            <div className="col-sm-1 separador vertical"></div>
            <div className="col-sm-12 col-md-7 info estructura blog">
              <h1 className="titulo instructivo">PREPARACIÓN</h1>

              <div className="paso a paso info">
  
                  <li className="info-preparacion">
                  <DangerouslySetHTMLContent html={receta.preparacion} />
                  </li>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
