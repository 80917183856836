import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import { EditarDocente } from "./editar-docentes";



export const DocentesGet = ({ areaId }) => {
    const [docentes, setDocentes] = useState([]);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [selectedDocenteId, setSelectedDocenteId] = useState(null);; // Store the selected docente ID
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const openEditPopup = (docenteId) => {
    setSelectedDocenteId(docenteId); // Store the selected docente ID
    setIsEditPopupOpen(true);
};
  
    const closeEditPopup = () => {
      setIsEditPopupOpen(false);
    };
    

    useEffect(() => {
      console.log("Area ID in DocentesGet:", areaId);
  
      axios
        .get(`${backendURL}/obtener_docentes`, {
          params: { area_id: areaId }  
        })
        .then((response) => {
          setDocentes(response.data.docentes);
        })
        .catch((error) => {
          console.error("Error fetching docentes:", error);
        });
    }, [areaId]);

    
    const handleDelete = async (docentesId) => {
      try {
          const response = await axios.delete(`${backendURL}/eliminar_docente/${docentesId}`);
          console.log(response.data);
          // You can add additional logic here, like updating the UI after deletion
           // After successful deletion, update the docentes list
      const updatedDocentes = docentes.filter(docente => docente.id !== docentesId);
      setDocentes(updatedDocentes);
      } catch (error) {
          console.error(error);
          // Handle error cases
      }
  };
  
    return (
      <table className="lista">
        <thead>
          <tr>
            <th className="titulobd">Imagen</th>
            <th className="titulobd">Docente</th>
            <th className="titulobd">Tipo Docente</th>
            <th className="titulobd">Descripción</th>
            <th className="titulobd">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {docentes.map((docente) => (
            <tr key={docente.id} className="info-bd">
              <td>
                <img
                  className="imagen-info-admi"
                  src={docente.imagen_url}
                  alt="Imagen"
                />
              </td>
              <td className="info-bd">{docente.nombre_docente}</td>
              <td className="info-bd">{docente.tipo_docente}</td>
              <td className="info-bd"><DangerouslySetHTMLContent html={docente.descripcion}/></td>
              <td className="edicion">
              <button className="boton-editar" onClick={() => openEditPopup(docente.id)} >
                <BiEdit />
               </button>
                <button className="boton-eliminar" onClick={() => handleDelete(docente.id)}>
                  <BiTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>

        {isEditPopupOpen && (
          <div className="popup-admi">
        <div   className={`popup-content-admi ${
        isEditPopupOpen ? "scroll-noticia" : ""
      }`}>
          <EditarDocente docenteId={selectedDocenteId} onClose={closeEditPopup} />
        </div></div>
      )}
      </table>
    );
  };