import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


export const Editartestimonio = ({testimonioId, onClose}) => {
    const [nombreEstudiante, setNombreEstudiante] = useState("");
  const [descripcionEstudiante, setDescripcionEstudiante] = useState("");
  const [cursoRealizado, setCursoRealizado] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  

  useEffect(() => {
    // Fetch the existing testimonial data based on testimonioId
    axios.get(`${backendURL}/obtener_testimonio/${testimonioId}`)
      .then(response => {
        const testimonioData = response.data;
        setNombreEstudiante(testimonioData.nombre_testimonio);
        setDescripcionEstudiante(testimonioData.descripcion);
        setCursoRealizado(testimonioData.curso_realizado);
      })
      .catch(error => {
        console.error("Error fetching testimonio data:", error);
      });
  }, [testimonioId]);

  const handleEdit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nombre_testimonio", nombreEstudiante);
    formData.append("descripcion", descripcionEstudiante);
    formData.append("curso_realizado", cursoRealizado);
    

    try {
      const response = await axios.put(`${backendURL}/editar_testimonio/${testimonioId}`, formData);
      console.log(response.data);
      // Add any additional logic you want after successful editing
      onClose(); // Close the popup after successful edit
    } catch (error) {
      console.error(error);
      // Handle error cases
    }
  };
  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [
      { 'color': ['black','#4444','#696965','Dark Gray', 'grey','red','Indian Red', 'blue','Royal Blue', 'green','white','violet','aqua','orange','yellow','pink','purple', 'Turquoise', 'Teal', 'Lime', 'Cyan', 'Magenta', 'Brown', 'Maroon', 'Silver'] },  // Specify custom colors
      { 'background': ['#696965', 'yellow', 'pink', 'orange'] }  // Specify custom background colors
    ],
    [{ 'font': [] }],
    [
      { 'align': [] },
      { 'align': ['center', 'right', 'justify'] }  // Additional alignment options
    ],
  
    ['clean']   
  ];
  const module = {
    toolbar: toolbarOptions,
  };
    return(
        <>
         <button className="close-button-admi" onClick={onClose}>
        X
      </button>
      <div className="titulo-admi-info">
        <h2>Editar Testimonio Estudiante</h2>
      </div>
      <form onSubmit={handleEdit} >
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Nombre Estudiante:
          </label>
          <input
            className="input-admi"
            type="text"
            value={nombreEstudiante}
            onChange={(e) => setNombreEstudiante(e.target.value)}
          />
        </div>
        

        <div>
          <label className="titulo-input-admi" htmlFor="descripcionCurso">
            Descripción estudiante:
          </label>

          <ReactQuill
            modules={module}
            theme="snow"
            className="input-admi"
            id="descripcionEstudiante"
            value={descripcionEstudiante}
            onChange={setDescripcionEstudiante}
            
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Curso Realizado:
          </label>
          <input
            className="input-admi"
            type="text"
            value={cursoRealizado}
            onChange={(e) => setCursoRealizado(e.target.value)}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
        
        
        </>
    )
}