import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const Botoncrearvideoadmision = ({ cerrarVentana }) => {
    const [descripcion_video_admi, setDescripcion] = useState(null);
    const [url_video_admi, setUrl] = useState("");
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const handleImageChange = (e) => {
      setDescripcion(e.target.files[0]);
    };
  
    const handleUrlChange = (e) => {
      setUrl(e.target.value);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      const formData = new FormData();
      formData.append("descripcion_video_admi", descripcion_video_admi);
      formData.append("url_video_admi", url_video_admi);
  
      axios
        .post(`${backendURL}/videos_admision`, formData)
        .then((response) => {
          console.log(response.data);
          // Aquí puedes realizar acciones adicionales después de guardar la portada
  
          // Cierra la ventana
          cerrarVentana();
        })
        .catch((error) => {
          console.error(error);
          // Aquí puedes manejar el error en caso de que ocurra
        });
    };
    
  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']   
  ];
  const module = {
    toolbar: toolbarOptions,
  };

    return(

        <>
        <div className="titulo-admi-info">
          <h2>Crear Video Admision</h2>
        </div>
        <form onSubmit={handleSubmit}>
        <div>
          <label className="titulo-input-admi" htmlFor="descripcion_video_admi">
            Descripción del Curso:
          </label>

          <ReactQuill
            modules={module}
            theme="snow"
            className="input-admi"
            id="descripcion_video_admi"
            value={descripcion_video_admi}
            onChange={setDescripcion}
          />
        </div>
          <div>
            <label className="titulo-input-admi" htmlFor="url_video_admi">
              URL:
            </label>
            <input className="input-admi" type="text" id="url_video_admi" value={url_video_admi} onChange={handleUrlChange} />
          </div>
          <div className="boton-guardar">
            <button className="boton-crear" type="submit">
              Guardar
            </button>
          </div>
        </form>
      </>
    )
}