import React, { useState, useEffect } from "react";
import "../../styles/egresados/egresado.css";

/* imagenes */
import banner from "../../img/egresados/banneregresado.png";
import bannermovil from "../../img/egresados/BANNER-SEC-EGRESADO-RESP.png";

export const BannerEmpleo = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado de isMobileView en función del ancho de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Cambia a "true" si el ancho es menor o igual a 700px
    };

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Llama a handleResize() una vez al cargar la página para establecer el estado inicial
    handleResize();

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="container banner egresado">
      <div className="contenedor-banner-egresado">
        <img  src={isMobileView ? bannermovil: banner} className="w-100 banner egresado" />
        <a
          className="link-formulario-egresado"
          href="https://docs.google.com/forms/d/e/1FAIpQLSftF-Zc9Osuq0oyF-wCCuhUXHg9Kv1LC8ZcD10t0ScbxB_Hmg/viewform?c=0&w=1"
          target="_black"
        >
          {" "}
          <button className="button2 egresado">INGRESA AQUÍ</button>
        </a>
      </div>
    </div>
  );
};
