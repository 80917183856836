import React from "react";

/* imagenes */

import ico1 from "../../img/especialidad/ico-01.png";
import ico2 from "../../img/especialidad/ico-02.png";
import ico3 from "../../img/especialidad/ico-03.png";
import ico4 from "../../img/especialidad/ico-04.png";
import ico5 from "../../img/especialidad/ico-05.png";
import ico6 from "../../img/especialidad/ico-06.png";
import ico7 from "../../img/especialidad/ico-07.png";

export const SeguroPasantia = () => {
  return (
    <div className="container beneficiospasantia">
      <hr className="separadorpasantia" />
      <div className="titulo-beneficiospasantia">
        <h1 className="titulo-beneficiospasantia">BENEFICIOS</h1>
        <h3 className="descripcion infopasantia">
          {" "}
          Nuestro Diplôme Maîtrise de Cuisine Française es un programa exclusivo
          que permite especializarte, vivir, trabajar y aprender de los mejores
          chef y restaurantes del mundo, donde algunos son reconocidos con tres
          estrellas Michelin, entre otros.
        </h3>
      </div>
      <div className="container seguropasantia">
        <div className="seguropasantia row">
          <div className="col col-xs-12 col-sm-12 col-xl-2 seguropasantia">
            <div className="info-seguridad-especalidad">
              <div className="imagenseguridad">
                {" "}
                <img
                  src={ico2}
                  className="w-100 card-img-top seguropasantia"
                  alt="..."
                />
              </div>
              <div className="icono-info-especialidad">
                <h5 className="texto seguropasantia">
                  Convenio en los mejores restaurantes
                </h5>
              </div>
            </div>
          </div>
          <div className="col  seguropasantia">
            <div className="info-seguridad-especalidad">
              <div className="imagenseguridad">
                {" "}
                <img
                  src={ico3}
                  className="w-100 card-img-top seguropasantia"
                  alt="..."
                />
              </div>
              <div className="icono-info-especialidad">
                <h5 className="texto seguropasantia">Cupo asegurado</h5>
              </div>
            </div>
          </div>
          <div className="col seguropasantia">
            <div className="info-seguridad-especalidad">
              <div className="imagenseguridad">
                {" "}
                <img
                  src={ico1}
                  className=" w-100 card-img-top seguropasantia"
                  alt="..."
                />
              </div>
              <div className="icono-info-especialidad">
                <h5 className="texto seguropasantia">Práctica Internacional</h5>
              </div>
            </div>
          </div>
          <div className="col  seguropasantia">
            <div className="info-seguridad-especalidad">
              <div className="imagenseguridad">
                {" "}
                <img
                  src={ico4}
                  className="w-100 card-img-top seguropasantia"
                  alt="..."
                />
              </div>
              <div className="icono-info-especialidad">
                <h5 className="texto seguropasantia">Posibilidad de trabajo</h5>
              </div>
            </div>
          </div>
          <div className="col  seguropasantia">
            <div className="info-seguridad-especalidad">
              <div className="imagenseguridad">
                {" "}
                <img
                  src={ico5}
                  className="w-100 card-img-top seguropasantia"
                  alt="..."
                />
              </div>
              <div className="icono-info-especialidad">
                <h5 className="texto seguropasantia">Alojamiento</h5>
              </div>
            </div>
          </div>
          <div className="col  seguropasantia">
            <div className="info-seguridad-especalidad">
              <div className="imagenseguridad">
                {" "}
                <img
                  src={ico6}
                  className="w-100 card-img-top seguropasantia"
                  alt="..."
                />
              </div>
              <div className="icono-info-especialidad">
                <h5 className="texto seguropasantia">
                  Alimentación durante la estadía
                </h5>
              </div>
            </div>
          </div>
          <div className="col  seguropasantia">
            <div className="info-seguridad-especalidad">
              <div className="imagenseguridad">
                {" "}
                <img
                  src={ico7}
                  className="w-100 card-img-top seguropasantia"
                  alt="..."
                />
              </div>
              <div className="icono-info-especialidad">
                <h5 className="texto seguropasantia">Sueldo</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="separadorpasantia" />
    </div>
  );
};
