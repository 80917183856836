import React, { useState } from "react";
import { FcAddImage } from "react-icons/fc";
import axios from "axios";

export const CrearProximoCurso = ({ onClose }) => {
  const [mostrarInput, setMostrarInput] = useState(false);
  const [imagenWeb, setImagenWeb] = useState(null);
  const [imagenMobil, setImagenMobil] = useState(null);
  const [ambasImagenesSeleccionadas, setAmbasImagenesSeleccionadas] = useState(
    false
  );
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleIngresarClick = () => {
    setMostrarInput(true);
  };
  const handleImagewebChange = (e) => {
    setImagenWeb(e.target.files[0]);
  };
  const handleImagemobilChange = (e) => {
    setImagenMobil(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar si ambas imágenes están seleccionadas
    if (imagenWeb && imagenMobil) {
      // Crear un nuevo objeto FormData
      const formData = new FormData();
      formData.append("imagen_web", imagenWeb);
      formData.append("imagen_mobil", imagenMobil);

      axios
        .post(`${backendURL}/guardar_proximocurso`, formData)
        .then((response) => {
          console.log(response.data);
          // Aquí puedes realizar acciones adicionales después de guardar las imágenes

          // Cierra la ventana
          handleCloseClick();
        })
        .catch((error) => {
          console.error(error);
          // Aquí puedes manejar el error en caso de que ocurra
        });
    } else {
      alert("Por favor, selecciona ambas imágenes");
    }
  };

  const handleCloseClick = () => {
    setMostrarInput(false);
  };

  return (
    <>
      <button className="close-button-admi" onClick={onClose}>
        X
      </button>
      <section><h1>Nuevo Proximo Curso</h1></section>

      <section className="galeria-botones scrollable">
        <button className="boton-crear" onClick={handleIngresarClick}>
          Agregar
        </button>
        {mostrarInput && (
          <form
            className="agregar-imagen-galeria-proximocurso"
            onSubmit={handleSubmit}
          >
            <div className="imagen-web">
              <label>Imagen Web</label>
              <input
                type="file"
                name="imagen_web"
                onChange={handleImagewebChange}
              />
              {imagenWeb && <img src={URL.createObjectURL(imagenWeb)} alt="Imagen Web Preview" />}
              {!imagenWeb && (
                <p style={{ color: "red" }}>Por favor, seleccione una imagen</p>
              )}
            </div>
            <div className="imagen-mobil">
              <label>Imagen Mobil</label>
              <input
                type="file"
                name="imagen_mobil"
                onChange={handleImagemobilChange}
              />
              {imagenMobil && <img src={URL.createObjectURL(imagenMobil)} alt="Imagen Mobil Preview" />}
              {!imagenMobil && (
                <p style={{ color: "red" }}>Por favor, seleccione una imagen</p>
              )}
            </div>
            <button
              className="imagenes-agregadas"
              type="submit"
              disabled={!imagenWeb || !imagenMobil}
            >
              <span className="button__text">Agregar</span>
              <span className="button__icon">
                <FcAddImage />
              </span>
            </button>
          </form>
        )}
      </section>
    </>
  );
};