import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const Botoneditarportadacursoydiplo = ({
  cerrarVentana,
  portadaId,
  actualizarPortadaEnLista // Recibe la función del componente padre
}) => {
  const [portadaData, setPortadaData] = useState({
    imagen: null,
    imagen_responsive: null,
    url: ''
  });
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    obtenerPortada();
  }, [portadaId]);

  const obtenerPortada = () => {
    axios
      .get(`${backendURL}/obtener_portadadc/${portadaId}`)
      .then((response) => {
        setPortadaData(response.data.portada);
      })
      .catch((error) => {
        console.error('Error al obtener los datos de la portada:', error);
      });
  };

  const handleGuardar = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('imagen', portadaData.imagen);
    formData.append('imagen_responsive', portadaData.imagen_responsive);
    formData.append('url', portadaData.url);

    axios
      .put(`${backendURL}/editar_portadadc/${portadaId}`, formData)
      .then((response) => {
        console.log(response.data);
        cerrarVentana();
        // Llama a la función del componente padre para actualizar la lista de portadas
        actualizarPortadaEnLista(portadaId, portadaData);

        // Vacía el campo de selección de archivo para evitar mostrar la imagen anterior en el próximo formulario
        document.getElementById('imagen').value = '';
        document.getElementById('imagen_responsive').value = '';
      })
      .catch((error) => {
        console.error('Error al actualizar la portada:', error);
      });
  };

  const handleChange = (event) => {
    if (event.target.id === 'imagen') {
      setPortadaData({
        ...portadaData,
        imagen: event.target.files[0]
      });
    } else {
      setPortadaData({
        ...portadaData,
        [event.target.id]: event.target.value
      });
    }
  };
  const handleChangemovil = (event) => {
    if (event.target.id === 'imagen_responsive') {
      setPortadaData({
        ...portadaData,
        imagen_responsive: event.target.files[0]
      });
    } else {
      setPortadaData({
        ...portadaData,
        [event.target.id]: event.target.value
      });
    }
  };

  return (
    <>
      <div className="titulo-admi-info">
        <h2>Editar Portada página curso y diplomado</h2>
      </div>
      <form onSubmit={handleGuardar}>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen"
            key={portadaData.imagen} // Agrega una clave única para el campo de selección de archivo
            onChange={handleChange}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen_home">
            Imagen movil:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen_responsive" // Cambiar el id a "imagen_home"
            key={portadaData.imagen_responsive}
            onChange={handleChangemovil}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            URL:
          </label>
          <input
            className="input-admi"
            type="text"
            id="url"
            value={portadaData.url}
            onChange={handleChange}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};