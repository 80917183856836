import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiTrash } from "react-icons/bi";
import { BotoncrearAreas } from "./crear-area";
import { SlUserFollow } from "react-icons/sl";
import { Docentes } from "../docentes/docente";


export const Areas = () => {
  const [areasData, setAreasData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showCreateDocentePopup, setShowCreateDocentePopup] = useState(false);
const [selectedAreaId, setSelectedAreaId] = useState(null);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const closePopups = () => {
    setShowCreatePopup(false);
    setShowEditPopup(false);
  };

  useEffect(() => {
    // Fetch áreas from the backend API
    axios
      .get(`${backendURL}/obtener_area`)
      .then((response) => {
        /* console.log(response.data.area); */ // Verifica si esto muestra un array en la consola
        setAreasData(response.data.area); // Actualiza el estado con el array de áreas
      })
      .catch((error) => {
        console.error("Error al obtener áreas:", error);
      });
  }, []);

  
  const handleDelete = async (areaId) => {
    try {
      const response = await axios.delete(
        `${backendURL}/eliminar_area/${areaId}`
      );
      console.log(response.data);
      // You can add additional logic here, like updating the UI after deletion
    } catch (error) {
      console.error(error);
      // Handle error cases
    }
  }; 

  return (
    <>
      <section>
        <h1 className="titulo-info-componenete-admi">Areas Docentes</h1>
      </section>
      <section className="boton-crear-chef-cursosydiplomado">
        <button className="boton-crear" onClick={togglePopup}>
          Crear
        </button>
      </section>
      {showPopup && (
        <div className="popup-admi">
          <div
            className={`popup-content-admi ${
              showPopup ? "scroll-noticia" : ""
            }`}
          >
          <BotoncrearAreas onClose={togglePopup} /> 
          </div>
        </div>
      )}

      <section className="lista-chef-cursosydiplomado">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Area docencia </th>
              <th className="titulobd">Area docencia movil </th>
              <th className="titulobd">Acciones</th>
            </tr>
          </thead>
          {areasData.map((area) => (
      
            <tr key={area.id} className="info-bd">
              <td>
                <img
                  className="imagen-info-admi"
                  src={`${backendURL}/imagen_area/${area.imagen}`}
                  alt="Imagen "
                />
              </td>
              <td>
                <img
                  className="imagen-info-admi"
                  src={`${backendURL}/imagen_area_movil/${area.imagen_responsive}`}
                  alt="Imagen "
                />
              </td>

              <td className="edicion">
              <button
  className="boton-eliminar"
  onClick={() => {
    setSelectedAreaId(area.id);
    setShowCreateDocentePopup(true);
  }}
>
  <SlUserFollow />
</button>
                <button
                  className="boton-eliminar"
                  onClick={() => handleDelete(area.id)} 
                >
                  <BiTrash />
                </button>
              </td>
            </tr>
          ))}
        </table>
      </section>
      {showCreateDocentePopup && (
  <div className="popup-admi">
    <div
      className={`popup-content-admi ${
        showCreateDocentePopup ? "scroll-noticia" : ""
      }`}
    >
       <Docentes areaId={selectedAreaId} onClose={() => setShowCreateDocentePopup(false)} />
      
    </div>
  </div>
)}
    </>
  );
};
