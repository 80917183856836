import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import "../../styles/style Home/home.css";

export const Portadacursos = () => {
 
  const backendURL = process.env.REACT_APP_BACKEND_URL;
const [listaPortadas, setListaPortadas] = useState([]);
const [imagenKey, setImagenKey] = useState(Date.now());
const [activeSlide, setActiveSlide] = useState(0);
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const [preloadedImages, setPreloadedImages] = useState([]);

const obtenerPortadas = async () => {
  try {
    const response = await axios.get(`${backendURL}/obtener_portadadc`);
    setListaPortadas(response.data.portadas);
  } catch (error) {
    console.error("Error al obtener la lista de portadas:", error);
  }
};

const preloadImages = () => {
  const images = listaPortadas.map((portada) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src =
          windowWidth < 700
            ? `${backendURL}/imagen_portadacursoresponsive/${portada.imagen_responsive}`
            : `${backendURL}/imagen_portadadc/${portada.imagen}`;
      
      img.onload = resolve;
    });
  });

  Promise.all(images).then(() => {
    setPreloadedImages(images);
  });
};

const nextSlide = () => {
  setActiveSlide((prevSlide) => (prevSlide + 1) % listaPortadas.length);
};

useEffect(() => {
  obtenerPortadas();
}, []); // Ejecutar el efecto solo una vez al montar el componente

useEffect(() => {
  preloadImages();

  const interval = setInterval(nextSlide, 2000);

  return () => {
    clearInterval(interval);
  };
}, [listaPortadas]);

useEffect(() => {
  // Actualizar la clave única para forzar la recarga de la imagen
  setImagenKey(Date.now());
}, [activeSlide]);

useEffect(() => {
  // Manejar el cambio de tamaño de la ventana
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Agregar el evento de cambio de tamaño
  window.addEventListener("resize", handleResize);

  // Limpiar el evento cuando el componente se desmonta
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []); // No olvides las dependencias vacías para que se ejecute solo una vez al montar el componente

useEffect(() => {
  // Actualizar las imágenes cuando cambie el ancho de la ventana
  preloadImages();
}, [windowWidth]);

return (
  <section className="portada home1">
    <div
      id="carouselExampleFade"
      className="carousel slide carousel-fade-home"
      style={{ width: "99.5%", height: "100%" }}
    >
      {listaPortadas.map((portada, index) => (
        <Link to={portada.url} key={portada.id}>
          <div
            className={`carousel-item-home ${
              index === activeSlide ? "active" : ""
            }`}
          >
            <Link to={portada.url}>
              <button className="portada-boton">
                <img
                  src={ windowWidth < 700
                    ? `${backendURL}/imagen_portadacursoresponsive/${portada.imagen_responsive}`
                    : `${backendURL}/imagen_portadadc/${portada.imagen}`}
                  className="d-block w-100"
                  alt="..."
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </button>
            </Link>
          </div>
        </Link>
      ))}
    </div>

    <div className="carousel-indicators">
      {listaPortadas.map((_, index) => (
        <button
          key={index}
          type="button"
          className={`carousel-indicator ${
            index === activeSlide ? "active" : ""
          }`}
          onClick={() => setActiveSlide(index)}
        ></button>
      ))}
    </div>
  </section>
);
};