import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiTrash } from "react-icons/bi";
import { CrearBtoncursopasado } from "./crear-curso-pasado";

export const CursosPasados = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [showPopup, setShowPopup] = useState(false);
  const [cursosPasados, setCursosPasados] = useState([]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const cerrarVentana = () => {
    setShowPopup(false);
  };

  const obtenerCursosPasados = async () => {
    try {
      const response = await axios.get(`${backendURL}/obtener_cursospasados`);
      setCursosPasados(response.data); // Suponiendo que el servidor devuelve directamente los cursos pasados
    } catch (error) {
      console.error("Error al obtener la lista de cursos pasados:", error);
    }
  };

  const eliminarCursopasado = async (cursopasadoId) => {
    try {
      await axios.delete(`${backendURL}/eliminar_cursopasado/${cursopasadoId}`);
      setCursosPasados((prevCursosPasados) =>
        prevCursosPasados.filter((curso) => curso.id !== cursopasadoId)
      );
    } catch (error) {
      console.error("Error al eliminar curso pasado:", error);
    }
  };

  useEffect(() => {
    obtenerCursosPasados();
  }, []);
  return (
    <>
      <section>
        <h1 className="titulo-info-componenete-admi">Cursos Pasados</h1>
      </section>
      <section className="boton-crear-chef-cursosydiplomado">
        <button className="boton-crear" onClick={togglePopup}>
          Crear
        </button>
      </section>
      {showPopup && (
        <div className="popup-admi">
          <div className="popup-content-admi">
            <button
              className="close-button-admi"
              onClick={cerrarVentana}
              aria-label="Cerrar"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <CrearBtoncursopasado onClose={cerrarVentana} />
          </div>
        </div>
      )}
      <section className="lista-chef-cursosydiplomado">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Video Curso Pasado</th>
              <th className="titulobd">Imagen Curso Pasado</th>
              <th className="titulobd">Acciones</th>
            </tr>
          </thead>
          <tbody>
          {cursosPasados.map((cursopasado) => (
            <tr  className="info-bd" key={cursopasado.id}>
              <td className="info-bd">
                <a href={cursopasado.video_url} target="_blank" rel="noopener noreferrer">
                  Ver Video
                </a>
              </td>
              <td>
                <img
                 className="imagen-info-admi"
                  src={cursopasado.imagen_url}
                  alt="Imagen Video Pasado"
                />
              </td>
              <td className="edicion">
                <button  className="boton-eliminar" onClick={() => eliminarCursopasado(cursopasado.id)}>
                  <BiTrash />
                </button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </section>
    </>
  );
};