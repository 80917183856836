import React from "react";
import "../../styles/admision/admision.css";

class BotonCorreo extends React.Component {
  redirigirCorreo() {
    window.location.href = "mailto: admisión@escuelaculinaria.cl";
  }

  render() {
    return (
      <section className="boton-admision-correo">
      <h1 className="footer-requisito-info">
        Si necesitas mas detalles de Admisión escríbenos a:
        <button className="admision-correo" onClick={this.redirigirCorreo}>
          <strong>admisión@escuelaculinaria.cl</strong>
        </button>
      </h1>
      </section>
    );
  }
}

export default BotonCorreo;
