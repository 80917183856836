import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";

import { BeneficioCrear } from "./crear-benmeficio";
import { EditarBeneficio } from "./editar-beneficio";


export const Beneficio = () => {
    const [beneficio, setBeneficio] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [showCreatePopup, setShowCreatePopup] = useState(false);
    const [editTestId, setEditTestId] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const togglePopup = () => {
        setShowPopup(!showPopup);
      };
      const closePopups = () => {
        setShowCreatePopup(false);
        setShowEditPopup(false);
      };
    
      const toggleEditPopup = (beneficioId) => {
        setShowEditPopup(!showEditPopup);
        setEditTestId(beneficioId);
      };

      useEffect(() => {
        // Fetch testimonios from the backend API
        axios.get(`${backendURL}/beneficios`)
          .then(response => {
            console.log("Respuesta de la API:", response.data);
            setBeneficio(response.data);
          })
          .catch(error => {
            console.error("Error fetching beneficio:", error);
          });
      }, []);

      const handleDelete = async (beneficioId) => {
        try {
            const response = await axios.delete(`${backendURL}/eliminar_beneficio/${beneficioId}`);
            console.log(response.data);
            // You can add additional logic here, like updating the UI after deletion
            const updatedResponse = await axios.get(`${backendURL}/beneficios`);
            setBeneficio(updatedResponse.data);
        } catch (error) {
            console.error(error);
            // Handle error cases
        }
    };

    return(
        <>
        <section>
            <h1> Beneficio Estudiante</h1>
        </section>
        <section className="boton-crear-chef-cursosydiplomado">
        <button className="boton-crear" onClick={togglePopup} >
          Crear
        </button>
        </section> 
        {showPopup && (
        <div className="popup-admi">
          <div className={`popup-content-admi ${showEditPopup ? 'scroll-noticia' : ''}`}>
          <BeneficioCrear onClose={togglePopup}/>
          </div>
        </div>
      )}
        <section className="lista-chef-cursosydiplomado">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Imagen Principal</th>
              <th className="titulobd">Imagen Secundaria </th>
              <th className="titulobd">Titulo </th>
              <th className="titulobd">Descuento </th>
              <th className="titulobd">Descripcion principal</th>
              <th className="titulobd">Tipo</th>
              <th className="titulobd">Fecha Incio</th>
              <th className="titulobd">Fecha termino</th>
              <th className="titulobd">Beneficio</th>
              <th className="titulobd">Acciones</th>

            </tr>
          </thead>
          {beneficio.map(beneficio => (
    <tr key={beneficio.id} className="info-bd">
      <td className="info-bd">
      <img
          className="imagen-info-admi"
          src={beneficio.imagen_url}
          alt="Imagen "
        />
      </td>
      <td className="info-bd">
        <img
          className="imagen-info-admi"
          src={beneficio.imagen_url_secundaria}
          alt="Imagen "
        />
      </td>
      <td className="info-bd">{beneficio.titulo} </td>
      <td className="info-bd">{beneficio.descuento} </td>
      <td className="info-bd">{beneficio.descripcion_principal} </td>
      <td className="info-bd">{beneficio.tipo}</td>
      <td className="info-bd">{beneficio.fecha_inicio}</td>
      <td className="info-bd">{beneficio.fecha_termino} </td>
      <td className="info-bd">{beneficio.beneficios}</td>

      <td className="edicion">
        <button className="boton-editar" onClick={() => toggleEditPopup(beneficio.id)}  >
          <BiEdit />
        </button>
        <button className="boton-eliminar" onClick={() => handleDelete(beneficio.id)} >
          <BiTrash />
        </button>
      </td>
    </tr>
      ))}
 </table>
 </section>
 {showEditPopup && (
        <div className="popup-admi">
          <div className={`popup-content-admi ${showEditPopup ? 'scroll-noticia' : ''}`}>
           
         <EditarBeneficio beneficioId={editTestId} onClose={closePopups} /> 
          </div></div>
        
      )}
        </>
    )
}