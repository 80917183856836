import React from "react";

/* imagenes */
import separador from "../../img/nuestra-carreras/lineacarrera.png";

export const Infoperfil = () => {
  return (
    <div>
      <div className="contiene-imagen-perfil-carrera">
      <div className="imagen-linea-carrera">
      <img className="w-20 separadorcarrera" src={separador} />
      </div></div>
      <h1 className="titulodeperfilegresado">
        PERFIL DE EGRESO
      </h1>
      <h1 className="titulodeperfilegresadosecundario">Técnico de Nivel Superior en Gastronomía Internacional
        École{" "}</h1>

      <div
        className="Carrera accordion accordion-flush"
        id="accordionFlushExample"
      >
        <div className=" Carrera accordion-item">
          <h2 className="Carrera accordion-header">
            <button
              className="Carrera accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              {" "}
              <p className="info-perfil-carrera">
                {" "}
                MENCIÓN GASTRONOMÍA FRANCESA
              </p>
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="Carrera accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="Carrera accordion-body">
            El egresado de Técnico de Nivel Superior en Gastronomía Internacional Mención Gastronomía
Francesa, está dotado de un conjunto de aptitudes y destrezas prácticas y conceptuales, del
ámbito gastronómico tanto en cocina como pastelería. Esta formación tiene como base el rigor de
la cocina francesa, para su desempeño tanto en la gastronomía nacional como internacional. Lo
anterior le permite enfrentar el mundo laboral y/o del emprendimiento con las herramientas
necesarias para el logro de esos objetivos, además de tener las competencias y las bases para
crear nuevos nichos de negocios y diversos emprendimientos culinarios. Es un profesional
responsable, comprometido y con gran perseverancia en lo que emprende.
            </div>
          </div>
        </div>
        <div className="Carrera accordion-item">
          <h2 className="Carrera accordion-header">
            <button
              className="Carrera accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              <p className="info-perfil-carrera"> MENCIÓN PANADERÍA</p>
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="Carrera accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="Carrera accordion-body">
            egresado de Técnico de Nivel Superior en Gastronomía Internacional Mención panadería, está
habilitado para desarrollarse en cualquier cocina o establecimiento gastronómico de renombre, ya
sea nacional o extranjero, cumplir con los requerimientos y expectativas del mercado, teniendo las
capacidades necesarias para desenvolverse adecuadamente en todos los rubros asociados a la
producción y manejo de productos de panadería y viennoiserie, para cumplir con los
requerimientos y expectativas del mercado, de acuerdo con las normativas vigentes, estándares
nutricionales, de higiene e inocuidad alimentaria.
            </div>
          </div>
        </div>
      </div>

     
    </div>
  );
};
