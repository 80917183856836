import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import { CrearNoticiasUnidad } from "./crear-noticia-unidad";

export const NoticiasUnidad = () => {
const [noticias, setNoticias] = useState([]);
const [showPopup, setShowPopup] = useState(false);
const [showCreatePopup, setShowCreatePopup] = useState(false);
const [showEditPopup, setShowEditPopup] = useState(false);
const [editTestId, setEditTestId] = useState(null);
const backendURL = process.env.REACT_APP_BACKEND_URL;

const togglePopup = () => {
    setShowPopup(!showPopup);
};

const closePopups = () => {
    setShowCreatePopup(false);
    setShowEditPopup(false);
};

const toggleEditPopup = (noticiaId) => {
    setShowEditPopup(!showEditPopup);
    setEditTestId(noticiaId);
};

const obtenerNoticias = () => {
    axios
    .get(`${backendURL}/obtener_noticiasunidad`)
    .then((response) => {
        setNoticias(response.data);
    })
    .catch((error) => {
        console.error("Error fetching noticias:", error);
    });
};

useEffect(() => {
    obtenerNoticias();
}, []);

    const handleDelete = async (noticiaId) => {
        try {
        await axios.delete(`${backendURL}/eliminar_noticiasunidad/${noticiaId}`);
          // Después de eliminar con éxito, actualiza la lista de noticias
        obtenerNoticias();
        } catch (error) {
        console.error(error);
          // Handle error cases
        }
    };

return (
    <>
    <section>
        <h1 className="titulo-info-componenete-admi">Noticias</h1>
    </section>
    <section className="boton-crear-chef-cursosydiplomado">
        <button className="boton-crear" onClick={togglePopup}>
        Crear
        </button>
    </section>
    {showPopup && (
        <div className="popup-admi">
        <div
            className={`popup-content-admi ${
            showEditPopup ? "scroll-noticia" : ""
            }`}
        >
         <CrearNoticiasUnidad onClose={togglePopup} />  
        </div>
        </div>
    )}

    <section className="lista-chef-cursosydiplomado">
        <table className="lista">
        <thead>
            <tr>
            <th className="titulobd">Imagen </th>
            <th className="titulobd">Titulo principal</th>
            <th className="titulobd">Descripcion principal</th>
            <th className="titulobd">Url video</th>
            <th className="titulobd">Acciones</th>
            </tr>
        </thead>
        {noticias.map((noticia) => (
            <tr key={noticia.id} className="info-bd">
            <td>
                <img
                className="imagen-info-admi"
                src={noticia.imagen_url}
                alt="Imagen "
                />
            </td>
            <td className="info-bd">{noticia.titulo}</td>
            <td className="info-bd">{noticia.descripcion} </td>

            <td className="info-bd">{noticia.url}</td>
            <td className="edicion">
               
                <button
                  className="boton-eliminar"  onClick={() => handleDelete(noticia.id)}  
                >
                <BiTrash />
                </button>
            </td>
            </tr>
        ))}
        </table>
    </section>
   
    </>
);
};
