import React, { useRef, useEffect, useState } from "react";
import "../../styles/cursoydiplomado/cursoydiplomado.css";
import axios from "axios";



export const ProximoCursos = () => {
  const carouselRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false); // Estado para controlar si la pantalla es pequeña
  const [cursosproximo, setCursosproximo] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    obtenerProximosCursos();
  }, []);

  const obtenerProximosCursos = async () => {
    try {
      const response = await axios.get(`${backendURL}/proximos_cursos`);
      setCursosproximo(response.data); // Aquí guardamos los datos de los cursos
    } catch (error) {
      console.error("Error al obtener los próximos cursos:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 500 && !isSmallScreen) {
        setIsSmallScreen(true);
      } else if (screenWidth > 500 && isSmallScreen) {
        setIsSmallScreen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Llama a la función al cargar la página

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen]);

  if (cursosproximo.length === 0) {
    return null; // Si no hay cursos próximos, no renderizamos nada
  }


  return (
    <div className="contenedor-cursos-proximo">
      <div className="titulocursosproximo">
        <h1 className="proxicurso titulo">PROXIMOS CURSOS</h1>
      </div>
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide carousel-fade proximocurso"
        data-bs-ride="carousel"
        data-bs-interval="3000"
        ref={carouselRef}
      >
        <div className="carousel-inner proximocurso">
        {cursosproximo.map((proxicurso, index) => (
            <div
              key={proxicurso.id}
              className={`carousel-item ${index === 0 ? "active" : ""} proximocurso`}
            >
              <img
                src={isSmallScreen ?`${backendURL}/imagen_proximocurso/${proxicurso.imagen_mobil}` : `${backendURL}/imagen_proximocurso/${proxicurso.imagen_web}`}
                className="d-block w-100 proximocurso"
                alt={`Curso ${proxicurso.id}`}
              />
            </div>
          ))}
        </div>
        <div className="carousel-control-container">
          <button
            className="carousel-control-prev proximocurso"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon proximocurso" aria-hidden="true"></span>
            <span className="visually-hidden proximocurso">Previous</span>
          </button>
          <button
            className="carousel-control-next proximocurso"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon proximocurso" aria-hidden="true"></span>
            <span className="visually-hidden proximocurso">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};