import React from "react";
/* imagenes */

/* componentes */
import { Portadablog } from "../component/blog/blog-portada";
import { BienvenidaBlog } from "../component/blog/blog-bievenida";
import { Cardtips } from "../component/blog/card-tips";
import { Galeriablog } from "../component/blog/galeriablog";


export const Blog = () => {
  return (
    <div className="todo-home"> 
      
        <Portadablog />
     
      <section className="bienvenida blog">
        <BienvenidaBlog />
      </section>
      
      <section className="informacion de tips">
        <Cardtips />
      </section>
      <section className=" galeria blog">
        {" "}
        <Galeriablog />
      </section>
    </div>
  );
};
