import React, { useState, useEffect } from "react";
import axios from "axios";
import { FcAddImage } from "react-icons/fc";
import { BiTrash } from "react-icons/bi";

export const Imagenunidad = () => {
    const [mostrarInput, setMostrarInput] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

  
    const handleAgregarSubmit = async (event) => {
        event.preventDefault();
    
        const formData = new FormData();
        formData.append("imagenunidad", event.target.imagen.files[0]);
    
        try {
          const response = await axios.post(
            `${backendURL}/guardar_imagenunidad`,
            formData
          );
    
          if (response.status === 200) {
            setGalleryImages((prevImages) => [
              ...prevImages,
              { imagenunidad: response.data.imagen_url },
            ]);
            setMostrarInput(false);
          }
        } catch (error) {
          console.error("Error adding gallery image:", error);
        }
      };

  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const response = await axios.get(
          `${backendURL}/obtener_imagenunidad`
        );
        setGalleryImages(response.data.imagenunidad);
      } catch (error) {
        console.error("Error fetching gallery images:", error);
      }
    };

    fetchGalleryImages();
  }, []);


    const handleIngresarClick = () => {
      setMostrarInput(true);
    };

    
  const handleEliminarClick = async (imagenunidad_id, imageName) => {
    try {
      const response = await axios.delete(
        `${backendURL}/eliminar_imagenunidad/${imagenunidad_id}`
      );

      if (response.status === 200) {
        // Remove the deleted image from the galleryImages state
        setGalleryImages((prevImages) =>
          prevImages.filter((image) => image.imagenunidad !== imageName)
        );
      }
    } catch (error) {
      console.error("Error deleting gallery image:", error);
    }
  };

    return(
        <>
        
        <section>
        <h1 className="titulo-info-componenete-admi">Galeria Carrera</h1>
      </section>
      
      <section className="galeria-botones">
        <button className="boton-crear" onClick={handleIngresarClick}>
          Agregar
        </button>
        {mostrarInput && (
          <form className="agregar-imagen-galeria"  onSubmit={handleAgregarSubmit}>
            <input type="file" name="imagen" />
            <button className="agregarimagen" type="submit">
              <FcAddImage />
            </button>
            
          </form>
        )}
      </section>
      <section className="imagenes-incorporadas">
      <div className="container">
          <div className="row admi-galeria-curso">
            {galleryImages.map((image, index) => (
              <div className="col-4 admi-galeria-curso" key={index}>
                <div className="card imagen-carrera">
                  <div className="contenedor-imagen">
                    <img
                      className="imagen-galeria"
                      src={`${backendURL}/imagen_imagenunidad/${image.imagenunidad}`}
                      alt="Imagen del curso"
                    />
                  </div>
                  <button
                    className="eliminar-imagen"
                    onClick={() =>
                      handleEliminarClick(
                        image.id,
                        image.imagenunidad
                      )
                    }
                  >
                    <BiTrash />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
        </>
    )
}