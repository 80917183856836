import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
/* cursos y diplomado */
import { Admichefcursoydiplomado } from "../component/Administrador/chef";
import { Admiportadadiploycursopage } from "../component/Administrador/componentes/cursoydiplopage/portadacursoydiplo";
import { Cursosydiplomado } from "../component/Administrador/componentes/cursoydiplomado/tabla cursos y diplomados/crear-curso-diplomado";
import { ProximosCursos } from "../component/Administrador/componentes/cursoydiplomado/proximoscursos/proximoscursos";
import { CursosPasados } from "../component/Administrador/componentes/cursoydiplomado/cursospasados/cursos-pasados";
import { Testimonio } from "../component/Administrador/componentes/cursoydiplomado/testimonio/testimonios";
import { PDFUnidad } from "../component/Administrador/componentes/unidadgenero/pdfunidad";
/* home */
import { PortadaPrincipal } from "../component/Administrador/componentes/home/portada principal/portada-principal";
import { Portadasegunda } from "../component/Administrador/componentes/home/portada segunda/portada-segunda";
import { PDFCalendarioAcademico } from "../component/Administrador/componentes/home/pdfcalendarioacademico.js/pdf-caledario-academico";

/* admision */
import { VideoAdmision } from "../component/Administrador/componentes/admision/videos-admi/videoadmision";
import { PDFAdmision } from "../component/Administrador/componentes/admision/pdf malla beca/pdf";


/* carrera */
import { GaleriaCarrera } from "../component/Administrador/componentes/carrera/galeriacarrera";

/* escuela */
import { Autoridades } from "../component/Administrador/componentes/autoridades/autoridades";

/* especialidad */
import { TestimonioEspecialidad } from "../component/Administrador/componentes/especialidad/testimonio estudiante/testimonio-especialidad";
import { Mapa } from "../component/Administrador/componentes/especialidad/mapa especialidad/mapa";
import { PDFespecialidad } from "../component/Administrador/componentes/especialidad/pdf glosario/pdf-glosa-frances";

/* egresados */
import { Egresados } from "../component/Administrador/componentes/egresados/egresados";

/* noticias */
import { Noticias } from "../component/Administrador/componentes/blog/noticias";

/* recetas y tips */
import { RecetasTips } from "../component/Administrador/componentes/blog/noticias/recetas-tips";


/* docentes */
import { Areas } from "../component/Administrador/componentes/docente/area/areas";

/* unidad */
import { Imagenunidad } from "../component/Administrador/componentes/unidadgenero/imagenunidad";
import { NoticiasUnidad } from "../component/Administrador/componentes/unidadgenero/noticias link/noticias-unidad";


/* descuentos */
import { Descuentoficial } from "../component/Administrador/componentes/descuento/descuentooficial";

/* beneficio*/
import { Beneficio } from "../component/Administrador/beneficio/beneficio";



import "../styles/administrador/admi.css"
import { FaPowerOff } from "react-icons/fa";

import logoadmi from "../img/administrador/logoadmi.png";

export const AdminstradorEcole = ({ setShowNavbarFooter }) => {
  const navigate = useNavigate();
  const [showList, setShowList] = useState(false);
  const [showListhome, setShowListhome] = useState(false);
  const [showListadmi, setShowListadmi] = useState(false);
  const [showListacarrera, setShowListacarrera] = useState(false);
  const [showListaescuela, setShowListaescuela] = useState(false);
  const [showListaespe, setShowListaespe] = useState(false);
  const [showListaegresado, setShowListaegresado] = useState(false);
  const [showListadocente, setShowListadocente] = useState(false);
  const [showListaunidad, setShowListaunidad] = useState(false);
  const [showListanoticia, setShowListanoticia] = useState(false);
  const [showListareceta, setShowListareceta] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  
  // Objeto que asocia cada opción con su respectivo componente
  const componentMap = {
    "Chef Cursos y Diplomado": <Admichefcursoydiplomado />,
    "Portada Curso y diplomado":<Admiportadadiploycursopage/>,
    "Cursos y Diplomados":<Cursosydiplomado/>,
    "Proximos Cursos" : <ProximosCursos/>,
    "Cursos Pasados" : <CursosPasados/>,
    "Testimonios" : <Testimonio/>,
    "Portada Principal" : <PortadaPrincipal/>,
    "Portada Secundaria" : <Portadasegunda/>,
    "Videos Admision" : <VideoAdmision/>,
    "PDF Admision" : <PDFAdmision/>,
    "Galeria Carrera" : <GaleriaCarrera/>,
    "Autoridades" : <Autoridades/>,
    "Testimonio Especialidad" : <TestimonioEspecialidad/>,
    "Mapa" : <Mapa/>,
    "PDF Glosa y Frances" : <PDFespecialidad/>,
    "Egresados" : <Egresados/>,
    "Noticias" : <Noticias/>,
    "Recetas y Tips" : <RecetasTips/>,
    "Areas" : <Areas/>,
    "Unidad" : <PDFUnidad/>,
    "Imagen Unidad" : <Imagenunidad/>,
    "Noticia Unidad" : <NoticiasUnidad/>,
    "Descuento" : <Descuentoficial/>,
    "Beneficio" : <Beneficio/>,
    "CalendarioAcademico" : <PDFCalendarioAcademico/>
    // Asocia las demás opciones con sus respectivos componentes
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      navigate("/login");
    } else {
      axios
        .post(`${backendURL}/verify_token`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          // Token válido, el usuario puede acceder a la vista del administrador
        })
        .catch(function (error) {
          console.log(error);
          if (error.response && error.response.status === 401) {
            navigate("/login");
          }
        });
    }
  }, [navigate]);

  useEffect(() => {
    setShowNavbarFooter(false);
    return () => {
      setShowNavbarFooter(true);
    };
  }, [setShowNavbarFooter]);

  const handleLogout = () => {
    // Elimina el token de sessionStorage
    sessionStorage.removeItem("token");
    // Redirige al usuario a la página de inicio de sesión
    navigate("/login");
  };

  return (
    <div className="administrador-info">
     <main className="container admi">
  <section className="settings">
    
      <a className="close-login-administrador">
       <button className="buton-close-login" onClick={handleLogout}> <FaPowerOff className="close-login"/> </button>
      </a>
      
  </section>
  <aside className="lateral">
    <a id="toggler">
      <i className="material-icons">menu</i>
      
    </a>
    
    <section className="user-panel">
      <figure className="user-avatar">
        <img src={logoadmi} alt="" />
      </figure>
      <section className="user-info">
        <span className="user-name">Administrador<span id="arrow">▼</span></span>

      </section>
    </section>
    <nav className="navigation">
      <span className="title">Navegación</span>
      <ul className="menu admi">
        <li className="menu admi">
          <a href="#" className="active administrador" onClick={() => setShowListhome(!showListhome)}>
            <span>Home</span>
          </a>
          {showListhome && (
            <section className="admi-list">
              <ul className="admi-list">
                <li className="admi-list" onClick={() => setSelectedOption("Portada Principal")}>Portada  home</li>
                <li className="admi-list" onClick={() => setSelectedOption("Portada Secundaria")}>Portada Secundaria</li>
                <li className="admi-list" onClick={() => setSelectedOption("CalendarioAcademico")}>PDF Calendario Academico</li>
                {/* Agrega más elementos de la lista según sea necesario */}
              </ul>
            </section>
          )}
        </li>
        <li className="menu admi">
          <a href="#" className="active administrador" onClick={() => setShowListadmi(!showListadmi)}>
            <span>Admisión</span>
          </a>
          {showListadmi && (
            <section className="admi-list">
              <ul className="admi-list">
                <li className="admi-list" onClick={() => setSelectedOption("Videos Admision")}>Videos Admision</li>
                <li className="admi-list" onClick={() => setSelectedOption("PDF Admision")}>PDF Descargas</li>
                {/* Agrega más elementos de la lista según sea necesario */}
              </ul>
            </section>
          )}
        </li>
        <li className="menu admi">
          <a href="#" className="active administrador"onClick={() => setSelectedOption("Galeria Carrera")}>
            <span>Carrera</span>
          </a>
          
        </li>
        <li className="menu admi">
          <a href="#" className="active administrador"  onClick={() => setSelectedOption("Autoridades")}>
            <span>Escuela</span>
          </a>
        </li>
        <li className="menu admi">
          <a href="#" className="active administrador" onClick={() => setShowListaespe(!showListaespe)}>
            <span>Especialidad</span>
          </a>
          {showListaespe && (
            <section className="admi-list">
              <ul className="admi-list">
                <li className="admi-list" onClick={() => setSelectedOption("Testimonio Especialidad")}>Testimonio Especialidad</li>
                <li className="admi-list" onClick={() => setSelectedOption("Mapa")}>Mapa Especialidad</li>
                <li className="admi-list" onClick={() => setSelectedOption("PDF Glosa y Frances")}>PDF  Glosa y Frances</li>
              
              </ul>
            </section>
          )}
        </li>
        <li className="menu admi">
          <a href="#" className="active administrador" onClick={() => setShowList(!showList)}>
            <span>Cursos y Diplomados</span>
          </a>
          {showList && (
            <section className="admi-list">
              <ul className="admi-list">
                <li className="admi-list" onClick={() => setSelectedOption("Portada Curso y diplomado")}>Portada Curso y diplomado</li>
                <li className="admi-list" onClick={() => setSelectedOption("Chef Cursos y Diplomado")}>Chef Cursos y Diplomado</li>
                <li className="admi-list"onClick={() => setSelectedOption("Cursos y Diplomados")}> Cursos y Diplomados</li>
                <li className="admi-list" onClick={() => setSelectedOption("Proximos Cursos")}>  Proximos Cursos</li>
                <li className="admi-list" onClick={() => setSelectedOption("Cursos Pasados")}> Cursos Pasados</li>
                <li className="admi-list" onClick={() => setSelectedOption("Testimonios")}>Testimonios</li>
                {/* Agrega más elementos de la lista según sea necesario */}
              </ul>
            </section>
          )}
        </li>
        <li className="menu admi">
          <a href="#" className="active administrador" onClick={() => setSelectedOption("Egresados")}>
            <span>Egresados</span>
          </a>
        </li>
        <li className="menu admi">
          <a href="#" className="active administrador"onClick={() => setSelectedOption("Areas")} >
            <span>Docente</span>
          </a>
    
        </li>
        <li className="menu admi">
          <a href="#" className="active administrador"  onClick={() => setSelectedOption("Noticias")}>
            <span>Noticias</span>
          </a>
         
        </li>
        <li className="menu admi">
          <a href="#" className="active administrador"  onClick={() => setSelectedOption("Recetas y Tips")}>
            <span>Recetas y tips</span>
          </a>
        </li>
        <li className="menu admi">
          <a href="#" className="active administrador" onClick={() => setShowListaunidad(!showListaunidad)}>
            <span>Unidad de genero</span>
          </a>
          {showListaunidad && (
            <section className="admi-list">
              <ul className="admi-list">
                <li className="admi-list" onClick={() => setSelectedOption("Unidad")}>PDF Unidad</li>
                <li className="admi-list" onClick={() => setSelectedOption("Imagen Unidad")}>Imagen</li>
                <li className="admi-list" onClick={() => setSelectedOption("Noticia Unidad")}>Noticias Unidad</li>
              </ul>
            </section>
          )}
        </li>
        <li className="menu admi">
        <a href="#" className="active administrador" onClick={() => setSelectedOption("Descuento")}>
            <span>Descuento</span>
          </a>
        </li>
        <li className="menu admi">
        <a href="#" className="active administrador" onClick={() => setSelectedOption("Beneficio")}>
            <span>Beneficio</span>
          </a>
        </li>
        
      </ul>
    </nav>
   
  </aside>
  
</main>
{selectedOption && (
  <div className="componenete-editar-crear-eliminar">
    {componentMap[selectedOption]}
  </div>
)}
    </div>
  );
};