import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import { FcGallery } from "react-icons/fc";
import { Botoncrearcursosydiplomado } from "./boton-crear-curso-diplo";
import { Botoneditarcursosydiplomado } from "./boton-editar-curso-diplo";
import { Galeriacurso } from "./galeria-curso";

export const Cursosydiplomado = () => {
  const [cursos, setCursos] = useState([]);
  const [mostrarPopupCrear, setMostrarPopupCrear] = useState(false);
  const [mostrarPopupEditar, setMostrarPopupEditar] = useState(false);
  const [mostrarPopupGaleria, setMostrarPopupGaleria] = useState(false);
  const [cursoEditando, setCursoEditando] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(false); // Initialize updateTrigger to false
  const [nombreCursoGaleria, setNombreCursoGaleria] = useState("");
  const [cursoIdGaleria, setCursoIdGaleria] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    obtenerCursos();
  }, [updateTrigger]);

  const obtenerCursos = async () => {
    try {
      const timestamp = Date.now();
      const response = await axios.get(`${backendURL}/cardcursoydiplo?timestamp=${timestamp}`);
      setCursos(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarCurso = async (cursoId) => {
    try {
      const response = await axios.delete(
        `${backendURL}/cardcursoydiplo/${cursoId}`
      );
      console.log(response.data.message); // Mensaje de éxito

      // Mostrar un mensaje de confirmación al usuario
      alert("Curso eliminado correctamente");

      // Invertir el valor de updateTrigger para forzar la actualización
      setUpdateTrigger((prev) => !prev);
    } catch (error) {
      console.log(error);
    }
  };
  const abrirPopupCrear = () => {
    setCursoEditando(null);
    setMostrarPopupCrear(true);
  };

 
const abrirPopupGaleria = (cursoId, nombreCurso) => {
  setCursoEditando(null);
  setMostrarPopupGaleria(true);
  setNombreCursoGaleria(nombreCurso);
  setCursoIdGaleria(cursoId);
};

  
const cerrarPopupGaleria = () => {
  setMostrarPopupGaleria(false);
};
  
  const abrirPopupEditar = (cursoId) => {
    const cursoEdit = cursos.find((curso) => curso.id === cursoId);
    setCursoEditando(cursoEdit);
    setMostrarPopupEditar(true);
  };

  const cerrarPopupCrear = () => {
    setMostrarPopupCrear(false);

    // Actualizar la lista de cursos después de cerrar la ventana emergente de creación
    obtenerCursos();
  };
  const cerrarPopupEditar = () => {
    setMostrarPopupEditar(false);
    // Actualizar la lista de cursos después de cerrar la ventana emergente de edición
    obtenerCursos();
  };

  return (
    <div key={updateTrigger}>
      <section>
        <h1 className="titulo-info-componenete-admi">Cursos y Diplomados</h1>
      </section>
      <section className="boton-crear-chef-cursosydiplomado">
        <button className="boton-crear" onClick={abrirPopupCrear}>
          Crear
        </button>
      </section>
      <section className="lista-chef-cursosydiplomado">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Nombre Curso o Diplomado</th>
              <th className="titulobd">Categoria</th>
              <th className="titulobd">imagen portada</th>
              <th className="titulobd">Acciones</th>
            </tr>
          </thead>
          <tbody key={cursos.length}>
            {cursos.map((curso) => (
              <tr className="info-bd" key={curso.id}>
                <td className="info-bd">{curso.nombre_curso}</td>
                <td className="info-bd">{curso.categoria}</td>
                <td className="info-bd">
                
                <img
  className="imagen-info-admi"
  src={`${backendURL}/imagen_cardcursoydiplo/portada_curso/${curso.imagen_portada_curso}`}
  alt="jpg"
/>
                  </td>
                  
                <td className="edicion">
                  <button
                    className="boton-editar"
                    onClick={() => abrirPopupEditar(curso.id)}
                  >
                    <BiEdit />
                  </button>
                  <button
                    className="boton-eliminar"
                    onClick={() => eliminarCurso(curso.id)}
                  >
                    <BiTrash />
                  </button>
                  <button
                    className="boton-galleria-curso"
                    onClick={() => abrirPopupGaleria(curso.id, curso.nombre_curso)}
                  >
                    <FcGallery />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      {/* Renderizar el PopupCrear */}
      {mostrarPopupCrear && (
        <div className="popup-admi">
          <div className="popup-content-admi">
            <button className="close-button-admi" onClick={cerrarPopupCrear}>
              X
            </button>
            <Botoncrearcursosydiplomado
              onClose={cerrarPopupCrear} // Pasa la función de cierre a través de la prop onClose
            />
          </div>
        </div>
      )}
      {/* Renderizar el PopupEditar */}
      {mostrarPopupEditar && (
        <div className="popup-admi">
          <div className="popup-content-admi">
            <button className="close-button-admi" onClick={cerrarPopupEditar}>
              X
            </button>
            <Botoneditarcursosydiplomado
              cursoId={cursoEditando ? cursoEditando.id : null}
              onClose={cerrarPopupEditar} // Pasa la función de cierre a través de la prop onClose
            />
          </div>
        </div>
      )}
       {mostrarPopupGaleria && (
        <div className="popup-admi">
          <div className="popup-content-admi">
          <button className="close-button-admi" onClick={cerrarPopupGaleria}>
              X
            </button>
            <Galeriacurso cursoId={cursoIdGaleria} nombreCurso={nombreCursoGaleria} />
          </div>
        </div>
      )}
    </div>
  );
};