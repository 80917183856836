import React, { useState, useEffect } from "react";
import axios from "axios";

import "../../styles/docentes/docentes.css";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import info from "../../img/docentes/info.png"

import { GrFormClose } from "react-icons/gr";

/* imagenes */
import linea from "../../img/docentes/linea.png";

export const Carddocente = ({ areaId }) => {
  const [docentes, setDocentes] = useState([]);
  const [isMobileView, setIsMobileView] = useState(false);
  const [selectedDocente, setSelectedDocente] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 10, left: 0, bottom: 50 });
  const backendURL = process.env.REACT_APP_BACKEND_URL;



  useEffect(() => {
    axios
      .get(`${backendURL}/obtener_docentes`, {
        params: { area_id: areaId.id } // Usar areaId.id para obtener el ID del área
      })
      .then((response) => {
        setDocentes(response.data.docentes);
      })
      .catch((error) => {
        console.error("Error al obtener docentes:", error);
      });
  }, [areaId.id]);

  const handleDocenteClick = (docente, index, event) => {
    setSelectedDocente(docente);
  };

  const deselectDocente = () => {
    setSelectedDocente(null);
  };

  
  useEffect(() => {
    // Función para actualizar el estado de isMobileView en función del ancho de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Cambia a "true" si el ancho es menor o igual a 700px
    };

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Llama a handleResize() una vez al cargar la página para establecer el estado inicial
    handleResize();

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container cuerpo docente">
      <div className="contendor-area-docente">
        {areaId && (
        <div className="titulo docente">
       
          <img
           src={isMobileView ?`${backendURL}/imagen_area_movil/${areaId.imagen_responsive}` :`${backendURL}/imagen_area/${areaId.imagen}`}
           
            className="w-100 pasteleria"
            alt="Área Imagen"
          />
        </div>
      )}
</div>


     
      <div
        className="row row-cols-1 row-cols-md-4 g-4 docentescard"
      >  {docentes.length > 0 ? (
        docentes.map((docente, index) => (
        <div
          className="col-md-6 col-lg-4 col-xl-4 col-xll-3 docentescard"
          key={docente.id}
          onClick={(event) => handleDocenteClick(docente, index, event)}
        >
          {/* Renderizar la información del docente */}
          <div className="flip-card docentescard">
            <div className="flip-card-inner docentescard">
              <div className="flip-card-front docentescard">
                <div className="img-docente-info">
                  <img
                    src={docente.imagen_url}
                    className="w-100 docente"
                    alt="Sección"
                  />
                </div>
                <div className="content-docentes">
                      <p className="heading-docentes">
                        <button className="boton-icono-docentes" >
                        <img src={info} className="imagen-info-docente" alt="" />
                        </button>

                      </p>
                      <h5 className="icono-docente">INFORMACIÓN</h5>
                    </div>
              </div>
            </div>
          </div>
          <h2 className="nombredocente">{docente.nombre_docente}</h2>
          <h2 className="titulodechef">{docente.tipo_docente}</h2>
        </div>
      ))
      ) : (
        <p>No hay docentes disponibles para esta área.</p>
      )}
    </div>
    {selectedDocente && (
     
        <div
          className="docente-popup"  
          
        >
          <div className="docente-popup-inner" >
            <div className="flip-card-back docentescard" >
              <button
                className="close-button-docente"
                onClick={deselectDocente}
              >
                <GrFormClose className="closedocente" />
              </button>
              <div className="img-docente-info2">
                <img
                  src={selectedDocente.imagen_url}
                  className="w-100 docente2"
                  alt="Sección"
                />
              </div>
              <h2 className="nombredocente2">
                {selectedDocente.nombre_docente}
              </h2>
              <h2 className="titulodechef2">
                {selectedDocente.tipo_docente}
              </h2>
              <img src={linea} className="w-20 linea separador" />
              <p className="info docentescard">
                <DangerouslySetHTMLContent
                  html={selectedDocente.descripcion}
                />
              </p>
            </div>
          </div>
        </div>
      
    )}
    </div>
   
  );
};

export const Docentescar = () => {
  const [areasData, setAreasData] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Fetch áreas from the backend API
    axios
      .get(`${backendURL}/obtener_area`)
      .then((response) => {
        setAreasData(response.data.area);
      })
      .catch((error) => {
        console.error("Error al obtener áreas:", error);
      });
  }, []);


  return (
    <div className="docentes">

      {areasData.map((area) => (
        <Carddocente key={area.id} areaId={area} /> 
      ))}
    </div>
  );
};
