import React, { useState, useEffect,  } from "react";
import { Link } from "react-router-dom";
import "../styles/index.css";
import { TfiClose } from "react-icons/tfi";
import { FormularioAdmision } from "./formulario-admision";
import matricula from "../img/iconopaginas/botonmovil.png"
export const Botonadmimovil = () => {
 const [showForm, setShowForm] = useState(false);
 const toggleForm = () => {
    setShowForm(!showForm);
  };
  const cerrarVentana = () => {
    setShowForm(false);
  };
    return(
       
        <>
         <Link to="/admision" className="contenedor-icono-movil" >
      <img src={matricula}  onClick={toggleForm} className="iconoadminmovil w-100"  />

    </Link>
    {showForm && ( 
                       <div className="popups-admision">
                          
            <button
              className="close-admision
              "
              onClick={cerrarVentana}
              aria-label="Cerrar"
            >
              
                <TfiClose />
              
            </button>

            
            <FormularioAdmision  />
                         
                        
                         </div>
                      )}
        </>
    )
}