import React from "react";
import "../styles/index.css";
import { FaWhatsapp } from "react-icons/fa";

export const Wtssp = () => {
  return (
    <a
      href="https://wa.me/56963046360?text=Me%20gustaría%20saber%20por%20información%20general"
      className="whatsapp"
      target="_blank"
    >
      <FaWhatsapp className="whatsapp-icon" />
      
    </a>
  );
};
