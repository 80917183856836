import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";

import { Botoncreartestimonio } from "./boton-crear-testimonio";
import { Editartestimonio } from "./boton-editar-testimonio";


export const Testimonio = () =>{
    const [testimonios, setTestimonios] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editTestId, setEditTestId] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

    const togglePopup = () => {
        setShowPopup(!showPopup);
      };
      
      const closePopups = () => {
        setShowCreatePopup(false);
        setShowEditPopup(false);
      };
    
      const toggleEditPopup = (testimonioId) => {
        setShowEditPopup(!showEditPopup);
        setEditTestId(testimonioId);
      };


      useEffect(() => {
        // Fetch testimonios from the backend API
        axios.get(`${backendURL}/obtener_testimonios`)
          .then(response => {
            setTestimonios(response.data);
          })
          .catch(error => {
            console.error("Error fetching testimonios:", error);
          });
      }, []);

      const handleDelete = async (testimonioId) => {
        try {
            const response = await axios.delete(`${backendURL}/eliminar_testimonio/${testimonioId}`);
            console.log(response.data);
            // You can add additional logic here, like updating the UI after deletion
        } catch (error) {
            console.error(error);
            // Handle error cases
        }
    };

    return(
        <><section>
        <h1 className="titulo-info-componenete-admi">Testimonios</h1>
      </section>
      <section className="boton-crear-chef-cursosydiplomado">
        <button className="boton-crear" onClick={togglePopup} >
          Crear
        </button></section> 
        {showPopup && (
        <div className="popup-admi">
          <div className="popup-content-admi">
            <Botoncreartestimonio onClose={togglePopup} />
          </div>
        </div>
      )}
        
        <section className="lista-chef-cursosydiplomado">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Nombre Testimonio</th>
              <th className="titulobd">Imagen Testimonio</th>
              <th className="titulobd">Descripcion</th>
              <th className="titulobd">Curso Realizado</th>
              <th className="titulobd">Acciones</th>

            </tr>
          </thead>
          {testimonios.map(testimonio => (
    <tr key={testimonio.id} className="info-bd">
      <td className="info-bd">{testimonio.nombre_testimonio}</td>
      <td>
        <img
          className="imagen-info-admi"
          src={testimonio.imagen_url}
          alt="Imagen Testimonio"
        />
      </td>
      <td className="info-bd">{testimonio.descripcion}</td>
      <td className="info-bd">{testimonio.curso_realizado}</td>
      <td className="edicion">
        <button className="boton-editar" onClick={() => toggleEditPopup(testimonio.id)} >
          <BiEdit />
        </button>
        <button className="boton-eliminar" onClick={() => handleDelete(testimonio.id)}>
          <BiTrash />
        </button>
      </td>
    </tr>
  ))}
        </table></section>
        {showEditPopup && (
        <div className="popup-admi">
          <div className="popup-content-admi">
            <Editartestimonio testimonioId={editTestId} onClose={closePopups} />
          </div>
        </div>
      )}</>
    )

}