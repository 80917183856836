import React, { useState, useEffect } from "react";
import "../../styles/cursoydiplomado/cursoydiplomado.css";

/* imagenes */
import portadacurso0 from "../../img/cursosydiplomados/portadacurso.jpg";
import portada2 from "../../img/cursosydiplomados/imagenes portada/CURSOS-DIPLOMADOS-MOVIL.jpg"
export const Segundotitulo =() => {

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado de isMobileView en función del ancho de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Cambia a "true" si el ancho es menor o igual a 700px
    };

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Llama a handleResize() una vez al cargar la página para establecer el estado inicial
    handleResize();

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
    return(
        <>
         <section className="titulocurso-diplomado-banner">
         <img
        src={isMobileView ? portada2: portadacurso0}
        alt="Portada Beneficio"
        className="portada-beneficio-img"
      />
      </section> 
        </>
    )
}