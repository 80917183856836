import React from "react";

/* imagenes */
import separador from "../../img/nosotros escuela/separadorescuela.png";
import frase from "../../img/nosotros escuela/frase.png";
export const Bonappetit = () => {
  return (
    <div className="container  info-bonappetit">
      <h3 className="info-principal-bonappetit">
      Nuestro cuerpo docente, está altamente capacitado y cuenta con especializaciones en cada área. 
Mantienen constantemente sentido de compromiso y búsqueda de innovación, teniendo un
profundo compromiso por transmitir e inspirar.
      </h3>
      <h3 className="info-principal-bonappetit-final">
      Queremos entregar la formación que tu chef interior necesita.
      </h3>
      <img src={separador} className="w-30 separador-info-escuela" />
      <h1 className="info-chef-feliz">
        QUEREMOS QUE TE CONVIERTAS EN UN CHEF FELIZ
      </h1>
      <div className=" frase-bonappetit">
      <img src={frase} className="w-65 frase-bonappetit" /></div>
    </div>
  );
};
