import React from "react";
import axios from "axios";

import { VideoComponent } from "./videodescargable";

/* imagenes */
import glosario from "../../img/especialidad/glosario-v2.png";
import frances from "../../img/especialidad/frances-v2.png";

export const Footerglosario = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;


  return (
    <>
      <div className=" footerpasantia">
        <h1 className="titulo-footerpasantia">DOCUMENTOS PARA DESCARGAR</h1>
        <div className="row footerpasantia flex-column flex-sm-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 footerpasantia">
          <a
                href={`${backendURL}/static/glosa/glosario.pdf`}
                target="_blank"
              >
              <button className="glosario" >
                <img src={glosario} className="w-100 glosario" alt="" />
              </button>
            </a>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 footerpasantia1">
          <a
                href={`${backendURL}/static/frances/frances-instrumental.pdf`}
                target="_blank"
              >
              <button className="frances" >
                <img src={frances} className="w-100 glosario" alt="" />
              </button>
           </a>
          </div>
        </div>
      </div>
      <section className="video-pasantia-final">
        <VideoComponent />
      </section>
    </>
  );
};
