import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

/* componente */
import { PortadaEstructuraBlog } from "./componente estrcublog/portada-estruc-blog";
import { Bienvenidaestrucblog } from "./componente estrcublog/tituloidblog";
import { Infoestrucblog } from "./componente estrcublog/inforecetablog";
import { Bannerfooterblog } from "./componente estrcublog/bannerblogestruc";
import { Articuloblog } from "./componente estrcublog/articulo-relacionado";
export const EstructuraBlog = () => {

  const { id } = useParams();
  const [recetaData, setRecetaData] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    obtenerDatosreceta();
  }, []);

  const obtenerDatosreceta = async () => {
    try {
      const response = await axios.get(
        `${backendURL}/obtener_recetas/${id}`
      );
      const receta = response.data;
      setRecetaData(receta);
    } catch (error) {
      console.error(error);
    }
  };

  if (!recetaData) {
    // Aquí podrías mostrar un spinner o un mensaje de carga mientras se obtienen los datos
    return <div>Cargando...</div>;
  }
  return (
    <div>
      <section className=" portada artblog">
      <PortadaEstructuraBlog  receta={recetaData}/>
      </section>
      <section className="titulo bienvenida estrcblog">
        <Bienvenidaestrucblog receta={recetaData} />
      </section>
      <section className="info estrcblog">
        <Infoestrucblog receta={recetaData} />
      </section>
     
      <section className="articulo">
        <Articuloblog  />
      </section>
    </div>
  );
};
