import React from "react";
import "../../../styles/estructura/estrucblog.css";

import porcion from "../../../img/estructuras/blog/porcion.png";
import linea from "../../../img/docentes/linea.png";

export const Bienvenidaestrucblog = ({receta}) => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  

  return (
    <div>
      <section className="container bienvenida estructura blog">
        <div className="row row-cols-1 row-cols-md-2 g-4 estrucblog">
          <div className="bienvenida estructura blog">
            <div className="col estrucblogimagen">
              <div className="contenedor-imagen-blog-estructura">
                <img src={`${backendURL}/imagen_receta/${receta.imagen}`} className="w-100 imagen-receta-estructura" alt="Imagen de receta" />
              </div>
            </div>
          </div>
          <div className="col estrucblog">
            <div className="info-receta-estructura-bienvenida">
              <p className="categoria-receta">{receta.categoria}</p> {/* Mostrar la categoría "Receta" */}
              <div className="imagen-linea-estrcutura-blog">
                <img src={linea} className="w-100 linea-estrcutura-blog" alt="Línea" />
              </div>
              <h1 className="titulo bienvenida estructura-blog">{receta.titulo}</h1>
              <h2 className="subtitulo bienvenida estructura blog">{receta.descripcion_principal}</h2>
              <div className="row servir-estructura">
                <div className="row porciones-estructura">
                  <div className="col porciones-estructura">
                    <div className="porcion-imagen">
                      <img src={porcion} className="w-100 porcion" alt="Imagen de porción" />
                    </div>
                  </div>
                  <div className="col porciones-estructura">
                    <div className="info-porciones-estructura">
                      <h3 className="titulo-rendimiento">Rendimiento:</h3>
                      <h5 className="titulo-rendimiento">{receta.porciones} Porciones</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
