import React, { useState, useEffect } from "react";
import axios from "axios";

export const Botoneditarautoridad = ({ autoridadId, cerrarVentana }) => {
  const [nombre, setNombre] = useState("");
  const [imagen, setImagen] = useState(null);
  const [cargo, setCargo] = useState("");
  const [correo, setCorreo] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;
 

  useEffect(() => {
    obtenerAutoridad();
  }, []);

  const obtenerAutoridad = async () => {
    try {
      const response = await axios.get(`${backendURL}/obtener_autoridad/${autoridadId}`);
      const autoridadData = response.data;
      setNombre(autoridadData.nombreautoridad);
      setCargo(autoridadData.cargo);
      setCorreo(autoridadData.correo);
      // Puedes guardar la imagen en el estado si es necesario
    } catch (error) {
      console.error("Error al obtener la autoridad", error);
    }
  };

 
  const handleImageChange = (event) => {
    setImagen(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("nombreautoridad", nombre);
    formData.append("cargo", cargo);
    formData.append("correo", correo);
    if (imagen) {
      formData.append("imagenautoridad", imagen);
    }

    try {
      const response = await axios.put(`${backendURL}/editar_autoridad/${autoridadId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);

      cerrarVentana();
    } catch (error) {
      console.error("Error al editar la autoridad", error);
    }
  };
  return (
    <>
      <div className="titulo-admi-info">
        <h2>Editar Autoridad</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input className="input-admi" type="file" id="imagen" onChange={handleImageChange} />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="nombre">
            Nombre:
          </label>
          <input
            className="input-admi"
            type="text"
            id="nombre"
            value={nombre} // Establece el valor del campo
            onChange={(e) => setNombre(e.target.value)} // Actualiza el estado al cambiar el valor
          />
        </div>
        <div>
          <label htmlFor="descripcion">Cargo:</label>
          <input
            className="input-admi"
            type="text"
            id="cargo"
            value={cargo} // Establece el valor del campo
            onChange={(e) => setCargo(e.target.value)} // Actualiza el estado al cambiar el valor
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            Correo:
          </label>
          <input
            className="input-admi"
            type="text"
            id="correo"
            value={correo} // Establece el valor del campo
            onChange={(e) => setCorreo(e.target.value)} // Actualiza el estado al cambiar el valor
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};