import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Slider from "react-slick";


/* imagenes */
import separador from "../../img/nuestra-carreras/lineacarrera.png";
import logomedalla from "../../img/home/00LogoHome.png";
import logomedalla2 from "../../img/home/logo-maitres-cuisiniers.png";
import bannerdocente from "../../img/nosotros escuela/banner-docente-ecole.png";
import bannermovil from "../../img/nosotros escuela/banner-docente-movil.png";


export const FotterCarerra = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado de isMobileView en función del ancho de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Cambia a "true" si el ancho es menor o igual a 700px
    };

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Llama a handleResize() una vez al cargar la página para establecer el estado inicial
    handleResize();

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  
  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const response = await axios.get(
          `${backendURL}/obtener_galeriacarrera`
        );
        setGalleryImages(response.data.galeriacarrera);
      } catch (error) {
        console.error("Error fetching gallery images:", error);
      }
    };

    fetchGalleryImages();
  }, []);

 
  
  
  const obtenerInformacionPDF = async () => {
    try {
      const response = await axios.get(`${backendURL}/static/pdf/mallacurrricular.pdf`, {
        responseType: 'blob',  // Indicamos que esperamos una respuesta de tipo blob (archivo binario)
      });

      // Crear una URL temporal para el blob
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      // Crear un enlace temporal y simular un clic para descargar el PDF
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'mallacurrricular.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el PDF:", error);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="todobannermalla">
      <div className=" container bannermalla">
        <div class="card Mallados">
          <h1 className="titulo-malla-cv">Malla Curricular</h1>
          <div className="col info-Mallacurricular">
            <h3 className="info-malla-cv">
              Conoce todo sobre cada semestre y asignaturas
            </h3>
          </div>

          <div className="col info-Mallacurricular-carrera">
            
              <button className="button-dercarga-malla" type="button" onClick={obtenerInformacionPDF}>
                <span className="button__text-dercarga-malla">
                  <p className="titulo-boton-descarga">Descargar</p>
                </span>
                <span className="button__icon-dercarga-malla">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 35 35"
                    id="bdd05811-e15d-428c-bb53-8661459f9307"
                    data-name="Layer 2"
                    class="svg"
                  >
                    <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
                    <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
                    <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
                  </svg>
                </span>
              </button>
           
          </div>
        </div>
      </div>
      <div className="container infobannermalla">
        <div className="bannermalla">
          <div className="titulofinalcarrera">
            <h1 className="titulos bannermalla">NUESTRAS ASOCIACIONES</h1>
            <h2 className="titulos bannermalla">
              en Escuela Culinaria Francesa
            </h2>
            <div className="imagen-linea-carrera">
            <img className="w-20 separadorcarrera" src={separador} />
          </div>
          </div>
          <div className="row bannermalla ">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 bannermalla2">
              <img
                className="bannermalla logo w-100"
                src={logomedalla2}
                alt=""
              />
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 bannermalla2">
              <h1 className="titulobannermalla">
                {" "}
                Maîtres Cuisiniers de France
              </h1>
              <p className=" infobannermalla">
                Muchos de nuestros titulados, actualmente, se desempeñan en
                prestigiosos establecimientos gastronómicos de nivel mundial
                gracias a nuestro convenio con los "Maîtres Cuisiniers de
                France“, así como nuestros estudiantes quienes tienen la
                posibilidad de realizar su pasantía durante el tercer año.
              </p>
              <p className=" infobannermalla">
                Esta asociación francesa nació en 1951, hace más de 60 años y
                agrupa a más de 450 chefs distribuidos por todo el territorio
                francés y regiones de ultramar. Desde entonces, han trabajado
                para apoyar y promover el arte culinario francés, en Francia e
                internacionalmente. Una doble misión encarnada por los Chefs
                miembros, embajadores en sus establecimientos, de esta
                permanente búsqueda de la excelencia y la transmisión de este
                legado culinario a los nuevos aprendices. Esto nos convierte en
                la única escuela en el mundo que entrega la certificación de
                estudio respaldada por esta reconocida organización.
              </p>
            </div>
          </div>
          <div className="row bannermalla2  ">
            <div className=" bannermalla2 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 bannermalla2">
              <img
                className="bannermalla logo w-100"
                src={logomedalla}
                alt=""
              />
            </div>
            <div className=" bannermalla2 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 dosbannermalla">
              <h1 className="titulobannermalla"> Christian Vabret</h1>
              <div className="info-medalla-carrera">
                <p className=" infobannermalla1">
                  Presidente de la asociación de panaderos de Francia.
                </p>
                <p className="infobannermalla1">
                  Fundador de la Copa Mundial de Panadería.
                </p>
                <p className=" infobannermalla1">
                  Presidente y fundador de la escuela de boulangerie-pâtisserie,
                  con sedes en Arillac – Francia y Shangai – China.
                </p>
                <p className=" infobannermalla1">
                  Propietario de Petit Versailles , le Marais - París
                </p>
                <p className=" infobannermalla1">
                  MOF en Panadería, titulo que se otorga al Campeón de Francia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="docentes banner dos">
        {/* banner autoridades */}
        <Link to="/docentes">
          {" "}
          <div className="container dosdocentes">
            <img
             src={isMobileView ? bannermovil : bannerdocente}
              className="bannerdocentes w-100"
              onClick={() => window.scrollTo(0, 0)}
            />
          </div>
        </Link>
      </div>
      <div className=" container-fluid    imagenes de carrera">
        {" "}
        <div className=" carrera-imagen">
          <Slider {...settings}>
          {galleryImages.map((imageName, index) => (
            <div className="card carrera" key={index}>
              <img
                src={`${backendURL}/imagen_galeriacarrera/${imageName.galeriacarrera}`}
                alt={`Imagen ${index}`}
              />
            </div>
          ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
