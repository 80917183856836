import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiEdit, BiTrash } from "react-icons/bi";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import { BotoncrearReceta } from "./crear-receta-tips";
import { Botoneditareceta } from "./editar-receta-tips";





export const RecetasTips = () =>{
    const [recetas, setRecetas] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editTestId, setEditTestId] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL

    const togglePopup = () => {
        setShowPopup(!showPopup);
      };
      
      const closePopups = () => {
        setShowCreatePopup(false);
        setShowEditPopup(false);
      };
    
      const toggleEditPopup = (recetaId) => {
        setShowEditPopup(!showEditPopup);
        setEditTestId(recetaId);
      };


      useEffect(() => {
        // Fetch testimonios from the backend API
        axios.get(`${backendURL}/obtener_recetas`)
          .then(response => {
            setRecetas(response.data);
          })
          .catch(error => {
            console.error("Error fetching testimonios:", error);
          });
      }, []);

      const handleDelete = async (recetaId) => {
        try {
            const response = await axios.delete(`${backendURL}/eliminar_receta/${recetaId}`);
            console.log(response.data);
            // You can add additional logic here, like updating the UI after deletion
        } catch (error) {
            console.error(error);
            // Handle error cases
        }
    };

    return(
        <><section>
        <h1 className="titulo-info-componenete-admi">Recetas y tips</h1>
      </section>
      <section className="boton-crear-chef-cursosydiplomado">
        <button className="boton-crear" onClick={togglePopup} >
          Crear
        </button></section> 
        {showPopup && (
        <div className="popup-admi">
          <div className={`popup-content-admi ${showPopup ? 'scroll-noticia' : ''}`}>
       <BotoncrearReceta onClose={togglePopup} />  
          </div>
        </div>
      )}
        
        <section className="lista-chef-cursosydiplomado">
        <table className="lista">
          <thead>
            <tr>
              <th className="titulobd">Categoria</th>
              <th className="titulobd">Imagen </th>
              <th className="titulobd">Titulo </th>
              <th className="titulobd">Descripcion principal</th>
              <th className="titulobd">porciones</th>
              <th className="titulobd">preparacion</th>
              <th className="titulobd">ingredientes</th>
              <th className="titulobd">Acciones</th>

            </tr>
          </thead>
          {recetas.map(receta => (
    <tr key={receta.id} className="info-bd">
      <td className="info-bd">{receta.categoria}</td>
      <td>
        <img
          className="imagen-info-admi"
          src={`${backendURL}/imagen_receta/${receta.imagen}`}
          alt="Imagen "
        />
      </td>
      <td className="info-bd">{receta.titulo}</td>
      <td className="info-bd">{receta.descripcion_principal} </td>
      <td className="info-bd">{receta.porciones}</td>
      <td className="info-bd"> {receta.preparacion ? <DangerouslySetHTMLContent html={receta.preparacion}/> : null}</td>
      <td className="info-bd">{receta.ingredientes ? <DangerouslySetHTMLContent html={receta.ingredientes} /> : null}</td>
      <td className="edicion">
        <button className="boton-editar"  onClick={() => toggleEditPopup(receta.id)} >
          <BiEdit />
        </button>
        <button className="boton-eliminar"onClick={() => handleDelete(receta.id)} >
          <BiTrash />
        </button>
      </td>
    </tr>
  ))}
        </table></section>
        {showEditPopup && (
        <div className="popup-admi">
          <div className={`popup-content-admi ${showEditPopup ? 'scroll-noticia' : ''}`}>
           
          <Botoneditareceta recetaId={editTestId} onClose={closePopups} />  
          </div></div>
        
      )}</>
    )

}