import React from "react";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';


/* imagenes */
import linea from "../../../img/docentes/linea.png";

export const Infoestructips = ({receta}) => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  
  return (
    <div>
      <section className="container info arttips">
        <div className=" info arttips">
          <div className="row info arttips">
            <div className="col-sm-5  info arttips">
            
              <div className="img-artips">
                <img src={`${backendURL}/imagen_receta/${receta.imagen}`} alt="" className="w-100" />
              </div>
            </div>

            <div className="col-sm-6 info arttips">
            <p className="categoria-tips">{receta.categoria}</p> {/* Mostrar la categoría "Receta" */}
              <div className="imagen-linea-estrcutura-blog">
                <img
                  src={linea}
                  className="w-100 linea-estrcutura-blog"
                  alt="Línea"
                />
              </div>
              <div className="titulo arttips info">
                <h1 className="titulo arttips nombre">
                {receta.titulo}
                </h1>
                <h2 className="subtitulo bienvenida estructura blog">
                {receta.descripcion_principal}
                </h2>
              </div>
              <h1 className="titulo instructivoarttips ">PREPARACIÓN</h1>
              <hr className="separador instructivoarttips" />
              <div className="paso a paso info arttips">
                
                  <li className="info arttip nombre">
                  <DangerouslySetHTMLContent html={receta.preparacion} />
                  </li>
                
              </div>{" "}
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
