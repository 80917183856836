// EditarBotonCrearChef.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const EditarBotonCrearChef = ({ cerrarVentana, chefId, editarChef }) => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [chefData, setChefData] = useState({
    nombre: '',
    descripcion: '',
    url: '',
    imagen: null
  });

  useEffect(() => {
    obtenerChef();
  }, [chefId]);

  const obtenerChef = () => {
    axios
      .get(`${backendURL}/obtener_chef/${chefId}`)
      .then((response) => {
        setChefData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('nombre', chefData.nombre);
    formData.append('descripcion', chefData.descripcion);
    formData.append('url', chefData.url);
    formData.append('imagen', chefData.imagen);

    axios
      .put(`${backendURL}/editar_chef/${chefId}`, formData)
      .then((response) => {
        console.log(response.data);
        cerrarVentana();
        // Realizar cualquier acción adicional después de la edición exitosa del chef
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (e) => {
    if (e.target.id === 'imagen') {
      setChefData({
        ...chefData,
        imagen: e.target.files[0]
      });
    } else {
      setChefData({
        ...chefData,
        [e.target.id]: e.target.value
      });
    }
  };

  return (
    <>
      <div className="titulo-admi-info">
        <h2>Editar Chef cursos y diplomados</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen">
            Imagen:
          </label>
          <input className="input-admi" type="file" id="imagen" onChange={handleChange} />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="nombre">
            Nombre:
          </label>
          <input
            className="input-admi"
            type="text"
            id="nombre"
            value={chefData.nombre}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="descripcion">Descripción:</label>
          <textarea
            className="input-admi"
            id="descripcion"
            value={chefData.descripcion}
            onChange={handleChange}
          ></textarea>
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="url">
            URL:
          </label>
          <input
            className="input-admi"
            type="text"
            id="url"
            value={chefData.url}
            onChange={handleChange}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
    </>
  );
};