import React, { useState, useEffect } from "react";
import { BiTrash } from "react-icons/bi";
import { useCart } from "./CartContext";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/pago/pago.css";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useResultado } from "./useRespuesta";


/* imagen */
import imagenlogo from "../img/home/logoEcole.png";

export const Pago = ({ setShowNavbarFooter }) => {
  useEffect(() => {
    setShowNavbarFooter(false);
    return () => {
      setShowNavbarFooter(true);
    };
  }, [setShowNavbarFooter]);
  const [countdown, setCountdown] = useState(3);
  const [scrollEnabled, setScrollEnabled] = useState(false);
  const { cartItems } = useCart();
  const [isChecked, setIsChecked] = useState(false); // Checkbox state
  const navigate = useNavigate(); // Obtiene la función navigate
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [productQuantities, setProductQuantities] = useState({});
  const quantitiesObject = JSON.parse(queryParams.get("ids") || "{}");
  const discountApplied = parseFloat(queryParams.get("discountApplied")) || 0;
  const [descuento, setDescuento] = useState(0);
  const discountValues = queryParams.get("discountValue");
  const [isPercentageDiscount, setIsPercentageDiscount] = useState(false);
  const [tuNombre, setTuNombre] = useState("");
  const [mostrarPopupState, setMostrarPopup] = useState(false);
  const [mostrarErrorPopup, setMostrarErrorPopup] = useState(false);
  const [nombreInvitado, setNombreInvitado] = useState("");
  const [dedicatoria, setDedicatoria] = useState("");
  const [tarjetaRegaloInfoPresente, setTarjetaRegaloInfoPresente] =
    useState(false);
  const [codigoDescuento, setCodigoDescuento] = useState("");
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const { setResultadoTransaccion } = useResultado();


  
  const [token, setToken] = useState(
    "0ouVOxvAZU7NKtJp9NfPywS9cT19VAKmX1qPXqO1fC78bsiM"
  );
  const [ordenPago, setOrdenPago] = useState("");
  const [resultadoURL, setResultadoURL] = useState(
    ""
  );
  const [retornoURL, setRetornoURL] = useState("https://www.qaecoleculinaria.aleramatech.com/resultado");
  const [formulario, setFormulario] = useState({
    nombreCompleto: "",
    rut: "",
    telefono: "",
    email: "",
    // Otros campos del formulario
  });

  const [tarjetaRegaloInfo, setTarjetaRegaloInfo] = useState({
    nombreInvitado: '',
    tuNombre: '',
    dedicatoria: '',
  });

  const obtenerCursoId = () => {
    // Obtener los IDs de los cursos del objeto quantitiesObject
    const cursoIds = Object.keys(quantitiesObject);
    // Devolver los IDs en formato de array
    return cursoIds;
  };

  // Llamada a la función obtenerCursoId
  const idsCursos = obtenerCursoId();

  useEffect(() => {
    const savedTuNombre = localStorage.getItem("tuNombre");
    const savedNombreInvitado = localStorage.getItem("nombreInvitado");
    const savedDedicatoria = localStorage.getItem("dedicatoria");
  
    if (savedTuNombre || savedNombreInvitado || savedDedicatoria) {
      setTarjetaRegaloInfoPresente(true);
      setTuNombre(savedTuNombre || "");
      setNombreInvitado(savedNombreInvitado || "");
      setDedicatoria(savedDedicatoria || "");
    } else {
      setTarjetaRegaloInfoPresente(false);
    }
  
    // Agrega este bloque de código para borrar los datos al cargar la página
    localStorage.removeItem("tuNombre");
     // Si nombreInvitado está presente, establece su valor como el valor inicial de nombreCompleto
  if (savedNombreInvitado) {
    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      nombreCompleto: savedNombreInvitado,
    }));
  }
    localStorage.removeItem("nombreInvitado");
    localStorage.removeItem("dedicatoria");
  }, []);

  useEffect(() => {
    if (discountValues !== null) {
      const parsedDiscountValue = parseFloat(discountValues.replace(/,/g, "")); // Elimina las comas en caso necesario
      setIsPercentageDiscount(parsedDiscountValue < 100);
    }
  }, [discountValues]);

  // Determina si el descuento es porcentaje o monto en función de discountApplied
  useEffect(() => {
    setIsPercentageDiscount(discountApplied < 100);
  }, [discountApplied]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handlePaymentSubmission = (event) => {
    if (!isChecked) {
      alert("Please check the box to confirm payment.");
      event.preventDefault(); // Prevent form submission
    }
  };

  const showAlerts = () => {
    if (!isChecked) {
      alert("Please check the box to confirm payment.");
    }
    // Add alerts for other input fields here if needed
  };

  useEffect(() => {
    const cursosContainer = document.querySelector(".cursos-container");
    if (cursosContainer) {
      // Verifica si la altura del contenido supera la altura máxima
      if (cursosContainer.scrollHeight > cursosContainer.clientHeight) {
        setScrollEnabled(true); // Habilita el scroll vertical
      } else {
        setScrollEnabled(false); // Deshabilita el scroll vertical
      }
    }
  }, [cartItems]); // Escucha cambios en cartItems

  const total = cartItems.reduce((acc, item) => {
    const quantity = quantitiesObject[item.id] || 1;
    return acc + item.precio_final * quantity;
  }, 0);

  const totalConDescuento = total - descuento;


  const handleCrearOrdenPago = async (numeroOrden) => {
    try {
      // Tu usuario y contraseña de autenticación
      const usuario = 'ecole';
      const contraseña = 'wxa66vea8uL16e2N';
  
      // URL de la API para crear una orden de pago
      const apiUrl = 'https://iopago.net/api-cert/io/pagos/crear';
      
    
    // Datos de la solicitud
    const datosSolicitud = {
      token,
      ordenPago: numeroOrden,
      total: totalConDescuento,
      emailCliente: formulario.email,
      resultadoURL,
      retornoURL,
    };

    // Configuración de la solicitud
    const config = {
      auth: {
        username: usuario,
        password: contraseña,
      },
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', // Reemplaza con el dominio permitido en producción
      },
    };
    // Realizar la solicitud POST a la API
    const response = await axios.post(apiUrl, datosSolicitud, config);
    
  
    const { codigoResultado, ordenPago, pagoURL } = response.data;

    window.location.href = pagoURL;


    setResultadoTransaccion(response.data);
   
   
    
    /* navigate('/resultado'); */
      // Puedes manejar la respuesta de la API según tus necesidades aquí
    } catch (error) {
      console.error("Error al crear la orden de pago:", error);
      mostrarError();
      // Registra la información detallada del error
      if (error.response) {
        // La solicitud se realizó y el servidor respondió con un código de estado
        // que está fuera del rango de 2xx
        console.error("Datos de respuesta:", error.response.data);
        console.error("Estado de respuesta:", error.response.status);
        console.error("Encabezados de respuesta:", error.response.headers);
        mostrarError();
      } else if (error.request) {
        // La solicitud se realizó pero no se recibió ninguna respuesta
        console.error("No se recibió respuesta, solicitud:", error.request);
        mostrarError();
      } else {
        // Algo sucedió al configurar la solicitud que desencadenó un Error
        console.error("Error al configurar la solicitud:", error.message);
        mostrarError();
      }
      // Puedes manejar los errores según tus necesidades aquí
    }
  };

  const aplicarDescuento = () => {
    // Realiza una solicitud al backend para verificar el código de descuento
    fetch(`${backendURL}/descuentos_validacion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ codigo_descuento: codigoDescuento }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("El código de descuento no es válido");
        }
        return response.json();
      })
      .then((data) => {
        const valorDescuento = data.valor_descuento;
        const cursoId = data.curso_id;

        // Busca el curso con el curso_id ingresado en cartItems
        const selectedCurso = cartItems.find((item) => item.id === cursoId);

        if (selectedCurso) {
          // El curso con curso_id ingresado está en el carrito, aplica el descuento
          const quantities = { ...productQuantities };
          const quantity = quantities[selectedCurso.id] || 1; // Toma la cantidad del producto

          // Realiza el cálculo del total con el descuento aplicado
          const totalWithoutDiscount = selectedCurso.precio_final * quantity;

          // Aplica el descuento al curso seleccionado
          const discountDecimal = parseFloat(valorDescuento) / 100;
          const discount = totalWithoutDiscount * discountDecimal;

          // Actualiza el estado del descuento solo para el curso seleccionado
          const updatedCartItems = cartItems.map((item) => {
            if (item.id === cursoId) {
              return {
                ...item,
                descuento: discount, // Agrega una propiedad descuento al curso
              };
            }
            return item;
          });

          // Calcula el nuevo descuento total sumando los descuentos de todos los cursos
          const newDescuento = updatedCartItems.reduce(
            (acc, item) => acc + (item.descuento || 0),
            0
          );

          // Actualiza el estado del descuento
          setDescuento(newDescuento);

          // Actualiza el estado de los cursos en el carrito
        }
      })
      .catch((error) => {
        console.error(error.message);
        // Maneja el error, por ejemplo, mostrando un mensaje al usuario
      });

    // Limpia el campo de descuento
    setCodigoDescuento("");
  };

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Actualiza ambos campos si el nombreInvitado está presente
    if (tarjetaRegaloInfoPresente && name === 'nombreInvitado') {
      setTarjetaRegaloInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
      
      setFormulario((prevFormulario) => ({
        ...prevFormulario,
        nombreCompleto: value,
      }));
    } else {
      // Si no es el nombreInvitado, actualiza el campo correspondiente
      setFormulario((prevFormulario) => ({
        ...prevFormulario,
        [name]: value,
      }));
    }
  };
  /* se crea orden de pago  */
    const CrearOrdenPago = async () => {
      try {
        const idsCursos = obtenerCursoId();
        const response = await axios.post(`${backendURL}/storeFactura`, {
          nombre: formulario.nombreCompleto,
          rut: formulario.rut,
          telefono: formulario.telefono,
          correo_compra: formulario.email,
          cursos: idsCursos, // Implementa la lógica para obtener el curso_id
          monto: totalConDescuento,
        });

        // Obtener los datos de la respuesta
    const factura = response.data.factura;

    // Puedes acceder a los datos de la factura aquí
    const facturaId = factura.id;
    const numeroOrden = factura.numero_orden;
    const correoCompra = factura.correo_compra;
    const estado = factura.estado;
    const rut = factura.rut;
    const nombre = factura.nombre;
    const telefono = factura.telefono;
    const fecha = factura.fecha;
    const cursos = factura.cursos;
        // Obtener el número de orden de la respuesta y actualizar el estado si es necesario
        
        setOrdenPago(numeroOrden);
        handleCrearOrdenPago(numeroOrden);


        
        mostrarPopup();
        // Puedes manejar la respuesta de la API según tus necesidades aquí
      } catch (error) {
        // Manejar errores
        console.error("Error al crear la orden de pago:", error);
        mostrarError();
      }
    };

    const handlePagarClick = () => {
      // Llama a la función CrearOrdenPago
      CrearOrdenPago();
    };

    const mostrarPopup = () => {
      // Muestra el diseño de procesamiento normal
      setMostrarPopup(true);
    };
    
    const mostrarError = () => {
      setMostrarErrorPopup(true);
    
      // Iniciar el conteo regresivo de 3 segundos
      let secondsLeft = 3;
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
        secondsLeft -= 1;
    
        // Verificar si el tiempo ha llegado a cero
        if (secondsLeft === 0) {
          clearInterval(countdownInterval);
          setMostrarErrorPopup(false);
    
          // Redirigir al usuario al inicio
          navigate('/');
        }
      }, 1000);
    };
  const cerrarPopup = () => {
    setMostrarPopup(false);
    setMostrarErrorPopup(false);
  };

  // Renderizar el componente del popup (puedes implementar tu propio componente de popup)
  const Popup = ({ onClose }) => {
    return (
      <div className="popups-admision">
        <button  className="buton-close-factura-proceso" onClick={onClose}>x</button>
        <div className="proceso-factura">
        
        <div className="loader">
     <div className="box-1">
     </div>
     <span>
         Procesando
     </span>
 </div>
      </div></div>
    );
  };

  const ErrorPopup = ({ onClose }) => (
    <div className="popups-admision">
      <button className="buton-close-factura-proceso" onClick={onClose}>x</button>
      <div className="proceso-factura">
      
  <div className="container orden-pago-error">
      <div className="circle-border"></div>
      <div className="circle">
          <div className="error"></div>
        </div>
      </div>
        <p className="error-orden-crear">Error en el proceso</p>
        <p className="error-orden-crear">Regresas al home en {countdown} segundos...</p>
      </div>
    </div>
  );


  const enviarRespuestaAPython = async (respuestaOrdenPago) => {
    try {
      const { ordenPago, codigoResultado } = respuestaOrdenPago;
  
      const response = await axios.post(`${backendURL}/updateEstado`, {
        numero_orden: ordenPago,
        estado: "aplica",
        codigoResultado: codigoResultado,
      });
  
      
      // Puedes manejar la respuesta según tus necesidades aquí
  
    } catch (error) {
      console.error('Error al enviar respuesta al servidor Python:', error);
      // Puedes manejar errores según tus necesidades aquí
    }
  };

  return (
    <>
      <section className="pago">
        <div className="container-fluid">
          <div className="row">
            <div className=" col-md-12 col-lg-6 pago">
              <section className="formulario-pago">
                <section className="titulo-pago">
                  <img src={imagenlogo} className="logo-pago" />
                </section>

                <div class="mercadouser-container">
                  <div id="mercadouser-checkout">
                    <h1 className="info-pago"> Información de pago</h1>

                    <form className="mercadouser-form" action="submit">
                      <div className="mercadouser-form-izquierda form-group">
                        <input
  className="form-control-pago"
  type="text"
  placeholder="Nombre Completo"
  name="nombreCompleto"
  value={formulario.nombreCompleto}
  onChange={handleInputChange}
/>
                      </div>
                      <div className="mercadouser-form-derecha form-group">
                        <div className="row input-pago">
                          <div className="col-6 col-sm-12 ">
                            {" "}
                            <input
                              className="form-control-pagos"
                              type="text"
                              placeholder="   Rut"
                              name="rut"
                              value={formulario.rut}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="col-6 col-sm-12 ">
                            {" "}
                            <input
                              className="form-control-pagos"
                              type="number"
                              placeholder="   Telefono Ej: 912345678"
                              name="telefono"
                              value={formulario.telefono}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mercadouser-form-izquierda form-group">
                        <input
                          className="form-control-pago"
                          type="email"
                          placeholder="   Correo Electronico"
                          name="email"
                          value={formulario.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      {tarjetaRegaloInfoPresente && (
                        <>
                          <input
                            className="form-control-pago"
                            type="text"
                            placeholder="Tu Nombre"
                            value={tuNombre}
                            onChange={(e) => setTuNombre(e.target.value)}
                          />
                         
                          <input
                            className="form-control-pago"
                            type="text"
                            placeholder="Dedicatoria"
                            value={dedicatoria}
                            onChange={(e) => setDedicatoria(e.target.value)}
                          />
                        </>
                      )}
                      <h1 className="info-pago-web"> Medio de pago</h1>
                      <div className="form-group-pago">
                        <div className="container-pago-boton-webpay">
                          <label className="webpay">
                            <input
                              type="checkbox"
                              style={{ display: "none" }}
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                            />
                            <div className="checkmarks"></div>
                          </label>
                        </div>{" "}
                        <label className="pago-webpay">Webpay</label>
                      </div>
                    </form>
                  </div>
                </div>
                <section className="buton-pago-home">
                  {" "}
                  <Link to="/cursos">
                    {" "}
                    <button className="buton-paho-home">
                      <div className="arrow-wrapper">
                        <div className="arrow"></div>
                        <p className="texto-carrito">Volver al carrito</p>
                      </div>
                    </button>
                  </Link>
                </section>
              </section>
            </div>

            <div className=" col-md-12 col-lg-6 pagos">
              <section className="detalle-pago">
                <h1 className="info-pago-dos">Detalle de compra </h1>
                <div className="mapeo-cursos-compra">
                  <div
                    className={`cursos-container ${
                      scrollEnabled ? "scrollable" : ""
                    }`}
                  >
                    {cartItems.map((item, index) => {
                      const quantity = quantitiesObject[item.id] || 1;
                      return (
                        <div className="curso-seleccionado" key={index}>
                          <div className="row carrito-cursodoss">
                            <div className="col-4 col-sm-12 col-md-4">
                              <div className="img-carritos">
                                {" "}
                                {item.imagen_principal_url && (
                                  <img
                                    src={item.imagen_principal_url}
                                    className="imagen-curso-seleccionado"
                                    alt="Imagen del curso"
                                  />
                                )}{" "}
                              </div>
                            </div>
                            <div className="col-6 col-sm-12 col-md-6">
                              <div className="nombre-curso-carrito">
                                <h3 className="categoria-curso-carritos">
                                  <p>{item.categoria}</p>
                                </h3>
                                <h2 className="nombre-curso-carritos">
                                  {item.nombre_curso}
                                </h2>
                                <p className="precio-curso-carrito">
                                  ({quantity})
                                </p>
                              </div>
                            </div>
                            <div className="col-2 col-sm-12 col-md-2">
                              <div className="cantidad-curso-carritodos">
                                <div className="quantity-controls">
                                  <span className="quantity">
                                    {" "}
                                    $ {item.precio_final.toLocaleString()}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <section className="descuento-dos">
                  <hr className="separador-carrito-dos" />
                  <div className="descuento-carritodos-pago">
                    <h1 className="titulo-descuento-pago">
                      ¿Tienes un cupón de descuento?
                    </h1>
                    <div className="row descuentos-dos-pagos">
                      <div className="col-10">
                        <input
                          className="descuentos-dos-pagos"
                          placeholder="  Código de descuento"
                          value={codigoDescuento}
                          onChange={(e) => setCodigoDescuento(e.target.value)}
                        />
                      </div>
                      <div className="col-2">
                        <button
                          className="usar-descuento-pagos"
                          onClick={aplicarDescuento}
                        >
                          Usar
                        </button>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="total-carrito-pago">
                  <div className="row precio-curso-pago">
                    <div className="col-6 curso-precio-nombre">Subtotal</div>
                    <div className="col-6 curso-precio-pago">
                      ${total.toLocaleString()}
                    </div>
                    <div className="col-6 curso-precio-nombre">Descuento</div>
                    <div className="col-6 curso-precio-pago">
                      -${descuento.toLocaleString()}
                    </div>
                  </div>
                </section>

                <hr className="separador-carrito-dos" />
                <section>
                  <section className="contienen-pago-final-pago">
                    <div className="row precio-curso">
                      <div className="col-6 curso-precio-nombre-pago">
                        Total a Pagar
                      </div>
                      <div className="col-6 curso-precio">
                        <div className="cantidad-pago">
                          ${totalConDescuento.toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className=" button-pagar-pago">
                    <button
                      className="comprar-continuars"
                      onClick={handlePagarClick}
                    >
                      Pagar
                    </button>
                  </section>
                 
                </section>
              </section>
            </div>
          </div>
        </div>
      </section> {mostrarPopupState && <Popup onClose={cerrarPopup} />}
      {mostrarErrorPopup && <ErrorPopup onClose={cerrarPopup} />}
      
    </>
  );
};
