import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../../styles/estructura/estructips.css";
/* componenetes */
import { PortadaEstructuratips } from "./componenetes estructura tips/portada-arttips";
import { Bienvenidatips } from "./componenetes estructura tips/bienvenida-arttips";
import { Infoestructips } from "./componenetes estructura tips/info-arttips";
import { Articuloblog } from "./componente estrcublog/articulo-relacionado";
/* imagenes */
import bannerblog from "../../img/blog/bannerblog.png";

export const EstructuraTips = () => {
  const { id } = useParams();
  const [recetaData, setRecetaData] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    obtenerDatosreceta();
  }, []);

  const obtenerDatosreceta = async () => {
    try {
      const response = await axios.get(
        `${backendURL}/obtener_recetas/${id}`
      );
      const receta = response.data;
      setRecetaData(receta);
    } catch (error) {
      console.error(error);
    }
  };

  if (!recetaData) {
    // Aquí podrías mostrar un spinner o un mensaje de carga mientras se obtienen los datos
    return <div>Cargando...</div>;
  }

  return (
    <div>
      <section className=" portada arttips">
       <PortadaEstructuratips  receta={recetaData} />
      </section>
      
      <section className="info arttips">
        <Infoestructips receta={recetaData} />
      </section>
      
      <section className="articulo arttips">
        <Articuloblog />
      </section>
    </div>
  );
};
