import React, { useState } from "react";
import axios from "axios";

export const BDeportiva = () => {
    const [selectedFile, setSelectedFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      alert("Please select a file");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(`${backendURL}/pdfdeportiva`, formData);

      const data = response.data;
      if (data.pdf_url) {
        setPdfUrl(data.pdf_url);
        setErrorMessage(null);
      } else {
        setErrorMessage("Conversion to PDF failed");
        setPdfUrl(null);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Something went wrong");
      setPdfUrl(null);
    }
  };
  

    return(
        <>
        <section>
        <h2>Crear Beca Deportiva</h2>
      
      </section><form onSubmit={handleSubmit}>
        <div>
          <label className="titulo-input-admi" htmlFor="imagen_admision">
            Imagen:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagen_admision"
            onChange={handleFileChange}
          />
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>
      </form>
      
      {pdfUrl && (
        <div>
          <p>PDF creado exitosamente.</p>
          <a href={`${backendURL}/static/pdfDeportiva/BecaDeportiva.pdf`} target="_blank" rel="noopener noreferrer">
            Ver PDF
          </a>
        </div>
      )}
      
      {errorMessage && (
        <div>
          <p>Error: {errorMessage}</p>
        </div>
      )}
    </>
  );
};