import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPlayer from 'react-player';
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import { useParams } from "react-router-dom";
import portadanoticia from "../../img/noticias/portadanoticia.jpg";
import noticia2 from "../../img/noticias/NOTICIAS-MOIVL.jpg"
import "../../styles/noticias/noticias.css";
import calendario from "../../img/noticias/calendario.png"

export const Viewnoticia = () => {
  const { id } = useParams();
  const [noticiaData, setnoticiaData] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Función para actualizar el estado de isMobileView en función del ancho de la pantalla
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Cambia a "true" si el ancho es menor o igual a 700px
    };

    // Escucha el evento de redimensionamiento de la ventana
    window.addEventListener("resize", handleResize);

    // Llama a handleResize() una vez al cargar la página para establecer el estado inicial
    handleResize();

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    obtenerDatosNoticia();
  }, []);

  const  obtenerDatosNoticia = async () => {
    try {
      const response = await axios.get(
        `${backendURL}/obtener_noticias/${id}`
      );
      const noticia = response.data;
      setnoticiaData(noticia);
    } catch (error) {
      console.error(error);
    }
  };

  if (!noticiaData) {
    // Aquí podrías mostrar un spinner o un mensaje de carga mientras se obtienen los datos
    return <div>Cargando...</div>;
  }

  return (
    <div  className="todo-home">
      <div className="portadas-general">
        <img
          src={isMobileView ? noticia2 : portadanoticia}
          className="w-100 noticia-portada-estructura"
        />
      </div>
      <section className=" container estructura-noticia">
        <div className="titulo-estructura-noticia">
          <h1 className="titulo-estructura-noticias">
          {noticiaData.titulo_principal}
          </h1>
        </div>
        <div className=" container calendario-publicidad">
            <div className="row estructura-noticia">
                <div className="col-1 estructura-noticia">
                    <img src={calendario} className="w-50 icono-calendario" />
                </div>
                <div className="col-11 estructura-noticia">
                    <h2 className="titulo-calendario-publicidad"> PUBLICADO EL {noticiaData.fecha}</h2>
                </div> </div>
<section className="imagen-noticia-portada-selecion">
    <img src={`${backendURL}/imagen_noticia/${noticiaData.imagen}`} className=" imagen-estructura-noticia" />
</section>
<section className="informacion-estructura-noticia-selecion">
    <h3 className="informacion-estructura-noticia-selecion">{noticiaData.descripcion_principal}</h3>
    <p className="informacion-estructura-noticia-selecion"><DangerouslySetHTMLContent html={noticiaData.descripcion_noticias}/></p>
  
</section>
<section className="video-noticia">
   <ReactPlayer
              url={noticiaData.url_video} // Utiliza la URL del video seleccionado
              className="react-player video pasado"
              controls
              loop
              
            /></section>
           
        </div>
      </section>
    </div>
  );
};
