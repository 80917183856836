import React from "react";
import { useParams } from "react-router-dom";

import "../../../styles/estructura/estruccursos.css";


/* imagenes */

import persona from"../../../img/estructuras/blog/cursos/cantidad.png"
import calendario from"../../../img/estructuras/blog/cursos/calen.png"
import tiempo from"../../../img/estructuras/blog/cursos/tiempo.png"
import ubicacion from"../../../img/estructuras/blog/cursos/ubicacion.png"
import precio from "../../../img/estructuras/blog/cursos/precio.png";




export const BienvenidaEstructuraCursos = ({ curso }) => {
 
  return (
    <div>
     
      <section className="container-xl info-bienvenida-estructura-cursos">
        <div className="card bienvenida-estructura-cursos">
          <div className="row info-bienvenida-estructura-cursos">
            <div className="col col-xs-12 col-sm-6 col-md-2 icono-bienvenida-estructura-cursos ">
              <div className="icono-estructura-cursos">
              <img src={persona} className="w-100 icono-estructura-cursos" /></div>
              <div className="info-icono-cursos">
                <h4 className="info-icono-cursos">Cupos</h4>
                <h5 className="info-icono-cursos">{curso.participantes}</h5>
              </div>
            </div>
            <div className="col col-xs-12 col-sm-6 col-md-2 icono-bienvenida-estructura-cursos ">
            <div className="icono-estructura-cursos-p">
              <img src={tiempo} className="w-100 icono-estructura-cursos" /></div>
              <div className="info-icono-cursos">
                <h4 className="info-icono-cursos">Horas</h4>
                <h5 className="info-icono-cursos">{curso.horas}</h5>
              </div>
            </div>
            <div className="col col-xs-12 col-sm-6 col-md-2 icono-bienvenida-estructura-cursos">
            <div className="icono-estructura-cursos-p">
              <img src={calendario} className="w-100 icono-estructura-cursos" /></div>
              <div className="info-icono-cursos">
                <h4 className="info-icono-cursos">Días</h4>
                <h5 className="info-icono-cursos">{curso.secciones}</h5>
              </div>
            </div>
            <div className="col col-xs-12 col-sm-6 col-md-2 icono-bienvenida-estructura-cursos">
            <div className="icono-estructura-cursos-p">
              <img src={calendario} className="w-100 icono-estructura-cursos" /></div>
              <div className="info-icono-cursos">
                <h4 className="info-icono-cursos">Fecha</h4>
                <h5 className="info-icono-cursos">{curso.fecha_curso}</h5>
              </div>
            </div>
            <div className="col col-xs-12 col-sm-6 col-md-2 icono-bienvenida-estructura-cursos">
            <div className="icono-estructura-cursos">
              <img src={ubicacion} className="w-100 icono-estructura-cursos" /></div>
              <div className="info-icono-cursos">
                {" "}
                <h4 className="info-icono-cursos">Modalidad</h4>
                <h5 className="info-icono-cursos">{curso.modalidad} </h5>
              </div>
            </div>
            <div className="col col-xs-12 col-sm-6 col-md-2 icono-bienvenida-estructura-cursos">
            <div className="icono-estructura-cursos">
              <img src={precio} className="w-100 icono-estructura-cursos" /></div>
              <div className="info-icono-cursos">
                {" "}
                <h4 className="info-icono-cursos">Precio curso</h4>
                <h5 className="info-icono-cursos">${curso.precio_final.toLocaleString()} </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
