import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const Botoncrearcursosydiplomado = ({ onClose }) => {
  const [imagenPortadaPrincipal, setImagenPortadaPrincipal] = useState(null);
  const [imagenTituloPortada, setImagenTituloPortada] = useState(null);
  const [imagenPortadaCurso, setImagenPortadaCurso] = useState(null);
  const [imagenresponsivoCurso, setImagenresponsivoCurso] = useState(null);
  const [nombreCurso, setNombreCurso] = useState("");
  const [pieCursoPortada, setPieCursoPortada] = useState("");
  const [categoria, setCategoria] = useState("");
  const [fechaCurso, setFechaCurso] = useState("");
  const [precioCurso, setPrecioCurso] = useState(0);
  const [participantes, setParticipantes] = useState(0);
  const [horas, setHoras] = useState(0);
  const [secciones, setSecciones] = useState(0);
  const [modalidad, setModalidad] = useState("");
  const [descripcionCurso, setDescripcionCurso] = useState("");
  const [horario, setHorario] = useState("");
  const [descuento, setDescuento] = useState(0);
  const [precioFinal, setPrecioFinal] = useState(0);
  const [chefs, setChefs] = useState([]);
  const [chefId, setChefId] = useState(0);
  const [esDiplomado, setEsDiplomado] = useState(false);
  const [esCurso, setEsCurso] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }], 
    ['link'],                        // text direction
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [
      { 'color': ['black','#4444','#696965','Dark Gray', 'grey','red','Indian Red', 'blue','Royal Blue', 'green','white','violet','aqua','orange','yellow','pink','purple', 'Turquoise', 'Teal', 'Lime', 'Cyan', 'Magenta', 'Brown', 'Maroon', 'Silver'] },  // Specify custom colors
      { 'background': ['#696965', 'yellow', 'pink', 'orange'] }  // Specify custom background colors
    ],
    [{ 'font': [] }],
    [
      { 'align': [] },
      { 'align': ['center', 'right', 'justify'] }  // Additional alignment options
    ],
  
    ['clean']   
  ];
  const module = {
    toolbar: toolbarOptions,
  };

  useEffect(() => {
    const obtenerChefs = async () => {
      try {
        const response = await axios.get(`${backendURL}/obtener_chefs`);
        setChefs(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    obtenerChefs();
  }, []);

  useEffect(() => {
    const calcularPrecioFinal = () => {
      const descuentoDecimal = Math.max(descuento, 0) / 100;
      const precioConDescuento = precioCurso * (1 - descuentoDecimal);
      setPrecioFinal(precioConDescuento);
    };

    calcularPrecioFinal();
  }, [descuento, precioCurso]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("imagen_portada_principal", imagenPortadaPrincipal);
    formData.append("imagen_titulo_portada", imagenTituloPortada);
    formData.append("imagen_portada_curso", imagenPortadaCurso);
    formData.append("imagen_responsive_curso", imagenresponsivoCurso);
    formData.append("nombre_curso", nombreCurso);
    formData.append("pie_curso_portada", pieCursoPortada);
    formData.append("categoria", categoria);
    formData.append("fecha_curso", fechaCurso);
    formData.append("precio_curso", precioCurso);
    formData.append("participantes", participantes);
    formData.append("horas", horas);
    formData.append("secciones", secciones);
    formData.append("modalidad", modalidad);
    formData.append("descripcion_curso", descripcionCurso);
    formData.append("horario", horario);
    formData.append("descuento", descuento);
    formData.append("precio_final", precioFinal);
    formData.append("chef_id", chefId);

    try {
      const response = await axios.post(
        `${backendURL}/guardar_cardcursoydiplo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setEsDiplomado(response.data.es_diplomado);
      setEsCurso(response.data.es_curso);

      setImagenPortadaPrincipal(null);
      setImagenTituloPortada(null);
      setImagenPortadaCurso(null);
      setImagenresponsivoCurso(null);
      setNombreCurso("");
      setPieCursoPortada("");
      setCategoria("");
      setFechaCurso("");
      setPrecioCurso(0);
      setParticipantes(0);
      setHoras(0);
      setSecciones(0);
      setModalidad("");
      setDescripcionCurso("");
      setHorario("");
      setDescuento(0);
      setPrecioFinal(0);
      setChefId(0);

      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="contenciondelformadmi">
      <form className="contenido-admi" onSubmit={handleSubmit}>
        <div>
          <label className="titulo-input-admi" htmlFor="imagenPortadaPrincipal">
            Imagen Portada Principal:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagenPortadaPrincipal"
            onChange={(e) => setImagenPortadaPrincipal(e.target.files[0])}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagenTituloPortada">
            Imagen Portada Título:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagenTituloPortada"
            onChange={(e) => setImagenTituloPortada(e.target.files[0])}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagenPortadaCurso">
            Imagen Portada Curso:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagenPortadaCurso"
            onChange={(e) => setImagenPortadaCurso(e.target.files[0])}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="imagenPortadaCurso">
            Imagen Responsivo Curso:
          </label>
          <input
            className="input-admi"
            type="file"
            id="imagenPortadaCurso"
            onChange={(e) => setImagenresponsivoCurso(e.target.files[0])}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="nombreCurso">
            Nombre del Curso:
          </label>
          <input
            className="input-admi"
            type="text"
            id="nombreCurso"
            value={nombreCurso}
            onChange={(e) => setNombreCurso(e.target.value)}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="pieCursoPortada">
            Pie de Página de la Portada:
          </label>
          <input
            className="input-admi"
            type="text"
            id="pieCursoPortada"
            value={pieCursoPortada}
            onChange={(e) => setPieCursoPortada(e.target.value)}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="categoria">
            Categoría:
          </label>
          <select
            className="input-admi"
            id="categoria"
            value={categoria}
            onChange={(e) => setCategoria(e.target.value)}
          >
            <option value="">Seleccionar categoría</option>
            <option value="curso">Curso</option>
            <option value="diplomado">Diplomado</option>
          </select>
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="fechaCurso">
            Fecha del Curso:
          </label>
          <input
            className="input-admi"
            type="text"
            id="fechaCurso"
            value={fechaCurso}
            onChange={(e) => setFechaCurso(e.target.value)}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="precioCurso">
            Precio del Curso:
          </label>
          <input
            className="input-admi"
            type="number"
            id="precioCurso"
            value={precioCurso}
            onChange={(e) => setPrecioCurso(Number(e.target.value))}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="participantes">
            Participantes:
          </label>
          <input
            className="input-admi"
            type="number"
            id="participantes"
            value={participantes}
            onChange={(e) => setParticipantes(Number(e.target.value))}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="horas">
            Horas:
          </label>
          <input
            className="input-admi"
            type="number"
            id="horas"
            value={horas}
            onChange={(e) => setHoras(Number(e.target.value))}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="secciones">
            Secciones:
          </label>
          <input
            className="input-admi"
            type="number"
            id="secciones"
            value={secciones}
            onChange={(e) => setSecciones(Number(e.target.value))}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="modalidad">
            Modalidad:
          </label>
          <select
            className="input-admi"
            id="modalidad"
            value={modalidad}
            onChange={(e) => setModalidad(e.target.value)}
          >
            <option value="">Seleccionar modalidad</option>
            <option value="presencial">Presencial</option>
            <option value="semipresencial">Semipresencial</option>
            <option value="online">Online</option>
          </select>
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="descripcionCurso">
            Descripción del Curso:
          </label>

          <ReactQuill
            modules={module}
            theme="snow"
            className="input-admi"
            id="descripcionCurso"
            value={descripcionCurso}
            onChange={setDescripcionCurso}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="horario">
            Horario:
          </label>
          <input
            className="input-admi"
            type="text"
            id="horario"
            value={horario}
            onChange={(e) => setHorario(e.target.value)}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="descuento">
            Descuento (%):
          </label>
          <input
            className="input-admi"
            type="number"
            id="descuento"
            value={descuento}
            onChange={(e) => setDescuento(Number(e.target.value))}
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="precioFinal">
            Precio Final:
          </label>
          <input
            className="input-admi"
            type="number"
            id="precioFinal"
            value={precioFinal}
            disabled
          />
        </div>
        <div>
          <label className="titulo-input-admi" htmlFor="chefId">
            Chef:
          </label>
          <select
            className="input-admi"
            id="chefId"
            value={chefId}
            onChange={(e) => setChefId(Number(e.target.value))}
          >
            <option value="">Seleccionar chef</option>
            {chefs.map((chef) => (
              <option key={chef.id} value={chef.id}>
                {chef.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="boton-guardar">
          <button className="boton-crear" type="submit">
            Guardar
          </button>
        </div>

        {esDiplomado && (
          <div>
            <h3>Es un Diplomado</h3>
          </div>
        )}

        {esCurso && (
          <div>
            <h3>Es un Curso</h3>
          </div>
        )}
      </form>
    </div>
  );
};
